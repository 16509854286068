const Pera = () => {
    return (
        <svg
            width="61"
            height="24"
            viewBox="0 0 61 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.751 7.79802V7.42206H31.2012V17.4488H32.751V14.7471C32.751 14.4547 32.751 14.2179 32.7228 13.8976H32.751C33.3146 14.8168 34.2726 15.3041 35.3997 15.3041C37.3017 15.3041 38.9642 13.8698 38.9642 11.2099C38.9642 8.60574 37.3017 7.19922 35.3997 7.19922C34.3149 7.19922 33.3568 7.67268 32.751 8.60574H32.7228C32.751 8.29938 32.751 8.07654 32.751 7.79802ZM35.0052 13.9812C33.5681 13.9673 32.7369 12.7557 32.7369 11.196C32.7369 9.71982 33.5681 8.53614 35.0052 8.52222C36.4141 8.50824 37.344 9.59448 37.344 11.2099C37.344 12.8671 36.4141 13.9951 35.0052 13.9812Z"
                fill="#F0F0FA"
            />
            <path
                d="M47.1024 10.5554C47.1024 8.63358 45.5526 7.19922 43.4392 7.19922C41.185 7.19922 39.6211 8.71716 39.6211 11.2517C39.6211 13.6888 41.1568 15.3041 43.4392 15.3041C45.3131 15.3041 46.7079 14.2179 47.0319 12.7279H45.3413C45.0735 13.4798 44.3409 13.9812 43.4392 13.9812C42.2839 13.9812 41.4386 13.1596 41.2554 11.7948H47.1024V10.5554ZM43.4392 8.52222C44.5805 8.52222 45.3694 9.30204 45.5385 10.43H41.2695C41.4668 9.3438 42.2699 8.52222 43.4392 8.52222Z"
                fill="#F0F0FA"
            />
            <path
                d="M48.2402 15.0814H49.79V10.7643C49.79 9.28812 50.6072 8.52222 51.8752 8.52222H52.7065V7.19922H52.0443C50.903 7.19922 50.1986 7.9512 49.79 8.60574H49.7618V7.42206H48.2402V15.0814Z"
                fill="#F0F0FA"
            />
            <path
                d="M59.9528 13.7444C59.7415 13.7444 59.6429 13.6191 59.6429 13.3684V9.99834C59.6429 8.38296 58.8539 7.19922 56.5292 7.19922C54.275 7.19922 53.2464 8.3133 53.1338 9.90084H54.6836C54.7821 9.03744 55.4725 8.52222 56.5292 8.52222C57.4309 8.52222 58.0508 8.9121 58.0508 9.52488C58.0508 10.0262 57.6986 10.3326 56.5433 10.3326H55.9234C54.0777 10.3326 52.8379 11.0707 52.8379 12.7557C52.8379 14.5243 54.1482 15.332 55.6839 15.332C56.8251 15.332 57.7972 14.8307 58.1776 13.7584C58.2199 14.5382 58.7412 15.0814 59.7274 15.0814H60.6009V13.7444H59.9528ZM58.0931 11.5163C58.0931 13.2431 57.1491 13.9951 55.9374 13.9951C54.8808 13.9951 54.4581 13.3823 54.4581 12.7557C54.4581 12.1569 54.8244 11.6695 55.9515 11.6695H56.177C57.2618 11.6695 57.9381 11.2934 58.079 10.639H58.0931V11.5163Z"
                fill="#F0F0FA"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.8 0H7.20001C3.22355 0 0 3.22355 0 7.2V16.8C0 20.7765 3.22355 24 7.20001 24H16.8C20.7765 24 24 20.7765 24 16.8V7.2C24 3.22355 20.7765 0 16.8 0ZM12.8779 6.93797C13.1932 8.22323 13.0865 9.35381 12.6398 9.46319C12.193 9.57251 11.5751 8.61923 11.2598 7.33403C10.9445 6.04877 11.0511 4.91819 11.4979 4.80884C11.9447 4.69948 12.5625 5.65274 12.8779 6.93797ZM14.9811 8.48413C15.9966 7.51255 17.3859 7.31521 18.0842 8.04331C18.7825 8.77147 18.5254 10.1493 17.5099 11.1209C16.4945 12.0924 15.1052 12.2898 14.4069 11.5617C13.7086 10.8336 13.9657 9.45571 14.9811 8.48413ZM12.5297 19.1922C12.9765 19.0829 13.0674 17.8884 12.7328 16.5242C12.3981 15.1601 11.7646 14.1428 11.3178 14.2522C10.871 14.3616 10.7801 15.5561 11.1147 16.9202C11.4494 18.2844 12.0829 19.3016 12.5297 19.1922ZM10.0792 10.1758C10.2064 9.74047 9.26443 9.08437 7.97539 8.71039C6.68635 8.33641 5.53834 8.38621 5.4112 8.82151C5.28407 9.25687 6.22597 9.91297 7.51501 10.2869C8.80405 10.6609 9.95209 10.6112 10.0792 10.1758ZM16.3416 13.6246C17.7098 14.0216 18.7158 14.6963 18.5887 15.1317C18.4615 15.567 17.2493 15.5982 15.8812 15.2012C14.513 14.8043 13.507 14.1296 13.6342 13.6942C13.7612 13.2589 14.9734 13.2277 16.3416 13.6246ZM7.08425 13.772C8.05793 12.8607 9.10643 12.3863 9.42611 12.7122C9.74573 13.0383 9.21551 14.0413 8.24183 14.9526C7.26809 15.8639 6.21959 16.3383 5.89994 16.0123C5.58028 15.6863 6.11051 14.6833 7.08425 13.772Z"
                fill="#F0F0FA"
            />
        </svg>
    );
};

export default Pera;
