import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Fonts from "../utils/Fonts";

import AboutImage from "../assets/encourage.png";
import { useTranslation } from "react-i18next";

function AboutUs({ isMobile }) {
    const [t, i18n] = useTranslation("common");

    return (
        <Grid
            container
            marginTop={isMobile ? "48px" : "100px"}
            style={{
                display: "flex",
                padding: isMobile ? "20px 30px" : "64px",
                alignItems: isMobile ? "flex-start" : "center",
                justifyContent: "center",
                flexDirection: isMobile ? "column" : "row",
                width: '100vw'
            }}
            
        >
            <Grid
                xs={12}
                md={'auto'}
                marginRight={isMobile ? "24px" : "48px"}
            >
                <img
                    src={
                        AboutImage
                    }
                    alt="musii"
                    style={{
                        width: isMobile ? "256px" : "466px",
                        marginTop: isMobile ? "24px" : "0px",
                        borderRadius: "48px",
                    }}
                />
            </Grid>
            <Grid
                xs={12}
                md={5.5}
                xl={4}
            >
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h2BoldSize2,
                        color: "#ABABCC",
                        whiteSpace: "break-spaces",
                    }}
                >
                    {t('aboutUs')}
                </Typography>
                <Box
                    style={{
                        width: "35px",
                        height: "6px",
                        background:
                            "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                        borderRadius: "3px",
                        marginTop: "8px",
                        marginBottom: "16px",
                    }}
                ></Box>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].h1BoldSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces",
                        marginTop: 4,
                        marginBottom: 4,
                    }}
                >
                    {t('aboutUsTitle')}
                </Typography>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h3RegularSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces"
                    }}
                >
{t('aboutUsDesc')}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default AboutUs;
