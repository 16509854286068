const Video = () => {
    return (
        <svg
            width="40"
            height="23"
            viewBox="0 0 40 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M38.8397 1.27518C38.1109 0.889427 37.2536 0.93219 36.5676 1.36101L29.4943 5.86208V5.21916C29.4943 2.5183 27.308 0.332031 24.6071 0.332031H4.88713C2.18627 0.332031 0 2.56133 0 5.21916V18.08C0 20.7809 2.18627 22.9672 4.88713 22.9672H24.6071C27.308 22.9672 29.4943 20.7809 29.4943 18.08V17.4799L36.5249 21.9382C36.9106 22.1953 37.2964 22.2812 37.7252 22.2812C38.111 22.2812 38.4967 22.1953 38.797 22.024C39.5685 21.6382 39.9973 20.8236 39.9973 20.0091V3.28998C40.0401 2.43266 39.5685 1.66086 38.8398 1.27511L38.8397 1.27518ZM38.1537 19.9663C38.1537 20.1377 38.0679 20.2666 37.8965 20.3521C37.7679 20.4379 37.6394 20.3948 37.5108 20.3521L29.4513 15.2505V8.0913L37.5108 2.90393C37.6394 2.81811 37.7679 2.81811 37.8965 2.90393C38.0251 2.98975 38.1109 3.11834 38.1109 3.28969L38.1537 19.9663ZM27.6507 5.21919V18.0801C27.6507 19.752 26.2787 21.124 24.6068 21.124H4.88676C3.21484 21.124 1.84286 19.752 1.84286 18.0801L1.84316 5.21919C1.84316 3.54727 3.21514 2.17529 4.88705 2.17529H24.6071C26.279 2.21835 27.6507 3.58999 27.6507 5.21919Z"
                fill="#C0BECB"
            />
        </svg>
    );
};

export default Video;
