const Plus = ({ size = 20 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0013 1.66797C9.08083 1.66797 8.33464 2.41416 8.33464 3.33464V8.33464H3.33464C2.41416 8.33464 1.66797 9.08083 1.66797 10.0013C1.66797 10.9218 2.41416 11.668 3.33464 11.668H8.33464V16.668C8.33464 17.5884 9.08083 18.3346 10.0013 18.3346C10.9218 18.3346 11.668 17.5884 11.668 16.668V11.668H16.668C17.5884 11.668 18.3346 10.9218 18.3346 10.0013C18.3346 9.08083 17.5884 8.33464 16.668 8.33464H11.668V3.33464C11.668 2.41416 10.9218 1.66797 10.0013 1.66797Z"
                fill="#F0F0FA"
            />
        </svg>
    );
};

export default Plus;
