import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import Close from "../../assets/close";
import Fonts from "../../utils/Fonts";

import axios from "axios";
import StorageEnum from "../../enums/StorageEnum";
import { useUser } from "../../hooks/useUser";
import Storage from "../../utils/Storage";
import {
    VisibilityOffOutlined,
    VisibilityOutlined,
} from "@mui/icons-material";
import Password from "../../assets/password";
import Email from "../../assets/email";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dev from "../../utils/Dev";
import AlertSuccess from "./AlertSuccess";

function AlertLoginEmail({ isMobile, open, handleClose }) {
    const { logIn } = useUser();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [messagePassword, setMessagePassword] = useState("");

    const [validateEmail, setValidateEmail] = useState(false);
    const [validatePassword, setValidatePassword] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    async function login() {
        if (!validateEmail && !validatePassword && password !== "") {
            setIsLoading(true);
            try {
                const response = await axios.post(
                    `${Dev.api}nft/signin`,
                    {
                        email: email.toLowerCase(),
                        password,
                        name: email.toLowerCase()
                    },
                    {
                        headers: {
                            "Content-Type": "application/json;charset=utf-8",
                        },
                        crossDomain: true,
                    }
                );

                setValidatePassword(false);

                if (response.data.user) {
                    await Storage.setItem(StorageEnum.USER, {
                        ...response.data.user,
                        token: response.data.token,
                    });
                } else {
                    await Storage.setItem(StorageEnum.USER, {
                        email: email.toLowerCase(),
                        password,
                        name: email.toLowerCase(),
                        token: response.data.token,
                    });
                }

                logIn();
                setEmail("");
                setPassword("");
                setValidateEmail(false)
                setValidatePassword(false);
                setIsLoading(false);
                handleClose();
            } catch (e) {
                setMessagePassword(t('possibleErrorsLogin'))
                setIsLoading(false);
                setValidatePassword(true);
            }
        } else {
            setMessagePassword(t('possibleErrorsLogin'))
            setIsLoading(false);
            setValidatePassword(true);
        }
    }

    return (
        <>
            {open && (
                <Box>
                    <AlertSuccess
                        isMobile={isMobile}
                        open={openModal}
                        handleClose={() => {
                            setOpenModal(false)
                            handleClose()
                        }}
                        title={"E-mail Sent"}
                    />
                    <Box
                        style={{
                            position: "absolute",
                            width: "100vw",
                            height: "100vh",
                            background: "#121229",
                            mixBlendMode: "normal",
                            opacity: "0.75",
                            top: 0,
                            left: 0,
                            zIndex: 9999,
                        }}
                    ></Box>
                    <Grid
                        container
                        paddingX={"48px"}
                        paddingY={"40px"}
                        zIndex={99}
                        style={{
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            flexWrap: "nowrap",
                            borderRadius: 16,
                            display: "flex",
                            alignItems: "center",
                            maxWidth: isMobile ? "93vw" : "50vw",
                            maxHeight: isMobile ? "94vh" : "",
                            flexDirection: "column",
                            justifyContent: isMobile ? "center" : "",
                            zIndex: 99999,
                            background:
                                "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                        }}
                    >
                        <Box
                            onClick={() => {
                                setEmail("");
                                setPassword("");
                                setValidateEmail(false)
                                setValidatePassword(false);
                                setIsLoading(false);
                                handleClose();
                            }}
                            style={{
                                position: "absolute",
                                right: "32px",
                                top: "32px",
                                cursor: "pointer",
                            }}
                        >
                            <Close />
                        </Box>
                        <Grid xs={12} md={12} style={{
                            marginTop: "32px"
                        }}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize1,
                                    color: "#ABABCC",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                {t('btLogIn')}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={12}
                            marginBottom={"32px"}
                            marginTop={"24px"}
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                flexDirection: isMobile ? "column" : "",
                            }}
                        >
                            <Grid
                                xs={12}
                                md={5.3}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h3BoldSize1,
                                        color: "#ABABCC",
                                        padding: "16px 20px",
                                        flex: 1,
                                    }}
                                >
                                    Email
                                </Typography>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Email />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    type="email"
                                    placeholder={"email@provider.com"}
                                    value={email}
                                    error={validateEmail}
                                    fullWidth
                                    helperText={
                                        validateEmail ? "Invalid email." : ""
                                    }
                                    onBlur={() => {
                                        if (
                                            !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                                                email
                                            )
                                        ) {
                                            setValidateEmail(true);
                                        } else {
                                            setValidateEmail(false);
                                        }
                                    }}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setEmail(value.toLowerCase());
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        padding: "16.5px 20px",
                                        border: "0px solid #transparent",
                                        borderRadius: "24px",
                                        borderBottomWidth: 0,
                                        maxWidth: "90%",
                                        background:
                                            "rgba(18, 18, 41, 0.25)",
                                        "& .MuiInputBase-input": {
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].b1RegularSize1,
                                            color: "#B8B8DB",
                                        },
                                    }}
                                />
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].l1LightSize1,
                                        color: "#B8B8DB",
                                        textAlign: "left",
                                        padding: "16px 20px",
                                    }}
                                >
                                    {t('purchaseFlowEmailInfo')}
                                </Typography>
                            </Grid>
                            <Grid
                                xs={12}
                                md={5.3}
                                marginTop={isMobile ? "24px" : "0px"}
                                display={"flex"}
                                flexDirection={"column"}
                                marginLeft={isMobile ? "0px" : "40px"}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h3BoldSize1,
                                        color: "#ABABCC",
                                        padding: "16px 20px",
                                        flex: 1,
                                    }}
                                >
                                    {t('textFieldPassword')}
                                </Typography>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Password />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOffOutlined htmlColor="#B8B8DB" />
                                                    ) : (
                                                        <VisibilityOutlined htmlColor="#B8B8DB" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    placeholder={"********"}
                                    value={password}
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    onBlur={(e) => {
                                        if (
                                            !/(?=.*\d)(?=.*[A-z]).{8,}/.test(
                                                password
                                            )
                                        ) {
                                            setValidatePassword(true);
                                            setMessagePassword(t('passwordWithoutRules'))
                                        } else {
                                            setValidatePassword(false);
                                        }
                                    }}
                                    inputProps={{ maxLength: 50 }}
                                    error={validatePassword}
                                    helperText={
                                        validatePassword
                                            ? messagePassword
                                            : ""
                                    }
                                    sx={{
                                        boxShadow: 0,
                                        padding: "16.5px 20px",
                                        border: "0px solid #transparent",
                                        borderRadius: "24px",
                                        borderBottomWidth: 0,
                                        maxWidth: "90%",
                                        background:
                                            "rgba(18, 18, 41, 0.25)",
                                        "& .MuiInputBase-input": {
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].b1RegularSize1,
                                            color: "#B8B8DB",
                                        },
                                    }}
                                />
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].l1LightSize1,
                                        color: "#B8B8DB",
                                        textAlign: "left",
                                        padding: "16px 20px",
                                    }}
                                >
                                    {t('passwordWithoutRules')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={10} xl={9} marginBottom={"24px"} width={"inherit"}>
                            <Button
                                onClick={login}
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                    color: "#F0F0FA",
                                    background:
                                        !validateEmail && !validatePassword ? "linear-gradient(130.54deg, rgba(137, 48, 240, 0.8) 0%, rgba(94, 71, 214, 0.8) 102.22%), rgba(240, 240, 250, 0.05)" : "#B8B8DB",
                                    borderRadius: "40px",
                                    padding: "16px 32px",
                                    textTransform: 'uppercase'
                                }}
                                fullWidth
                                variant="contained"

                            >
                                {t('btLogIn')}
                            </Button>
                        </Grid>
                        <Grid xs={12} md={10} xl={9} marginBottom={"24px"} width={"inherit"}>
                            <Button
                                onClick={async () => {
                                    if (email === "") {
                                        setValidateEmail(true)
                                        return
                                    }
                                    try {
                                        const response = await axios.put(
                                            `${Dev.api}nft/user/user/create-code`,
                                            {
                                                email: email.toLowerCase(),
                                            },
                                            {
                                                headers: {
                                                    "Content-Type": "application/json;charset=utf-8",
                                                },
                                                crossDomain: true,
                                            }
                                        );

                                        setOpenModal(true)
                                        console.log(response)
                                    } catch {
                                        setMessagePassword(t('possibleErrorsForgot'))
                                        setIsLoading(false);
                                        setValidatePassword(true);
                                    }

                                    //navigate('/reset-password')
                                }}
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                    color: "#121229",
                                    background: "#7272A3",
                                    borderRadius: "40px",
                                    padding: "16px 32px",
                                }}
                                fullWidth
                                variant="contained"
                            >

                                {t('btForgotPassword')}
                            </Button>
                        </Grid>
                        <Grid xs={12} md={10} marginBottom={"12px"}>
                            <Button
                                onClick={() => {
                                    setEmail("");
                                    setPassword("");
                                    setValidateEmail(false)
                                    setValidatePassword(false);
                                    setIsLoading(false);
                                    handleClose();
                                }}
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                    color: "#7272A3",
                                    background: "transparent",
                                    borderRadius: "40px",
                                    padding: "16px 32px",
                                }}
                                fullWidth
                                variant="text"
                            >
                                {t('createCollectionCheckButtomCancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}

export default AlertLoginEmail;
