import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import AlertTwoButtons from "../commons/AlertTwoButtons";
import { Link, useNavigate } from "react-router-dom";
import MusiiCheckbox from "../commons/MusiiCheckbox";
import Fonts from "../../utils/Fonts";
import { Check } from "@mui/icons-material";
import Pera from "../../assets/pera";
import Defly from "../../assets/defly";
import Play from "../../assets/play";
import MintNftCard from "../commons/MintNftCard";

import { PeraWalletConnect } from "@perawallet/connect";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import WalletConnected from "../../assets/walletConnected";
import axios from "axios";

import algosdk from "algosdk";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";

const peraWallet = new PeraWalletConnect();
const deflyWallet = new DeflyWalletConnect();

const Step3 = ({ setStep, nft, nftId }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    const [accountAddress, setAccountAddress] = useState(null);
    const [isPera, setIsPera] = useState(true);
    const [showModalAccept, setShowModalAccept] = useState(false);

    const [loading, setLoading] = useState(false);

    const [acceptedBox1, setAcceptedBox1] = useState(false);
    const [acceptedBox2, setAcceptedBox2] = useState(false);
    const [acceptedBox3, setAcceptedBox3] = useState(false);
    const [acceptedBox4, setAcceptedBox4] = useState(false);
    const [acceptedBox5, setAcceptedBox5] = useState(false);
    const [acceptedBox6, setAcceptedBox6] = useState(false);

    const [canClick, setCanClick] = useState(false);

    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    async function optIn() {
        const node = Dev.node
        const musiiAccount = algosdk.mnemonicToSecretKey(process.env.REACT_APP_WALLET);

        const algod = new algosdk.Algodv2(
            "",
            node,
            443
        );

        const enc = new TextEncoder();
        const note = enc.encode("musii transfer")

        const emptyParams = await algod.getTransactionParams().do();

        const account = await algod.accountInformation(accountAddress).do()

        if (account.amount <= 200000) {
            const optInTxn =
                algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                    from: musiiAccount.addr,
                    to: accountAddress,
                    note: note,
                    amount: 110000,
                    suggestedParams: emptyParams,
                });

            const signedMusii = optInTxn.signTxn(musiiAccount.sk);

            await algod.sendRawTransaction(signedMusii).do();
        }

        const transferOptInNft =
            algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                from: accountAddress,
                to: accountAddress,
                assetIndex: 312769,
                amount: 0,
                suggestedParams: emptyParams,
            });

        const signOptInAndTransferMoneyNft = [
            { txn: transferOptInNft, signers: [accountAddress] },
        ];

        if (isPera) {
            const signedOptIn = await peraWallet.signTransaction([
                signOptInAndTransferMoneyNft
            ]);

            const sendRawTransactionTransferOptIn = await algod
                .sendRawTransaction(signedOptIn[0])
                .do();

            await algosdk.waitForConfirmation(
                algod,
                sendRawTransactionTransferOptIn.txId,
                4
            );
        } else {
            const signedOptIn = await deflyWallet.signTransaction([
                signOptInAndTransferMoneyNft
            ]);

            const sendRawTransactionTransferOptIn = await algod
                .sendRawTransaction(signedOptIn[0])
                .do();

            await algosdk.waitForConfirmation(
                algod,
                sendRawTransactionTransferOptIn.txId,
                4
            );
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <Box>
            <AlertTwoButtons
                isMobile={isMobile}
                open={showModalAccept}
                handleClose={() => setShowModalAccept(false)}
                title={t('createCollectionFinalInformation')}
                description={
                    <Box>
                        <Box
                            display={"flex"}
                            alignItems={"flex-end"}
                            marginBottom={2}
                        >
                            <MusiiCheckbox
                                checked={acceptedBox6}
                                onChange={(e) => {
                                    setAcceptedBox6(e.target.checked);
                                }}
                            />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#7272A3",
                                }}
                            >
                                {t('createCollectionCheckUsdt')}
                            </Typography>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"flex-end"}
                            marginBottom={2}
                        >
                            <MusiiCheckbox
                                checked={acceptedBox1}
                                onChange={(e) => {
                                    setAcceptedBox1(e.target.checked);
                                }}
                            />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#7272A3",
                                }}
                            >
                                {t('createCollectionCheckInformationTrue')}
                            </Typography>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"flex-end"}
                            marginBottom={2}
                        >
                            <MusiiCheckbox
                                checked={acceptedBox2}
                                onChange={(e) => {
                                    setAcceptedBox2(e.target.checked);
                                }}
                            />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#7272A3",
                                }}
                            >
                                {t('createCollectionCheckRightHolder')}
                            </Typography>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"flex-end"}
                            marginBottom={2}
                        >
                            <MusiiCheckbox
                                checked={acceptedBox3}
                                onChange={(e) => {
                                    setAcceptedBox3(e.target.checked);
                                }}
                            />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#7272A3",
                                }}
                            >
                                {t('createCollectionCheckBenefits')}
                            </Typography>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"flex-end"}
                            marginBottom={2}
                        >
                            <MusiiCheckbox
                                checked={acceptedBox4}
                                onChange={(e) => {
                                    setAcceptedBox4(e.target.checked);
                                }}
                            />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#7272A3",
                                }}
                            >
                                {t('createCollectionCheckAgreeWithTax')}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"flex-end"}>
                            <MusiiCheckbox
                                checked={acceptedBox5}
                                onChange={(e) => {
                                    setAcceptedBox5(e.target.checked);
                                }}
                            />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#7272A3",
                                }}
                            >
                                {t('createCollectionCheckTerms')}
                            </Typography>
                        </Box>
                    </Box>
                }
                buttonLeftText={t('createCollectionCheckButtomCancel')}
                buttonLeftAction={() => {
                    setShowModalAccept(false);
                }}
                buttonRightText={loading ? (<CircularProgress sx={{ color: "white" }} />) : t('createCollectionCheckButtomContinue')}
                buttonRightColor={
                    acceptedBox1 &&
                        acceptedBox2 &&
                        acceptedBox3 &&
                        acceptedBox4 &&
                        acceptedBox5 &&
                        acceptedBox6
                        ? "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)"
                        : "#B8B8DB"
                }
                buttonRightAction={async () => {
                    if (
                        acceptedBox1 &&
                        acceptedBox2 &&
                        acceptedBox3 &&
                        acceptedBox4 &&
                        acceptedBox5 &&
                        acceptedBox6
                    ) {
                        setLoading(true)
                        const response = await axios.put(
                            `${Dev.api}nft/wallet/create`,
                            {
                                id: nftId,
                                artistName: nft.artistName,
                                artistEmail: nft.artistEmail,
                                wallet: accountAddress
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json;charset=utf-8",
                                },
                                crossDomain: true,
                            }
                        );

                        console.log(response)

                        setLoading(false)
                        setShowModalAccept(false);
                        navigate("/sent-to-approval");
                    }
                }}
            />
            <Grid container position="relative" xs={12} marginBottom={12}>
                <Grid xs={12} md={12}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('createCollectionTitle')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    paddingRight={isMobile ? 4 : 0}
                    paddingLeft={isMobile ? 4 : 8}
                    marginTop={6}
                    gap={8}
                    xs={12}
                    md={10}
                    lg={10}
                    xl={9}
                    display={"flex"}
                    justifyContent={isMobile ? "center" : ""}
                >
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"20px"}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                                cursor: "pointer",
                            }}
                            onClick={() => setStep(0)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('createCollectionStepProfile')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                                cursor: "pointer",
                            }}
                            onClick={() => setStep(1)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('createCollectionStepCollection')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#7272A3",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#ABABCC",
                                }}
                            >
                                {t('createCollectionStepWallet')}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 0px",
                                whiteSpace: "break-spaces",
                                display: "flex",
                                alignItems: "flex-end",
                            }}
                        >
                            {t('createCollectionPayOff')}
                        </Typography>
                        <Link
                            to="/tutorial-create-wallet"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    color: "#7272A3",
                                    textAlign: "left",
                                }}
                            >
                                {t('createCollectionPayOffInfo')}
                            </Typography>
                        </Link>
                        <Link
                            to="/#faq9"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[
                                        isMobile ? "mobile" : "desktop"
                                    ].b1BoldSize1,
                                    color: "#7272A3",
                                    textAlign: "left",
                                    cursor: "pointer",
                                }}
                            >
                                {t('createCollectionPayOffInfoLearnMore')}
                            </Typography>
                        </Link>
                    </Grid>
                    {!accountAddress && (
                        <Grid
                            xs={12}
                            md={4}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Button
                                onClick={() => {
                                    peraWallet
                                        .connect()
                                        .then(async (newAccounts) => {
                                            peraWallet.connector.on(
                                                "disconnect",
                                                () => peraWallet.disconnect()
                                            );

                                            setAccountAddress(newAccounts[0])
                                            setCanClick(true)
                                            setIsPera(true)
                                        })
                                        .catch((e) => { });
                                }}
                                style={{
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "40px",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "32px",
                                    padding: "16px 32px",
                                    color: "#FFFFFF",
                                    height: "56px",
                                    textTransform: "lowercase",
                                }}
                                fullWidth
                                variant="contained"
                            >
                                <Pera />
                            </Button>
                        </Grid>
                    )}
                    {!accountAddress && (
                        <Grid
                            xs={12}
                            md={4}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Button
                                onClick={() => {
                                    deflyWallet
                                        .connect()
                                        .then(async (newAccounts) => {
                                            // Setup the disconnect event listener
                                            deflyWallet.connector?.on(
                                                "disconnect",
                                                () => deflyWallet.disconnect()
                                            );

                                            setAccountAddress(newAccounts[0]);
                                            setCanClick(true);
                                        })
                                        .reject((error) => {
                                            // You MUST handle the reject because once the user closes the modal, deflyWallet.connect() promise will be rejected.
                                            // For the async/await syntax you MUST use try/catch
                                            if (
                                                error?.data?.type !==
                                                "CONNECT_MODAL_CLOSED"
                                            ) {
                                                // log the necessary errors
                                            }
                                        });
                                }}
                                style={{
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "40px",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "32px",
                                    padding: "16px 32px",
                                    color: "#FFFFFF",
                                    height: "56px",
                                    textTransform: "lowercase",
                                }}
                                fullWidth
                                variant="contained"
                            >
                                <Defly />
                            </Button>
                        </Grid>
                    )}
                    {accountAddress && (
                        <Grid
                            xs={12}
                            md={'auto'}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <WalletConnected />
                                <Typography
                                    size="small"
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        marginLeft: 3,
                                        color: "#5CB88D",
                                    }}
                                >
                                    {t('createCollectionWalletConnected')}
                                </Typography>
                            </Box>
                            <Typography
                                size="small"
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1RegularSize1,
                                    color: "#5CB88D",
                                }}
                            >
                                {`${accountAddress.slice(
                                    0,
                                    5
                                )}...${accountAddress.slice(
                                    accountAddress.length - 5,
                                    accountAddress.lenght
                                )}`}
                            </Typography>
                        </Grid>
                    )}
                    {accountAddress && (
                        <Grid
                            xs={12}
                            md={8}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].b1MediumSize1,
                                        color: "#F0F0FA",
                                        background: canClick
                                            ? "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)"
                                            : "#B8B8DB",
                                        borderRadius: "40px",
                                        padding: "12px 18px",
                                    }}
                                    onClick={async () => {
                                        optIn()
                                    }}
                                    variant="contained"
                                >
                                    Habilitar recebimentos em USDT
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        alignItems="center"
                    >
                        <Link
                            to="/tutorial-create-wallet"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Play size="46" />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1RegularSize1,
                                    color: "#ABABCC",
                                    marginTop: 8,
                                    textAlign: "left",
                                    marginLeft: "24px",
                                }}
                            >
                                {t('createCollectionTutorial')}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Grid
                        marginTop={4}
                        gap={8}
                        xs={8}
                        md={2}
                        style={{
                            position: isMobile ? "unset" : "fixed",
                            right: 86,
                            top: "30%",
                            transform: isMobile ? "" : "translateY(-20%)",
                        }}
                    >
                        <MintNftCard
                            image={nft.image}
                            name={nft.name}
                            price={nft.price}
                            totalSuply={nft.totalSuply}
                            categorie={nft.categorie}
                            subCategorie={nft.subCategorie}
                            artistName={nft.artistName}
                            artistPhoto={nft.artistPhoto}
                            isMobile={isMobile}
                        />
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: canClick
                                    ? "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)"
                                    : "#B8B8DB",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={() => {
                                if (canClick) { setShowModalAccept(true); }
                            }}
                            variant="contained"
                        >
                            {t('createCollectionCardSend')}
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
};

export default Step3;
