const Nft = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.2697 4.07834C14.2667 4.07834 14.2667 4.07527 14.2636 4.07527C14.2545 4.06915 14.2455 4.06303 14.2333 4.05691L8.26364 0.573464C8.22424 0.548976 8.17879 0.53061 8.13636 0.518366C8.12424 0.515305 8.11515 0.512244 8.10303 0.509183C8.0697 0.503061 8.03636 0.5 8 0.5C7.93939 0.5 7.87879 0.509183 7.82121 0.53061C7.80303 0.536732 7.78485 0.545915 7.76667 0.552037C7.75455 0.558159 7.74242 0.564281 7.73333 0.570403L6.93333 1.03568L1.76364 4.05079C1.76061 4.05385 1.75455 4.05691 1.75152 4.05997C1.59394 4.15792 1.5 4.32934 1.5 4.51606V11.4799C1.50909 11.6544 1.59091 11.8227 1.73333 11.9237C1.73333 11.9237 1.73636 11.9237 1.73636 11.9268C1.74545 11.9329 1.75455 11.939 1.76667 11.9452L7.73636 15.4286C7.75151 15.4378 7.7697 15.447 7.78485 15.4531C7.8 15.4592 7.81818 15.4684 7.83636 15.4745C7.85758 15.4806 7.87879 15.4868 7.9 15.4898C7.99394 15.5082 8.09091 15.5021 8.17879 15.4684C8.19697 15.4623 8.21515 15.4562 8.23333 15.447C8.24546 15.4409 8.25758 15.4347 8.26667 15.4286L9.06667 14.9633L14.2364 11.9482C14.2394 11.9452 14.2455 11.9421 14.2485 11.939C14.403 11.8411 14.4909 11.6697 14.5 11.4829V4.51912C14.5 4.34464 14.4152 4.17935 14.2697 4.07834ZM8 14.3481L2.87879 11.3636L2.56061 11.1768V4.82829L2.83636 4.66605L8 1.65707L13.1212 4.64462L13.4394 4.83135V11.1768L13.1636 11.3391L11.4758 12.3247L8 14.3481Z"
                fill="#ABABCC"
            />
        </svg>
    );
};

export default Nft;
