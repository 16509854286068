const type = "prod"

const urls = {
    dev: {
        api: "https://kag0gc0cr0.execute-api.us-east-2.amazonaws.com/dev/",
        node: "https://testnet-api.algonode.cloud"
    },
    prod: {
        api: "https://uwbm1wd2lk.execute-api.us-east-2.amazonaws.com/production/",
        node: "https://mainnet-api.algonode.cloud"
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default urls[type];
