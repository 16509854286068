const MyCollections = ({ selected }) => {
    return (
        selected ?
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">                
                <path d="M4.01066 9.03479L11.7116 12.5352C11.8956 12.6192 12.1036 12.6192 12.2876 12.5352L19.9886 9.03479C20.1526 8.95878 20.2806 8.82677 20.3486 8.65875C20.4166 8.49073 20.4166 8.30671 20.3486 8.13869C20.2806 7.97067 20.1526 7.83465 19.9886 7.76264L12.2876 4.26223C12.1036 4.17822 11.8956 4.17822 11.7116 4.26223L4.01066 7.76264C3.84664 7.83865 3.71862 7.97067 3.65062 8.13869C3.58261 8.30671 3.58261 8.49073 3.65062 8.65875C3.71862 8.82677 3.84664 8.96278 4.01066 9.03479Z" fill="url(#paint0_linear_1750_98413)" />
                <path d="M19.4129 11.2635L12.0001 14.6279L4.58718 11.2595C4.36315 11.1755 4.11112 11.2115 3.9191 11.3555C3.72707 11.4995 3.62306 11.7315 3.64306 11.9716C3.66307 12.2116 3.80308 12.4236 4.01511 12.5356L11.716 16.036C11.9 16.1201 12.1081 16.1201 12.2921 16.036L19.993 12.5356C20.205 12.4236 20.3451 12.2116 20.3651 11.9716C20.3851 11.7315 20.281 11.4995 20.089 11.3555C19.897 11.2115 19.637 11.1755 19.4129 11.2635Z" fill="url(#paint1_linear_1750_98413)" />
                <path d="M19.4129 14.7907L12.0001 18.1591L4.58718 14.7907C4.36315 14.7067 4.11112 14.7427 3.9191 14.8867C3.72707 15.0308 3.62306 15.2628 3.64306 15.5028C3.66307 15.7428 3.80308 15.9549 4.01511 16.0669L11.716 19.5673C11.9 19.6513 12.1081 19.6513 12.2921 19.5673L19.993 16.0669C20.205 15.9549 20.3451 15.7428 20.3651 15.5028C20.3851 15.2628 20.281 15.0308 20.089 14.8867C19.897 14.7427 19.637 14.7067 19.4129 14.7907Z" fill="url(#paint2_linear_1750_98413)" />
                <defs>
                    <linearGradient id="paint0_linear_1750_98413" x1="3.59961" y1="4.19922" x2="11.7132" y2="18.0796" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#8930F0" />
                        <stop offset="1" stop-color="#5E47D6" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_1750_98413" x1="3.64062" y1="11.2148" x2="6.95203" y2="20.914" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#8930F0" />
                        <stop offset="1" stop-color="#5E47D6" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_1750_98413" x1="3.64062" y1="14.7461" x2="6.95203" y2="24.4453" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#8930F0" />
                        <stop offset="1" stop-color="#5E47D6" />
                    </linearGradient>
                </defs>
            </svg>

            :
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.71574 9.03479L12.4167 12.5352C12.6007 12.6192 12.8087 12.6192 12.9927 12.5352L20.6936 9.03479C20.8577 8.95878 20.9857 8.82677 21.0537 8.65875C21.1217 8.49073 21.1217 8.30671 21.0537 8.13869C20.9857 7.97067 20.8577 7.83465 20.6936 7.76264L12.9927 4.26223C12.8087 4.17822 12.6007 4.17822 12.4167 4.26223L4.71574 7.76264C4.55172 7.83865 4.4237 7.97067 4.35569 8.13869C4.28769 8.30671 4.28769 8.49073 4.35569 8.65875C4.4237 8.82677 4.55172 8.96278 4.71574 9.03479ZM12.7047 5.67039L18.7094 8.39872L12.7047 11.131L6.69997 8.39872L12.7047 5.67039Z" fill="#7272A3" />
                <path d="M20.1161 11.2635L12.7032 14.6279L5.2903 11.2595C5.06627 11.1755 4.81424 11.2115 4.62222 11.3555C4.4302 11.4995 4.32619 11.7315 4.34619 11.9716C4.36619 12.2116 4.50621 12.4236 4.71823 12.5356L12.4191 16.036C12.6032 16.1201 12.8112 16.1201 12.9952 16.036L20.6961 12.5356C20.9082 12.4236 21.0482 12.2116 21.0682 11.9716C21.0882 11.7315 20.9842 11.4995 20.7921 11.3555C20.6001 11.2115 20.3401 11.1755 20.1161 11.2635Z" fill="#7272A3" />
                <path d="M20.1161 14.7907L12.7032 18.1591L5.2903 14.7907C5.06627 14.7067 4.81424 14.7427 4.62222 14.8867C4.4302 15.0308 4.32619 15.2628 4.34619 15.5028C4.36619 15.7428 4.50621 15.9549 4.71823 16.0669L12.4191 19.5673C12.6032 19.6513 12.8112 19.6513 12.9952 19.5673L20.6961 16.0669C20.9082 15.9549 21.0482 15.7428 21.0682 15.5028C21.0882 15.2628 20.9842 15.0308 20.7921 14.8867C20.6001 14.7427 20.3401 14.7067 20.1161 14.7907Z" fill="#7272A3" />
            </svg>
    );
};

export default MyCollections;
