import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, Button, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Check from "../assets/check";
import Fonts from "../utils/Fonts";
import { useTranslation } from "react-i18next";

const PaymentApproved = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const [t, i18n] = useTranslation("common");
    const navigate = useNavigate();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header isMobile={isMobile} />
            <Grid
                container
                gap={4}
                marginTop={isMobile ? "48px":"104px"}
                paddingX={isMobile ? "32px": "196px"}
                paddingY={isMobile ? "32px": "96px"}
                zIndex={99}
                display={"flex"}
                style={{
                    justifyContent: "center",
                    textAlign: "center",
                }}
            >
                <Check />
                <Grid xs={12} md={12}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                         {t('purchaseFlowConfirmationTitle')}
                    </Typography>
                </Grid>
                <Grid xs={12} md={12} marginBottom={4}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3RegularSize1,
                            color: "#B8B8DB",
                        }}
                    >
                         {t('purchaseFlowConfirmationInfo')}
                    </Typography>
                </Grid>
                <Grid xs={12} md={4} marginBottom={4}>
                    <Button
                        onClick={() =>
                            navigate("/profile", { state: { selected: 1 } })
                        }
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize2,
                            background:
                                "linear-gradient(130.54deg, rgba(137, 48, 240, 0.8) 0%, rgba(94, 71, 214, 0.8) 102.22%), rgba(240, 240, 250, 0.05)",
                            borderRadius: "40px",
                            padding: "16px 32px",
                            color: "#F0F0FA",
                        }}
                        fullWidth
                        variant="contained"
                    >
                         {t('purchaseFlowConfirmationButtonView')}
                    </Button>
                </Grid>
            </Grid>
            <Footer />
        </Box>
    );
};

export default PaymentApproved;
