import React from "react";

import { Box } from "@mui/material";
import Collections from "./profile/Collections";
import Favorites from "./profile/Favorites";
import Edit from "./profile/Edit";
import MyCollections from "./profile/MyCollections";

function ProfileBody({ selected, setSelected, user, another = false }) {
    return (
        <Box>
            {selected === 0 && <MyCollections anotherUser={user} another={another} />}
            {selected === 1 && <Collections anotherUser={user} another={another} setSelected={setSelected} />}
            {selected === 3 && <Favorites />}
            {selected === 4 && <Edit />}
        </Box>
    );
}

export default ProfileBody;