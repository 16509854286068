const ProfileTwitter = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=twitter">
                <path id="Path" d="M33.75 11.3692C32.7395 11.8168 31.6525 12.1154 30.506 12.2567C31.6716 11.5647 32.5684 10.461 32.9892 9.15712C31.8985 9.79644 30.6901 10.2662 29.4046 10.5137C28.378 9.42522 26.9138 8.75 25.2914 8.75C22.175 8.75 19.649 11.2538 19.649 14.3412C19.649 14.7781 19.7002 15.2036 19.7964 15.6168C15.1081 15.3815 10.9498 13.1527 8.16797 9.77047C7.67912 10.5969 7.40414 11.5647 7.40414 12.5859C7.40414 14.5268 8.39788 16.2355 9.91331 17.2422C8.98831 17.2139 8.11755 16.9565 7.35526 16.5441C7.35526 16.5609 7.35526 16.5861 7.35526 16.6098C7.35526 19.3221 9.30072 21.5807 11.8794 22.0948C11.4081 22.2239 10.9101 22.2957 10.3968 22.2957C10.0325 22.2957 9.67728 22.2544 9.33356 22.1926C10.0516 24.4092 12.1345 26.0293 14.6024 26.0774C12.6715 27.5745 10.2395 28.4712 7.5951 28.4712C7.13833 28.4712 6.69149 28.4452 6.25 28.3918C8.74847 29.9736 11.7152 30.9009 14.8996 30.9009C25.2761 30.9009 30.9529 22.3797 30.9529 14.9874C30.9529 14.7453 30.9452 14.5039 30.9338 14.2656C32.0413 13.4834 32.9961 12.4966 33.75 11.3692Z" fill="#ABABCC" />
            </g>
        </svg>
    );
};

export default ProfileTwitter;
