import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box } from "@mui/material";
import ProfileInfos from "../components/ProfileInfos";
import Footer from "../components/Footer";
import Capa from "../assets/capa_colecao.png"
import BaseadosDigitais from "./BaseadosDigitais";

const PlantaRaiz = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showModalConnect, setShowModalConnect] = useState(false);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            <ProfileInfos isMobile={isMobile} another={true} user={{
                name: "Planta e Raiz",
                photo: Capa,
                wallet: "VKUNMWTEB66TDMAOGBFCH73OUQM6BOBMAXGXGLUMVIR4MCSXEMKWYTJAWA",
                bio: "Formado por Zeider Pires (voz), Franja e Fernandinho (guitarra), Samambaia (baixo) e Juliano (bateria) o Planta e Raiz completa 21 anos de estrada, donos de hits atemporais como “Com Certeza”, “Aquele Lugar”, “Gueto do Universo/Dias de luta, dias de glória”, com participação especial de Chorão, ex-vocalista do Charlie Brown Jr. A banda ainda mostra que tem muita lenha para queimar após mais de duas décadas juntos. Sua última turnê “Exército Delirante”, homônima ao seu último álbum de estúdio, produzido por Daniel Ganjaman e participações especiais de Haikaiss e Oriente, passou por 15 Estados, mais de 100 cidades, levou cerca de 300 mil pessoas aos shows.",
                genres: [
                    "reggae",
                    "reggae rock"
                ],
                socials: [
                    {
                        social: "twitter",
                        value: "PlantaeRaiz"
                    },
                    {
                        social: "instagram",
                        value: "plantaeraiz"
                    },
                    {
                        social: "tiktok",
                        value: "@bandaplantaeraiz"
                    },
                    {
                        social: "youtube",
                        value: "https://www.youtube.com/channel/UCdn6bwpJdtYvEVNnnebNZLA"
                    },
                    {
                        social: "spotify",
                        value: "https://open.spotify.com/artist/5fKHm5RVUWIIirTGZlwGeO?autoplay=true"
                    },
                    {
                        social: "deezer",
                        value: "https://www.deezer.com/br/artist/315031"
                    },
                    {
                        social: "soundcloud",
                        value: "https://soundcloud.com/plantaeraiz"
                    },
                    {
                        social: "site",
                        value: "https://lojadoplanta.com.br"
                    }
                ]
            }} />
            <BaseadosDigitais isProfile={true} />
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default PlantaRaiz;
