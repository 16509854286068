import React, { useEffect, useState } from "react";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import MintNftCard from "../commons/MintNftCard";
import Fonts from "../../utils/Fonts";
import "react-phone-number-input/style.css";

import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MusiiCheckbox from "../commons/MusiiCheckbox";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";


const Step1 = ({ setStep, nft, setNft }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    const [artistPhotoField,] = useState(nft.artistPhoto ? nft.artistPhoto : "");
    const [artistNameField,] = useState(nft.artistName ? nft.artistName : "");
    const [emailField,] = useState(nft.artistEmail ? nft.artistEmail : "");
    const [phoneField,] = useState(nft.artistPhone ? nft.artistPhone : "");
    const [documentField,] = useState(nft.artistDocument ? nft.artistDocument : "");

    const [recusePhoto, setRecusePhoto] = useState(nft.recusedData?.includes("artistPhoto") ? true : false);
    const [recuseArtistName, setRecuseArtistName] = useState(nft.recusedData?.includes("artistName") ? true : false);
    const [recuseEmail, setRecuseEmail] = useState(nft.recusedData?.includes("artistEmail") ? true : false);
    const [recusePhone, setRecusePhone] = useState(nft.recusedData?.includes("artistPhone") ? true : false);
    const [recuseDocument, setRecuseDocument] = useState(nft.recusedData?.includes("artistDocument") ? true : false);


    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const [comments, setComments] = useState(nft.recusedReason);

    useEffect(() => {
        setNft({ ...nft, recusedReason: comments })
    }, [comments]);

    useEffect(() => {
        if (recusePhoto) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("artistPhoto")) {
                    nft.recusedData.push("artistPhoto")
                }
            } else {
                nft.recusedData = ["artistPhoto"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'artistPhoto')
        }

    }, [recusePhoto]);

    useEffect(() => {
        if (recuseArtistName) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("artistName")) {
                    nft.recusedData.push("artistName")
                }
            } else {
                nft.recusedData = ["artistName"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'artistName')
        }

    }, [recuseArtistName]);

    useEffect(() => {
        if (recuseEmail) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("artistEmail")) {
                    nft.recusedData.push("artistEmail")
                }
            } else {
                nft.recusedData = ["artistEmail"]
            }
        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'artistEmail')
        }

        console.log(nft.recusedData)

    }, [recuseEmail]);

    useEffect(() => {
        if (recuseDocument) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("artistDocument")) {
                    nft.recusedData.push("artistDocument")
                }
            } else {
                nft.recusedData = ["artistDocument"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'artistDocument')
        }

    }, [recuseDocument]);

    useEffect(() => {
        if (recusePhone) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("artistPhone")) {
                    nft.recusedData.push("artistPhone")
                }
            } else {
                nft.recusedData = ["artistPhone"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'artistPhone')
        }

    }, [recusePhone]);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <Box>
            <Grid container position="relative" xs={12} marginBottom={12}>
                <Grid xs={12} md={12}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('createCollectionTitle')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    paddingRight={isMobile ? 4 : 0}
                    paddingLeft={isMobile ? 4 : 8}
                    marginTop={6}
                    gap={8}
                    xs={12}
                    md={10}
                    lg={10}
                    xl={9}
                    display={"flex"}
                    justifyContent={isMobile ? "center" : ""}
                >
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"20px"}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#7272A3",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    1
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#CACAE5",
                                }}
                            >
                                {t('createCollectionStepProfile')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "rgba(114, 114, 163, 0.4)",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                            onClick={() => setStep(1)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "rgba(114, 114, 163, 0.4)",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    2
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "rgba(114, 114, 163, 0.4)",
                                }}
                            >
                                {t('createCollectionStepCollection')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "rgba(114, 114, 163, 0.4)",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                            onClick={() => setStep(2)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "rgba(114, 114, 163, 0.4)",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "rgba(114, 114, 163, 0.4)",
                                }}
                            >
                                {t('createCollectionStepWallet')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 0px",
                                whiteSpace: "break-spaces",
                                display: "flex",
                                alignItems: "flex-end",
                            }}
                        >
                            Created at: {nft.createdAt}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder={"Comments"}
                            fullWidth
                            multiline
                            type="text"
                            value={comments}
                            onChange={(e) => {
                                setComments(e.target.value);
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Box position={"relative"}>
                            <img
                                width={"80px"}
                                height={"80px"}
                                src={artistPhotoField}
                                style={{ borderRadius: 99 }}
                                alt="cover"
                                referrerpolicy="no-referrer"
                            />
                            <MusiiCheckbox
                                checked={recusePhoto}
                                onChange={(e) => {
                                    setRecusePhoto(e.target.checked);
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "0px 20px",
                            }}
                        >
                            {t('createCollectionArtisticName')}*
                            <MusiiCheckbox
                                checked={recuseArtistName}
                                onChange={(e) => {
                                    setRecuseArtistName(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            value={artistNameField}
                            placeholder={t('createCollectionArtisticNamePlaceholder')}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionArtisticNameInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3}
                        display={"flex"}
                        flexDirection={"column"}
                    ></Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "0px 20px",
                            }}
                        >
                            {t('createCollectionEmail')}*
                            <MusiiCheckbox
                                checked={recuseEmail}
                                onChange={(e) => {
                                    setRecuseEmail(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            value={emailField}
                            placeholder={t('createCollectionEmailPlaceholder')}
                            type="email"
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionEmailInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "0px 20px",
                            }}
                        >
                            {t('createCollectionPhone')}*
                            <MusiiCheckbox
                                checked={recusePhone}
                                onChange={(e) => {
                                    setRecusePhone(e.target.checked);
                                }}
                            />
                        </Typography>
                        <PhoneInput
                            disabled
                            value={phoneField}
                            placeholder={t('createCollectionPhonePlaceholder')}
                            id={"phonenumberfield"}
                            numberInputProps={{
                                style: {
                                    boxShadow: 0,
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    border: "0px solid transparent",
                                    backgroundColor: "transparent",
                                    color: "#B8B8DB",
                                },
                            }}
                            style={{
                                boxShadow: 0,
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                padding: "16.5px 20px",
                                border: "0px solid transparent",
                                color: "#B8B8DB",
                                borderRadius: "24px",
                                borderBottomWidth: 0,
                                background:
                                    "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                            }}
                            placeholderColor="red"
                        />
                        <TextField
                            disabled
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={"Phone Number"}
                            value={phoneField}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    display: "none",
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />

                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionPhoneInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "0px 20px",
                            }}
                        >
                            {t('createCollectionCPForId')}*
                            <MusiiCheckbox
                                checked={recuseDocument}
                                onChange={(e) => {
                                    setRecuseDocument(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder={t('createCollectionCPForIdPlaceholder')}
                            type="text"
                            value={documentField}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionCPForIdInfo')}
                        </Typography>
                    </Grid>
                </Grid>

                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Grid
                        marginTop={4}
                        gap={8}
                        xs={8}
                        md={2}
                        style={{
                            position: isMobile ? "unset" : "fixed",
                            right: 86,
                            top: "30%",
                            transform: isMobile ? "" : "translateY(-20%)",
                        }}
                    >
                        <MintNftCard
                            artistName={artistNameField}
                            artistPhoto={artistPhotoField}
                            image={nft.imageField}
                            name={nft.nameField}
                            price={nft.priceField}
                            totalSuply={nft.totalSuplyField}
                            categorie={nft.categorieField}
                            subCategorie={nft.subCategorieField}
                            isMobile={isMobile}
                        />
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                const response = await axios.put(
                                    `${Dev.api}nft/approve/id`,
                                    {
                                        id: nft.id,
                                        email: "admin@musii.app",
                                    },
                                    {
                                        headers: {
                                            "Content-Type": "application/json;charset=utf-8",
                                        },
                                        crossDomain: true,
                                    }
                                );
                                console.log(response)
                                navigate("/profile");
                            }}
                            variant="contained"
                        >
                            Approve
                        </Button>
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                const remove = {}

                                nft.recusedData.map(item =>
                                    remove[item] = ""
                                )

                                const body = {
                                    id: nft.id,
                                    email: "admin@musii.app",
                                    recusedReason: comments,
                                    recusedData: nft.recusedData,
                                    remove
                                }

                                const response = await axios.put(
                                    `${Dev.api}nft/reprove/id`,
                                    body,
                                    {
                                        headers: {
                                            "Content-Type": "application/json;charset=utf-8",
                                        },
                                        crossDomain: true,
                                    }
                                );

                                console.log(response)
                                navigate("/profile");
                            }}
                            variant="contained"
                        >
                            Recuse
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
};

export default Step1;
