import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
    Box, Typography,
} from "@mui/material";
import Footer from "../components/Footer";
import Fonts from "../utils/Fonts";
import { useTranslation } from "react-i18next";

const TutorialNft = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showModalConnect, setShowModalConnect] = useState(false);

    const [t, i18n] = useTranslation("common");

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header
                showModalConnect={showModalConnect}
                setShowModalConnect={setShowModalConnect}
                isMobile={isMobile}
            />
            <Box style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "138px",
            }}>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h2RegularSize1,

                        color: "#F0F0FA",
                        textAlign: "center",
                        padding: "12px 32px",
                        marginRight: "32px",
                        width: "70%"
                    }}
                >
                    {t('tutorialNftTitle')}                    
                </Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "16px" }} >
                <video
                    style={{ width: "70%" }}
                    controls={true}
                    autoPlay
                    src={
                        "https://musii-uploads.s3.amazonaws.com/tutorial_criar_nft.mp4"
                    }
                />
            </Box>
            <Box style={{ marginTop: 128 }} />
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default TutorialNft;
