import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";

import CapaComum from "../assets/capa_comum.jpg"
import CapaEpico from "../assets/capa_epico.jpg"
import CapaLendario from "../assets/capa_lendario.jpg"
import CapaRaro from "../assets/capa_raro.jpg"
import NftCardPlanta from "../components/commons/NftCardPlanta";
import Fonts from "../utils/Fonts";
import axios from "axios";

import Capa from "../assets/capa_colecao.png"
import Dev from "../utils/Dev";

const BaseadosDigitais = ({ isProfile = false }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showModalConnect, setShowModalConnect] = useState(false);

    const [commons, setCommons] = useState(0);
    const [epics, setEpics] = useState(0);
    const [legendary, setLegendary] = useState(0);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const loadInfos = async () => {
            axios.get(
                `${Dev.api}nft/tag?tag=comum`).then(value => {
                    let counter = 0
                    value.data.Items.map(item => {
                        if (item.totalSold === 1 || item.totalSold === "1") {
                            counter++
                        }
                    })
                    setCommons(counter)
                })

            axios.get(
                `${Dev.api}nft/tag?tag=epico`).then(value => {
                    console.log(value)
                    let counter = 0
                    value.data.Items.map(item => {
                        if (item.totalSold === 1 || item.totalSold === "1") {
                            counter++
                        }
                    })
                    setEpics(counter)
                })

            axios.get(
                `${Dev.api}nft/tag?tag=lendaria`).then(value => {
                    let counter = 0
                    value.data.Items.map(item => {
                        if (item.totalSold === 1 || item.totalSold === "1") {
                            counter++
                        }
                    })
                    setLegendary(counter)
                })

        };

        loadInfos();


        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            <Box display={"flex"} justifyContent={"center"} paddingY={"56px"} marginTop={isProfile ? "0" : "96px"}>
                <Grid
                    container
                    marginLeft={isMobile ? 2 : 0}
                    gap={"32px"}
                    maxWidth={"80vw"}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    {!isProfile &&
                        <Grid xs={12} md={12} display={"flex"} alignItems={"center"} flexDirection={"column"}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#ABABCC",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                Planta e Raiz
                            </Typography>
                            <Box style={{
                                width: "35px",
                                height: "6px",
                                background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                                borderRadius: "3px",
                                marginTop: "8px",
                                marginBottom: "16px"
                            }}></Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h1BoldSize1,
                                    color: "#EBEBFA",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                Baseados Digitais
                            </Typography>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3RegularSize1,
                                    color: "#EBEBFA",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                    marginTop: "16px"
                                }}
                            >
                                O Planta & Raiz está explorando o potencial dos NFTs para enriquecer ainda mais sua coleção de maneira empolgante. Agora, cada NFT da coleção apresenta músicas icônicas que definiram os 25 anos de história da banda, oferecendo benefícios exclusivos e experiências únicas para seus fãs. Essa iniciativa promete levar a interação entre a banda e seus admiradores a um novo patamar, proporcionando uma experiência musical memorável e recompensas especiais aos proprietários dos NFTs. Confira abaixo as categorias e benefícios.
                            </Typography>
                        </Grid>}
                    <Grid xs={12} md={11} lg={11} xl={10}>
                        <Grid container spacing={3} padding={"0px 16px 16px 16px"} style={{ display: "flex", justifyContent: "center" }}>
                            {[{
                                id: "baseados-digitais-comum",
                                image: CapaComum,
                                name: "Baseados Comuns",

                                categorie: "products",
                                subCategorie: "Merchandising",

                                price: 300,
                                totalSold: commons,
                                totalSuply: 70,
                                status: "ACTIVE",

                                artistName: "Planta e Raiz",
                                artistEmail: "planta-e-raiz",
                                artistPhoto: Capa
                            },
                            {
                                id: "baseados-digitais-epico",
                                image: CapaRaro,
                                name: "Baseados Épicos",

                                categorie: "experiences",
                                subCategorie: "Backstage",

                                price: 389,
                                totalSold: epics,
                                totalSuply: 27,
                                status: "ACTIVE",

                                artistName: "Planta e Raiz",
                                artistEmail: "planta-e-raiz",
                                artistPhoto: Capa
                            }, {
                                id: "baseados-digitais-lendario",
                                image: CapaLendario,
                                name: "Baseados Lendários",

                                categorie: "products",
                                subCategorie: "Phygital",

                                price: 1000,
                                totalSold: legendary,
                                totalSuply: 3,
                                status: "ACTIVE",

                                artistName: "Planta e Raiz",
                                artistEmail: "planta-e-raiz",
                                artistPhoto: Capa
                            }
                            ].map((nft, index) => (
                                <NftCardPlanta
                                    key={index}
                                    index={index}
                                    nft={nft}
                                    isMobile={isMobile}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default BaseadosDigitais;
