import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    Grid,
    Typography,
} from "@mui/material";

import Fonts from "../../utils/Fonts";
import { Check } from "@mui/icons-material";

import axios from "axios";
import Pera from "../../assets/pera";
import Defly from "../../assets/defly";
import Verified from "../../assets/verified";

import { usePera } from "../../hooks/usePera";
import { useDefly } from "../../hooks/useDefly";
import { useUser } from "../../hooks/useUser";
import Pix from "../../assets/pix";
import AlertPix from "../commons/AlertPix";
import { useTranslation } from "react-i18next";
import WalletConnected from "../../assets/walletConnected";

import algo from "../../utils/Algorand"
import AlertTransaction from "../commons/AlertTransaction";
import { useNavigate } from "react-router-dom";
import Dev from "../../utils/Dev";

const Step2 = ({ setStep, isMobile, nft }) => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    const { pera, accountAddress, logIn: loginPera } = usePera();
    const { logIn: loginDefly } = useDefly();

    const [open, setOpen] = useState(false);

    const [openMint, setOpenMint] = useState(false);
    const [progress, setProgress] = useState(0);

    const [nftPayment, setNftPayment] = useState();

    return (
        <Box>
            <AlertPix
                open={open}
                handleClose={() => setOpen(false)}
                isMobile={isMobile}
                nftPayment={nftPayment}
                nft={nft}
            />
            <AlertTransaction
                open={openMint}
                handleClose={() => setOpenMint(false)}
                isMobile={isMobile}
                progress={progress}
            />
            <Grid container position="relative" xs={12} marginBottom={12}>
                <Grid
                    container
                    paddingRight={isMobile ? 4 : 0}
                    paddingLeft={isMobile ? 4 : 8}
                    marginTop={6}
                    gap={8}
                    xs={12}
                    md={11}
                    lg={11}
                    xl={10}
                    display={"flex"}
                    justifyContent={isMobile ? "center" : ""}
                >
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"20px"}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('btLogIn')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                                cursor: "pointer",
                            }}
                            onClick={() => setStep(0)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('purchaseFlowPersonalDataIcon')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#7272A3",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#CACAE5",
                                }}
                            >
                                {t('purchaseFlowPaymentIcon')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        container
                        gap={"24px"}
                        xs={12}
                        md={7}
                        xl={7.5}
                        display={"flex"}
                        justifyContent={isMobile ? "center" : ""}
                    >
                        <Grid
                            xs={12}
                            md={10.5}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize1,
                                    color: "#ABABCC",
                                    textAlign: "left",
                                    padding: "16px 0px",
                                }}
                            >
                                {t('purchaseFlowCheckoutTitle')}
                            </Typography>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    color: "#7272A3",
                                    marginTop: 8,
                                    textAlign: "left",
                                    padding: "16px 0px",
                                }}
                            >
                                {t('purchaseFlowCheckoutInfo')}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={12}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                    color: "#CACAE5",
                                    marginBottom: 8,
                                    padding: "16px 20px",
                                }}
                            >
                                {t('purchaseFlowPayWithWallet')}
                            </Typography>
                        </Grid>
                        {accountAddress && accountAddress !== "" &&
                            <Grid
                                xs={12}
                                md={4.5}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                        color: "#5CB88D",
                                        marginBottom: 8,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: "8px"
                                    }}>
                                        <WalletConnected />
                                    </Box> Wallet Connected
                                </Typography>
                                <Typography
                                    sx={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"].l1RegularSize1,
                                        color: "#5CB88D",
                                    }}
                                >
                                    {`${accountAddress.slice(0, 5)}...${accountAddress.slice(
                                        accountAddress.length - 5,
                                        accountAddress.lenght
                                    )}`}
                                </Typography>
                                <Button
                                    onClick={async () => {
                                        setOpenMint(true)
                                        await algo.pay(pera, accountAddress, nft, ((nft.quantity * nft.price) + ((nft.quantity * nft.price / 10) / 2)).toFixed(2), setProgress)
                                        const assetId = await algo.mint(pera, accountAddress, nft, setProgress)
                                        await algo.transfer(pera, accountAddress, assetId, setProgress)

                                        const response = await axios.post(
                                            `${Dev.api}nft/payment/wallet`,
                                            {
                                                id: nft.id,
                                                email: user.email,
                                                redeemed: true,
                                            },
                                            {
                                                headers: {
                                                    "Content-Type": "application/json;charset=utf-8"
                                                },
                                                crossDomain: true,
                                            }
                                        );

                                        setOpenMint(false)
                                        navigate("/payment-approved");
                                    }}
                                    style={{
                                        background:
                                            "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                        borderRadius: "40px",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "32px",
                                        height: "56px",
                                        padding: "16px 32px",
                                        color: "#FFFFFF",
                                        textTransform: "lowercase",
                                        marginTop: "16px"
                                    }}
                                    fullWidth
                                    variant="contained"
                                >
                                    <Pera />
                                </Button>
                            </Grid>}
                        {(!accountAddress || accountAddress === "") &&
                            <Grid
                                xs={12}
                                md={4}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Button
                                    onClick={() => {
                                        loginPera()
                                    }}
                                    style={{
                                        background:
                                            "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                        borderRadius: "40px",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "32px",
                                        height: "56px",
                                        padding: "16px 32px",
                                        color: "#FFFFFF",
                                        textTransform: "lowercase",
                                    }}
                                    fullWidth
                                    variant="contained"
                                >
                                    <Pera />
                                </Button>
                            </Grid>}
                        <Grid
                            xs={12}
                            md={4}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Button
                                onClick={() => {
                                    loginDefly()
                                }}
                                style={{
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "40px",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "32px",
                                    padding: "16px 32px",
                                    height: "56px",
                                    color: "#FFFFFF",
                                    textTransform: "lowercase",
                                }}
                                fullWidth
                                variant="contained"
                            >
                                <Defly />
                            </Button>
                        </Grid>
                        <Grid
                            xs={12}
                            md={12}
                            display={"flex"}
                            flexDirection={"column"}
                            marginTop={"32px"}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                    color: "#CACAE5",
                                    marginBottom: 8,
                                    padding: "16px 20px",
                                }}
                            >
                                {t('purchaseFlowPayWithPix')}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Button
                                onClick={async () => {
                                    const response = await axios.post(
                                        `${Dev.api}nft/payment/create`,
                                        {
                                            id: nft.id,
                                            name: nft.name,
                                            artistId: nft.artistId,
                                            quantity: nft.quantity,
                                            price: nft.price,
                                            document: user.document
                                        },
                                        {
                                            headers: {
                                                "Content-Type": "application/json;charset=utf-8"
                                            },
                                            crossDomain: true,
                                        }
                                    );

                                    console.log(response)
                                    setNftPayment(response.data)
                                    setOpen(true)
                                }}
                                style={{
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "40px",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "32px",
                                    padding: "16px 32px",
                                    height: "56px",
                                    color: "#FFFFFF",
                                }}
                                fullWidth
                                variant="contained"
                            >
                                <Pix />
                                PIX
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        gap={8}
                        xs={12}
                        md={4}
                        xl={3.5}
                        style={{
                            maxWidth: "466px",
                            padding: "48px",
                            borderRadius: "32px",
                            gap: "24px",
                            height: "fit-content",
                            backgroundColor: "#242447"
                        }}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                whiteSpace: "break-spaces",
                            }}
                        >
                            {t('purchaseFlowOrderSummary')}
                        </Typography>
                        <Box padding="16px 0px 0px 0px">
                            <Box style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginTop: "24px",
                            }}>
                                <img
                                    src={nft.image}
                                    alt="musii"
                                    style={{
                                        width: "64px",
                                        borderRadius: "16px",
                                    }}
                                />
                                <Box style={{ marginLeft: "12px", flex: 1 }}>
                                    <Typography
                                        style={{
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .h2BoldSize2,
                                            color: "#F0F0FA",
                                            whiteSpace: "break-spaces",
                                        }}
                                    >
                                        {nft.name.length < 16 ? nft.name : `${nft.name.slice(0, 16)}...`}
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .l1LightSize1,
                                            color: "#F0F0FA",
                                            whiteSpace: "break-spaces",
                                        }}
                                    >
                                        {nft.artistName.length < 12 ? nft.artistName : `${nft.artistName.slice(0, 12)}...`} <Verified size={12} />
                                    </Typography>
                                </Box>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1BoldSize1,
                                        color: "#F0F0FA",
                                        whiteSpace: "break-spaces",
                                    }}
                                >
                                    R${nft.price} x {nft.quantity}
                                </Typography>
                            </Box>
                            <Box style={{ margin: "32px 0px", border: "0px solid #7272A366", borderBottomWidth: "2px", borderRadius: "32px" }}></Box>

                            <Box display={"flex"} marginBottom={"16px"}>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1RegularSize1,
                                        color: "#F0F0FA",
                                        whiteSpace: "break-spaces",
                                        flex: 1
                                    }}
                                >
                                    {t('purchaseFlowOrderSubtotal')}:
                                </Typography>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1RegularSize1,
                                        color: "#F0F0FA",
                                        whiteSpace: "break-spaces",
                                    }}
                                >
                                    R${(nft.quantity * nft.price).toFixed(2)}
                                </Typography>
                            </Box>
                            <Box display={"flex"} marginBottom={"16px"}>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1RegularSize1,
                                        color: "#F0F0FA",
                                        whiteSpace: "break-spaces",
                                        flex: 1
                                    }}
                                >
                                    {t('purchaseFlowServiceTax')}:
                                </Typography>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1RegularSize1,
                                        color: "#F0F0FA",
                                        whiteSpace: "break-spaces",
                                    }}
                                >
                                    R${((nft.quantity * nft.price / 10) / 2).toFixed(2)}
                                </Typography>
                            </Box>
                            <Box display={"flex"} marginBottom={"16px"}>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h3BoldSize1,
                                        color: "#F0F0FA",
                                        whiteSpace: "break-spaces",
                                        flex: 1
                                    }}
                                >
                                    {t('purchaseFlowOrderTotal')}:
                                </Typography>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h3BoldSize1,
                                        color: "#F0F0FA",
                                        whiteSpace: "break-spaces",
                                    }}
                                >
                                    R${((nft.quantity * nft.price) + ((nft.quantity * nft.price / 10) / 2)).toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
};

export default Step2;
