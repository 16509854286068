import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Fonts from "../utils/Fonts";

import SeaLion from "../assets/sealion.png";
import { useTranslation } from "react-i18next";

function SeaLionCollection({ isMobile }) {
    const [t, i18n] = useTranslation("common");

    return (
        <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            marginTop={isMobile ? "48px" : "108px"}
            style={{
                display: "flex",
                padding: isMobile ? "20px 30px" : "56px",
                alignItems: isMobile ? "flex-start" : "center",
                borderRadius: 16,
                flexDirection: isMobile ? "column" : "row",
            }}
        >


            <Grid
                xs={12}
                md={4}
                xl={3}
                marginRight={isMobile ? "24px" : "122px"}
            >
                <img
                    src={
                        SeaLion
                    }
                    alt="musii"
                    style={{
                        width: isMobile ? "256px" : "456px",
                        marginTop: isMobile ? "24px" : "0px",
                        borderRadius: "48px",
                    }}
                />
            </Grid>
            <Grid
                xs={12}
                md={5.5}
                xl={4}
                marginRight={isMobile ? "24px" : "122px"}
            >
                <Box
                    style={{
                        width: "35px",
                        height: "6px",
                        background:
                            "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                        borderRadius: "3px",
                        marginTop: "8px",
                        marginBottom: "16px",
                    }}
                ></Box>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].h1BoldSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces",
                        marginTop: 4,
                        marginBottom: 4
                    }}
                >
                    {t('musiiSeaLionsTitle')}
                </Typography>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h3RegularSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces"
                    }}
                >
                    {t('musiiSeaLionsDescription')}
                </Typography>
                <Box
                    display={"flex"}
                    marginTop={"48px"}
                    flexDirection={isMobile ? "column" : "row"}
                >
                    <a
                        aria-label="musii collection"
                        href="https://algoxnft.com/shuffle/1745"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h3BoldSize1,
                                color: "#EBEBFA",
                                textAlign: "center",
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "#8C29D9E5",
                                borderRadius: 32,
                                padding: "12px 32px",
                                cursor: "pointer",
                                marginRight: "32px",
                                background:
                                    "linear-gradient(130.54deg, #8C29D9 0%, #5E47D6 102.22%), rgba(235, 235, 250, 0.25)",
                            }}
                        >
                            {t('musiiSeaLionsCTA1')}
                        </Typography>
                    </a>
                    <a
                        aria-label="musii collection"
                        href="/promo-video"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3BoldSize1,
                            color: "#EBEBFA",
                            textAlign: "center",
                            borderWidth: "2px",
                            borderStyle: "solid",
                            borderColor: "#8C29D9E5",
                            borderRadius: 32,
                            padding: "12px 32px",
                            cursor: "pointer",
                            marginTop: isMobile ? "24px" : "0px",
                        }}
                    >
                        {t('musiiSeaLionsCTA2')}
                    </Typography>
                    
                    </a>
                </Box>
            </Grid>
        </Grid>
    );
}

export default SeaLionCollection;
