import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box } from "@mui/material";
import ProfileInfos from "../components/ProfileInfos";
import Footer from "../components/Footer";
import ProfileMenu from "../components/ProfileMenu";
import ProfileBody from "../components/ProfileBody";
import { useLocation } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import ProfileMenuAdmin from "../components/ProfileMenuAdmin";

const Profile = () => {
    const location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const [showModalConnect, setShowModalConnect] = useState(false);
    const { user } = useUser();

    const [selected, setSelected] = useState(location?.state?.selected ? location?.state?.selected : user?.artist ? 0 : 1);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            <ProfileInfos isMobile={isMobile} setSelected={setSelected} user={user} />
            {user?.email !== "admin@musii.app" && <ProfileMenu isMobile={isMobile} selected={selected} setSelected={setSelected} />}
            {user?.email !== "admin@musii.app" && <ProfileBody isMobile={isMobile} selected={selected} setSelected={setSelected} />}
            {user?.email === "admin@musii.app" && <ProfileMenuAdmin isMobile={isMobile} selected={selected} setSelected={setSelected} />}
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default Profile;
