import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import NftList from "../NftList";

const Favorites = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    console.log(isMobile)

    return (
        <Box backgroundColor={"#121229"}>
            <Grid container spacing={3} padding={isMobile ? "0px 32px": "0px 128px"} justifyContent={"center"}>
                <Grid item sm={12} md={11} xl={10}>
                    <NftList padding={0} size={3} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Favorites;
