// eslint-disable-next-line import/no-anonymous-default-export
export default {
    mobile: {
        h1BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "28px",
            lineHeight: "56px",
        },
        h1BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "48px",
        },
        h2BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "32px",
        },
        h2BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "32px",
        },
        h2RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "32px",
        },
        h3BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "24px",
        },
        h3MediumSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "24px",
        },
        h3MediumSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "24px",
        },
        h3RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "32px",
        },
        b1BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "24px",
        },
        b1BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "24px",
        },
        b1MediumSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "16px",
        },
        b1RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "24px",
        },
        b1RegularSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "10px",
            lineHeight: "16px",
        },
        l1RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "24px",
        },
        l1RegularSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "24px",
        },
        l1LightSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "12px",
            lineHeight: "24px",
        },
    },
    tablet: {
        h1BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: "56px",
        },
        h1BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "28px",
            lineHeight: "48px",
        },
        h2BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "40px",
        },
        h2BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "32px",
        },
        h2RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "32px",
        },
        h3BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "24px",
        },
        h3MediumSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
        },
        h3MediumSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "24px",
        },
        h3RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "32px",
        },
        b1BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "24px",
        },
        b1BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "24px",
        },
        b1MediumSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "16px",
        },
        b1RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "24px",
        },
        l1RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "24px",
        },
        l1RegularSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "24px",
        },
        l1LightSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "12px",
            lineHeight: "24px",
        },
    },
    desktop: {
        h1BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "40px",
            lineHeight: "56px",
        },
        h1BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "36px",
            lineHeight: "48px",
        },
        h2BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "40px",
        },
        h2BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "32px",
        },
        h2RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "32px",
        },
        h3BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "24px",
        },
        h3MediumSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
        },
        h3MediumSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "24px",
        },
        h3RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "32px",
        },
        b1BoldSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "24px",
        },
        b1BoldSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "24px",
        },
        b1MediumSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "16px",
        },
        b1RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "24px",
        },
        b1RegularSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "10px",
            lineHeight: "16px",
        },
        l1RegularSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "24px",
        },
        l1RegularSize2: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "24px",
        },
        l1LightSize1: {
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "12px",
            lineHeight: "24px",
        },
    },
};
