const Products = () => {
    return (
        <svg
            width="120"
            height="120"
            viewBox="0 0 184 184"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="92" cy="92" r="92" fill="#1D1736" fillOpacity="0.5" />
            <circle cx="92" cy="92" r="92" fill="#7054FF" fillOpacity="0.2" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M96.5688 41.5046L132.089 62.013V62.0145C133.769 62.9555 135.165 64.3322 136.128 66.0004C137.091 67.668 137.586 69.5653 137.56 71.4916V112.508C137.586 114.435 137.091 116.332 136.128 118C135.165 119.669 133.769 121.045 132.089 121.986L96.5688 142.495C94.914 143.48 93.024 144 91.0977 144C89.1714 144 87.2815 143.48 85.6267 142.495L50.1069 121.986C48.426 121.045 47.0299 119.668 46.0675 118C45.1043 116.331 44.6097 114.434 44.6357 112.508V71.4909C44.6097 69.5646 45.1043 67.6671 46.0675 65.9989C47.03 64.3306 48.426 62.9538 50.1069 62.013L85.6267 41.5046C87.2815 40.5199 89.1722 40 91.0977 40C93.0233 40 94.9139 40.5199 96.5688 41.5046ZM91.097 46.5123C90.3138 46.5123 89.5447 46.7265 88.8746 47.1318L54.3799 67.048L69.4722 75.7611L106.189 54.5624L93.3193 47.1318C92.6492 46.7265 91.8802 46.5123 91.097 46.5123ZM112.689 58.3152L75.9724 79.5137L91.0973 88.2456L127.815 67.048L112.689 58.3152ZM51.704 114.742C52.0959 115.42 52.6664 115.978 53.3529 116.355L87.8479 136.271V93.8738L51.1305 72.6743V112.507C51.1141 113.291 51.312 114.063 51.704 114.742ZM94.3468 136.271L128.842 116.355C129.528 115.978 130.099 115.42 130.491 114.742C130.883 114.064 131.081 113.291 131.064 112.507V72.6744L94.3468 93.874V136.271Z"
                fill="#806FDC"
            />
        </svg>
    );
};

export default Products;
