const Defly = () => {
    return (
        <svg
            width="63"
            height="18"
            viewBox="0 0 63 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.6997 16.3587L10.0549 0L0.410156 16.3587L10.0549 10.7806L19.6997 16.3587Z"
                fill="#F0F0FA"
            />
            <path
                d="M18.0154 18.0002L10.0546 13.8047L2.09375 18.0002L10.0546 15.4086L18.0154 18.0002Z"
                fill="#F0F0FA"
            />
            <path
                d="M30.5147 14.17C29.8857 14.17 29.3452 14.0005 28.8931 13.6615C28.441 13.3175 28.0946 12.8335 27.8538 12.2095C27.6179 11.5854 27.5 10.8532 27.5 10.013C27.5 9.14324 27.6327 8.40129 27.898 7.78703C28.1634 7.16789 28.5344 6.69623 29.011 6.37191C29.4877 6.04266 30.0381 5.87806 30.6621 5.87806C31.0651 5.87806 31.4115 5.94685 31.7015 6.08444C31.9963 6.22203 32.2346 6.40628 32.4164 6.63726C32.5983 6.86818 32.7186 7.12122 32.7776 7.3964L33.043 8.14824L32.6818 8.67151C32.6327 8.48479 32.5491 8.32514 32.4312 8.19243C32.3182 8.05485 32.1707 7.94922 31.9889 7.87548C31.812 7.8018 31.6007 7.76496 31.355 7.76496C30.8587 7.76496 30.473 7.9541 30.1978 8.33249C29.9275 8.70594 29.7924 9.28083 29.7924 10.0572C29.7924 10.7992 29.9226 11.3569 30.183 11.7303C30.4484 12.1038 30.8317 12.2905 31.3329 12.2905C31.5737 12.2905 31.7899 12.2561 31.9815 12.1873C32.1781 12.1136 32.3378 12.0251 32.4607 11.922C32.5835 11.8139 32.6572 11.7082 32.6818 11.605L32.7703 11.7008L32.6818 12.1873C32.613 12.4822 32.5196 12.7524 32.4017 12.9981C32.2838 13.2389 32.1388 13.4477 31.9668 13.6246C31.7948 13.7966 31.5884 13.9317 31.3476 14.03C31.1118 14.1234 30.8341 14.17 30.5147 14.17ZM32.6892 14.0742L32.6818 2.60547H34.9668V14.0742H32.6892Z"
                fill="#F0F0FA"
            />
            <path
                d="M40.2413 14.1812C39.4256 14.1812 38.7303 14.0191 38.1553 13.6948C37.5804 13.3705 37.1406 12.9012 36.8359 12.287C36.5313 11.6678 36.3789 10.921 36.3789 10.0463C36.3789 9.14219 36.5411 8.38052 36.8654 7.76144C37.1898 7.13736 37.6467 6.66563 38.2364 6.34627C38.8261 6.02684 39.5165 5.86719 40.3077 5.86719C41.0742 5.86719 41.718 6.01213 42.2388 6.30201C42.7647 6.59196 43.1627 7.01943 43.4329 7.58455C43.7032 8.1496 43.8383 8.84489 43.8383 9.67041C43.8383 9.77363 43.8335 9.89397 43.8236 10.0316C43.8187 10.1643 43.8113 10.3166 43.8015 10.4886H38.6271C38.6811 11.1568 38.8433 11.6458 39.1136 11.9553C39.3838 12.26 39.7622 12.4123 40.2487 12.4123C40.6516 12.4123 40.9563 12.3239 41.1627 12.147C41.374 11.9701 41.4993 11.7366 41.5386 11.4467H43.7352C43.6909 12.002 43.5214 12.486 43.2265 12.8987C42.9366 13.3066 42.5386 13.6235 42.0325 13.8496C41.5263 14.0707 40.9293 14.1812 40.2413 14.1812ZM39.5632 9.19868H41.6344C41.6148 8.68277 41.4895 8.29702 41.2585 8.0415C41.0325 7.78598 40.6836 7.65822 40.2119 7.65822C39.6565 7.65822 39.2512 7.85725 38.9956 8.25524C38.7401 8.64834 38.6099 9.20851 38.605 9.93576C38.6443 9.6557 38.7401 9.46403 38.8924 9.36087C39.0448 9.25277 39.2684 9.19868 39.5632 9.19868Z"
                fill="#F0F0FA"
            />
            <path
                d="M45.6615 14.0725V8.56662C45.6615 8.32093 45.7033 8.14403 45.7868 8.03593C45.8753 7.92782 45.9981 7.85409 46.1554 7.81478L45.6615 6.35541V5.07294C45.6615 4.44397 45.7868 3.92063 46.0375 3.50299C46.288 3.08529 46.6247 2.77328 47.0473 2.5669C47.4699 2.35563 47.9342 2.25 48.4404 2.25C48.7893 2.25 49.0522 2.25983 49.229 2.27948C49.4109 2.29419 49.5903 2.31879 49.7672 2.35316V4.32109C49.6591 4.28178 49.5215 4.2523 49.3544 4.23264C49.1873 4.20811 49.0031 4.19581 48.8016 4.19581C48.6345 4.19581 48.4871 4.22776 48.3593 4.29161C48.2315 4.35058 48.1308 4.46115 48.0571 4.62334C47.9883 4.78058 47.9539 5.00414 47.9539 5.29403V14.0725H45.6615ZM44.6738 7.81478V6.02374H49.4502V7.81478H44.6738ZM50.7917 14.0725V2.55954H53.0915V14.0725H50.7917Z"
                fill="#F0F0FA"
            />
            <path
                d="M55.04 17.2494V15.3035H55.9171C56.2709 15.3035 56.5412 15.274 56.728 15.2151C56.9147 15.161 57.0522 15.0799 57.1407 14.9718C57.2341 14.8686 57.3078 14.7458 57.3618 14.6033C57.4257 14.4362 57.4871 14.3134 57.5461 14.2348C57.6051 14.1561 57.6812 14.1021 57.7746 14.0726H56.8827L54.207 6.03125H56.6837L57.7525 10.3283L58.1211 11.8909H58.1874L58.5264 10.3357L59.551 6.03125H62.0129L59.4626 14.0726C59.3053 14.564 59.1284 15.0062 58.9319 15.3993C58.7353 15.7924 58.5019 16.1266 58.2316 16.4017C57.9613 16.6769 57.637 16.8858 57.2586 17.0282C56.8803 17.1756 56.4331 17.2494 55.9171 17.2494H55.04Z"
                fill="#F0F0FA"
            />
        </svg>
    );
};

export default Defly;
