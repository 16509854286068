const Empty = () => {
    return (
        <svg
            width="88"
            height="88"
            viewBox="0 0 88 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.8571 81.7142H9.42854C8.59513 81.7142 7.79538 81.3831 7.2061 80.7938C6.61683 80.2045 6.2857 79.4048 6.2857 78.5713V69.1428C6.2857 68.0197 5.6866 66.9821 4.71427 66.4209C3.74195 65.8597 2.54373 65.8597 1.57142 66.4209C0.599117 66.9821 0 68.0197 0 69.1428V78.5713C0 81.0723 0.993356 83.4701 2.76122 85.2387C4.52978 87.0065 6.92756 87.9999 9.42854 87.9999H18.8571C19.9802 87.9999 21.0178 87.4008 21.579 86.4285C22.1402 85.4561 22.1402 84.2579 21.579 83.2856C21.0178 82.3133 19.9802 81.7142 18.8571 81.7142Z"
                fill="#242447"
            />
            <path
                d="M78.5713 0H69.1428C68.0197 0 66.9821 0.599099 66.4209 1.57142C65.8597 2.54375 65.8597 3.74196 66.4209 4.71427C66.9821 5.68658 68.0197 6.2857 69.1428 6.2857H78.5713C79.4048 6.2857 80.2045 6.61683 80.7938 7.2061C81.3831 7.79538 81.7142 8.59513 81.7142 9.42854V18.8571C81.7142 19.9802 82.3133 21.0178 83.2856 21.579C84.2579 22.1402 85.4562 22.1402 86.4285 21.579C87.4008 21.0178 87.9999 19.9802 87.9999 18.8571V9.42854C87.9999 6.92756 87.0065 4.52983 85.2387 2.76122C83.4701 0.993356 81.0723 0 78.5713 0Z"
                fill="#242447"
            />
            <path
                d="M84.857 66C84.0236 66 83.2239 66.3311 82.6346 66.9204C82.0453 67.5097 81.7142 68.3094 81.7142 69.1428V78.5714C81.7142 79.4048 81.3831 80.2046 80.7938 80.7938C80.2045 81.3831 79.4048 81.7142 78.5713 81.7142H69.1428C68.0197 81.7142 66.9821 82.3133 66.4209 83.2857C65.8597 84.258 65.8597 85.4562 66.4209 86.4285C66.9821 87.4008 68.0197 87.9999 69.1428 87.9999H78.5713C81.0723 87.9999 83.4701 87.0066 85.2387 85.2387C87.0065 83.4702 87.9999 81.0724 87.9999 78.5714V69.1428C87.9999 68.3094 87.6688 67.5097 87.0795 66.9204C86.4902 66.3311 85.6905 66 84.857 66Z"
                fill="#242447"
            />
            <path
                d="M18.8571 0H9.42854C6.92756 0 4.52983 0.993356 2.76122 2.76122C0.993356 4.52978 0 6.92756 0 9.42854V18.8571C0 19.9802 0.599099 21.0178 1.57142 21.579C2.54375 22.1402 3.74196 22.1402 4.71427 21.579C5.68658 21.0178 6.2857 19.9802 6.2857 18.8571V9.42854C6.2857 8.59513 6.61683 7.79538 7.2061 7.2061C7.79538 6.61683 8.59513 6.2857 9.42854 6.2857H18.8571C19.9802 6.2857 21.0178 5.6866 21.579 4.71427C22.1402 3.74195 22.1402 2.54373 21.579 1.57142C21.0178 0.599117 19.9802 0 18.8571 0Z"
                fill="#242447"
            />
            <path
                d="M3.14285 53.4274C3.97626 53.4274 4.77602 53.0962 5.36529 52.507C5.95457 51.9177 6.2857 51.1179 6.2857 50.2845V37.7131C6.2857 36.59 5.6866 35.5525 4.71427 34.9912C3.74195 34.43 2.54373 34.43 1.57142 34.9912C0.599117 35.5525 0 36.59 0 37.7131V50.2845C0 51.1179 0.331131 51.9177 0.920406 52.507C1.50968 53.0962 2.30944 53.4274 3.14285 53.4274Z"
                fill="#242447"
            />
            <path
                d="M84.8577 34.5703C84.0243 34.5703 83.2245 34.9014 82.6352 35.4907C82.046 36.08 81.7148 36.8797 81.7148 37.7132V50.2846C81.7148 51.4077 82.3139 52.4452 83.2863 53.0064C84.2586 53.5677 85.4568 53.5677 86.4291 53.0064C87.4014 52.4452 88.0005 51.4077 88.0005 50.2846V37.7132C88.0005 36.8797 87.6694 36.08 87.0801 35.4907C86.4909 34.9014 85.6911 34.5703 84.8577 34.5703Z"
                fill="#242447"
            />
            <path
                d="M50.2845 81.7148H37.7131C36.59 81.7148 35.5525 82.3139 34.9912 83.2863C34.43 84.2586 34.43 85.4568 34.9912 86.4291C35.5525 87.4014 36.59 88.0005 37.7131 88.0005H50.2845C51.4077 88.0005 52.4452 87.4014 53.0064 86.4291C53.5676 85.4568 53.5676 84.2586 53.0064 83.2863C52.4452 82.314 51.4076 81.7148 50.2845 81.7148Z"
                fill="#242447"
            />
            <path
                d="M50.2845 0H37.7131C36.59 0 35.5525 0.599099 34.9912 1.57142C34.43 2.54375 34.43 3.74196 34.9912 4.71427C35.5525 5.68658 36.59 6.2857 37.7131 6.2857H50.2845C51.4077 6.2857 52.4452 5.6866 53.0064 4.71427C53.5676 3.74195 53.5676 2.54373 53.0064 1.57142C52.4452 0.599117 51.4076 0 50.2845 0Z"
                fill="#242447"
            />
        </svg>
    );
};

export default Empty;
