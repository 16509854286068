
import { NFTStorage, Blob } from "nft.storage";

import algosdk from "algosdk";
import { CID } from "multiformats/cid";
import axios from "axios";
import Dev from "./Dev";

let account = algosdk.mnemonicToSecretKey(process.env.REACT_APP_WALLET);

const client = new NFTStorage({
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEU4ZEJENzI3YzExMDFGQWU4Q2I4YUJlOTZBQWExZjkxRTJjQjMyODIiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3NDE1MzQ2MTc0MiwibmFtZSI6Im11c2lpIn0.ZbOM0bwDFnEKxi4yKPV18a94LaPQceuFbAAY0Q2RMaM",
});

const node = Dev.node

const algodClient = new algosdk.Algodv2(
    "",
    node,
    443
);

async function pay(pera, accountAddress, nft, totalPrice, setProgress) {

    const emptyParams = await algodClient.getTransactionParams().do();

    const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=algorand&vs_currencies=brl`,
        {
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            crossDomain: true,
        }
    );

    console.log(response.data.algorand.brl)
    const artistPrice = (parseFloat(totalPrice) / parseFloat(response.data.algorand.brl)) * 0.9048 * 1000000;
    const musiiPrice = (parseFloat(totalPrice) / parseFloat(response.data.algorand.brl) * 0.0952 * 1000000) - 110000;

    console.log(artistPrice)
    console.log(musiiPrice)

    const artistPayment = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: accountAddress,
        to: nft.wallet,
        amount: parseInt(artistPrice),
        suggestedParams: emptyParams,
    });

    const musiiPayment = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: accountAddress,
        to: "JK3TIANG6XP4L6PXKKSSRFC4W5BPUTVHH4UGFKK635DD32N7YDVRM2BE4Y",
        amount: parseInt(musiiPrice),
        suggestedParams: emptyParams,
    });

    const walletPayment = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: accountAddress,
        to: "BCUS7VHRCIM65APNT7YF2CEGF7SO5Y2AOAE3272KMK4A4RA3J4UUBTN3UA",
        amount: 110000,
        suggestedParams: emptyParams,
    });

    setProgress(5)

    const signOptInAndTransferMoneyNft = [
        { txn: artistPayment, signers: [accountAddress] },
        { txn: musiiPayment, signers: [accountAddress] },
        { txn: walletPayment, signers: [accountAddress] },
    ];

    const signedOptIn = await pera.signTransaction([
        signOptInAndTransferMoneyNft
    ]);

    const sendRawTransactionArtist = await algodClient
        .sendRawTransaction(signedOptIn[0])
        .do();

    await algosdk.waitForConfirmation(
        algodClient,
        sendRawTransactionArtist.txId,
        4
    );

    const sendRawTransactionMusii = await algodClient
        .sendRawTransaction(signedOptIn[1])
        .do();

    await algosdk.waitForConfirmation(
        algodClient,
        sendRawTransactionMusii.txId,
        4
    );
    setProgress(15)

    const sendRawTransactionWallet = await algodClient
        .sendRawTransaction(signedOptIn[2])
        .do();

    await algosdk.waitForConfirmation(
        algodClient,
        sendRawTransactionWallet.txId,
        4
    );

}

const mint = async (pera, accountAddress, nft, setProgress) => {
    console.log("Minting...");

    //const imagePath = path.join(basePath, "src/2500.png");
    //const imageBuffer = fs.readFileSync(imagePath);

    const response = await axios.get(nft.image.split('?')[0], { responseType: 'arraybuffer' })
    const imageBuffer = Buffer.from(response.data, "utf-8")
    setProgress(20)
    let imageCID = "";
    let videoCID = "";
    let metadataCID = "";
    let url = "";
    let reserveAddress = "";

    console.log(imageBuffer)

    //const { createHash } = await import('crypto');

    // Get sha-256 checksum of image
    /*imageChecksum = createHash("sha256")
        .update(imageBuffer)
        .digest("base64");*/

    // Pin image to IPFS and get CID
    //const readableStreamForImage = fs.createReadStream(imagePath);
    //const readableStreamForImage = fs.createReadStream(imagePath);

    try {
        const someData = new Blob([imageBuffer], { type: "image/png" });
        const cid = await client.storeBlob(someData);

        /*const response = await pinata.pinFileToIPFS(readableStreamForImage, {
            pinataMetadata: {
                name: "2017.png",
            },
            pinataOptions: {
                cidVersion: 1,
                wrapWithDirectory: true
            },
        });*/

        console.log(cid);
        //imageCID = response.IpfsHash;
        imageCID = cid;
    } catch (error) {
        console.error("error pinning image to IPFS", error);
    }

    const responseVideo = await axios.get(nft.video.split('?')[0], { responseType: 'arraybuffer' })
    const videoBuffer = Buffer.from(responseVideo.data, "utf-8")

    setProgress(30)

    try {
        const someData = new Blob([videoBuffer], { type: "video/mp4" });
        const cid = await client.storeBlob(someData);

        /*const response = await pinata.pinFileToIPFS(readableStreamForImage, {
            pinataMetadata: {
                name: "2017.png",
            },
            pinataOptions: {
                cidVersion: 1,
                wrapWithDirectory: true
            },
        });*/

        console.log(cid);
        //imageCID = response.IpfsHash;
        videoCID = cid;
    } catch (error) {
        console.error("error pinning image to IPFS", error);
    }

    setProgress(40)
    // Pin metadata JSON to IPFS and get CID
    const metadata = {
        standard: "arc3",
        name: nft.name,
        description: nft.description,
        image: "ipfs://" + imageCID,
        image_mimetype: "image/png",
        collection: nft.name,
        animation_url: "ipfs://" + videoCID,
        animation_url_mimetype: "video/mp4",
        properties: {
            artistName: nft.artistName,
            artistWallet: nft.wallet
        }
    };

    try {
        /*const response = await pinata.pinJSONToIPFS(metadata, {
            pinataMetadata: {
                name: "2017.json",
            },
            pinataOptions: {
                cidVersion: 1,
                wrapWithDirectory: true,
            },
        });*/

        const str = JSON.stringify(metadata);
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
            type: "application/json;charset=utf-8",
        });

        const metadataResp = await client.storeBlob(blob);

        console.log(metadataResp);

        //metadataCID = response.IpfsHash;
        metadataCID = metadataResp;
    } catch (error) {
        console.error("error pinning metadata to IPFS", error);
    }

    // Decode the metadata CID to derive the Reserve Address and URL
    const decodedCID = CID.parse(metadataCID);

    // Derive the Reserve Address
    reserveAddress = algosdk.encodeAddress(
        Uint8Array.from(Buffer.from(decodedCID.multihash.digest))
    );

    // Derive the URL
    const getCodec = (code) => {
        // As per multiformats table
        // https://github.com/multiformats/multicodec/blob/master/table.csv#L9
        switch (code.toString(16)) {
            case "55":
                return "raw";
            case "70":
                return "dag-pb";
        }
    };

    const version = decodedCID.version;
    const code = decodedCID.code;
    const codec = getCodec(code);

    url = `template-ipfs://{ipfscid:${version}:${codec}:reserve:sha2-256}`;

    // Mint the NFT!
    /*const MNEMONIC = process.env.MNEMONIC;
    const { addr: ADDRESS, sk: SECRET_KEY } =
        algosdk.mnemonicToSecretKey(MNEMONIC);*/

    try {
        const suggestedParams = await algodClient.getTransactionParams().do();

        const transaction =
            algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
                from: "BCUS7VHRCIM65APNT7YF2CEGF7SO5Y2AOAE3272KMK4A4RA3J4UUBTN3UA",
                assetName: nft.name,
                unitName: `${nft.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "").slice(0, 8).toUpperCase()}`,
                assetURL: url,
                manager: nft.wallet,
                reserve: reserveAddress,
                decimals: 0,
                total: 1,
                suggestedParams,
                defaultFrozen: false,
            });
        setProgress(50)
        console.log(accountAddress)
        /*const signNft = await pera.signTransaction([
            [{ txn: transaction, signers: [accountAddress] }],
        ]);*/
        let signNft = transaction.signTxn(account.sk);
        console.log(transaction, reserveAddress, url, signNft)
        //const signedTransaction = transaction.signTxn(SECRET_KEY);
        //const transactionId = transaction.txID().toString();


        //await algodClient.sendRawTransaction(signedTransaction).do();

        /*const confirmedTxn = await algosdk.waitForConfirmation(
            algodClient,
            transactionId,
            4
        );*/

        const rawTrans = await algodClient.sendRawTransaction(signNft).do();
        console.log(rawTrans)
        const confirmedTxn = await algosdk.waitForConfirmation(
            algodClient,
            rawTrans.txId,
            4
        );

        setProgress(65)

        console.log("Succesfully minted!");
        console.log("\n");
        console.log("Asset ID:", confirmedTxn["asset-index"]);
        console.log("URL:", url);
        console.log("Reserve Address:", reserveAddress);
        console.log("Metadata CID:", metadataCID);
        console.log("Image CID:", imageCID);
        console.log("\n");
        console.log(
            "View your NFT at: ",
            "https://arc3.xyz/nft/" + confirmedTxn["asset-index"]
        );
        console.log("\n");
        return confirmedTxn["asset-index"]
    } catch (error) {
        console.error("error minting NFT", error);
    }
}

async function transfer(pera, accountAddress, assetId, setProgress) {

    const emptyParams = await algodClient.getTransactionParams().do();

    const transferOptInNft =
        algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            from: accountAddress,
            to: accountAddress,
            assetIndex: assetId,
            amount: 0,
            suggestedParams: emptyParams,
        });

    const accountAmout = await algodClient.accountInformation(accountAddress).do()

    if (accountAmout.amount <= 200000) {
        const optInTxn =
            algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                from: account.addr,
                to: accountAddress,
                amount: 120000,
                suggestedParams: emptyParams,
            });

        const signedMusii = optInTxn.signTxn(account.sk);

        await algodClient.sendRawTransaction(signedMusii).do();
    }
    setProgress(75)

    const transferNft =
        algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            from: account.addr,
            to: accountAddress,
            assetIndex: assetId,
            amount: 1,
            suggestedParams: emptyParams,
        });

    const signOptInAndTransferMoneyNft = [
        { txn: transferOptInNft, signers: [accountAddress] },
    ];

    setProgress(80)
    const signedOptIn = await pera.signTransaction([
        signOptInAndTransferMoneyNft
    ]);

    const sendRawTransactionTransferOptIn = await algodClient
        .sendRawTransaction(signedOptIn[0])
        .do();

    await algosdk.waitForConfirmation(
        algodClient,
        sendRawTransactionTransferOptIn.txId,
        4
    );

    setProgress(90)

    let rawSignedTxn = transferNft.signTxn(account.sk);

    let sendRawTransactionTransferNft = await algodClient.sendRawTransaction(rawSignedTxn).do();

    await algosdk.waitForConfirmation(
        algodClient,
        sendRawTransactionTransferNft.txId,
        4
    );
}


export default {
    mint,
    transfer,
    pay
}
