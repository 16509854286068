const Close = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.41391 17.9137C0.632863 18.6947 0.632863 19.9611 1.41391 20.7421C2.19496 21.5232 3.46129 21.5232 4.24234 20.7421L11.0001 13.9843L18.2559 21.24C19.0369 22.0211 20.3032 22.0211 21.0843 21.24C21.8653 20.459 21.8653 19.1926 21.0843 18.4116L13.8286 11.1559L20.7411 4.24331C21.5222 3.46227 21.5222 2.19594 20.7411 1.41489C19.9601 0.633839 18.6938 0.63384 17.9127 1.41489L11.0001 8.32746L4.58548 1.91279C3.80444 1.13174 2.53811 1.13174 1.75706 1.91279C0.976009 2.69384 0.976009 3.96017 1.75706 4.74122L8.17172 11.1559L1.41391 17.9137Z"
                fill="#9E9EB8"
            />
        </svg>
    );
};

export default Close;
