import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Fonts from "../utils/Fonts";

import Algorand from "../assets/algorand.png";
import Borderless from "../assets/borderless.png";
import Brangels from "../assets/brangels.png";
import Rokker from "../assets/rokker.png";
import { useTranslation } from "react-i18next";

function Partners({ isMobile }) {
    const [t, i18n] = useTranslation("common");

    return (
        <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            marginTop={isMobile ? "48px" : "64px"}
            id="partners"
            style={{
                display: "flex",
                padding: isMobile ? "20px 30px" : "56px",
                alignItems: isMobile ? "flex-start" : "center",
                borderRadius: 16,
                flexDirection: isMobile ? "column" : "row",
            }}
        >

            <Grid
                xs={12}
                md={12}
                xl={12}
            >
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h2BoldSize2,
                        color: "#ABABCC",
                        whiteSpace: "break-spaces",
                        textAlign: "center"
                    }}
                >
                    {t('partners')}
                </Typography>
                <Box style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <Box
                        style={{
                            width: "35px",
                            height: "6px",
                            background:
                                "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                            borderRadius: "3px",
                            marginTop: "8px",
                            marginBottom: "16px",
                        }}
                    ></Box>
                </Box>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].h1BoldSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces",
                        marginTop: 4,
                        marginBottom: 4,
                        textAlign: "center"
                    }}
                >
                    {t('partnersTitle')}
                </Typography>
            </Grid>
            <Grid
                xs={12}
                md={12}
                xl={12}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "96px"
                }}
            >
                <a
                    href={"https://www.algorand.foundation/"}
                    target={"_blank"}
                    rel={"noreferrer"}
                    style={{
                        textDecoration: "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "42px 45px",

                        width: "248px",
                        height: "123px",

                        border: "2px solid #F0F0FA",
                        borderRadius: "16px 0px 0px 16px",
                    }}>
                    <img src={Algorand} alt="algorand" />
                </a>
                <a
                    href={"https://borderlesscapital.io/"}
                    target={"_blank"}
                    rel={"noreferrer"}
                    style={{
                        textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "42px 45px",

                    width: "248px",
                    height: "123px",

                    border: "2px solid #F0F0FA",
                }}>
                    <img src={Borderless} alt="Borderless" />
                </a>
                <a
                    href={"https://brangels.global/"}
                    target={"_blank"}
                    rel={"noreferrer"}
                    style={{
                        textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "42px 45px",

                    width: "248px",
                    height: "123px",

                    border: "2px solid #F0F0FA",
                }}>
                    <img src={Brangels} alt="Brangels" />
                </a>
                <a
                    href={"https://www.rokk3r.com/"}
                    target={"_blank"}
                    rel={"noreferrer"}
                    style={{
                        textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "42px 45px",

                    width: "248px",
                    height: "123px",

                    border: "2px solid #F0F0FA",
                    borderRadius: "0px 16px 16px 0px",
                }}>
                    <img src={Rokker} alt="Rokker" />
                </a>

            </Grid>
        </Grid >
    );
}

export default Partners;
