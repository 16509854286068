const Discord = ({size = 48}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM26.9416 16.2868C28.4117 16.5406 29.8177 16.9839 31.1289 17.5855C31.1402 17.5904 31.1499 17.5986 31.1556 17.61C33.4787 21.027 34.6261 24.8815 34.1971 29.3189C34.1955 29.3376 34.1858 29.3548 34.1704 29.3662C32.4108 30.6584 30.7064 31.4428 29.0255 31.9628C28.9987 31.971 28.9696 31.9612 28.9533 31.9383C28.5649 31.3979 28.2122 30.8282 27.9033 30.2298C27.8855 30.1947 27.9017 30.1523 27.9382 30.1384C28.4984 29.927 29.0312 29.6739 29.5437 29.3744C29.5842 29.3507 29.5866 29.2927 29.5493 29.265C29.4407 29.1842 29.3328 29.0993 29.2298 29.0144C29.2104 28.9989 29.1844 28.9956 29.1625 29.0062C25.8356 30.5433 22.1908 30.5433 18.8242 29.0062C18.8023 28.9964 18.7763 28.9997 18.7577 29.0152C18.6547 29.1001 18.5468 29.1842 18.439 29.265C18.4017 29.2927 18.4049 29.3507 18.4455 29.3744C18.9579 29.6682 19.4907 29.927 20.0502 30.1392C20.0866 30.1531 20.1037 30.1947 20.0858 30.2298C19.7834 30.829 19.4307 31.3988 19.035 31.9391C19.0179 31.9612 18.9896 31.971 18.9628 31.9628C17.29 31.4428 15.5856 30.6584 13.8261 29.3662C13.8115 29.3548 13.8009 29.3368 13.7993 29.3181C13.4409 25.4799 14.1715 21.5935 16.8384 17.6092C16.8449 17.5986 16.8546 17.5904 16.8659 17.5855C18.1779 16.9831 19.5839 16.5398 21.0532 16.2868C21.08 16.2827 21.1067 16.2949 21.1205 16.3186C21.3021 16.6402 21.5097 17.0525 21.65 17.3896C23.1987 17.1529 24.7718 17.1529 26.3529 17.3896C26.4932 17.0598 26.6935 16.6402 26.8743 16.3186C26.8881 16.2941 26.9149 16.2819 26.9416 16.2868ZM18.7398 24.9076C18.7398 26.0504 19.575 26.981 20.5886 26.981C21.6184 26.981 22.4373 26.0504 22.4373 24.9076C22.4536 23.773 21.6265 22.8343 20.5886 22.8343C19.5588 22.8343 18.7398 23.7648 18.7398 24.9076ZM27.4241 26.981C26.4105 26.981 25.5753 26.0504 25.5753 24.9076C25.5753 23.7648 26.3943 22.8343 27.4241 22.8343C28.462 22.8343 29.289 23.773 29.2728 24.9076C29.2728 26.0504 28.462 26.981 27.4241 26.981Z"
                fill="#EBEBFA"
            />
        </svg>
    );
};

export default Discord;
