const WalletConnected = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.5272 3.49296H6.38672L14.1573 1.1117C15.4895 0.70258 16.9005 1.45262 17.307 2.78487L17.5272 3.49296ZM3.55413 4.41016H16.7455C18.138 4.41016 19.2684 5.54047 19.2684 6.93303V9.88076C19.2684 9.88338 19.2657 9.88338 19.2657 9.88338H12.8851C11.8466 9.88338 11.0047 10.7619 11.0651 11.8136C11.1201 12.7813 11.9462 13.5287 12.9166 13.5287H19.2657C19.2684 13.5287 19.2684 13.5313 19.2684 13.5313V15.1383C17.3114 15.5872 15.7528 17.08 15.21 18.9993H3.55413C2.16156 18.9993 1.03125 17.869 1.03125 16.4764V6.93303C1.03125 5.54047 2.16156 4.41016 3.55413 4.41016ZM12.8853 12.6163C13.3888 12.6163 13.7979 12.2072 13.7979 11.7037C13.7979 11.2001 13.3888 10.791 12.8853 10.791C12.3818 10.791 11.9727 11.2001 11.9727 11.7037C11.9727 12.2072 12.3818 12.6163 12.8853 12.6163Z"
                fill="#ABABCC"
            />
            <circle cx="20.5" cy="20.5" r="3.5" fill="#5CB88D" />
        </svg>
    );
};

export default WalletConnected;
