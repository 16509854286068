const Pix = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="outros/site/icones/pix">
                <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M3.21319 9.07098L9.07098 3.21318C10.6886 1.59561 13.3112 1.59561 14.9288 3.21318L20.7868 9.07098C22.3696 10.6537 22.4035 13.1991 20.8886 14.8233C20.5293 15.3189 20.087 15.7605 19.5961 16.1194L14.9288 20.7866C13.3112 22.4042 10.6886 22.4042 9.07098 20.7866L4.38947 16.1051C3.9051 15.7499 3.46885 15.3142 3.11347 14.8256C1.59632 13.2015 1.62955 10.6546 3.21319 9.07098ZM5.66236 14.4491L4.69508 13.4818C4.41108 13.0596 4.23337 12.5832 4.23337 12.1041C4.23337 10.2688 6.84097 8.47452 8.01897 9.58032L8.58249 10.188C9.80421 11.5087 10.6597 12.0961 11.9726 12.1041C10.7159 12.1087 9.91348 12.6132 8.79287 13.7946L8.13537 14.5049C7.5386 15.192 6.51255 15.0284 5.66236 14.4491ZM8.14053 16.9273L10.5355 19.3222C11.3445 20.1311 12.6557 20.1311 13.4645 19.3222L15.8503 16.9364C15.3075 16.7607 14.7957 16.4316 14.3509 15.9189L13.6038 15.1168C12.9155 14.4113 12.5112 14.1764 11.9971 14.1751C11.4148 14.1737 10.9838 14.4686 10.115 15.4131L9.54195 16.0355C9.11833 16.4776 8.64199 16.7671 8.14053 16.9273ZM19.3061 13.4806L18.323 14.4638C17.5222 15.0077 16.5716 15.1827 15.9808 14.6279L15.7244 14.3544L15.4415 14.0462C14.1889 12.6852 13.3615 12.1119 12.0185 12.1041C13.3189 12.0993 14.0991 11.5878 15.3235 10.287L15.8884 9.67636C17.016 8.37754 19.7664 10.2193 19.7664 12.1042L19.7664 12.1041C19.7664 12.5826 19.5891 13.0584 19.3061 13.4806ZM16.0208 7.23394C15.4601 7.38094 14.9265 7.68333 14.4575 8.17272L13.8415 8.8396L13.539 9.1535C12.8755 9.81974 12.4907 10.0331 11.9997 10.0331C11.4708 10.0331 11.0274 9.75458 10.2716 8.9617L9.64931 8.29014C9.16051 7.72687 8.59095 7.38508 7.98826 7.22489L10.5355 4.67764C11.3443 3.86882 12.6555 3.86882 13.4645 4.67764H13.4644L16.0208 7.23394Z" fill="#F0F0FA" />
            </g>
        </svg>
    );
};

export default Pix;
