import React, { useEffect, useState, useContext } from "react";
import { createContext } from "react";
import { PeraWalletConnect } from "@perawallet/connect";
import StorageEnum from "../enums/StorageEnum";
import Storage from "../utils/Storage";
import { useUser } from "./useUser";

const peraWallet = new PeraWalletConnect();

export const PeraContext = createContext({});

export function PeraProvider({ children }) {
    const [accountAddress, setAccountAddress] = useState(null);
    const [pera, setPera] = useState(null);
    const userHook = useUser();

    async function logOut() {
        const user = userHook.user

        delete user.wallet
        await Storage.setItem(StorageEnum.USER, user);
        
        userHook.logIn();

        peraWallet.disconnect();

        setPera(null);
        setAccountAddress(null);
    }

    useEffect(() => {
        async function checkPera() {
            peraWallet
                .reconnectSession()
                .then(async (accounts) => {
                    peraWallet.connector.on("disconnect", () => {
                        peraWallet.disconnect();

                        setPera(null);
                        setAccountAddress(null);
                    });

                    if (accounts.length) {
                        setAccountAddress(accounts[0]);
                    }

                    setPera(peraWallet);
                })
                .catch((e) => { });

            setPera(peraWallet);
        }

        checkPera();
    }, []);

    async function logIn(handleClose) {
        peraWallet
            .connect()
            .then(async (newAccounts) => {
                peraWallet.connector.on("disconnect", logOut);

                /*await axios.put(`https://jhh71gbi7j.execute-api.us-east-2.amazonaws.com/production/v2/nft/user/${userHook.user.email.toLowerCase()}`,
        {
          wallet: newAccounts[0],
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': userHook.user.auth
          },
          crossDomain: true
        });

      await Storage.setItem(StorageEnum.USER, { ...userHook.user, wallet: newAccounts[0] });*/
                //userHook.logIn()

                await Storage.setItem(StorageEnum.USER, {
                    ...userHook.user,
                    wallet: newAccounts[0],
                });
                userHook.logIn();

                setAccountAddress(newAccounts[0]);
                if (handleClose) {
                    handleClose();
                }
            })
            .catch((e) => { });

        setPera(peraWallet);
    }

    return (
        <PeraContext.Provider
            value={{
                accountAddress,
                pera,
                logOut,
                logIn,
            }}
        >
            {children}
        </PeraContext.Provider>
    );
}

export function usePera() {
    const context = useContext(PeraContext);

    return context;
}
