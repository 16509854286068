import {
    Typography,
    Box,
} from "@mui/material";
import Fonts from "../../utils/Fonts";

export default function Person({ img, name, position, link, isMobile }) {
    return (
        <a
            href={link}
            target={"_blank"}
            rel={"noreferrer"}
            style={{
                position: "relative", height: "340px", display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                textDecoration: "none",
            }}>

            <img src={img} alt="Arthur" style={{ position: "absolute", top: 0 }} />
            <Box style={{
                borderRadius: "16px 16px 0px 0px",
                width: "208px",
                height: "128px",
                background: "linear-gradient(130.54deg, rgba(235, 235, 250, 0.1875) 0%, rgba(235, 235, 250, 0.05) 102.22%)"
            }}>
            </Box>
            <Box style={{
                borderRadius: "0px 0px 16px 16px",
                width: "160px",
                height: "68px",
                padding: "24px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)"
            }}>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                        color: "#F0F0FA",
                        marginBottom: 4,
                        textAlign: "center"
                    }}
                >
                    {name}
                </Typography>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].h3RegularSize1,
                        color: "#F0F0FA",
                        marginTop: 4,
                        textAlign: "center"
                    }}
                >
                    {position}
                </Typography>
            </Box>
        </a>
    );
}
