import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box } from "@mui/material";
import Featured from "../components/Featured";
import Footer from "../components/Footer";
import Categories from "../components/Categories";
import LiveCollections from "../components/LiveCollections";
import VerifiedArtists from "../components/VerifiedArtists";
import Living from "../components/Living";
import Faq from "../components/Faq";
import Musii from "../components/Musii";

const Home = () => {
    const [showModalConnect, setShowModalConnect] = useState(false);
    const [redirectModal, setRedirectModal] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} redirectModal={redirectModal} setRedirectModal={setRedirectModal} isMobile={isMobile} />
            <Musii isMobile={isMobile} setShowModalConnect={setShowModalConnect} setRedirectModal={setRedirectModal} />
            <Featured isMobile={isMobile} />
            <LiveCollections isMobile={isMobile}/>
            <Categories isMobile={isMobile} />
            <VerifiedArtists isMobile={isMobile}/>
            <Living isMobile={isMobile} setShowModalConnect={setShowModalConnect} />
            <Faq isMobile={isMobile} />
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default Home;
