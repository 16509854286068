import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

import UploadFile from "../commons/UploadFile";
import MintNftCard from "../commons/MintNftCard";
import Fonts from "../../utils/Fonts";
import TypeNfts from "../../utils/TypeNfts";
import { Check } from "@mui/icons-material";
import Collectible from "../../assets/collectible";
import Product from "../../assets/product";
import Experience from "../../assets/experience";
import Royaltie from "../../assets/royaltie";
import Verified from "../../assets/verified";

import { NumericFormat } from "react-number-format";
import Up from "../../assets/up";
import Down from "../../assets/down";
import axios from "axios";
import StorageEnum from "../../enums/StorageEnum";
import Storage from "../../utils/Storage";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return formattedValue === "" || (floatValue > 0 && floatValue <= 9999.99);
            }}
            allowNegative={false}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix="R$"
        />
    );
});

const SuplyFormatCustom = React.forwardRef(function SuplyFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return formattedValue === "" || (formattedValue.length < 6 && floatValue > 0 && floatValue <= 99999);
            }}
            allowedDecimalSeparators={[",", "."]}
            allowNegative={false}
            allowLeadingZeros={true}
            decimalScale={0}
        />
    );
});

const Step2 = ({ setStep, nft, setNft }) => {
    const [t, i18n] = useTranslation("common");

    const [width, setWidth] = useState(window.innerWidth);
    const [categorieField, setCategorie] = useState(nft.categorie ? nft.categorie : "COLLECTIBLES");
    const [subCategorieField, setSubCategorie] = useState(nft.subCategorie ? nft.subCategorie : "Collectible");

    const [imageField, setImage] = useState(nft.image ? nft.image : "");
    const [videoField, setVideo] = useState(nft.video ? nft.video : "");

    const [nameField, setName] = useState(nft.name ? nft.name : "");
    const [descriptionField, setDescription] = useState(nft.description ? nft.description : "");
    const [linkField, setLink] = useState("");
    const [priceField, setPrice] = useState(nft.price ? nft.price : "");
    const [totalSuplyField, setTotalSuply] = useState(nft.totalSuply ? nft.totalSuply : "");
    const [tagsField, setTags] = useState(nft.tags ? nft.tags : "");

    const [distributorProfileImage, setDistributorProfileImage] = useState(nft.distributorProfileImage ? nft.distributorProfileImage : "");
    const [stmDashboardImage, setStmDashboardImage] = useState(nft.stmDashboardImage ? nft.stmDashboardImage : "");

    const [isrcField, setIsrc] = useState(nft.isrc ? nft.isrc : "");
    const [associationField, setAssociation] = useState(nft.association ? nft.association : "");
    const [associationFieldOther, setAssociationOther] = useState(nft.associationOther ? nft.associationOther : "");

    const [open, setOpen] = useState(false);
    const [canClick, setCanClick] = useState(false);

    const [validateLink, setValidateLink] = useState(false);

    const { user, logIn } = useUser();
    const navigate = useNavigate();

    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    function isValidHttpUrl(link) {
        if (link === "") {
            return true;
        }

        const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(link);
    }

    useEffect(() => {
        if (
            imageField !== "" &&
            videoField !== "" &&
            isrcField !== "" &&
            ((associationField === "Other" && associationFieldOther !== "") || (associationField !== "" && associationField !== "Other")) &&
            isValidHttpUrl(linkField) &&
            nameField !== "" &&
            descriptionField !== "" &&
            distributorProfileImage !== "" &&
            stmDashboardImage !== "" &&
            priceField &&
            priceField !== "" &&
            priceField !== "0" &&
            priceField !== 0 &&
            totalSuplyField &&
            totalSuplyField !== "" &&
            totalSuplyField !== "0" &&
            totalSuplyField !== 0
        ) {
            setNft({
                ...nft,
                imageField,
                nameField,
                priceField,
                totalSuplyField,
                categorieField,
                subCategorieField,
            });
            setCanClick(true);
        } else {
            setNft({
                ...nft,
                imageField,
                nameField,
                priceField,
                totalSuplyField,
                categorieField,
                subCategorieField,
            });
            setCanClick(false);
        }
    }, [
        imageField,
        videoField,
        isrcField,
        associationField,
        associationFieldOther,
        linkField,
        nameField,
        descriptionField,
        distributorProfileImage,
        stmDashboardImage,
        priceField,
        totalSuplyField,
        categorieField,
        subCategorieField,
        nft,
        setNft,
    ]);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <Box>
            <Grid container position="relative" xs={12} marginBottom={12}>
                <Grid xs={12} md={12}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('createCollectionTitle')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    paddingRight={isMobile ? 4 : 0}
                    paddingLeft={isMobile ? 4 : 8}
                    marginTop={6}
                    gap={8}
                    xs={12}
                    md={10}
                    lg={10}
                    xl={9}
                    display={"flex"}
                    justifyContent={isMobile ? "center" : ""}
                >
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"20px"}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                                cursor: "pointer",
                            }}
                            onClick={() => setStep(0)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('createCollectionStepProfile')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#7272A3",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    2
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#CACAE5",
                                }}
                            >
                                {t('createCollectionStepCollection')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "rgba(114, 114, 163, 0.4)",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "rgba(114, 114, 163, 0.4)",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "rgba(114, 114, 163, 0.4)",
                                }}
                            >
                                {t('createCollectionStepWallet')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                color: "#FB5E87",
                                fontFamily: "Ubuntu",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "20px",
                                lineHeight: "32px",
                                marginLeft: 8,
                                whiteSpace: "break-spaces",
                                wordWrap: "break-word"
                            }}
                        >
                            {nft.recusedReason}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                textAlign: "left",
                                padding: "16px 0px",
                            }}
                        >
                            {t('createCollectionIncludeBenefits')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                color: "#7272A3",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 0px",
                            }}
                        >
                            {t('createCollectionIncludeBenefitsInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                        border={"2px solid #313152"}
                        borderRadius={"24px"}
                        padding="24px"
                    >
                        <Grid
                            container
                            xs={12}
                            md={12}
                            gap={4}
                            display={"flex"}
                            onClick={() => setOpen(!open)}
                            style={{ cursor: "pointer" }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3BoldSize1,
                                    color: "#ABABCC",
                                    marginBottom: 8,
                                    flex: 1,
                                }}
                            >
                                {t('createCollectionCategories')}
                            </Typography>
                            <Box>{open ? <Up /> : <Down />}</Box>
                        </Grid>
                        {open && (
                            <Grid
                                container
                                xs={12}
                                md={12}
                                gap={4}
                                display={"flex"}
                                marginTop={"24px"}
                            >
                                <Box
                                    style={{
                                        backgroundColor: "#301D3D",
                                        padding: "8px 24px",
                                        borderRadius: 32,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginTop: "24px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Box
                                        style={{
                                            marginRight: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Collectible size="24" />
                                    </Box>
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2BoldSize2,
                                            color: "#F5A6F2",
                                        }}
                                    >
                                        {t('categoryCollectible')}
                                    </Typography>
                                </Box>
                                <Grid
                                    xs={12}
                                    md={12}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["collectibles"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                boxShadow:
                                                    categorieField ===
                                                        "COLLECTIBLES"
                                                        ? "0px 2px 24px rgba(236, 152, 233, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("COLLECTIBLES");
                                                setSubCategorie("Collectible");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["collectibles"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "collectibles"
                                                    ].color,
                                                }}
                                            >
                                                {t('Collectible')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    md={12}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].b1RegularSize1,
                                            color: "#7272A3",
                                            marginTop: 8,
                                            textAlign: "left",
                                            padding: "4px 0px",
                                        }}
                                    >
                                        {t('createCollectionCategoriesCollectiblesInfo')}
                                    </Typography>
                                </Grid>
                                <Box
                                    style={{
                                        backgroundColor: "#211C45",
                                        padding: "8px 24px",
                                        borderRadius: 32,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginTop: "24px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Box
                                        style={{
                                            marginRight: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Product size="24" />
                                    </Box>
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2BoldSize2,
                                            color: "#806FDC",
                                        }}
                                    >
                                        {t('categoryProduct')}
                                    </Typography>
                                </Box>
                                <Grid
                                    xs={12}
                                    md={12}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["products"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField === "CD"
                                                        ? "0px 2px 24px rgba(128, 111, 220, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("PRODUCTS");
                                                setSubCategorie("CD");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["products"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts["products"]
                                                        .color,
                                                }}
                                            >
                                                {t('CD')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["products"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Vinyl"
                                                        ? "0px 2px 24px rgba(128, 111, 220, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("PRODUCTS");
                                                setSubCategorie("Vinyl");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["products"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts["products"]
                                                        .color,
                                                }}
                                            >
                                                {t('Vinyl')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["products"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Autographed Items"
                                                        ? "0px 2px 24px rgba(128, 111, 220, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("PRODUCTS");
                                                setSubCategorie(
                                                    "Autographed Items"
                                                );
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["products"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts["products"]
                                                        .color,
                                                }}
                                            >
                                                {t('Autographed Items')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["products"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Merchandising"
                                                        ? "0px 2px 24px rgba(128, 111, 220, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("PRODUCTS");
                                                setSubCategorie(
                                                    "Merchandising"
                                                );
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["products"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts["products"]
                                                        .color,
                                                }}
                                            >
                                                {t('Merchandising')}
                                            </Typography>
                                        </Box>

                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["products"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Phygital"
                                                        ? "0px 2px 24px rgba(128, 111, 220, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("PRODUCTS");
                                                setSubCategorie("Phygital");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["products"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts["products"]
                                                        .color,
                                                }}
                                            >
                                                {t('Phygital')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid
                                    xs={12}
                                    md={12}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].b1RegularSize1,
                                            color: "#7272A3",
                                            marginTop: 8,
                                            textAlign: "left",
                                            padding: "4px 0px",
                                        }}
                                    >
                                        {t('createCollectionCategoriesProductsInfo')}
                                    </Typography>
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].b1BoldSize1,
                                            color: "#7272A3",
                                            textAlign: "left",
                                        }}
                                    >
                                        {t('createCollectionCategoriesProductsInfo2')}
                                    </Typography>
                                </Grid>
                                <Box
                                    style={{
                                        backgroundColor: "#102633",
                                        padding: "8px 24px",
                                        borderRadius: 32,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginTop: "24px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Box
                                        style={{
                                            marginRight: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Experience size="24" />
                                    </Box>
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2BoldSize2,
                                            color: "#49A6A3",
                                        }}
                                    >
                                        {t('categoryExperience')}
                                    </Typography>
                                </Box>
                                <Grid
                                    xs={12}
                                    md={12}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Show Ticket"
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie("Show Ticket");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Show Ticket')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Studio Session"
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie(
                                                    "Studio Session"
                                                );
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Studio Session')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Membership"
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie("Membership");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Membership')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Early Access "
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie(
                                                    "Early Access "
                                                );
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Early Access')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Lesson / Workshop"
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie(
                                                    "Lesson / Workshop"
                                                );
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Lesson / Workshop')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Gift Video"
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie("Gift Video");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Gift Video')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Meet-and-Greet"
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie(
                                                    "Meet-and-Greet"
                                                );
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Meet-and-Greet')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Q&A Forum"
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie("Q&A Forum");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Q&A Forum')}
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["experiences"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "pointer",
                                                marginRight: 8,
                                                boxShadow:
                                                    subCategorieField ===
                                                        "Backstage"
                                                        ? "0px 2px 24px rgba(73, 166, 163, 0.75)"
                                                        : "",
                                            }}
                                            onClick={() => {
                                                setCategorie("EXPERIENCES");
                                                setSubCategorie("Backstage");
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["experiences"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts[
                                                        "experiences"
                                                    ].color,
                                                }}
                                            >
                                                {t('Backstage')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    md={12}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].b1RegularSize1,
                                            color: "#7272A3",
                                            marginTop: 8,
                                            textAlign: "left",
                                            padding: "4px 0px",
                                            display: "flex",
                                        }}
                                    >
                                        {t('createCollectionCategoriesExperiencesInfo')}
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].b1BoldSize1,
                                                color: "#7272A3",
                                                textAlign: "left",
                                            }}
                                        >
                                            {t('createCollectionCategoriesExperiencesInfo2')}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Box
                                    style={{
                                        backgroundColor: "#242447F2",
                                        padding: "8px 24px",
                                        borderRadius: 32,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginTop: "24px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Box
                                        style={{
                                            marginRight: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Royaltie size="24" />
                                    </Box>
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2BoldSize2,
                                            color: "#ABABCC",
                                        }}
                                    >
                                        ROYALTIES
                                    </Typography>
                                </Box>
                                <Grid
                                    xs={12}
                                    md={12}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Box>
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    TypeNfts["royalties"]
                                                        .backgroundColor,
                                                padding: "8px 16px",
                                                borderRadius: 32,
                                                display: "inline-flex",
                                                alignItems: "center",
                                                marginTop: "24px",
                                                cursor: "no-drop",
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {TypeNfts["royalties"].image}
                                            </Box>
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].b1BoldSize2,
                                                    color: TypeNfts["royalties"]
                                                        .color,
                                                }}
                                            >
                                                {"royalties".toUpperCase()}
                                            </Typography>
                                        </Box>

                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].b1RegularSize1,
                                                color: "#7272A3",
                                                marginTop: 8,
                                                textAlign: "left",
                                                padding: "32px 0px",
                                                whiteSpace: "break-spaces",
                                                display: "flex",
                                                alignItems: "flex-end",
                                            }}
                                        >
                                            {t('createCollectionCategoriesRoyaltiesInfo')}
                                            <a
                                                href="https://api.whatsapp.com/send?phone=5511951564022&text=Olá time do Musii, meu nome é XXX. Estou entrando em contato, pois desejo vender royalties das minhas músicas e gostaria de saber mais como funciona."
                                                target={"_blank"}
                                                rel={"noreferrer"}
                                            >
                                                <Typography
                                                    style={{
                                                        ...Fonts[
                                                            isMobile
                                                                ? "mobile"
                                                                : "desktop"
                                                        ].b1BoldSize1,
                                                        color: "#7272A3",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {t('createCollectionCategoriesRoyaltiesInfo2')}
                                                </Typography>
                                            </a>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <UploadFile
                        title={`${t('createCollectionThumbnail')}*`}
                        imageName={`nft`}
                        camera={true}
                        types={"(.png, .jpeg)"}
                        isMobile={isMobile}
                        square={true}
                        state={imageField}
                        setState={setImage}
                        description={
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    color: "#ABABCC",
                                    marginTop: 8,
                                    textAlign: "left",
                                    width: "70%",
                                    maxWidth: "200px",
                                    padding: isMobile ? "0px" : "16px 20px",
                                }}
                            >
                                {t('createCollectionThumbnailInfo')}
                            </Typography>
                        }
                    />
                    <UploadFile
                        title={`${t('createCollectionVideo')}*`}
                        camera={false}
                        types={"(.mp4, .webm, .mov)"}
                        isMobile={isMobile}
                        square={true}
                        state={videoField}
                        setState={setVideo}
                        imageName={`video`}
                        description={
                            <Box
                                style={{
                                    marginTop: 8,
                                    width: "100%",
                                    maxWidth: "200px",
                                    padding: isMobile ? "0px" : "16px 20px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].l1LightSize1,
                                        color: "#ABABCC",
                                    }}
                                >
                                    {t('createCollectionVideoInfo')}
                                </Typography>
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].b1BoldSize2,
                                        color: "#ABABCC",
                                    }}
                                >
                                    {t('createCollectionVideoInfo2')}
                                </Typography>
                            </Box>
                        }
                    />
                    <Grid
                        xs={12}
                        md={2}
                        display={"flex"}
                        flexDirection={"column"}
                    ></Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionName')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionNamePlaceHolder')}
                            value={nameField}
                            onChange={(e) => setName(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionNameInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionDescription')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionDescriptionPlaceHolder')}
                            multiline
                            value={descriptionField}
                            onChange={(e) => setDescription(e.target.value)}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionDescriptionInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionTags')}
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionTagsPlaceHolder')}
                            value={tagsField.toString()}
                            type="text"
                            onChange={(e) => {
                                const strings = e.target.value.split(",");
                                console.log("strings.length", strings.length);
                                if (strings.length > 5) {
                                    console.log("here");
                                    e.target.value =
                                        strings[0] +
                                        "," +
                                        strings[1] +
                                        "," +
                                        strings[2] +
                                        "," +
                                        strings[3] +
                                        "," +
                                        strings[4];
                                } else {
                                    setTags(strings);
                                }
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionTagsInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionExternalURL')}
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionExternalURLPlaceHolder')}
                            type="url"
                            inputProps={{ maxLength: 100 }}
                            error={validateLink && !isValidHttpUrl(linkField)}
                            helperText={
                                validateLink && !isValidHttpUrl(linkField)
                                    ? t('websiteError')
                                    : ""
                            }
                            value={linkField}
                            onChange={(e) => setLink(e.target.value)}
                            onBlur={(e) => {
                                if (!isValidHttpUrl(linkField)) {
                                    setValidateLink(true);
                                } else {
                                    setValidateLink(false);
                                }
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionExternalURLInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionTotalSupply')}*
                        </Typography>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                inputComponent: SuplyFormatCustom,
                            }}
                            variant="standard"
                            placeholder={t('createCollectionTotalSupplyPlaceHolder')}
                            value={totalSuplyField}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            onChange={(e) => {
                                setTotalSuply(e.target.value);
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionTotalSupplyInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionPrice')}*
                        </Typography>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                inputComponent: NumericFormatCustom,
                            }}
                            variant="standard"
                            placeholder={t('createCollectionPricePlaceHolder')}
                            value={priceField}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            onChange={(e) => {
                                setPrice(e.target.value);
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionPriceInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 28px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Verified /> <Box marginRight={1} />
                            {t('createCollectionLegitimacyVerification')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 28px",
                            }}
                        >
                            {t('createCollectionLegitimacyVerificationInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionISRC')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionISRCPlaceHolder')}
                            type="url"
                            inputProps={{ maxLength: 100 }}
                            value={isrcField}
                            onChange={(e) => setIsrc(e.target.value)}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionISRCInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionAssociation')}*
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={associationField}
                                label="Age"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(e) => setAssociation(e.target.value)}
                                disableUnderline
                                sx={{
                                    boxShadow: "none",
                                    border: "0px solid transparent",
                                    borderRadius: "24px",
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,
                                    },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,
                                    },
                                    "& .MuiInputBase-input": {
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].b1RegularSize1,
                                        padding: "16.5px 20px",
                                        border: "0px solid transparent",
                                        color: "#B8B8DB",
                                        borderRadius: "24px",
                                        borderBottomWidth: 0,
                                        background:
                                            "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                    },
                                    "& .Mui-focused": {
                                        boxShadow: "none",
                                        border: "0px solid transparent",
                                        borderRadius: "24px",
                                    },
                                    "& .Mui-selected": {
                                        boxShadow: "none",
                                        border: "0px solid transparent",
                                        borderRadius: "24px",
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                        border: 0,
                                    },
                                    ".MuiSelect-icon": {
                                        color: "#B8B8DB",
                                    },
                                }}
                            >
                                <MenuItem value={"Abramus"}>Abramus</MenuItem>
                                <MenuItem value={"Amar Sombrás"}>
                                    Amar Sombrás
                                </MenuItem>
                                <MenuItem value={"ASSIM"}>ASSIM</MenuItem>
                                <MenuItem value={"SbaceM"}>SbaceM</MenuItem>
                                <MenuItem value={"Sicam"}>Sicam</MenuItem>
                                <MenuItem value={"SOCINPRO"}>SOCINPRO</MenuItem>
                                <MenuItem value={"UBC"}>UBC</MenuItem>
                                <MenuItem value={"Other"}>{t('createCollectionAssociationOther')}:</MenuItem>
                            </Select>
                        </FormControl>
                        {associationField === "Other" && (
                            <TextField
                                InputProps={{ disableUnderline: true }}
                                variant="standard"
                                placeholder={t('createCollectionAssociationOtherPlaceHolder')}
                                type="text"
                                inputProps={{ maxLength: 100 }}
                                value={associationFieldOther}
                                onChange={(e) =>
                                    setAssociationOther(e.target.value)
                                }
                                sx={{
                                    boxShadow: 0,
                                    "& .MuiInputBase-input": {
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].b1RegularSize1,
                                        padding: "16.5px 20px",
                                        border: "0px solid #transparent",
                                        color: "#B8B8DB",
                                        borderRadius: "24px",
                                        borderBottomWidth: 0,
                                        marginTop: 2,
                                        background:
                                            "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                    },
                                }}
                            />
                        )}
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionAssociationInfo')}
                        </Typography>
                    </Grid>
                    <UploadFile
                        title={t('createCollectionStreamingDashboard')}
                        imageName={`stm`}
                        camera={true}
                        types={"(.png, .jpeg)"}
                        isMobile={isMobile}
                        state={stmDashboardImage}
                        setState={setStmDashboardImage}
                        description={
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    color: "#ABABCC",
                                    marginTop: 8,
                                    textAlign: "left",
                                    width: "70%",
                                    maxWidth: "200px",
                                    padding: isMobile ? "0px" : "16px 20px",
                                }}
                            >
                                {t('createCollectionStreamingDashboardInfo')}
                            </Typography>
                        }
                    />
                    <UploadFile
                        title={`${t('createCollectionDistributorProfile')}*`}
                        imageName={`distributor`}
                        camera={true}
                        types={"(.png, .jpeg)"}
                        isMobile={isMobile}
                        state={distributorProfileImage}
                        setState={setDistributorProfileImage}
                        description={
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    color: "#ABABCC",
                                    marginTop: 8,
                                    textAlign: "left",
                                    width: "70%",
                                    maxWidth: "200px",
                                    padding: isMobile ? "0px" : "16px 20px",
                                }}
                            >
                                {t('createCollectionDistributorProfileInfo')}
                            </Typography>
                        }
                    />
                </Grid>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Grid
                        marginTop={4}
                        gap={8}
                        xs={8}
                        md={2}
                        style={{
                            position: isMobile ? "unset" : "fixed",
                            right: 86,
                            top: "30%",
                            transform: isMobile ? "" : "translateY(-20%)",
                        }}
                    >
                        <MintNftCard
                            image={imageField}
                            name={nameField}
                            price={priceField}
                            totalSuply={totalSuplyField}
                            isMobile={isMobile}
                            categorie={categorieField}
                            subCategorie={subCategorieField}
                            artistName={nft.artistName}
                            artistPhoto={nft.artistPhoto}
                        />
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: canClick
                                    ? "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)"
                                    : "#B8B8DB",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                if (canClick) {
                                    const response = await axios.put(
                                        `${Dev.api}nft/${nft.id}`,
                                        {
                                            id: nft.id,
                                            image: imageField,
                                            video: videoField,
                                            name: nameField,
                                            description: descriptionField,
                                            link: linkField,
                                            price: priceField,
                                            tags: tagsField.toString(),
                                            totalSuply: totalSuplyField,
                                            categorie: categorieField,
                                            subCategorie: subCategorieField,
                                            distributorProfileImage,
                                            stmDashboardImage,
                                            isrc: isrcField,
                                            association: associationField,
                                            associationOther: associationFieldOther,
                                            artistPhoto: nft.artistPhoto,
                                            artistName: nft.artistName,
                                            artistEmail: nft.artistEmail,
                                            artistPhone: nft.artistPhone,
                                            artistDocument: nft.artistDocument,
                                            status: "PENDING"
                                        },
                                        {
                                            headers: {
                                                "Content-Type": "application/json;charset=utf-8",
                                            },
                                            crossDomain: true,
                                        }
                                    );
                                    console.log(response)
                                    await Storage.setItem(StorageEnum.USER, {
                                        ...user,
                                        artist: true
                                    });

                                    logIn()

                                    if (nft.wallet) {
                                        navigate("/profile")
                                    } else {
                                        setStep(2);
                                    }
                                }
                            }}
                            variant="contained"
                        >
                            {t('createCollectionCardNext')}
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
};

export default Step2;
