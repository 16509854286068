const ProfileSpotify = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=spotify">
                <g id="perfil/redes/icone_spotify_off">
                    <path id="spotify" fill-rule="evenodd" clip-rule="evenodd" d="M20 6.25C12.4063 6.25 6.25 12.4057 6.25 20C6.25 27.594 12.4063 33.75 20 33.75C27.5937 33.75 33.75 27.5938 33.75 19.9997C33.75 12.4057 27.5937 6.25 20 6.25ZM25.172 26.4025C24.9509 26.4025 24.8001 26.3261 24.5937 26.1999C22.6134 25.0018 20.144 24.3721 17.5245 24.3721C16.0623 24.3721 14.5909 24.5602 13.2148 24.8463C12.9912 24.8949 12.7101 24.9807 12.5412 24.9807C12.0226 24.9807 11.677 24.569 11.677 24.1218C11.677 23.5484 12.0076 23.2618 12.4201 23.1832C14.1076 22.7984 15.7882 22.5787 17.5337 22.5787C20.5232 22.5787 23.1876 23.2651 25.4804 24.6393C25.8215 24.8385 26.0212 25.0418 26.0212 25.5477C26.0215 26.0408 25.6209 26.4025 25.172 26.4025ZM26.6603 22.7859C26.3639 22.7859 26.1775 22.6659 25.9755 22.5486C23.7533 21.2297 20.6728 20.3535 17.3 20.3535C15.57 20.3535 14.0767 20.596 12.8408 20.9252C12.5744 20.9988 12.4253 21.0777 12.1758 21.0777C11.5886 21.0777 11.1086 20.5983 11.1086 20.0066C11.1086 19.4257 11.3905 19.0263 11.9583 18.8663C13.4942 18.444 15.063 18.1193 17.3455 18.1193C20.9219 18.1193 24.3817 19.0099 27.1014 20.6377C27.5575 20.8986 27.7253 21.2313 27.7253 21.7175C27.7253 22.3081 27.2542 22.7859 26.6603 22.7859ZM28.354 18.5801C28.0763 18.5801 27.911 18.5123 27.6504 18.3695C25.1779 16.8867 21.3396 16.0703 17.6285 16.0703C15.7757 16.0703 13.8943 16.2589 12.1707 16.7264C11.9721 16.7761 11.7218 16.8756 11.4704 16.8756C10.7415 16.8756 10.1821 16.2989 10.1821 15.5702C10.1821 14.8274 10.6421 14.4108 11.1382 14.2644C13.086 13.6907 15.2599 13.4235 17.6232 13.4235C21.6351 13.4235 25.8563 14.2488 28.9393 16.0592C29.3549 16.295 29.6429 16.6497 29.6429 17.3003C29.6424 18.0448 29.044 18.5801 28.354 18.5801Z" fill="#ABABCC" />
                </g>
            </g>
        </svg>
    );
};

export default ProfileSpotify;
