const Collectible = ({ size = "16" }) => {
    return (
        <svg
            width={`${size}`}
            height={`${size}`}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3308_273354)">
                <g filter="url(#filter0_bd_3308_273354)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.2349 4.01776C14.3991 4.11257 14.5 4.28761 14.5 4.47711V11.3704C14.4914 11.5586 14.3963 11.7332 14.2349 11.8298L8.26483 15.2764C8.18304 15.3238 8.09131 15.3473 7.9997 15.3473C7.90798 15.3473 7.81636 15.3238 7.73458 15.2764L1.76513 11.8298C1.6025 11.7347 1.50864 11.5567 1.5 11.3704V4.47711C1.5 4.2875 1.60096 4.11256 1.76513 4.01776L7.73458 0.571105C7.89826 0.476298 8.10114 0.476298 8.26483 0.571105L14.2349 4.01776ZM13.4395 4.78305L7.9997 1.64273L2.56048 4.78323V10.0479L5.0554 7.43629C5.26383 7.22573 5.62981 7.22573 5.8373 7.43783L6.7437 8.39395L9.069 5.55685C9.27743 5.31255 9.68044 5.31279 9.88923 5.55661L13.4395 9.88652V4.78305ZM5.44397 8.5644L2.87957 11.2488L7.99975 14.2048L13.1638 11.2235L9.479 6.72936L7.47895 9.16959L8.88872 10.6567C9.09017 10.8692 9.08141 11.2048 8.86908 11.4062C8.6564 11.6074 8.32119 11.5994 8.11927 11.3863L5.44397 8.5644ZM7.68663 5.35616C7.68663 6.23332 6.97303 6.94692 6.09587 6.94692C5.21871 6.94692 4.50511 6.23332 4.50511 5.35616C4.50511 4.479 5.21871 3.7654 6.09587 3.7654C6.97303 3.7654 7.68663 4.479 7.68663 5.35616ZM6.62612 5.35616C6.62612 5.06381 6.38845 4.8259 6.09587 4.8259C5.80328 4.8259 5.56562 5.06381 5.56562 5.35616C5.56562 5.64851 5.80328 5.88641 6.09587 5.88641C6.38845 5.88641 6.62612 5.64851 6.62612 5.35616Z"
                        fill="#F59DF1"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_bd_3308_273354"
                    x="-22.5"
                    y="-23.5"
                    width="61"
                    height="62.8477"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_3308_273354"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.156863 0 0 0 0.8 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_3308_273354"
                        result="effect2_dropShadow_3308_273354"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3308_273354"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_3308_273354">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Collectible;
