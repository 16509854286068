const Pause = ({ size = 96 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0ZM24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1ZM15 17C15 15.8954 15.8954 15 17 15H20C21.1046 15 22 15.8954 22 17V31C22 32.1046 21.1046 33 20 33H17C15.8954 33 15 32.1046 15 31V17ZM28 15C26.8954 15 26 15.8954 26 17V31C26 32.1046 26.8954 33 28 33H31C32.1046 33 33 32.1046 33 31V17C33 15.8954 32.1046 15 31 15H28Z"
                fill="url(#paint0_linear_0_22387)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_0_22387"
                    x1="0"
                    y1="0"
                    x2="52.5714"
                    y2="44.9628"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#8930F0" />
                    <stop offset="1" stop-color="#5E47D6" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Pause;
