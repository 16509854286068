import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box } from "@mui/material";
import ProfileInfos from "../components/ProfileInfos";
import Footer from "../components/Footer";
import ProfileMenu from "../components/ProfileMenu";
import ProfileBody from "../components/ProfileBody";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Dev from "../utils/Dev";

const AnotherProfile = () => {
    const location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const [selected, setSelected] = useState(0);
    const [user, setUser] = useState({})
    const [showModalConnect, setShowModalConnect] = useState(false);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        async function loadUser() {
            const responseNft = await axios.get(
                `${Dev.api}nft/user/${location?.state?.user.email}`)

                console.log(responseNft)
                setUser(responseNft.data)
        }


        loadUser()
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            <ProfileInfos isMobile={isMobile} setSelected={setSelected} another={true} user={user} />
            <ProfileMenu selected={selected} setSelected={setSelected} another={true} anotherUser={user} />
            <ProfileBody selected={selected} another={true} user={user}  />
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default AnotherProfile;
