import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NftCard from "./commons/NftCard";
import { Link } from "react-router-dom";
import Fonts from "../utils/Fonts";
import axios from "axios";
import { useTranslation } from "react-i18next";

import Capa from "../assets/capa_colecao.png"
import NftCardPlantaMain from "./commons/NftCardPlantaMain";
import Dev from "../utils/Dev";

function LiveCollections({ isMobile }) {
    const [nfts, setNfts] = useState([]);
    const { t, i18n } = useTranslation("common");

    useEffect(() => {
        async function getNfts() {
            const response = await axios.get(
                `${Dev.api}nft`)

            setNfts(response.data.Items)
        }

        getNfts()
    }, []);


    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={"56px"}>
            <Grid
                container
                marginLeft={isMobile ? 2 : 0}
                gap={"32px"}
                maxWidth={"95vw"}
                display={"flex"}
                justifyContent={"center"}
            >
                <Grid xs={12} md={12} display={"flex"} alignItems={"center"} flexDirection={"column"}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize2,
                            color: "#ABABCC",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('liveCollectionsT1')}
                    </Typography>
                    <Box style={{
                        width: "35px",
                        height: "6px",
                        background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                        borderRadius: "3px",
                        marginTop: "8px",
                        marginBottom: "16px"
                    }}></Box>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('liveCollectionsT2')}
                    </Typography>
                </Grid>
                <Grid xs={12} md={12} lg={12} xl={10}>
                    <Grid container spacing={3} padding={"0px 16px 16px 16px"} style={{ display: "flex", justifyContent: "center" }}>
                        <NftCardPlantaMain
                            isMobile={isMobile}
                            nft={{
                                id: "baseados-digitais",
                                image: Capa,
                                name: "Baseados Digitais",

                                categorie: "collectibles",
                                subCategorie: "Phygital",

                                price: "300-1000",
                                totalSold: 0,
                                totalSuply: 100,
                                status: "ACTIVE",

                                artistName: "Planta e Raiz",
                                artistEmail: "planta-e-raiz",
                                artistPhoto: Capa
                            }} />
                        {nfts.map((nft, index) => (
                            <NftCard
                                key={index}
                                index={index}
                                isMobile={isMobile}
                                nft={nft}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid xs={12} md={12}>
                    <Link
                        aria-label="marketplace"
                        to={`/marketplace`}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box display={"flex"} justifyContent={"center"}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3BoldSize1,
                                    color: "#EBEBFA",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: "#8C29D9E5",
                                    borderRadius: 32,
                                    padding: "12px 32px",
                                    cursor: "pointer",
                                }}
                            >
                                {t('viewAll')}
                            </Typography>
                        </Box>
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
}

export default LiveCollections;
