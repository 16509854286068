const Royalties = () => {
    return (
        <svg
            width="120"
            height="120"
            viewBox="0 0 184 184"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="92" cy="92" r="92" fill="#1D1736" fillOpacity="0.5" />
            <circle cx="92" cy="92" r="92" fill="#E69E39" fillOpacity="0.2" />
            <path
                d="M127.701 54.4152C129.931 51.5678 134.501 53.3326 134.239 56.9397L130.096 113.955C129.957 115.87 128.362 117.353 126.442 117.353H58.9975C57.1127 117.353 55.5353 115.922 55.3513 114.047L49.7695 57.0311C49.4154 53.4144 53.9768 51.5442 56.2632 54.3694L75.2213 77.7965L88.3269 54.8572C89.7236 52.4129 93.2407 52.3918 94.6659 54.8187L108.688 78.6875L127.701 54.4152ZM123.035 110.026L126.06 68.3928L111.14 87.4417C109.546 89.4754 106.406 89.2661 105.097 87.0386L91.5532 63.9821L78.9268 86.0819C77.6478 88.32 74.5191 88.5726 72.8985 86.5693L58.2529 68.473L62.3212 110.025L123.035 110.026Z"
                fill="#E69E39"
            />
            <path
                d="M59.8568 132.904H127.448C129.471 132.904 131.111 131.264 131.111 129.241C131.111 127.218 129.471 125.577 127.448 125.577H59.8568C57.8337 125.577 56.1934 127.218 56.1934 129.241C56.1934 131.264 57.8337 132.904 59.8568 132.904Z"
                fill="#E69E39"
            />
        </svg>
    );
};

export default Royalties;
