import React, { useState } from "react";

import { Verified } from "@mui/icons-material";
import { Box, Grid, Popover, Typography } from "@mui/material";


import ProfileSite from "../assets/profileSite";
import ProfileInstagram from "../assets/profileInstagram";
import ProfileTiktok from "../assets/profileTiktok";
import ProfileTwitter from "../assets/profileTwitter";
import ProfileYoutube from "../assets/profileYoutube";

import ProfileSpotify from "../assets/profileSpotify";
import ProfileDeezer from "../assets/profileDeezer";
import ProfileSoundcloud from "../assets/profileSoundcloud";
import ProfileAmazon from "../assets/profileAmazon";
import ProfileApple from "../assets/profileApple";

import ProfileTwitch from "../assets/profileTwitch";
import ProfileDiscord from "../assets/profileDiscord";

import AlertTwoButtons from "./commons/AlertTwoButtons";
import { useNavigate } from "react-router-dom";

import { usePera } from "../hooks/usePera";
import { useDefly } from "../hooks/useDefly";
import { useUser } from "../hooks/useUser";
import ProfileIcon from "../assets/profileIcon";
import Fonts from "../utils/Fonts";
import Plus from "../assets/plus";
import Copy from "../assets/copy";
import { useTranslation } from "react-i18next";

function ProfileInfos({ isMobile, setSelected, another, user }) {
    const [showModalSell, setShowModalSell] = useState(false);
    const [showModalMint, setShowModalMint] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [t, i18n] = useTranslation("common");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const { logOut: disconnectUser } = useUser();
    const { logOut: disconnectPera } = usePera();
    const { logOut: disconnectDefly } = useDefly();

    const navigate = useNavigate();

    return (
        <Box maxWidth={"95vw"}>
            <AlertTwoButtons
                isMobile={isMobile}
                open={showModalSell}
                handleClose={() => setShowModalSell(false)}
                title={"Sell NFT"}
                description={
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3RegularSize1,
                            color: "#F0F0FA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        Would you like to resell an existing NFT from your
                        wallet or mint a new one from scratch? For both options,
                        Musii has to approve the NFT art to check if it’s within
                        our guidelines. Only verified artists can mint their own
                        NFTs (if you are a musician, get verified here)
                    </Typography>
                }
                buttonLeftText={"LIST NFT FROM WALLET"}
                buttonLeftAction={() => {
                    setSelected(1);
                    setShowModalSell(false);
                }}
                buttonRightText={"MINT NFT"}
                buttonRightAction={() => {
                    setShowModalSell(false);
                    setShowModalMint(true);
                }}
            />
            <AlertTwoButtons
                isMobile={isMobile}
                open={showModalMint}
                handleClose={() => setShowModalMint(false)}
                title={"Include benefits to the NFT?"}
                description={
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3RegularSize1,
                            color: "#F0F0FA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        Musicians can attach special benefits to their NFTs on
                        Musii, as physical products and real life experiences.
                        Learn more about the possibilities of benefits and their
                        categories here. If you chose to not include any
                        benefit, your NFT will be classified as a ‘Collectible’.
                    </Typography>
                }
                buttonLeftText={"INCLUDE BENEFITS"}
                buttonLeftAction={() => {
                    setShowModalMint(false);
                    isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint");
                }}
                buttonRightText={"MINT WITHOUT BENEFITS"}
                buttonRightAction={() => {
                    setShowModalMint(false);
                    isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint");
                }}
            />
            <Grid
                container
                marginBottom="12px"
                padding={isMobile ? "96px 16px 32px 16px" : "188px 72px 56px 72px"}
                maxWidth={"95vw"}
                mx={isMobile ? 2 : "64px"}
                gap={isMobile ? 3 : "24px"}
            >
                <Grid xs={12} md={"auto"}>
                    {user && user.photo && (
                        <img
                            width={"80px"}
                            height={"80px"}
                            src={user.photo}
                            style={{ borderRadius: 99 }}
                            alt="cover"
                            referrerpolicy="no-referrer"
                        />
                    )}
                    {(!user || !user.photo) && <ProfileIcon size={80} />}
                </Grid>
                <Grid
                    xs={12}
                    md={2.4}
                    xl={3}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                >
                    <Box
                        display={"flex"}
                        justifyItems={"center"}
                        alignItems={"center"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2RegularSize1,
                                color: "#F0F0FA",
                                marginRight: 4,
                                whiteSpace: "nowrap",
                            }}
                        >
                            {user && user.name
                                ? user.name
                                : user && user.email
                                    ? user.email
                                    : user && user.name
                                        ? user.name
                                        : ""}
                        </Typography>
                        {user && (user.name || user.email) && (
                            <Verified
                                style={{
                                    color: "#EBEBFA",
                                    width: 16,
                                    height: 16,
                                }}
                            />
                        )}
                    </Box>
                    {user && user.wallet && (
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1RegularSize1,
                                color: "#ABABCC",
                                display: "flex",
                                cursor: "pointer",
                                userSelect: "none",
                            }}
                            onClick={(e) => {
                                handleClick(e)
                                navigator.clipboard.writeText(user.wallet)
                                setTimeout(handleClose, 1500)
                            }}
                        >
                            {`${user.wallet.slice(0, 5)}...${user.wallet.slice(
                                user.wallet.length - 5,
                                user.wallet.lenght
                            )}`}
                            <Box
                                style={{
                                    display: "flex",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    marginLeft: "8px",
                                    width: "min-content",
                                    position: "relative"
                                }}
                                aria-describedby={id}
                            >
                                <Copy />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            backgroundColor: "#121229",
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .l1LightSize1,
                                            color: "#F0F0FA",
                                            display: "flex",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        }}
                                    >Address copied!</Typography>
                                </Popover>
                            </Box>
                        </Typography>
                    )}
                    {!another &&
                        <Typography
                            onClick={() => {
                                disconnectPera();
                                disconnectUser();
                                navigate("/");
                            }}
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1RegularSize1,
                                color: "#ABABCC",
                                cursor: "pointer",
                            }}
                        >
                            {t('profileLogOut')}
                        </Typography>}
                </Grid>

                <Grid xs={12} md={4.8} xl={5} display={"flex"} justifyContent={isMobile ? "flex-start" : "flex-end"}>
                    {user && user?.socials?.findIndex(el => el.social === 'site') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'site')].value !== "" && (
                        <a
                            aria-label="site"
                            href={user?.socials[user?.socials?.findIndex(el => el.social === 'site')].value}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileSite />
                        </a>
                    )}

                    {user && user?.socials?.findIndex(el => el.social === 'instagram') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'instagram')].value !== "" && (
                        <a
                            aria-label="instagram"
                            href={`https://www.instagram.com/${user?.socials[user?.socials?.findIndex(el => el.social === 'instagram')].value.replace("@", "")}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileInstagram />
                        </a>
                    )}
                    {user && user?.socials?.findIndex(el => el.social === 'tiktok') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'tiktok')].value !== "" && (
                        <a
                            aria-label="tiktok"
                            href={`https://www.tiktok.com/${user?.socials[user?.socials?.findIndex(el => el.social === 'tiktok')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileTiktok />
                        </a>
                    )}
                    {user && user?.socials?.findIndex(el => el.social === 'twitter') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'twitter')].value !== "" && (
                        <a
                            aria-label="twitter"
                            href={`https://www.twitter.com/${user?.socials[user?.socials?.findIndex(el => el.social === 'twitter')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileTwitter />
                        </a>
                    )}
                    {user && user?.socials?.findIndex(el => el.social === 'youtube') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'youtube')].value !== "" && (
                        <a
                            aria-label="youtube"
                            href={`${!user?.socials[user?.socials?.findIndex(el => el.social === 'youtube')].value.includes("https") ? "https://": ""}${user?.socials[user?.socials?.findIndex(el => el.social === 'youtube')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileYoutube />
                        </a>
                    )}
                    {user && user?.socials?.findIndex(el => el.social === 'spotify') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'spotify')].value !== "" && (
                        <a
                            aria-label="spotify"
                            href={`${!user?.socials[user?.socials?.findIndex(el => el.social === 'spotify')].value.includes("https") ? "https://": ""}${user?.socials[user?.socials?.findIndex(el => el.social === 'spotify')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileSpotify />
                        </a>
                    )}
                    {user && user?.socials?.findIndex(el => el.social === 'deezer') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'deezer')].value !== "" && (
                        <a
                            aria-label="deezer"
                            href={`${!user?.socials[user?.socials?.findIndex(el => el.social === 'deezer')].value.includes("https") ? "https://": ""}${user?.socials[user?.socials?.findIndex(el => el.social === 'deezer')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileDeezer />
                        </a>
                    )}
                    {user && user?.socials?.findIndex(el => el.social === 'soundcloud') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'soundcloud')].value !== "" && (
                        <a
                            aria-label="soundcloud"
                            href={`${!user?.socials[user?.socials?.findIndex(el => el.social === 'soundcloud')].value.includes("https") ? "https://": ""}${user?.socials[user?.socials?.findIndex(el => el.social === 'soundcloud')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileSoundcloud />
                        </a>
                    )}
                    {user && (user?.socials?.length < 9 || another) && user?.socials?.findIndex(el => el.social === 'amazon') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'amazon')].value !== "" && (
                        <a
                            aria-label="amazon"
                            href={`${!user?.socials[user?.socials?.findIndex(el => el.social === 'amazon')].value.includes("https") ? "https://": ""}${user?.socials[user?.socials?.findIndex(el => el.social === 'amazon')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileAmazon />
                        </a>
                    )}
                    {user && (user?.socials?.length < 10 || another) && user?.socials?.findIndex(el => el.social === 'apple') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'apple')].value !== "" && (
                        <a
                            aria-label="apple"
                            href={`${!user?.socials[user?.socials?.findIndex(el => el.social === 'apple')].value.includes("https") ? "https://": ""}${user?.socials[user?.socials?.findIndex(el => el.social === 'apple')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileApple />
                        </a>
                    )}
                    {user && (user?.socials?.length < 11 || another) && user?.socials?.findIndex(el => el.social === 'discord') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'discord')].value !== "" && (
                        <a
                            aria-label="discord"
                            href={`https://www.discord.com/user/${user?.socials[user?.socials?.findIndex(el => el.social === 'discord')].value}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileDiscord />
                        </a>
                    )}
                    {user && (user?.socials?.length < 12 || another) && user?.socials?.findIndex(el => el.social === 'twitch') >= 0 && user?.socials[user?.socials?.findIndex(el => el.social === 'twitch')].value !== "" && (
                        <a
                            aria-label="twitch"
                            href={`https://www.twitch.tv/${user?.socials[user?.socials?.findIndex(el => el.social === 'twitch')].value.replace("@", "")}/`}
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                borderRadius: '48px',
                                width: '48px',
                                height: '48px',
                                flexShrink: 0,
                                margin: '0px 12px',
                                border: '1px solid var(--darkmode-grey-plus-1100, #ABABCC)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <ProfileTwitch />
                        </a>
                    )}
                </Grid>
                {!another &&
                    <Grid xs={12} md={2} xl={1.5}>
                        <Box
                            onClick={() => isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint")}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                background:
                                    "linear-gradient(130.54deg, rgba(140, 41, 217, 0.9) 0%, rgba(94, 71, 214, 0.9) 102.22%), rgba(235, 235, 250, 0.25)",
                                borderRadius: "32px",
                                padding: "16px 28px",
                                textDecoration: "none",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <Typography
                                size="small"
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3BoldSize1,
                                    color: "#F0F0FA",
                                    whiteSpace: "nowrap",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginRight: "12px",
                                    }}
                                >
                                    <Plus />
                                </Box>
                                {t('profileCTAAddOffer')}
                            </Typography>
                        </Box>
                    </Grid>}

                <Grid xs={12} md={10}>
                    <Box display="flex" gap={"16px"}>
                        {user && user.genres?.map(item => <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                background:
                                    "linear-gradient(130.54deg, rgba(137, 48, 240, 0.5) 0%, rgba(94, 71, 214, 0.5) 102.22%), rgba(240, 240, 250, 0.05)",
                                borderRadius: "32px",
                                padding: "4px 16px",
                                textDecoration: "none",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                size="small"
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1RegularSize1,
                                    color: "#F0F0FA",
                                }}
                            >
                                {item}
                            </Typography>
                        </Box>)}

                    </Box>
                </Grid>

                <Grid xs={12} md={10}>
                    <Typography
                        size="small"
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .l1LightSize1,
                            color: "#F0F0FA",
                            whiteSpace: "break-spaces",
                            wordWrap: "break-word"
                        }}
                    >
                        {user && user.bio}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ProfileInfos;
