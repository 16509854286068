import React from "react";

import Logo from "../assets/logo";
import LogoMobile from "../assets/logoMobile";

import {
    Toolbar,
    Box,
    Typography,
    TextField,
    IconButton,
    AppBar,
    Container,
    Grid,
    InputAdornment,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { MenuOutlined } from "@mui/icons-material";

import AlertLogin from "./commons/AlertLogin";
import Fonts from "../utils/Fonts";
import ProfileIcon from "../assets/profileIcon";
import Online from "../assets/online";
import Close from "../assets/close";
import Search from "../assets/search";
import { useTranslation } from "react-i18next";

function Header({ showModalConnect, setShowModalConnect, redirectModal, setRedirectModal, isMobile }) {
    const { user } = useUser();
    const [t, i18n] = useTranslation("common");
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(true);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(false);
    };

    return (
        <AppBar
            style={{
                backgroundColor: "#121229",
                position: "fixed",
                top: 0,
                zIndex: 9999,
            }}
        >
            <AlertLogin
                isMobile={isMobile}
                open={showModalConnect}
                redirect={redirectModal}
                handleClose={() => { setShowModalConnect(false); setRedirectModal(false) }}
            />
            <Container
                maxWidth="xl"
                sx={{
                    padding: "0px",
                }}
            >
                <Toolbar disableGutters>
                    <Link
                        to="/"
                        aria-label="link para home"
                        style={{ marginLeft: isMobile ? "32px" : "80px" }}
                    >
                        {!isMobile && <Logo />}
                        {isMobile && <LogoMobile />}
                    </Link>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {
                                xs: "flex",
                                md: "none",
                                justifyContent: "flex-end",
                            },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuOutlined color="white" />
                        </IconButton>

                        {anchorElNav && (
                            <Box>
                                <Box
                                    style={{
                                        position: "absolute",
                                        width: "100vw",
                                        height: "94vh",
                                        background: "#121229",
                                        mixBlendMode: "normal",
                                        opacity: "0.75",
                                        top: "6vh",
                                        left: 0,
                                        zIndex: 9999,
                                    }}
                                ></Box>
                                <Grid
                                    container
                                    paddingX={"24px"}
                                    paddingY={4}
                                    zIndex={99}
                                    style={{
                                        width: "100vw",
                                        height: "94vh",
                                        position: "absolute",
                                        top: "6vh",
                                        left: 0,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignContent: "flex-start",
                                        zIndex: 99999,
                                        background:
                                            "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                    }}
                                >
                                    <Box
                                        onClick={handleCloseNavMenu}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Close />
                                    </Box>
                                    <Grid xs={12} md={12} marginTop={"44px"} display={'flex'} justifyContent={'flex-end'}>
                                        {(user &&
                                            (user.wallet || user.email || user.name || user.name)) ?
                                            <Link
                                                to="/profile"
                                                aria-label="profile"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    borderRadius: "32px",
                                                    color: "#121229",
                                                    padding: "9px 19px",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                <Box position={"relative"}>
                                                    {user && user.photo && (
                                                        <img
                                                            width={"40px"}
                                                            height={"40px"}
                                                            src={user.photo}
                                                            style={{ borderRadius: 99 }}
                                                            alt="cover"
                                                            referrerpolicy="no-referrer"
                                                        />
                                                    )}
                                                    {(!user || !user.photo) && (
                                                        <ProfileIcon size={40} />
                                                    )}
                                                    <Box
                                                        position={"absolute"}
                                                        style={{
                                                            bottom: 0,
                                                            right: 0,
                                                        }}
                                                    >
                                                        <Online />
                                                    </Box>
                                                </Box>
                                                <Typography
                                                    size="small"
                                                    style={{
                                                        ...Fonts[
                                                            isMobile
                                                                ? "mobile"
                                                                : "desktop"
                                                        ].h3RegularSize1,
                                                        color: "#5CB88D",
                                                        marginLeft: "12px",
                                                    }}
                                                >
                                                    {user.name
                                                        ? user.name.length > 13
                                                            ? `${user.name.slice(0, 12)}...`
                                                            : user.name
                                                        : user.wallet
                                                            ? `${user.wallet.slice(
                                                                0,
                                                                5
                                                            )}...${user.wallet.slice(
                                                                user.wallet.length - 5,
                                                                user.wallet.lenght
                                                            )}`
                                                            : user.email.length > 13
                                                                ? `${user.email.slice(0, 12)}...`
                                                                : user.email}
                                                </Typography>
                                            </Link> :
                                            <Box
                                                onClick={() => {
                                                    setAnchorElNav(false);
                                                    setShowModalConnect(true);
                                                }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    background:
                                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                                    borderRadius: "32px",
                                                    color: "#121229",
                                                    marginLeft: "48px",
                                                    padding: "12px 32px",
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                    height: "24px",
                                                }}
                                            >
                                                <Typography
                                                    size="small"
                                                    style={{
                                                        ...Fonts[
                                                            isMobile
                                                                ? "mobile"
                                                                : "desktop"
                                                        ].h1BoldSize2,
                                                        color: "#F0F0FA",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {t('btLogIn')}
                                                </Typography>
                                            </Box>}
                                    </Grid>

                                    <Grid xs={12} md={12} marginTop={"44px"}>
                                        <Typography
                                            onClick={() =>
                                                navigate("/profile", { state: { selected: 1 } })
                                            }
                                            style={{
                                                cursor: "pointer",
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h1BoldSize2,
                                                color: window.location.pathname.includes(
                                                    "/profile"
                                                )
                                                    ? "#B65BFC"
                                                    : "#EBEBFA",
                                                textAlign: "right",
                                            }}
                                        >
                                            {t('profileNFTsHold')}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} md={12} marginTop={"44px"}>
                                        <Typography
                                            onClick={() =>
                                                navigate("/profile", { state: { selected: 4 } })
                                            }
                                            style={{
                                                cursor: "pointer",
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h1BoldSize2,
                                                color: "#EBEBFA",
                                                textAlign: "right",
                                            }}
                                        >
                                            Edit Profile
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} md={12} marginTop={"44px"}>
                                        <Link
                                            to="/marketplace"
                                            aria-label="marketplace"
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].h1BoldSize2,
                                                    color: window.location.pathname.includes(
                                                        "/marketplace"
                                                    )
                                                        ? "#B65BFC"
                                                        : "#EBEBFA",
                                                    textAlign: "right",
                                                }}
                                            >
                                                {t('menuMarketplace')}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                    <Grid xs={12} md={12} marginTop={"44px"}>
                                        <Link
                                            to="/app"
                                            aria-label="app"
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].h1BoldSize2,
                                                    color: window.location.pathname.includes(
                                                        "/app"
                                                    )
                                                        ? "#B65BFC"
                                                        : "#EBEBFA",
                                                    textAlign: "right",
                                                }}
                                            >
                                                {t('menuApp')}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                    <Grid xs={12} md={12} marginTop={"44px"}>
                                        <Link
                                            to="/about"
                                            aria-label="about"
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile
                                                            ? "mobile"
                                                            : "desktop"
                                                    ].h1BoldSize2,
                                                    color: window.location.pathname.includes(
                                                        "/about"
                                                    )
                                                        ? "#B65BFC"
                                                        : "#EBEBFA",
                                                    textAlign: "right",
                                                }}
                                            >
                                                {t('menuAbout')}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Box>
                    {!isMobile && (
                        <Box
                            component="h2"
                            color="inherit"
                            alignItems="center"
                            display={"flex"}
                            marginLeft={"48px"}
                            gap={"40px"}
                            noWrap={true}
                            sx={{ flex: 1 }}
                        >
                            <Link
                                to="/marketplace"
                                aria-label="marketplace"
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: window.location.pathname.includes(
                                            "/marketplace"
                                        )
                                            ? "#B65BFC"
                                            : "#EBEBFA",
                                    }}
                                >
                                    {t('menuMarketplace')}
                                </Typography>
                            </Link>
                            <Link
                                to="/app"
                                aria-label="app"
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: window.location.pathname.includes(
                                            "/app"
                                        )
                                            ? "#B65BFC"
                                            : "#EBEBFA",
                                    }}
                                >
                                    {t('menuApp')}
                                </Typography>
                            </Link>
                            <Link
                                to="/about"
                                aria-label="about"
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: window.location.pathname.includes(
                                            "/about"
                                        )
                                            ? "#B65BFC"
                                            : "#EBEBFA",
                                    }}
                                >
                                    {t('menuAbout')}
                                </Typography>
                            </Link>

                            <Box flex={1}>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    placeholder={ t('searchBar') }
                                    type="text"
                                    marginLeft={"24px"}
                                    size="small"
                                    value={searchParams.get("search")}
                                    sx={{
                                        boxShadow: 0,
                                        padding: "10px 20px",
                                        borderRadius: "24px",
                                        borderBottomWidth: 0,
                                        maxHeight: "24px",
                                        width: "412px",

                                        background:
                                            "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        "& .MuiInputBase-input": {
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].b1RegularSize1,
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                    onChange={(e) => {
                                        setSearchParams({ search: e.target.value })
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            navigate(`/marketplace?search=${e.target.value}`)
                                        }
                                    }}
                                />
                            </Box>

                            {!user && !isMobile && (
                                <Box
                                    onClick={() => setShowModalConnect(true)}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        background:
                                            "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                        borderRadius: "32px",
                                        color: "#121229",
                                        padding: "9px 19px",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                        height: "24px",
                                        marginRight: isMobile ? "32px" : "80px"
                                    }}
                                >
                                    <Typography
                                        size="small"
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2BoldSize2,
                                            color: "#F0F0FA",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        {t('btLogIn')}
                                    </Typography>
                                </Box>
                            )}
                            {user &&
                                (user.wallet || user.email || user.name || user.name) &&
                                !isMobile && (
                                    <Link
                                        to="/profile"
                                        aria-label="profile"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            borderRadius: "32px",
                                            color: "#121229",
                                            padding: "9px 19px",
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Box position={"relative"}>
                                            {user && user.photo && (
                                                <img
                                                    width={"40px"}
                                                    height={"40px"}
                                                    src={user.photo}
                                                    style={{ borderRadius: 99 }}
                                                    alt="cover"
                                                    referrerpolicy="no-referrer"
                                                />
                                            )}
                                            {(!user || !user.photo) && (
                                                <ProfileIcon size={40} />
                                            )}
                                            <Box
                                                position={"absolute"}
                                                style={{
                                                    bottom: 0,
                                                    right: 0,
                                                }}
                                            >
                                                <Online />
                                            </Box>
                                        </Box>
                                        <Typography
                                            size="small"
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h3RegularSize1,
                                                color: "#5CB88D",
                                                marginLeft: "12px",
                                            }}
                                        >
                                            {user.name
                                                ? user.name.length > 13
                                                    ? `${user.name.slice(0, 12)}...`
                                                    : user.name
                                                : user.wallet
                                                    ? `${user.wallet.slice(
                                                        0,
                                                        5
                                                    )}...${user.wallet.slice(
                                                        user.wallet.length - 5,
                                                        user.wallet.lenght
                                                    )}`
                                                    : user.email.length > 13
                                                        ? `${user.email.slice(0, 12)}...`
                                                        : user.email}
                                        </Typography>
                                    </Link>
                                )}
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
