const ProfileDiscord = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=discord">
                <g id="Group">
                    <path id="Vector" d="M29.5451 10.5328C27.765 9.69996 25.8616 9.09466 23.8717 8.75C23.6273 9.19185 23.3417 9.78614 23.1449 10.2589C21.0295 9.94077 18.9336 9.94077 16.8572 10.2589C16.6603 9.78614 16.3683 9.19185 16.1218 8.75C14.1297 9.09466 12.2241 9.70219 10.444 10.5372C6.85344 15.963 5.88011 21.254 6.36678 26.4699C8.74821 28.2482 11.0561 29.3286 13.325 30.0355C13.8852 29.2645 14.3849 28.4449 14.8153 27.5811C13.9955 27.2696 13.2104 26.8852 12.4685 26.4389C12.6653 26.2931 12.8579 26.1407 13.0439 25.9838C17.5688 28.1002 22.4852 28.1002 26.9561 25.9838C27.1442 26.1407 27.3367 26.2931 27.5314 26.4389C26.7873 26.8874 26 27.2718 25.1803 27.5833C25.6107 28.4449 26.1082 29.2667 26.6705 30.0377C28.9417 29.3307 31.2517 28.2505 33.6331 26.4699C34.2042 20.4233 32.6576 15.1809 29.5451 10.5328ZM15.4318 23.2621C14.0734 23.2621 12.9595 21.9941 12.9595 20.4498C12.9595 18.9056 14.0496 17.6354 15.4318 17.6354C16.8139 17.6354 17.9278 18.9034 17.904 20.4498C17.9062 21.9941 16.8139 23.2621 15.4318 23.2621ZM24.5681 23.2621C23.2098 23.2621 22.0959 21.9941 22.0959 20.4498C22.0959 18.9056 23.186 17.6354 24.5681 17.6354C25.9503 17.6354 27.0642 18.9034 27.0404 20.4498C27.0404 21.9941 25.9503 23.2621 24.5681 23.2621Z" fill="#ABABCC" />
                </g>
            </g>
        </svg>
    );
};

export default ProfileDiscord;
