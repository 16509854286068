import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, Grid, Typography } from "@mui/material";
import Capa from "../assets/capa_colecao.png"
import Footer from "../components/Footer";
import axios from "axios";
import Fonts from "../utils/Fonts";
import { useTranslation } from "react-i18next";
import NftCard from "../components/commons/NftCard";
import NftCardPlantaMain from "../components/commons/NftCardPlantaMain";
import Dev from "../utils/Dev";

const Marketplace = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showModalConnect, setShowModalConnect] = useState(false);
    const [nfts, setNfts] = useState([]);

    const [t, i18n] = useTranslation("common");

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        async function getNfts() {
            const response = await axios.get(
                `${Dev.api}nft`)

            setNfts(response.data.Items)
        }

        getNfts()

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            <Box display={"flex"} justifyContent={"center"} paddingY={"56px"} marginTop={"96px"}>
                <Grid
                    container
                    marginLeft={isMobile ? 2 : 0}
                    gap={"32px"}
                    maxWidth={"95vw"}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <Grid xs={12} md={12} display={"flex"} alignItems={"center"} flexDirection={"column"}>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#ABABCC",
                                whiteSpace: "break-spaces",
                                textAlign: "center",
                            }}
                        >
                            {t('liveCollectionsT1')}
                        </Typography>
                        <Box style={{
                            width: "35px",
                            height: "6px",
                            background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                            borderRadius: "3px",
                            marginTop: "8px",
                            marginBottom: "16px"
                        }}></Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h1BoldSize1,
                                color: "#EBEBFA",
                                whiteSpace: "break-spaces",
                                textAlign: "center",
                            }}
                        >
                            {t('liveCollectionsT2')}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={11} lg={11} xl={10}>
                        <Grid container spacing={3} padding={"0px 16px 16px 16px"} style={{ display: "flex", justifyContent: "center" }}>
                            <NftCardPlantaMain
                                isMobile={isMobile}
                                nft={{
                                    id: "baseados-digitais",
                                    image: Capa,
                                    name: "Baseados Digitais",

                                    categorie: "collectibles",
                                    subCategorie: "Phygital",

                                    price: "300-1000",
                                    totalSold: 0,
                                    totalSuply: 100,
                                    status: "ACTIVE",

                                    artistName: "Planta e Raiz",
                                    artistEmail: "planta-e-raiz",
                                    artistPhoto: Capa
                                }} />
                            {nfts.map((nft, index) => (
                                <NftCard
                                    key={index}
                                    index={index}
                                    nft={nft}
                                    isMobile={isMobile}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default Marketplace;
