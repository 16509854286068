const Copy = () => {
    return (
        <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.556 3.66016H2.41825C1.93118 3.66087 1.46438 3.85484 1.12025 4.19929C0.77599 4.54384 0.58245 5.01074 0.582031 5.49782V13.5788C0.58244 14.0658 0.775995 14.5328 1.12025 14.8773C1.4644 15.2218 1.9312 15.4157 2.41825 15.4165H8.556C9.04194 15.4141 9.5073 15.2195 9.85023 14.8752C10.1933 14.5309 10.386 14.0649 10.3863 13.5788V5.49782C10.386 5.01177 10.1933 4.5457 9.85023 4.20133C9.5073 3.857 9.04194 3.66241 8.556 3.66016ZM9.02822 13.5789C9.02822 13.7041 8.9785 13.8242 8.88991 13.9128C8.80132 14.0014 8.68121 14.0511 8.55599 14.0511H2.41824C2.29323 14.0507 2.17354 14.0008 2.08525 13.9123C1.99696 13.8238 1.94745 13.7039 1.94745 13.5789V5.49788C1.94745 5.37297 1.99706 5.25327 2.08535 5.16498C2.17364 5.0767 2.29333 5.02708 2.41824 5.02708H8.55599C8.681 5.02708 8.80091 5.0766 8.88938 5.16488C8.97787 5.25307 9.0278 5.37286 9.02821 5.49788L9.02822 13.5789Z"
                fill="#ABABCC"
            />
            <path
                d="M11.7592 0.750026H6.09233C5.65253 0.750435 5.23083 0.92527 4.91983 1.23626C4.60883 1.54725 4.43399 1.96896 4.43359 2.40876C4.44454 2.64518 4.57702 2.85909 4.78377 2.97419C4.99053 3.08938 5.24209 3.08938 5.44885 2.97419C5.6556 2.8591 5.78808 2.6452 5.79903 2.40876C5.79903 2.24681 5.93028 2.11546 6.09233 2.11546H11.7592C11.8842 2.11587 12.0039 2.1658 12.0922 2.25429C12.1805 2.34278 12.23 2.46268 12.23 2.58768V10.2051C12.23 10.283 12.1991 10.3576 12.1441 10.4126C12.0891 10.4675 12.0144 10.4984 11.9367 10.4984H11.5876C11.3434 10.4984 11.1179 10.6288 10.9957 10.8402C10.8737 11.0517 10.8737 11.3122 10.9957 11.5236C11.1179 11.7351 11.3434 11.8654 11.5876 11.8654H11.9381C12.3777 11.8646 12.799 11.6896 13.1097 11.3786C13.4204 11.0677 13.595 10.6462 13.5954 10.2067V2.58766C13.595 2.10069 13.4015 1.63368 13.0572 1.28914C12.713 0.944692 12.2462 0.750812 11.7592 0.75L11.7592 0.750026Z"
                fill="#ABABCC"
            />
        </svg>
    );
};

export default Copy;
