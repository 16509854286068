import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import Close from "../../assets/close";
import Loader from "../../assets/loader.gif";
import Fonts from "../../utils/Fonts";
import { useTranslation } from "react-i18next";

function AlertTransaction({
    isMobile,
    open,
    handleClose,
    progress,
}) {
    const [t, i18n] = useTranslation("common");

    return (
        <>
            {open && (
                <Box>
                    <Box
                        style={{
                            position: "fixed",
                            width: "100vw",
                            height: "100vh",
                            background: "#121229",
                            mixBlendMode: "normal",
                            opacity: "0.75",
                            top: 0,
                            left: 0,
                            zIndex: 9999,
                        }}
                    ></Box>
                    <Grid
                        container
                        gap={isMobile ? 4 : "16px"}
                        paddingX={isMobile ? 0 : 12}
                        paddingY={4}
                        zIndex={99999}
                        style={{
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            maxWidth: "90vw",
                            borderRadius: "32px",
                            display: "flex",
                            width: "fit-content",
                            justifyContent: "center",
                            background:
                                "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                        }}
                    >
                        <Box
                            onClick={handleClose}
                            style={{
                                position: "absolute",
                                right: 64,
                                cursor: "pointer",
                            }}
                        >
                            <Close />
                        </Box>
                        <Grid xs={12} md={12}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile? 'mobile': 'desktop'].h1BoldSize2,
                                    color: "#ABABCC",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                {t('purchaseFlowProcessingMint')}
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile? 'mobile': 'desktop'].h3RegularSize1,
                                    color: "#ABABCC",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                {t('purchaseFlowProcessingMintDesc')}
                            </Typography>
                        </Grid>
                        <Grid>
                            <img src={Loader} alt="musii"
                                style={{
                                    width: "256px",
                                    borderRadius: "48px",
                                }} />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile? 'mobile': 'desktop'].l1RegularSize1,
                                    color: "#ABABCC",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                {t('purchaseFlowProcessingMintWallet')}
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={12} marginBottom={"24px"}>
                            <LinearProgress color="inherit" variant="determinate" value={progress}
                            sx={{
                                borderRadius: '32px',
                                "&.MuiLinearProgress-root": {
                                    color: "#8930F0"
                                }
                            }} />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}

export default AlertTransaction;
