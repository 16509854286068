import {
    Card,
    Grid,
    Typography,
    CardContent,
    Button,
    Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TypeNfts from "../../utils/TypeNfts";
import Fonts from "../../utils/Fonts";
import Verified from "../../assets/verified";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/useUser";

export default function NftCardPlantaMain({ nft, isMobile }) {
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    const { cotation } = useUser();

    return (
        <Grid item sm={12} md={"auto"}>
            <Card
                style={{
                    position: "relative",
                    borderRadius: 32,
                    backgroundColor: "#121229",
                    width: "276px",
                    height: "426px",
                }}
            >
                <Box
                    style={{
                        position: "absolute",
                        top: "2px",
                        left: "24px",
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: TypeNfts[nft.categorie.toLowerCase()]
                                .backgroundColor,
                            padding: "8px 16px",
                            borderRadius: 32,
                            display: "inline-flex",
                            alignItems: "center",
                            marginTop: "24px",
                            marginBottom: "32px",
                        }}
                    >
                        <Box
                            style={{
                                marginRight: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {TypeNfts[nft.categorie.toLowerCase()].image}
                        </Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1BoldSize2,
                                color: TypeNfts[nft.categorie.toLowerCase()].color,
                            }}
                        >
                            Itens Variados
                        </Typography>
                    </Box>
                </Box>
                <Box maxHeight={"276px"}>
                    <Link
                        aria-label="nfts"
                        to={`/${nft.id}`}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                            zIndex: 99,
                        }}
                    >
                        <img
                            alt={"cover"}
                            style={{
                                height: "276px",
                                borderRadius: "32px 32px 0px 0px"
                            }}
                            src={nft.image}
                        />
                    </Link>
                </Box>

                <CardContent
                    style={{
                        background:
                            "linear-gradient(130.54deg, rgba(158, 158, 184, 0.125) 0%, rgba(24, 24, 46, 0.25) 102.22%), rgba(55, 55, 92, 0.5)",
                        padding: "24px",
                        position: "relative",
                        height: '100%'
                    }}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                    >

                        <Box
                            aria-label="nfts"
                            onClick={() =>
                                navigate(`/${nft.artistEmail}`, { state: { user: { email: nft.artistEmail } } })
                            }
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                zIndex: 99,
                                cursor: "pointer"
                            }}
                        >
                            <img
                                width={"40px"}
                                height={"40px"}
                                src={nft.artistPhoto}
                                style={{ borderRadius: 99, zIndex: 99 }}
                                alt="cover"
                            />
                        </Box>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            marginLeft={2}
                        >
                            <Box
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    top: 0,
                                    left: 0,
                                    backgroundImage: `url('${nft.image}')`,
                                    backgroundPosition: "bottom",
                                    filter: "blur(56px)",
                                }}
                            ></Box>
                            <Link
                                aria-label="nfts"
                                to={`/${nft.id}`}
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                    zIndex: 99,
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h3BoldSize1,
                                        color: "#F0F0FA",
                                        zIndex: 99,
                                    }}
                                >
                                    {nft.name.length < 16 ? nft.name : `${nft.name.slice(0, 16)}...`}
                                </Typography>
                            </Link>
                            <Box
                                aria-label="nfts"
                                onClick={() =>
                                    navigate(`/${nft.artistEmail}`, { state: { user: { email: nft.artistEmail } } })
                                }
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                    zIndex: 99,
                                    cursor: "pointer"
                                }}
                            >
                                <Box display={"flex"} alignItems={"center"} gap={"2px"}>

                                    <Typography
                                        style={{
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .l1RegularSize1,
                                            color: "#F0F0FA",
                                            zIndex: 99,
                                        }}
                                    >
                                        {nft.artistName.length < 16 ? nft.artistName : `${nft.artistName.slice(0, 16)}...`}
                                    </Typography>
                                    <Verified size={12} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {nft.status === "ACTIVE" &&
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            marginTop={"16px"}
                        >
                            <Box display={"flex"} flexDirection={"column"}>
                                <Box
                                    display={"flex"}
                                    style={{
                                        zIndex: 99
                                    }}
                                >
                                    <Typography
                                        style={{
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .b1BoldSize1,
                                            color: "#F0F0FA",
                                            zIndex: 99,
                                        }}
                                    >
                                        A partir de R$300
                                    </Typography>
                                </Box>
                            </Box>
                            <Link
                                aria-label="nfts"
                                to={`/${nft.id}`}
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                    zIndex: 99,
                                }}
                            >
                                <Button
                                    style={{

                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1BoldSize2,
                                        marginLeft: "12px",
                                        background: "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                        borderRadius: "40px",
                                        padding: "8px 16px",
                                        color: "#F0F0FA"
                                    }}
                                    variant="contained"
                                >
                                    {parseInt(nft.totalSold) >= parseInt(nft.totalSuply)
                                        ? t('nftCardSold')
                                        : t('nftCardBuy')}
                                </Button>
                            </Link>
                        </Box>}
                </CardContent>
            </Card>
        </Grid>
    );
}
