import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Fonts from "../utils/Fonts";

import Arthur from "../assets/arthur.png";
import Leo from "../assets/leo.png";
import George from "../assets/george.png";
import Lucas from "../assets/lucas.png";
import Hid from "../assets/hid.png";
import Person from "./commons/Person";
import { useTranslation } from "react-i18next";

function Team({ isMobile }) {
    const [t, i18n] = useTranslation("common");

    return (
        <Grid
            container
            id="team"
            display={"flex"}
            justifyContent={"center"}
            marginTop={isMobile ? "48px" : "64px"}
            style={{
                display: "flex",
                padding: isMobile ? "20px 30px" : "56px",
                alignItems: isMobile ? "flex-start" : "center",
                borderRadius: 16,
                flexDirection: isMobile ? "column" : "row",
            }}
        >

            <Grid
                xs={12}
                md={12}
                xl={12}
            >
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h2BoldSize2,
                        color: "#ABABCC",
                        whiteSpace: "break-spaces",
                        textAlign: "center"
                    }}
                >
                    {t('ourSquad')}
                </Typography>
                <Box style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <Box
                        style={{
                            width: "35px",
                            height: "6px",
                            background:
                                "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                            borderRadius: "3px",
                            marginTop: "8px",
                            marginBottom: "16px",
                        }}
                    ></Box>
                </Box>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].h1BoldSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces",
                        marginTop: 4,
                        marginBottom: 4,
                        textAlign: "center"
                    }}
                >
                    {t('ourSquadTitle')}
                </Typography>
            </Grid>
            <Grid
                xs={12}
                md={12}
                xl={12}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "96px"
                }}
                gap={"32px"}
            >
                <Person img={Arthur} name={"Arthur Baccam"} position={"CEO"} link={"https://www.linkedin.com/in/arthur-marques-baccam-54bb98a2/"} isMobile={isMobile} />
                <Person img={Leo} name={"Leonardo Garcez"} position={"CTO"} link={"https://www.linkedin.com/in/leonardogarcez"} isMobile={isMobile} />
                <Person img={George} name={"George Guerra"} position={"Marketing & UX"} link={"https://www.linkedin.com/in/george-gatt%C3%A1s-guerra-b7796b108/"} isMobile={isMobile} />
                <Person img={Lucas} name={"Lucas Limão"} position={"Back End Developer"} link={"#"} isMobile={isMobile} />
                <Person img={Hid} name={"Hid Miguel"} position={"Artistic Advisor"} link={"https://www.instagram.com/hid.miguel/"} isMobile={isMobile} />
            </Grid>
        </Grid>
    );
}

export default Team;
