const ProfileTwitch = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=twitch">
                <path id="Vector" d="M8.81608 6L7 10.8697V30.346H13.6559V34H17.2897L20.9199 30.346H26.3668L33.6307 23.0447V6H8.81608ZM11.2356 8.43329H31.2102V21.8249L26.9722 26.0864H20.3154L16.6862 29.7353V26.0864H11.2356V8.43329ZM17.8941 20.6091H20.3153V13.3055H17.8941V20.6091ZM24.5515 20.6091H26.9722V13.3055H24.5515V20.6091Z" fill="#ABABCC" />
            </g>
        </svg>
    );
};

export default ProfileTwitch;
