// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AUTH: 'AUTH',
  USER: 'USER',
  SHOW_SCREEN: 'SHOW_SCREEN',
  SPOTIFY_CODE: 'SPOTIFY_CODE',
  SPOTIFY_TOKEN: 'SPOTIFY_TOKEN',
  SPOTIFY_REFRESH_TOKEN: 'SPOTIFY_REFRESH_TOKEN',
  SPOTIFY_TOKEN_TIMER: 'SPOTIFY_TOKEN_TIMER',
  SPOTIFY_USER: 'SPOTIFY_USER',
  SPOTIFY_PLAYLIST_ID: 'SPOTIFY_PLAYLIST_ID',
  INITIAL_TUTORIAL: 'INITIAL_TUTORIAL',
  TWILIO_TOKEN: 'TWILIO_TOKEN',
  SCREEN: 'SCREEN',
};
