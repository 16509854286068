const ProfileIcon = ({ size = 48}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24" cy="24" r="24" fill="#ABABCC" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.7914 9C19.0741 9 15.25 12.9178 15.25 17.7502C15.25 22.5827 19.0741 26.5 23.7914 26.5C28.5092 26.5 32.3333 22.5827 32.3333 17.7502C32.3333 12.9178 28.5092 9 23.7914 9ZM24.2091 29C16.9261 29 10.9399 31.8182 10.25 38.4724C13.8796 41.8981 18.7921 44 24.2001 44C29.6166 44 34.5356 41.8918 38.1667 38.4566C37.4687 31.8138 31.4866 29 24.2091 29Z"
                fill="#121229"
            />
            <circle cx="24" cy="24" r="24" fill="#121229" fillOpacity="0.33" />
            <circle cx="24" cy="24" r="24" fill="#242447" fillOpacity="0.66" />
            <circle
                cx="24"
                cy="24"
                r="24"
                fill="url(#paint0_linear_1043_95373)"
                fillOpacity="0.33"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1043_95373"
                    x1="0"
                    y1="0"
                    x2="52.3184"
                    y2="42.7101"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#ABABCC" stop-opacity="0.33" />
                    <stop offset="1" stop-color="#121229" stop-opacity="0.33" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ProfileIcon;
