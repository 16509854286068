import "./App.css";
import Mint from "./pages/Mint";

import routes from "./pages/index";
import { Route, Routes, useLocation } from "react-router-dom";

import { useUser } from "./hooks/useUser";
import { useEffect } from "react";
import Home from "./pages/Home";
import Approval from "./pages/Approval";
import Review from "./pages/Review";
import { useTranslation } from "react-i18next";

import ReactGA from "react-ga";

function App() {
    const { user } = useUser();
    const { pathname, hash, key } = useLocation();

    const [t, i18n] = useTranslation("common");

    useEffect(() => {
        // if not a hash link, scroll to top
        ReactGA.pageview(pathname);

        if (hash === "") {
            window.scrollTo({
                top: 0,
                behavior: "smooth", // for smoothly scrolling
            });
        } else {
            setTimeout(() => {
                const id = hash.replace("#", "");
                const element = document.getElementById(id);
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop - 120,
                        behavior: "smooth", // for smoothly scrolling
                    });
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    useEffect(() => {
        ReactGA.initialize("UA-168662646-3");
        ReactGA.pageview("Home");

        const userLang = navigator.language || navigator.userLanguage;

        i18n.changeLanguage(userLang.substr(0, 2));        
    }, []);

    return (
        <Routes>
            {routes.map((data, index) => (
                <Route
                    exact={true}
                    path={data.path}
                    element={data.component}
                    key={index}
                />
            ))}
            {user && (user.wallet || user.email || user.name) && (
                <Route exact={true} path={"/mint"} element={<Mint />} />
            )}
            {user && (user.wallet || user.email || user.name) && (
                <Route exact={true} path={"/approval"} element={<Approval />} />
            )}
            {user && (user.wallet || user.email || user.name) && (
                <Route exact={true} path={"/review"} element={<Review />} />
            )}
            <Route path={"*"} element={<Home />} />
        </Routes>
    );
}

export default App;
