const es = {
  menuMarketplace: 'Marketplace',
  menuApp: 'App',
  menuAbout: 'Acerca de',
  searchBar: 'Buscar',
  btLogIn: 'Iniciar sesión',
  btUseEmail: 'USA UN EMAIL',
  btForgotPassword: 'Forgot password?',
  textFieldPassword: 'Contraseña',
  logInDesc: 'Acesar o crear una cuenta:',
  heroT1: 'Una nueva forma de ganar con la música',
  heroT2: 'Musii es la plataforma en la que los artistas involucran a sus fanáticos y seguidores a través de la venta de NFT coleccionables, con productos, experiencias exclusivas y royalties musicales.',
  heroCtaArtists: 'EMPIEZA A VENDER',
  heroCtaFans: 'EMPIEZA A COLECCIONAR',

  tagCardNewRelease: 'NUEVO LANZAMIENTO',
  tagCardUpcommingRelease: 'PRÓXIMO LANZAMIENTO',
  tagCardUpcommingReleaseDays: 'Días',
  tagCardUpcommingReleaseHours: 'Horas',
  tagCardUpcommingReleaseMin: 'Min',
  tagCardUpcommingReleaseSec: 'Seg',
  //tagCardEditions: 'ediciones',
  //tagCardCtaBuy: 'COMPRAR',

  categoryCollectible: 'COLECCIONABLES',
  categoryProduct: 'PRODUCTOS',
  categoryExperience: 'EXPERIENCIAS',
  categoryRoyalties: 'REGALÍAS',
  Collectible: 'Coleccionable',
  CD: 'CD',
  Vinyl: 'Vinilo',
  'Autographed Items': 'Artículos autografiados',
  Merchandising: 'Merchandising',
  Phygital: 'Físico-digital',
  'Show Ticket': 'Entrada de espectáculo',
  'Studio Session': 'Sesión de estudio',
  Membership: 'Membresía',
  'Early Access': 'Acceso anticipado',
  'Lesson / Workshop': 'Lección / Taller',
  'Gift Video': 'Video de regalo',
  'Meet-and-Greet': 'Conoce y saluda',
  'Q&A Forum': 'Foro de preguntas y respuestas',
  Backstage: 'Backstage',

  liveCollectionsT1: 'Marketplace',
  liveCollectionsT2: 'Colecciones en vivo',
  nftCardBuy: 'COMPRAR',
  nftCardSold: 'AGOTADO',
  nftCardAvailable: 'disponible',
  viewAll: 'Ver todo',

  secondarySalesT1: 'Marketplace',
  secondarySalesT2: 'Ventas secundarias',

  whatSellT1: '¿Qué pueden vender los artistas en Musii?',
  whatSellT2: 'Categorías de beneficios',
  whatSellB1: 'Al comprar los NFT, los fanáticos pueden adquirir beneficios según la categoría establecida en el artículo, como en los ejemplos a continuación:',
  //categoryCollectiblesT1: 'COLECCIONABLES',
  categoryCollectiblesB1: 'Arte digital\nImágenes exclusivas\nArchivo personal',
  //categoryProductsT1: 'PRODUCTOS',
  categoryProductsB1: 'CD/Vinilo\nArtículos autografiados\nMerchandising\nFísico-digitales',
  //categoryExperiencesT1: 'EXPERIENCIAS',
  categoryExperiencesB1: 'Entradas para shows\nSesiones de estudio\nMembresías\nAcceso anticipado a nuevos lanzamientos\nLecciones/Talleres\nVideos de regalo\nEncuentros y saludos\nForos de preguntas y respuestas\nAcceso al backstage',
  //categoryRoyaltiesT1: 'REGALÍAS',
  categoryRoyaltiesB1: 'Anticipación de regalías de plataformas de streaming\n\nPRÓXIMAMENTE',

  verifiedArtistsT2: 'Artistas verificados:',

  howWorksT1: 'Cómo funciona',
  howWorksT2: 'Involucra a tu base de fanáticos y crea una nueva fuente de ingresos',
  step1T1: 'Paso 01',
  step1T2: 'Envía la información',
  step1B1: 'Completa los detalles sobre ti y el artículo que deseas vender.',
  step2T1: 'Paso 02',
  step2T2: 'Envío para aprobación',
  step2B1: 'Verificaremos si la información que nos has enviado es legítima y si tienes los derechos de la canción.',
  step3T1: 'Paso 03',
  step3T2: 'Comienza a vender',
  step3B1: 'Una vez que recibas la aprobación, abre la venta y difunde tu oferta entre tus fanáticos.',

  bannerCtaT1: 'Comienza a involucrar a tus fanáticos',
  bannerCtaT2: 'Crea un sentido de comunidad dentro de tu base de fanáticos y recibe apoyo de aquellos que realmente creen en tu carrera',
  bannerCtaButton: 'CREAR AHORA',

  faqT1: 'Preguntas frecuentes',
  faqT1Title: 'Preguntas frecuentes',
  faq1T1: '+ ¿Qué es Musii?',
  faq1B1: 'Musii es una plataforma que brinda a los artistas la posibilidad de involucrar a su base de fanáticos y monetizar sus carreras de nuevas formas, mediante la venta de diferentes categorías de NFT.',
  faq2T1: '+ ¿Qué son los NFT?',
  faq2B1: 'Los NFT son artículos digitales únicos almacenados de forma permanente en Internet (blockchain) que contienen información inmutable y ofrecen propiedad, autoría y autenticidad. De esta manera, los NFT no se pueden falsificar ni cambiar sin el permiso del creador. Estos artículos únicos pueden intercambiarse entre compradores y, debido a su oferta limitada, pueden aumentar su valor. En Musii, los artistas pueden vender NFT a sus fanáticos que pueden tener beneficios (utilidades) asociados, como productos físicos y experiencias.',
  faq3T1: '+ ¿Cómo comprar NFT en Musii?',
  faq3B1: 'Puedes ver un video tutorial paso a paso sobre cómo comprar un NFT aquí.\nPara comprar un NFT en Musii, sigue los siguientes pasos:\n1. Elige el artículo deseado.\n2. Selecciona el método de pago:\na. Billetera de criptomonedas: conecta tu billetera de Algorand (Defly o Pera), recarga el saldo de la billetera con la cantidad necesaria y acepta todas las transacciones requeridas si estás de acuerdo. Estas transacciones pueden tardar unos minutos en completarse, así que ten un poco de paciencia una vez que presiones para aceptar.\nb. PIX: escanea o copia/pega el código de transacción en tu aplicación bancaria y finaliza el proceso allí. Recibirás una confirmación del pago en tu dirección de correo electrónico.',
  faq4T1: '+ ¿Cómo pueden los artistas vender NFT en Musii?',
  faq4B1: '1. Haz clic en "Vender NFT" en tu perfil o en la página de inicio.\n2. Completa los detalles sobre ti y el artículo que deseas vender.\n3. Envía para aprobación. Verificaremos si la información que nos has enviado es legítima y si tienes los derechos de la canción.\n4. Una vez que recibas la aprobación, abre la venta y difunde tu oferta entre tus fanáticos.',
  faq5T1: '+ ¿Qué tipos de categorías de NFT pueden vender los artistas en Musii?',
  faq5B1: 'Actualmente, Musii ofrece 3 opciones de categorías para los NFT:\n- Coleccionables: NFT que son arte digital único de colecciones creadas por los artistas, pero no tienen ningún beneficio adicional. Solo como una analogía para ilustrar: en el pasado, la gente solía coleccionar y colgar pósteres de sus bandas favoritas en las paredes de sus habitaciones, hoy muchas personas coleccionan NFT como lo hacían con sus pósteres.\n- Productos: NFT que se entregan junto con productos físicos, como CD / Vinyl, artículos autografiados, mercancía y productos físicos digitales. De esta manera, estos NFT también funcionan como certificados de autenticidad para esos artículos.\n- Experiencias: NFT que funcionan como acceso a experiencias en la vida real, como boletos para conciertos, membresías de sesiones de estudio, acceso anticipado a nuevos lanzamientos, lecciones / talleres, videos de regalo, encuentros y saludos, foros de preguntas y respuestas y acceso a backstage.\n- Regalías: Esta categoría aún no está disponible, pero la incluiremos lo antes posible. En este caso, los NFT representan un porcentaje de las regalías de streaming, por lo que cada vez que se generen ganancias en las plataformas distribuidas, cada comprador recibirá automáticamente su respectiva parte.',
  faq6T1: '+ ¿Cómo funcionan las colecciones con beneficios?',
  faq6B1: 'Si lo desean, los creadores pueden ofrecer beneficios relacionados con su colección. La entrega y/o ejecución de dichos beneficios a los compradores son responsabilidad exclusiva del creador, y Musii no asume ninguna responsabilidad por la entrega y/o ejecución de estos beneficios. Al ofrecer beneficios, los creadores deben incluir un plazo, que será visible en la plataforma en la página de oferta, para cumplir con la entrega y/o ejecución de estos beneficios.',
  faq7T1: '+ ¿Qué son las ediciones de las colecciones de NFT?',
  faq7B1: 'Cuando se crea una colección de NFT con ediciones, el número total de ediciones (oferta total) disponibles es definido por su creador. Por ejemplo, puede haber solo 100 ejemplares de una colección de NFT para una obra de arte específica. Cada edición se asigna a un NFT individual y tiene un identificador único que lo distingue de otras ediciones. De esta manera, los NFT de colecciones con pocas ediciones tienden a ser más raros, ya que hay una cantidad más limitada de ellos disponibles en el mercado.',
  faq8T1: '+ ¿Qué tipo de canciones pueden incluir los artistas en el NFT?',
  faq8B1: 'Los artistas solo pueden usar canciones de las cuales poseen los derechos. Para demostrarlo, solicitamos algunas pruebas de que tienes la propiedad para comercializarla: captura de pantalla de la página del distribuidor, el proceso de producción y la hoja de regalías del ISRC con los colaboradores.',
  faq9T1: '+ ¿Cómo se realizan los pagos en la plataforma?',
  faq9B1: 'Como artista que vende Colecciones en Musii, deberás proporcionarnos una billetera de Algorand para recibir los pagos. Las transacciones se realizan al instante a medida que los fanáticos compran tus artículos. Todos los pagos se realizan en USDT.\nUSDT es una criptomoneda de stablecoin de Tether que representa un dólar estadounidense. Ofrece a los artistas un método de pago digital estable y conveniente, reduciendo la volatilidad en comparación con otras criptomonedas.\nSi deseas cambiar tus ganancias a moneda real (BRL), podrás hacerlo a través de una plataforma de terceros en tu perfil o cualquier intercambio que prefieras que acepte estas monedas.',
  faq10T1: '+ ¿Cómo cobra Musii?',
  faq10B1: 'Musii deduce del creador el 5% del valor de cada venta principal según el precio establecido por el artista en la plataforma. Además, Musii cobra una tarifa de servicio del 5% al comprador en cada transacción basada en el precio ingresado por el artista. Las tarifas para las ventas secundarias son del 10%, con un 5% del valor que va al creador y otro 5% que va a Musii.',
  faq11T1: '+ ¿Qué derechos obtengo al comprar un NFT en Musii?',
  faq11B1: 'Los compradores solo tienen la propiedad del NFT y las ventajas de los beneficios que se explican en la descripción del NFT (si aún están disponibles y el artículo se adquirió en el mercado primario). Esto significa que no poseen ningún derecho de autor o propiedad intelectual de la canción, de acuerdo con las leyes.\nTen en cuenta que si adquieres un NFT en el mercado secundario, es posible que los beneficios del artículo (si los hay) ya se hayan utilizado, y no podemos garantizar que el vendedor te los transfiera (como cualquier tipo de producto o experiencia que ya haya ocurrido).',
  faq12T1: '+ ¿Cuáles son las pautas para que se apruebe un NFT en Musii?',
  faq12B1: 'Musii es una plataforma musical y, por lo tanto, solo aceptamos NFT que se relacionen con ese tema. Estos son los requisitos estándar para vender NFT en Musii:\n- Ser sobre música: Todas las colecciones deben tener una imagen en miniatura y un video en el contenido. Este video debe contener música del artista reproduciéndose en segundo plano.\n- Tener los derechos: Los vendedores deben tener todos los derechos de autor de los materiales proporcionados.\n- Calidad y contenido apropiados: las ilustraciones y el contenido de NFT deben ser apropiados y respetar las pautas de Musii.\n- Ser único: Para que tu NFT siga ganando valor con el tiempo y tenga el potencial de ser revendido, debe ser escaso, lo que significa que la colección debe ser una oportunidad única en la vida para que los compradores posean esta colección específica. Por lo tanto, una condición es no replicar esta obra o combinación de obras y ventajas en la blockchain nunca más.',

  footerCommnunity: 'Somos una comunidad con decenas de miles de usuarios conectados a través de la música.\nSi quieres ser parte de esta comunidad, descarga nuestra aplicación y únete a nuestras redes sociales.',
  footerDisclaimer: 'Ninguna de las citas de costos, datos, noticias o investigaciones disponibles en este sitio web, nuestra aplicación o compartidas a través de cualquier canal de comunicación por parte de Musii o sus asociados constituye asesoramiento, propuestas o ventas de ninguna oferta para comprar o vender cualquier activo, derecho de autor, especulación o elementos de inversión. El rendimiento pasado no garantiza retornos futuros, ni es fundamentalmente demostrativo del rendimiento futuro. Las imágenes y fotos en el sitio y la aplicación son solo ilustrativas y pueden contener información que ya no está actualizada o válida. Los creadores pueden ofrecer beneficios relacionados con su colección. La entrega y/o ejecución de dichos beneficios a los compradores son responsabilidad exclusiva del creador, y Musii no asume ninguna responsabilidad por la entrega y/o ejecución de estos beneficios.',
  footerHome: 'Inicio',
  //footerMarketplace: 'Marketplace',
  //footerAbout: 'Acerca de',
  //footerApp: 'Aplicación',
  footerMission: 'Misión',
  footerTeam: 'Equipo',
  footerPartners: 'Socios',
  footerFAQ: 'Preguntas frecuentes',
  footerSupport: 'Soporte',
  footerSellNFT: 'Vender NFT',
  footerMyCollections: 'Mis Colecciones',
  footerNFTsHold: 'NFT en posesión',
  footerProfile: 'Perfil',
  footerTerms: 'Términos y condiciones',
  footerPrivacy: 'Política de privacidad',
  footerCopyright: 'Derechos de autor © 2023, Musii - Una nueva forma de ganar con la música',

  aboutUs: 'Sobre nosotros',
  aboutUsTitle: 'Animamos a los artistas a hacer música',
  aboutUsDesc: `Queremos que las personas puedan elegir seguir lo que les gusta. Creemos que cualquier artista puede ganarse la vida con la música.
  
  Para aquellos que tienen miedo de seguir una carrera musical debido a la dificultad de ganarse la vida en la industria musical, Musii hace posible que los artistas nuevos y emergentes vendan NFT a aquellos que creen en su talento, ayudando a estos artistas a alcanzar más éxito., ourMission: 'Nuestra misión', ourMissionTitle: 'Democratizar el mercado de la música', ourMissionDesc: Musii nació con la misión de ayudar a los artistas independientes. Nuestros fundadores conocen personalmente el dolor de este mercado y lo centralizada que está la industria musical.
  
  Los artistas establecidos pueden beneficiarse de los algoritmos actuales y los grandes actores, pero los artistas emergentes e independientes enfrentan una gran dificultad para ser descubiertos. Musii está cambiando este problema.`,
  musiiSeaLionsTitle: 'Musii Sea Lions',
  musiiSeaLionsDescription: 'Musii Sea Lions es una colección de NFT (ARC-19) creada por nuestro equipo con el objetivo de involucrar a la comunidad web3 en la noble causa de ayudar a los artistas independientes, democratizar la industria de la música y hacer que más personas conozcan Musii.',
  musiiSeaLionsCTA1: 'COLECCIONAR EN SHUFFLE',
  musiiSeaLionsCTA2: 'Más información',
  ourSquad: 'Nuestro equipo',
  ourSquadTitle: 'Miembros del equipo',
  partners: 'Socios',
  partnersTitle: 'Somos socios de marcas líderes',

  appSessionTheMusicSocialMedia: 'La Red Social de la Música',
  appSessionTheMusicSocialMediaInfo: 'Somos una comunidad de miles de fans, músicos, compositores, letristas, productores, todos conectados por un propósito: promover buena música. Prueba nuestra aplicación:',
  appSessionStartSharing: 'Comienza a compartir música en el lugar correcto',
  appSessionStartSharingInfo: 'La música se supone que se disfruta juntos. En Musii, las personas realmente escuchan las canciones que compartes. Elige una de más de 100 millones de pistas, agrega una descripción con tus pensamientos y gana relevancia cuando las personas interactúan contigo para convertirte en un influencer musical.',
  appSessionTheMusicSaveYourDiscoveries: 'Guarda tus descubrimientos directamente en tu Spotify',
  appSessionTheMusicSaveYourDiscoveriesInfo: 'Amplía tu repertorio musical como nunca antes. Descubre nuevas canciones y artistas que nunca antes escuchaste en segundos. Guarda todos tus hallazgos en una lista de reproducción con un solo clic gracias a nuestra integración con Spotify.',
  appSessionStayAhead: 'Mantente al tanto de lo que sucede en la escena de la industria',
  appSessionStayAheadInfo: 'Descubre las canciones más populares de la comunidad, quiénes son los nuevos talentos en ascenso y sé de los primeros en saber quiénes son las joyas fuera del círculo tradicional.',
  appSessionFindPeople: 'Encuentra personas que coincidan con tu gusto musical',
  appSessionFindPeopleInfo: 'Descubre personas similares a ti, únete a tu tribu musical y acércate a tus artistas favoritos. Participa, chatea, celebra y experimenta la música de nuevas formas.',
  profileLogOut: 'CERRAR SESIÓN',
  profileCTAAddOffer: 'AGREGAR OFERTA',
  profileCTAAddOfferMobile: "Disponible solo en PC.",

  profileNFTsHold: 'NFTs Hold',
  profileMyCollections: 'Mis Colecciones',
  profileMyCollectionsStatusDeclined: 'DECLINADA',
  profileMyCollectionsStatusPending: 'PENDIENTE',
  profileMyCollectionsStatusWaitingApproval: 'ESPERANDO APROBACIÓN',
  profileMyCollectionsStatusWaitingWallet: 'ESPERANDO WALLET',
  profileMyCollectionsStatusReview: 'REVISIÓN',
  //profileMyCollectionsSoldOut: 'SOLD OUT',
  profileCollections: 'Colecciones',
  profileProfile: 'Perfil',
  profileNFTsHoldStatusView: 'VER',
  profileNFTsHoldStatusRedeem: 'CANJEAR',
  profileNFTsHoldEmptyWallet: 'Cartera vacía',
  profileNFTsHoldEmptyWalletMessage: 'Lo sentimos, no hemos encontrado ningún activo en esta cartera.',
  profileNFTsHoldCTAConnectWallet: 'CONECTAR WALLET',
  //profileNFTsHoldCTAStartCollecting: 'COMENZAR A COLECCIONAR',
  editProfileUpdatePFP: 'Actualizar imagen de perfil',
  editProfileDisconnect: 'DESCONECTAR',
  editProfileName: 'Nombre',
  editProfileNamePlaceholder: '¿Cómo te gustaría que te llamen?',
  editProfileBio: 'Biografía',
  editProfileBioPlaceholder: 'Escribe algo sobre ti aquí.',
  editProfileConnectWallet: 'Conectar Wallet',
  editProfileConnectWalletInfo: 'Esto se utilizará para mostrar los NFT que tienes en tu perfil y acelerar el proceso de compras con tu wallet. Solo puedes conectar una wallet a la vez.',
  editProfileGenres: 'Géneros',
  editProfileSocial: 'Redes sociales:',
  editProfileHiddenInformation: 'Información oculta: ',
  editProfileEmail: 'Correo electrónico',
  editProfileEmailPlaceholder: 'tu@email.com',
  editProfilePhone: 'Teléfono',
  editProfilePhonePlaceholder: '+1 123 456 789',
  editProfileCPForId: 'CPF, CNPJ o ID',
  editProfileCPForIdPlaceholder: '123 456 789',
  editProfileButtonEdit: 'EDITAR',
  editProfileSuccessConfirmation: 'Perfil actualizado',
  editProfileButtonSuccessConfirmation: 'OK',

  createCollectionTitle: 'Crear Colección',
  createCollectionStepProfile: 'Perfil',
  createCollectionStepCollection: 'Colección',
  createCollectionStepWallet: 'Cartera',
  createCollectionArtisticName: 'Nombre Artístico',
  createCollectionArtisticNamePlaceholder: 'Nombre Artístico',
  createCollectionArtisticNameInfo: 'Debe ser la misma denominación que usas en las plataformas de streaming.',
  createCollectionEmail: 'Correo Electrónico',
  createCollectionEmailPlaceholder: 'Correo Electrónico',
  createCollectionEmailInfo: 'Proporciona una dirección de correo electrónico válida para que podamos contactarte, si es necesario.',
  createCollectionPhone: 'Teléfono',
  createCollectionPhonePlaceholder: 'Número de Teléfono',
  createCollectionPhoneInfo: 'Proporciona un número de teléfono válido para que podamos contactarte, si es necesario.',
  createCollectionCPForId: 'CPF, CNPJ o ID',
  createCollectionCPForIdPlaceholder: '123 456 789',
  createCollectionCPForIdInfo: 'Proporciona el número completo con dígito.',
  createCollectionMandatoryFiled: 'Este campo es obligatorio.',
  createCollectionCardCollectionName: 'Nombre de la Colección',
  createCollectionCardNext: 'SIGUIENTE',
  createCollectionCardSend: 'ENVIAR PARA APROBACIÓN',
  createCollectionIncludeBenefits: '¿Incluir beneficios?',
  createCollectionIncludeBenefitsInfo: 'Los músicos pueden adjuntar beneficios especiales a sus NFT en Musii, como productos físicos y experiencias de la vida real. Si eliges no incluir ningún beneficio, tu NFT se clasificará como "Coleccionable". Si deseas incluir alguno, abre la casilla a continuación y selecciónalo.',
  createCollectionCategories: 'Categorías:',
  createCollectionCategoriesCollectiblesInfo: 'Cualquier NFT que no incluya beneficios se clasifica en Musii como "Coleccionable".',
  createCollectionCategoriesProductsInfo: 'Vende productos únicos con tu NFT. Esta categoría incluye objetos físicos como los mencionados anteriormente.',
  createCollectionCategoriesProductsInfo2: `La entrega de estos beneficios es responsabilidad del vendedor (se recopilará la dirección de los compradores para este fin).`,
  createCollectionCategoriesExperiencesInfo: 'Vende entradas y acceso especial a experiencias de la vida real.',
  createCollectionCategoriesExperiencesInfo2: `La producción del evento o experiencia es tu única responsabilidad.`,
  createCollectionCategoriesRoyaltiesInfo: 'PRÓXIMAMENTE. Si estás interesado en vender los derechos de autor de tu canción, ',
  createCollectionCategoriesRoyaltiesInfo2: `haz clic aquí para contactarnos.`,
  createCollectionThumbnail: 'Miniatura del NFT',
  createCollectionThumbnailInfo: 'Incluye la imagen de portada de tu NFT. Aparecerá en las páginas cuando tu video no esté reproduciéndose.',
  createCollectionVideo: 'Video (contenido)',
  createCollectionVideoInfo: 'Incluye el video (contenido) de tu NFT.',
  createCollectionVideoInfo2: 'Asegúrate de que tu canción se reproduzca en segundo plano.',
  createCollectionUploadFile: 'SUBIR ARCHIVO',
  createCollectionUploadErrorSquare: 'El archivo que enviaste no es cuadrado. Te sugerimos que envíes uno con una resolución de 512px x 512px.',
  createCollectionName: 'Nombre de la Colección',
  createCollectionNamePlaceHolder: 'Nombre de la Colección',
  createCollectionDescription: 'Descripción',
  createCollectionDescriptionPlaceHolder: 'Descripción',
  createCollectionTags: 'Etiquetas',
  createCollectionTagsPlaceHolder: 'Agregar etiquetas para encontrar tus NFT',
  createCollectionExternalURL: 'URL Externa',
  createCollectionExternalURLPlaceHolder: 'Enlace',
  createCollectionTotalSupply: 'Suministro Total',
  createCollectionTotalSupplyPlaceHolder: '0',
  createCollectionPrice: 'Precio',
  createCollectionPricePlaceHolder: '0,00 €',
  createCollectionLegitimacyVerification: 'Verificación de Legitimidad:',
  createCollectionLegitimacyVerificationInfo: 'Para vender tu colección como artista en la plataforma, necesitaremos que nos envíes la siguiente información para verificar la legitimidad de tu trabajo y carrera. Esto demostrará que realmente tienes los derechos para explotar comercialmente esta canción como NFT. Si es necesario, también podemos solicitar confirmaciones a través de mensajes directos en tus redes sociales para asegurarnos de que nadie esté intentando suplantarte. Solo recuerda que los compradores y poseedores de tus NFT no podrán ejercer un uso comercial de tu trabajo según el contrato. Si se aprueba por primera vez, obtendrás automáticamente una insignia de artista en tu perfil.',
  createCollectionISRC: 'ISRC',
  createCollectionISRCPlaceHolder: 'AA-6Q20000047',
  createCollectionISRCInfo: 'El código de tu canción publicada en ISRC que muestra su número y distribución de derechos (isrcsearch.ifpi.org).',
  createCollectionAssociation: 'Asociación',
  createCollectionAssociationInfo: 'Informa la asociación de la que formas parte.',
  createCollectionAssociationOther: 'Otra',
  createCollectionAssociationOtherPlaceHolder: 'Otra',
  createCollectionStreamingDashboard: 'Tablero de Streaming*',
  createCollectionStreamingDashboardInfo: 'Carga una captura de pantalla de tu panel de artistas en un servicio de streaming en el que tengas canciones distribuidas (por ejemplo, Spotify for Artists).',
  createCollectionDistributorProfile: 'Perfil del Distribuidor',
  createCollectionDistributorProfileInfo: 'Carga una captura de pantalla de tu área de inicio de sesión en la plataforma de distribución en la que tengas canciones distribuidas.',
  createCollectionPayOff: 'Cuenta de Pagos:',
  createCollectionPayOffInfo: 'Para recibir los pagos de tu colección, deberás proporcionar una cartera de Algorand (si aún no tienes una, aprende cómo crearla aquí). Esta cartera se utilizará para crear esta colección y recibir las ganancias de la misma. NO podrás cambiar esta cartera una vez que la hayas establecido como tu cuenta de recepción para esta colección específica.',
  createCollectionPayOffInfoLearnMore: 'Los pagos se realizan en USDT (más información).',
  createCollectionWalletConnected: 'Cartera Conectada',
  createCollectionTutorial: '¿Todavía no tienes una cartera? Mira este video tutorial para aprender cómo crear una.',
  createCollectionFinalInformation: 'Por favor, confirma la siguiente información',
  createCollectionCheckInformationTrue: 'Aseguro que toda la información proporcionada es verdadera.',
  createCollectionCheckUsdt: 'Confirmo que opté por USDT en mi billetera para recibir los montos de mis ventas.',
  createCollectionCheckRightHolder: 'Soy el titular de los derechos de todo el contenido incluido en el NFT, incluyendo la música.',
  createCollectionCheckBenefits: 'Si la colección incluye algún beneficio, acepto que soy totalmente responsable de su entrega y/o ejecución.',
  createCollectionCheckAgreeWithTax: 'Estoy consciente y acepto las tarifas de servicio de Musii del 5% para los vendedores y del 5% para los compradores sobre el valor de las ventas.',
  createCollectionCheckTerms: 'Al continuar, estoy aceptando los Términos de Servicio y las cláusulas contractuales de Musii.',
  createCollectionCheckButtomCancel: 'CANCELAR',
  createCollectionCheckButtomContinue: 'CONTINUAR',
  createCollectionSent: '¡Colección enviada para aprobación!',
  createCollectionSentInfo: 'Felicitaciones, tus datos se han enviado correctamente. Ahora, espera un período de hasta 72 horas para que nuestro equipo verifique que todo esté dentro de nuestras pautas. Mientras tanto, ¿qué tal si completas tu perfil para que las personas puedan saber más sobre ti?',
  createCollectionSentButtomViewRequest: 'VER MI SOLICITUD',
  createCollectionSentButtomCompleteProfile: 'COMPLETAR MI PERFIL',

  reviewFlowDeletedField: 'Este campo ha sido eliminado, completa y envía nuevamente.',
  emailError: 'Este correo electrónico no es válido.',
  cpfError: 'Este CPF no es válido, si desea pagar con PIX, debe completar un CPF válido. Proporcione el número completo con un dígito para que se pueda completar la transacción.',
  websiteError: 'Este sitio web no es válido.',
  //supplyError: 'Debes elegir una cantidad entre 1 y 99,999.',
  //priceError: 'Debes elegir un precio entre R$ 1.00 y R$ 9,999.00.',
  possibleErrorsLogin: 'Existen dos posibilidades de error: 1- este correo electrónico ya se ha utilizado para crear una cuenta de Musii a través de Google/Facebook o;\n2- la contraseña es incorrecta.',
  possibleErrorsForgot: 'Existen dos posibilidades de error: 1- este correo electrónico ya se ha utilizado para crear una cuenta de Musii a través de Google/Facebook o;\n2- el correo electrónico no tiene una cuenta en musii.',
  //passwordError: 'Contraseña incorrecta. Por favor, inténtalo de nuevo o haz clic en "¿Olvidaste tu contraseña?".',
  passwordNotMatchError: 'Debe coincidir con tu nueva contraseña.',
  passwordWithoutRules: 'Debe contener al menos 8 caracteres (letras y números).',

  changePassword: 'Cambiar la contraseña:',
  changePasswordDesc: 'Proporcione una nueva contraseña y confírmela.',
  changePasswordEmail: 'Email para recuperar contraseña.',
  changePasswordCode: 'Código*',
  changePasswordCodeDesc: 'Código enviado por correo electrónico.',
  changePasswordNewPassword: 'Nueva contraseña.',
  changePasswordConfirmPassword: 'Confirmar nueva contraseña*',
  changePasswordButton: 'CAMBIAR CONTRASEÑA',

  purchaseFlowCollectionDetails: 'Detalles de la Colección:',
  purchaseFlowCollectionDetailsCreator: 'Creador',
  purchaseFlowCollectionDetailsMetadata: 'Estándares de Metadatos',
  purchaseFlowCollectionDetailsSupply: 'Cantidad',
  purchaseFlowCollectionEditions: 'ediciones',
  purchaseFlowCollectionAttention: '*Los beneficios son válidos solo para los compradores de ventas primarias. \n**La entrega y/o ejecución de dichos beneficios a los compradores es responsabilidad exclusiva del creador, y Musii no asume responsabilidad por la entrega y/o ejecución de dichos beneficios.',
  //purchaseFlowCollectionButtomBuy: 'COMPRAR',
  purchaseFlowPersonalDataIcon: 'Datos Personales',
  purchaseFlowPaymentIcon: 'Pago',
  purchaseFlowPersonalDataTitle: 'Datos Personales y Envío',
  purchaseFlowPersonalDataInfo: 'Esta información es necesaria para que el creador de la colección pueda entregarte los beneficios y contactarte si es necesario.',
  purchaseFlowName: 'Nombre Completo',
  purchaseFlowNamePlaceholder: 'Nombre y Apellido',
  purchaseFlowEmail: 'Correo electrónico',
  purchaseFlowEmailPlaceholder: 'Correo electrónico',
  purchaseFlowEmailInfo: 'Por favor, proporciona una dirección de correo electrónico válida para que podamos contactarte, si es necesario.',
  purchaseFlowPhone: 'Teléfono',
  purchaseFlowPhonePlaceholder: 'Número de Teléfono',
  purchaseFlowPhoneInfo: 'Por favor, proporciona un número de teléfono válido para que podamos contactarte, si es necesario.',
  purchaseFlowCPForId: 'CPF o ID',
  purchaseFlowCPForIdInfo: 'Por favor, proporciona el número completo con el dígito.',
  //purchaseFlowMandatoryFiled: 'Este campo es obligatorio.',
  purchaseFlowZipCode: 'Código Postal',
  purchaseFlowZipCodePlaceholder: 'XXXXX-XXX',
  purchaseFlowAddress: 'Dirección',
  purchaseFlowAddressPlaceholder: 'Calle/Avenida/Boulevard',
  purchaseFlowAddressNumber: 'Número',
  purchaseFlowAddressComplement: 'Complemento',
  purchaseFlowAddressComplementPlaceholder: 'Apartamento/Piso/Bloque',
  purchaseFlowAddressNeighborhood: 'Barrio',
  purchaseFlowAddressNeighborhoodPlaceholder: 'Barrio/Distrito',
  purchaseFlowAddressCity: 'Ciudad',
  purchaseFlowAddressCityPlaceholder: 'Ciudad/Pueblo',
  purchaseFlowAddressState: 'Estado',
  purchaseFlowAddressStatePlaceholder: 'Estado/Provincia/Región',
  purchaseFlowAddressCountry: 'País',
  purchaseFlowAddressCountryPlaceholder: 'País',
  purchaseFlowCheckSharingData: 'Autorizo el compartir mis datos con terceros para la entrega de beneficios y, a partir de ahora, me pongo a disposición para ser contactado/a.',
  purchaseFlowCheckAgreeTerms: 'Al continuar, estoy de acuerdo con los Términos de Servicio de Musii y las cláusulas del contrato.',
  purchaseFlowCheckoutTitle: 'Confirmación de compra - Método de Pago',
  purchaseFlowCheckoutInfo: 'Si pagas con PIX (moneda fiduciaria), tu NFT estará disponible en tu perfil y, si lo deseas, podrás reclamarlo a una billetera de criptomonedas más adelante, haciendo clic en "canjear" y conectando una billetera.',
  purchaseFlowPayWithWallet: 'Pagar con $ALGO:',
  purchaseFlowPayWithPix: 'Pagar con PIX',
  purchaseFlowOrderSummary: 'Resumen del Pedido',
  purchaseFlowOrderSubtotal: 'Subtotal',
  purchaseFlowOrderTotal: 'Total',
  purchaseFlowServiceTax: 'Impuesto de Servicio',
  purchaseFlowProcessingMint: 'Procesando Mint',
  purchaseFlowProcessingMintDesc: 'Esperando el compás adecuado para conectar tu billetera. Gracias por tu paciencia mientras trabajamos en segundo plano.',
  purchaseFlowProcessingMintWallet: 'Mantenga su billetera abierta durante el proceso y verifíquela cuando sea necesario para aceptar transacciones.',
  purchaseFlowConfirmationTitle: 'Compra Aprobada',
  purchaseFlowConfirmationInfo: 'Para acceder a tu NFT, simplemente ingresa a tu perfil y luego haz clic en la pestaña "NFTs Hold".',
  purchaseFlowConfirmationButtonView: 'VER MIS NFT',

  tutorialTitle: 'Aprende en este video tutorial el proceso paso a paso de cómo crear o conectar una billetera para recibir pagos.',
  tutorialNftTitle: 'Aprende en este video tutorial el proceso paso a paso de cómo crear una NFT en musii'
};

export default es;
