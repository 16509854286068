import React, { useCallback, useEffect, useState } from "react";

import Header from "../components/Header";

import axios from "axios";
import Footer from "../components/Footer";

import WhiteWallet from "../assets/whiteWallet";

import {
    Box,
    Button,
    Grid,
    LinearProgress,
    Typography,
    linearProgressClasses,
    styled,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import TypeNfts from "../utils/TypeNfts";
import Fonts from "../utils/Fonts";
import Online from "../assets/online";
import Verified from "../assets/verified";
import Up from "../assets/up";
import Down from "../assets/down";
import { useUser } from "../hooks/useUser";
import { useTranslation } from "react-i18next";
import Dev from "../utils/Dev";

const MusiiLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    width: "40%",
    borderRadius: 8,
    marginLeft: 16,
    backgroundColor: "#242447",
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 8,
        backgroundColor: "#EBEBFA",
    },
}));

const Item = () => {
    const [t, i18n] = useTranslation("common");

    const [showModalConnect, setShowModalConnect] = useState(false);
    const [redirectModal, setRedirectModal] = useState(false);

    const videoRef = useCallback((x) => (x ? (x.volume = 0.4) : ""), []);
    const navigate = useNavigate();

    const [openDetails, setOpenDetails] = useState(false);

    const [nft, setNft] = useState({});

    // const [quantity, setQuantity] = useState(1);

    const [width, setWidth] = useState(window.innerWidth);

    const { id } = useParams();
    const { user, cotation } = useUser();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const loadNft = async () => {
            
            const responseNft = await axios.get(
                `${Dev.api}nft/id/${id}`)

            setNft(responseNft.data)
        };

        loadNft();

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    async function mint() {
        if (nft.totalSold < nft.totalSuply) {
            if (user && (user.email || user.name)) {
                navigate("/shop", { state: { nft: { ...nft, quantity: 1 } } })
            } else {
                setShowModalConnect(true)
            }
        }
    }

    return (
        <Box backgroundColor={"#121229"}>
            <Header isMobile={isMobile} showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} redirectModal={redirectModal} setRedirectModal={setRedirectModal} />
            <Box>
                <Grid
                    container
                    marginTop={isMobile ? "64px" : "108px"}
                    marginBottom={"72px"}
                    padding={isMobile ? "20px 30px" : "56px 72px"}
                    maxWidth={"90vw"}
                    mx={isMobile ? "16px" : "64px"}
                    borderRadius={"48px"}
                    gap={"74px"}
                >
                    <Grid xs={12} md={4} xl={3.6}>
                        {nft.video && (
                            <video
                                ref={videoRef}
                                style={{
                                    width: "100%",
                                    borderRadius: 32,
                                }}
                                autoPlay={true}
                                controls={true}
                                src={nft.video}
                                alt="Musii"
                                playsInline
                            />
                        )}
                        {!nft.video && (
                            <img
                                width={"100%"}
                                src={nft.image}
                                style={{ borderRadius: 32 }}
                                alt="cover"
                            />
                        )}
                        <Grid
                            xs={12}
                            md={12}
                            display={"flex"}
                            flexDirection={"column"}
                            border={"2px solid #313152"}
                            borderRadius={"24px"}
                            marginTop={4}
                            padding="24px"
                        >
                            <Grid
                                container
                                xs={12}
                                md={12}
                                gap={4}
                                display={"flex"}
                                onClick={() => setOpenDetails(!openDetails)}
                                style={{ cursor: "pointer" }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#CACAE5",
                                        marginBottom: 8,
                                        flex: 1,
                                    }}
                                >
                                    {t('purchaseFlowCollectionDetails')}
                                </Typography>
                                <Box>{openDetails ? <Up /> : <Down />}</Box>
                            </Grid>
                            {openDetails && (
                                <Grid
                                    container
                                    xs={12}
                                    md={12}
                                    gap={2}
                                    display={"flex"}
                                    marginTop={4}
                                >
                                    <Grid xs={12} display={"flex"}>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].b1RegularSize1,
                                                color: "#B8B8DB",
                                                flex: 1,
                                            }}
                                        >
                                            {t('purchaseFlowCollectionDetailsCreator')}
                                        </Typography>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].b1RegularSize1,
                                                color: "#B8B8DB",
                                            }}
                                        >
                                            {`${nft.wallet.slice(
                                                0,
                                                5
                                            )}...${nft.wallet.slice(
                                                nft.wallet.length - 5,
                                                nft.wallet.lenght
                                            )}`}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} display={"flex"}>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].b1RegularSize1,
                                                color: "#B8B8DB",
                                                flex: 1,
                                            }}
                                        >
                                            {t('purchaseFlowCollectionDetailsMetadata')}
                                        </Typography>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].b1RegularSize1,
                                                color: "#B8B8DB",
                                            }}
                                        >
                                            ARC-69
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} display={"flex"}>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].b1RegularSize1,
                                                color: "#B8B8DB",
                                                flex: 1,
                                            }}
                                        >
                                            {t('purchaseFlowCollectionDetailsSupply')}
                                        </Typography>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].b1RegularSize1,
                                                color: "#B8B8DB",
                                            }}
                                        >
                                            {nft.totalSuply}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={7}>
                        <Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h1BoldSize1,
                                    color: "#F0F0FA",
                                    display: "flex",
                                }}
                            >
                                {nft.name}
                                <Box
                                    marginLeft={2}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Online />
                                </Box>
                            </Typography>
                            <Box
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    navigate(`/user/${nft.artistName}`, {
                                        state: { user: nft.artist },
                                    })
                                }
                                display={"flex"}
                                justifyItems={"center"}
                                alignItems={"center"}
                                marginTop={2}
                            >
                                <img
                                    width={"32px"}
                                    height={"32px"}
                                    src={nft.artistPhoto}
                                    style={{ borderRadius: 99 }}
                                    alt="cover"
                                />

                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2RegularSize1,
                                        color: "#F0F0FA",
                                        marginRight: 8,
                                        marginLeft: 12,
                                    }}
                                >
                                    {nft.artistName}
                                </Typography>
                                <Verified
                                    style={{
                                        color: "#F0F0FA",
                                        width: 16,
                                        height: 16,
                                    }}
                                />
                            </Box>
                            <Box>
                                <Box
                                    style={{
                                        backgroundColor:
                                            TypeNfts[
                                                nft.categorie
                                                    ? nft.categorie.toLowerCase()
                                                    : "collectibles"
                                            ].backgroundColor,
                                        padding: "8px 16px",
                                        borderRadius: 32,
                                        display: "inline-flex",
                                        alignItems: "center",
                                        marginTop: "24px",
                                        marginBottom: "16px",
                                    }}
                                >
                                    <Box
                                        style={{
                                            marginRight: "8px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {
                                            TypeNfts[
                                                nft.categorie?.toLowerCase() ||
                                                "collectibles"
                                            ].image
                                        }
                                    </Box>
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].b1BoldSize2,
                                            color: TypeNfts[
                                                nft.categorie?.toLowerCase() ||
                                                "collectibles"
                                            ].color,
                                        }}
                                    >
                                        {nft.subCategorie
                                            ? nft.subCategorie
                                            : "Collectible"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                display={"flex"}
                                justifyItems={"center"}
                                alignItems={"center"}
                                marginTop={2}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].b1RegularSize1,
                                        color: "#ABABCC",
                                        whiteSpace: "break-spaces",
                                        wordWrap: "break-word",
                                        maxWidth: "100%"
                                    }}
                                >
                                    {nft.description}
                                </Typography>
                            </Box>
                            <Box
                                display={"flex"}
                                justifyItems={"center"}
                                alignItems={"center"}
                                marginTop={"40px"}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#F0F0FA",
                                        marginRight: isMobile ? 0 : 8,
                                        marginLeft: isMobile ? 0 : 12,
                                    }}
                                >
                                    {nft.totalSold}
                                </Typography>
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2RegularSize1,
                                        color: "#ABABCC",
                                    }}
                                >
                                    / {nft.totalSuply} {t('purchaseFlowCollectionEditions')}
                                </Typography>
                                {!isMobile &&
                                    <MusiiLinearProgress
                                        variant="determinate"
                                        value={(parseInt(nft.totalSold) / parseInt(nft.totalSuply)) *
                                            100}
                                    />}
                                    {/*
                                <Box
                                    style={{
                                        backgroundColor: "#242447",
                                        display: "flex",
                                        borderRadius: "16px",
                                        marginLeft: "40px",
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: "flex",
                                            width: "40px",
                                            height: "40px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            if (quantity > 1) {
                                                setQuantity(quantity - 1)
                                            }
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h2BoldSize1,
                                                color: "#7272A3",
                                                textAlign: "center",
                                                userSelect: "none"
                                            }}
                                        >
                                            -
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            backgroundColor: "#121229",
                                            display: "flex",
                                            border: "2px solid #242447",
                                            width: "59px",
                                            height: "40px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h2RegularSize1,
                                                color: "#F0F0FA",
                                                textAlign: "center",
                                                userSelect: "none"
                                            }}
                                        >
                                            {quantity}
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: "flex",
                                            width: "40px",
                                            height: "40px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            if (quantity < 10 && quantity < (parseInt(nft.totalSuply) - parseInt(nft.totalSold))) {
                                                setQuantity(quantity + 1)
                                            }
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h2BoldSize1,
                                                color: "#7272A3",
                                                textAlign: "center",
                                                userSelect: "none"
                                            }}
                                        >
                                            +
                                        </Typography>
                                    </Box>
                                        </Box>*/}
                            </Box>
                            <Box
                                display={"flex"}
                                flexDirection={isMobile ? "column" : "row"}
                                alignItems={"center"}
                                marginTop={6}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    backgroundColor="#12122933"
                                    padding="24px"
                                    borderRadius={8}
                                    style={{
                                        background:
                                            "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                    }}
                                >
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h2BoldSize1,
                                                color: "#F0F0FA",
                                                userSelect: "none"
                                            }}
                                        >
                                            R$ {parseFloat(nft.price).toFixed(2)}
                                        </Typography>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h2RegularSize1,
                                                color: "#ABABCC",
                                                marginLeft: "4px",
                                                userSelect: "none"
                                            }}
                                        >
                                            BRL
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h3RegularSize1,
                                                color: "#ABABCC",
                                                marginLeft: "2px",
                                                userSelect: "none"
                                            }}
                                        >
                                            {parseFloat(
                                                nft.price / cotation
                                            ).toFixed(2)}
                                        </Typography>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile
                                                        ? "mobile"
                                                        : "desktop"
                                                ].h3RegularSize1,
                                                color: "#7272A3",
                                                marginLeft: "4px",
                                                userSelect: "none"
                                            }}
                                        >
                                            USDT
                                        </Typography>
                                    </Box>
                                </Box>

                                <Button
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,

                                        background:
                                            parseInt(nft.totalSold) >= parseInt(nft.totalSuply)
                                                ? " linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)"
                                                : "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                        borderRadius: "40px",
                                        padding: "24px 48px",
                                        marginLeft: isMobile ? 0 : 64,
                                        marginTop: isMobile ? "16px" : 0
                                    }}
                                    startIcon={
                                        parseInt(nft.totalSold) < parseInt(nft.totalSuply) ? (
                                            <WhiteWallet />
                                        ) : (
                                            ""
                                        )
                                    }
                                    variant="contained"
                                    onClick={mint}
                                >
                                    {parseInt(nft.totalSold) >= parseInt(nft.totalSuply)
                                        ? t('nftCardSold')
                                        : t('nftCardBuy')}
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            display={"flex"}
                            justifyItems={"center"}
                            alignItems={"center"}
                            marginTop={"24px"}
                        >
                            <Typography
                                style={{
                                    color: "#7272A3",
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    marginRight: 8,
                                    marginLeft: 12,
                                    whiteSpace: "break-spaces",
                                    userSelect: "none"
                                }}
                            >
                                {t('purchaseFlowCollectionAttention')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default Item;
