import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Step1 from "../components/approval/Step1";
import Step2 from "../components/approval/Step2";
import Step3 from "../components/approval/Step3";
import { useLocation } from "react-router-dom";

const Approval = () => {
    const location = useLocation();

    const [width, setWidth] = useState(window.innerWidth);
    const [step, setStep] = useState(0);

    const [nft, setNft] = useState(location?.state?.nft);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });
    }, [step]);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"} marginTop={"120px"}>
            <Header isMobile={isMobile} />
            {step === 0 && <Step1 setStep={setStep} nft={nft} setNft={setNft} />}
            {step === 1 && <Step2 setStep={setStep} nft={nft} setNft={setNft} />}
            {step === 2 && <Step3 setStep={setStep} nft={nft} setNft={setNft} />}
            <Footer />
        </Box>
    );
};

export default Approval;
