import React, { useEffect, useState } from "react";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import MintNftCard from "../commons/MintNftCard";
import Fonts from "../../utils/Fonts";
import { useUser } from "../../hooks/useUser";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import "react-phone-number-input/style.css";

import PhoneInput from "react-phone-number-input";
import StorageEnum from "../../enums/StorageEnum";
import Storage from "../../utils/Storage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";

const client = new S3Client({
    region: "us-east-2",
    credentials: fromCognitoIdentityPool({
        clientConfig: { region: "us-east-2" },
        identityPoolId: "us-east-2:06569f2a-2a53-4326-93d3-167f56352778",
    }),
});

const Step1 = ({ setStep, nft, setNft }) => {
    const [width, setWidth] = useState(window.innerWidth);

    const [t, i18n] = useTranslation("common");
    const { user, logIn } = useUser();

    const [validateEmail, setValidateEmail] = useState(false);
    const [artistPhotoField, setArtistPhoto] = useState(nft?.artistPhoto ? nft?.artistPhoto : "");
    const [artistNameField, setArtistName] = useState(nft?.artistName ? nft?.artistName : "");
    const [emailField, setEmail] = useState(nft?.artistEmail ? nft?.artistEmail : "");
    const [phoneField, setPhone] = useState(nft?.artistPhone ? nft?.artistPhone : "");
    const [documentField, setDocument] = useState(nft?.artistDocument ? nft?.artistDocument : "");

    const [validateFields, setValidateField] = useState(false);
    const [canClick, setCanClick] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const awsUploadFile = async (file) => {
            const command = new PutObjectCommand({
                ACL: "public-read",
                Bucket: "musii-dev-repository",
                Key: `profile/${user.email}.png`,
                Body: file,
            });

            const uploadObject = await client.send(command);

            console.log(uploadObject)

            setArtistPhoto(
                `https://musii-dev-repository.s3.us-east-2.amazonaws.com/profile/${user.email}.png?counter=${new Date().getMilliseconds()}`
            );
        };

        if (selectedFile) {
            awsUploadFile(selectedFile);
        }
    }, [selectedFile]);

    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        if (
            !validateEmail &&
            artistNameField !== "" &&
            emailField !== "" &&
            phoneField &&
            phoneField !== "" &&
            documentField !== "" &&
            artistPhotoField !== ""
        ) {
            setNft({
                ...nft,
                artistPhoto: artistPhotoField,
                artistName: artistNameField,
                artistEmail: emailField,
                artistPhone: phoneField,
                artistDocument: documentField
            });

            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [artistNameField, artistPhotoField, emailField, phoneField, documentField]);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <Box>
            <Grid container position="relative" xs={12} marginBottom={12}>
                <Grid xs={12} md={12}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('createCollectionTitle')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    paddingRight={isMobile ? 4 : 0}
                    paddingLeft={isMobile ? 4 : 8}
                    marginTop={6}
                    gap={8}
                    xs={12}
                    md={10}
                    lg={10}
                    xl={9}
                    display={"flex"}
                    justifyContent={isMobile ? "center" : ""}
                >
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"20px"}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#7272A3",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    1
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#CACAE5",
                                }}
                            >
                                {t('createCollectionStepProfile')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "rgba(114, 114, 163, 0.4)",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "rgba(114, 114, 163, 0.4)",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    2
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "rgba(114, 114, 163, 0.4)",
                                }}
                            >
                                {t('createCollectionStepCollection')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "rgba(114, 114, 163, 0.4)",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "rgba(114, 114, 163, 0.4)",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "rgba(114, 114, 163, 0.4)",
                                }}
                            >
                                {t('createCollectionStepWallet')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Typography
                        style={{
                            color: "#FB5E87",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "20px",
                            lineHeight: "32px",
                            marginLeft: 8,
                            whiteSpace: "break-spaces",
                            wordWrap: "break-word"
                        }}
                    >
                        {nft.recusedReason}
                    </Typography>
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Box position={"relative"}>
                            <Button
                                component="label"
                                style={{
                                    backgroundColor: "transparent",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 99,
                                }}
                            >
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        setSelectedFile(e.target.files[0]);
                                    }}
                                    hidden
                                />
                            </Button>
                            <img
                                width={"80px"}
                                height={"80px"}
                                src={artistPhotoField}
                                style={{ borderRadius: 99 }}
                                alt="cover"
                                referrerpolicy="no-referrer"
                            />
                        </Box>
                        <Box position={"relative"}>
                            <Button
                                component="label"
                                style={{
                                    backgroundColor: "transparent",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 99,
                                }}
                            >
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        setSelectedFile(e.target.files[0]);
                                    }}
                                    hidden
                                />
                            </Button>
                            <Typography
                                style={{
                                    color: "#EBEBFA",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    lineHeight: "32px",
                                    marginLeft: 8,
                                }}
                            >
                                {t('editProfileUpdatePFP')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "0px 20px",
                            }}
                        >
                            {t('createCollectionArtisticName')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            value={artistNameField}
                            placeholder={t('createCollectionArtisticNamePlaceholder')}
                            onChange={(e) => setArtistName(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                            error={validateFields}
                            helperText={
                                validateFields && artistNameField === ""
                                    ? t('createCollectionMandatoryFiled')
                                    : ""
                            }
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionArtisticNameInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3}
                        display={"flex"}
                        flexDirection={"column"}
                    ></Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "0px 20px",
                            }}
                        >
                            {t('createCollectionEmail')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            value={emailField}
                            placeholder={t('createCollectionEmailPlaceholder')}
                            type="email"
                            error={validateEmail || validateFields}
                            helperText={
                                validateEmail
                                    ? t('emailError')
                                    : validateFields && emailField === ""
                                        ? t('createCollectionMandatoryFiled')
                                        : ""
                            }
                            onBlur={(e) => {
                                if (
                                    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                                        emailField
                                    )
                                ) {
                                    setValidateEmail(true);
                                } else {
                                    setValidateEmail(false);
                                }
                            }}
                            onChange={(e) => {
                                const { value } = e.target;
                                setEmail(value.toLowerCase());
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionEmailInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "0px 20px",
                            }}
                        >
                            {t('createCollectionPhone')}*
                        </Typography>
                        <PhoneInput
                            value={phoneField}
                            placeholder={t('createCollectionPhonePlaceholder')}
                            id={"phonenumberfield"}
                            numberInputProps={{
                                style: {
                                    boxShadow: 0,
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    border: "0px solid transparent",
                                    backgroundColor: "transparent",
                                    color: "#B8B8DB",
                                },
                            }}
                            style={{
                                boxShadow: 0,
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                padding: "16.5px 20px",
                                border: "0px solid transparent",
                                color: "#B8B8DB",
                                borderRadius: "24px",
                                borderBottomWidth: 0,
                                background:
                                    "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                            }}
                            placeholderColor="red"
                            onChange={setPhone}
                        />
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={"Phone Number"}
                            value={phoneField}
                            onChange={(e) => setPhone(e.target.value)}
                            error={validateFields}
                            helperText={
                                validateFields &&
                                    (!phoneField || phoneField === "")
                                    ? t('createCollectionMandatoryFiled')
                                    : ""
                            }
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    display: "none",
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />

                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionPhoneInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "0px 20px",
                            }}
                        >
                            {t('createCollectionCPForId')}*
                        </Typography>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder={t('createCollectionCPForIdPlaceholder')}
                            type="text"
                            value={documentField}
                            error={validateFields}
                            helperText={
                                validateFields && documentField === ""
                                    ? t('createCollectionMandatoryFiled')
                                    : ""
                            }
                            onChange={(e) => {
                                setDocument(e.target.value);
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionCPForIdInfo')}
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Grid
                        marginTop={4}
                        gap={8}
                        xs={8}
                        md={2}
                        style={{
                            position: isMobile ? "unset" : "fixed",
                            right: 86,
                            top: "30%",
                            transform: isMobile ? "" : "translateY(-20%)",
                        }}
                    >
                        <MintNftCard
                            artistName={artistNameField}
                            artistPhoto={artistPhotoField}
                            image={nft.image}
                            name={nft.name}
                            price={nft.price}
                            totalSuply={nft.totalSuply}
                            categorie={nft.categorie}
                            subCategorie={nft.subCategorie}
                            isMobile={isMobile}
                        />
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: canClick
                                    ? "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)"
                                    : "#B8B8DB",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                if (canClick) {
                                    const response = await axios.put(
                                        `${Dev.api}nft/user/${user.email}`,
                                        {
                                            ...user,
                                            name: artistNameField,
                                            email: emailField,
                                            phone: phoneField,
                                            document: documentField
                                        },
                                        {
                                            headers: {
                                                "Content-Type": "application/json;charset=utf-8",
                                            },
                                            crossDomain: true,
                                        }
                                    );

                                    await Storage.setItem(StorageEnum.USER, {
                                        ...user,
                                        name: artistNameField,
                                        photo: artistPhotoField,
                                        email: emailField,
                                        phone: phoneField,
                                        document: documentField
                                    });

                                    logIn()
                                    setStep(1)
                                } else setValidateField(true);
                            }}
                            variant="contained"
                        >
                            {t('createCollectionCardNext')}
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
};

export default Step1;
