const Royaltie = ({ size = "16" }) => {
    return (
        <svg
            width={`${size}`}
            height={`${size}`}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.9848 4.43546C27.6712 3.55933 29.0772 4.10233 28.9967 5.2122L27.7218 22.7554C27.679 23.3447 27.1884 23.8008 26.5976 23.8008H5.8454C5.26544 23.8008 4.78009 23.3608 4.72347 22.7836L3.00599 5.24035C2.89705 4.12751 4.30054 3.55205 5.00405 4.42135L10.8373 11.6297L14.8698 4.57143C15.2996 3.81936 16.3818 3.81286 16.8203 4.55961L21.1348 11.9038L26.9848 4.43546ZM25.5492 21.5465L26.4801 8.73624L21.8893 14.5975C21.3989 15.2232 20.4325 15.1588 20.0299 14.4734L15.8625 7.37909L11.9775 14.1791C11.5839 14.8677 10.6213 14.9454 10.1226 14.329L5.61628 8.76093L6.86806 21.546L25.5492 21.5465Z"
                fill="#ABABCC"
            />
            <path
                d="M6.10963 28.5864H26.9069C27.5294 28.5864 28.0341 28.082 28.0341 27.4592C28.0341 26.8368 27.5294 26.332 26.9069 26.332H6.10963C5.48715 26.332 4.98242 26.8368 4.98242 27.4592C4.98242 28.082 5.48715 28.5864 6.10963 28.5864Z"
                fill="#ABABCC"
            />
        </svg>
    );
};

export default Royaltie;
