import Collectible from "../assets/collectible";
import Experience from "../assets/experience";
import Nft from "../assets/nft";
import Product from "../assets/product";
import Royaltie from "../assets/royaltie";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    collectibles: {
        name: "nftCollectibles",
        image: <Collectible />,
        color: "#F5A6F2",
        backgroundColor: "#301D3DCC",
    },
    products: {
        name: "nftProducts",
        image: <Product />,
        color: "#806FDC",
        backgroundColor: "#211C45CC",
    },
    experiences: {
        name: "nftExperiences",
        image: <Experience />,
        color: "#49A6A3",
        backgroundColor: "#102633CC",
    },
    royalties: {
        name: "nftRoyalties",
        image: <Royaltie />,
        color: "#ABABCC",
        backgroundColor: "rgba(36, 36, 71, 0.95)",
    },
    asset: {
        name: "nftAsset",
        image: <Nft />,
        color: "#ABABCC",
        backgroundColor: "#121229CC",
    }
};
