import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import NftList from "../NftList";
import axios from "axios";
import { useUser } from "../../hooks/useUser";
import Dev from "../../utils/Dev";

const MyCollections = ({ another, anotherUser }) => {
    const { user } = useUser();

    const [width, setWidth] = useState(window.innerWidth);
    const [nfts, setNfts] = useState([]);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        async function getNfts() {
            const response = await axios.get(
                `${Dev.api}nft/artist/${another ? anotherUser.email : user.email}?${another ? "" : "active=false"}`)

            setNfts(response.data)
        }

        getNfts()

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, [another, anotherUser]);

    const isMobile = width <= 768;

    return (
        <Box>
            <Grid container spacing={3} padding={isMobile ? "0px 32px" : "0px 128px"} marginBottom={"64px"}>
                <Grid item sm={12} md={12}>
                    <NftList isMobile={isMobile} padding={0} nfts={nfts} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default MyCollections;
