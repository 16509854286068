import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Fonts from "../utils/Fonts";

import Mock1 from "../assets/mock_1.png";

import Playstore from "../assets/playstore.png";
import Appstore from "../assets/appstore.png";
import { useTranslation } from "react-i18next";

function DownloadApp({ isMobile }) {
    const [t, i18n] = useTranslation("common");

    return (
        <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            marginTop={isMobile ? "48px" : "96px"}
            style={{
                display: "flex",
                padding: isMobile ? "20px 30px" : "0px 56px",
                alignItems: isMobile ? "flex-start" : "center",
                borderRadius: 16,
                flexDirection: isMobile ? "column" : "row",
            }}
        >
            <Grid
                xs={12}
                md={5.5}
                xl={4}
                marginRight={isMobile ? "24px" : "122px"}
            >
                <Box
                    style={{
                        width: "35px",
                        height: "6px",
                        background:
                            "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                        borderRadius: "3px",
                        marginTop: "8px",
                        marginBottom: "16px",
                    }}
                ></Box>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].h1BoldSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces",
                        marginTop: 4,
                        marginBottom: 4
                    }}
                >
                    {t('appSessionTheMusicSocialMedia')}
                </Typography>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h3RegularSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces"
                    }}
                >
                    {t('appSessionTheMusicSocialMediaInfo')}
                </Typography>
                <Box
                    display={"flex"}
                    marginTop={"48px"}
                    flexDirection={isMobile ? "column" : "row"}
                >
                    <a
                        href="https://play.google.com/store/apps/details?id=com.musii.musii"
                        rel={"noreferrer"}
                        target="_blank"
                    >
                        <img
                            src={Playstore}
                            alt="playstore"
                            style={{
                                marginRight: "40px",
                            }}
                        />
                    </a>
                    <a
                        href="https://apps.apple.com/us/app/id1517893386"
                        rel={"noreferrer"}
                        target="_blank"
                    >
                        <img src={Appstore} style={{
                        }} alt="appstore" />
                    </a>
                </Box>
            </Grid>
            <Grid
                xs={12}
                md={"auto"}
                marginRight={isMobile ? "24px" : "32px"}
            >
                <img
                    src={
                        Mock1
                    }
                    alt="musii"
                    style={{
                        width: isMobile ? "256px" : "456px",
                        marginTop: isMobile ? "24px" : "0px",
                        borderRadius: "48px",
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default DownloadApp;
