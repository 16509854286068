import { useEffect, useState } from "react";
import { Grid, Typography, Box, Button, CircularProgress } from "@mui/material";
import Camera from "../../assets/camera";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import Fonts from "../../utils/Fonts";
import Video from "../../assets/video";
import { useCallback } from "react";
import { useUser } from "../../hooks/useUser";
import { useTranslation } from "react-i18next";

const client = new S3Client({
    region: "us-east-2",
    credentials: fromCognitoIdentityPool({
        clientConfig: { region: "us-east-2" },
        identityPoolId: "us-east-2:06569f2a-2a53-4326-93d3-167f56352778",
    }),
});

export default function UploadFile({
    title,
    camera = true,
    types,
    description,
    isMobile,
    square = false,
    imageName = "upload",
    state = "",
    setState = () => { },
}) {
    const videoRef = useCallback((x) => (x ? (x.volume = 0) : ""), []);
    const [t, i18n] = useTranslation("common");

    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [link, setLink] = useState(state);
    const [dateCreate, ] = useState(new Date().getMilliseconds())

    const { user } = useUser()

    useEffect(() => {
        const awsUploadFile = async (file) => {
            setLoading(true);

            if (camera) {
                const command = new PutObjectCommand({
                    ACL: "public-read",
                    Bucket: "musii-dev-repository",
                    Key: `nft/${user.email}/${dateCreate}/${imageName}.png`,
                    Body: file,
                });

                const uploadObject = await client.send(command);
                console.log(uploadObject);

                setState(
                    `https://musii-dev-repository.s3.us-east-2.amazonaws.com/nft/${user.email}/${dateCreate}/${imageName}.png?counter=${new Date().getMilliseconds()}`
                );
                setLink(
                    `https://musii-dev-repository.s3.us-east-2.amazonaws.com/nft/${user.email}/${dateCreate}/${imageName}.png?counter=${new Date().getMilliseconds()}`
                );
            } else {
                const command = new PutObjectCommand({
                    ACL: "public-read",
                    Bucket: "musii-dev-repository",
                    Key: `nft/${user.email}/${dateCreate}/${imageName}.mp4`,
                    Body: file,
                });

                const uploadObject = await client.send(command);
                console.log(uploadObject);

                setState(
                    `https://musii-dev-repository.s3.us-east-2.amazonaws.com/nft/${user.email}/${dateCreate}/${imageName}.mp4?counter=${new Date().getMilliseconds()}`
                );
                setLink(
                    `https://musii-dev-repository.s3.us-east-2.amazonaws.com/nft/${user.email}/${dateCreate}/${imageName}.mp4?counter=${new Date().getMilliseconds()}`
                );
            }

            setLoading(false);
        };

        if (selectedFile) {
            awsUploadFile(selectedFile);
        }
    }, [selectedFile]);

    return (
        <Grid
            xs={6}
            md={3}
            flexDirection={"column"}
            style={{ display: "flex", textAlign: "left" }}
        >
            <Typography
                style={{
                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                    color: "#CACAE5",
                    marginBottom: 8,
                    padding: "16px 20px",
                }}
            >
                {title}
            </Typography>
            <Box
                display={"flex"}
                flexDirection={"column"}
                width={"174px"}
                height={"174px"}
                position={"relative"}
                style={{
                    borderRadius: "32px",
                    padding: "24px",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    background:
                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                }}
            >
                <Button
                    component="label"
                    style={{
                        backgroundColor: "transparent",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 99,
                    }}
                >
                    {!isLoading && !camera && (
                        <input
                            type="file"
                            accept="video/*"
                            onChange={(e) => {
                                const file = e.target.files[0];

                                if (file) {
                                    // create an image element with that selected file
                                    const video =
                                        document.createElement("video");

                                    video.src = URL.createObjectURL(file);

                                    // as soon as the image has been loaded
                                    video.onloadedmetadata = function () {
                                        const width = video.videoWidth,
                                            height = video.videoHeight;

                                        // check its dimensions
                                        console.log(width, height, video);
                                        if (square) {
                                            if (width === height) {
                                                setSelectedFile(
                                                    e.target.files[0]
                                                );
                                            } else {
                                                alert(t('createCollectionUploadErrorSquare'));
                                            }
                                        } else {
                                            setSelectedFile(e.target.files[0]);
                                        }

                                        video.src = "";
                                    };
                                }
                            }}
                            hidden
                        />
                    )}
                    {!isLoading && camera && (
                        <input
                            type="file"
                            accept = ".jpg, .jpeg, .png"
                            onChange={(e) => {
                                const file = e.target.files[0];

                                if (file) {
                                    // create an image element with that selected file
                                    const img = new Image();
                                    img.src = window.URL.createObjectURL(file);

                                    // as soon as the image has been loaded
                                    img.onload = function () {
                                        const width = img.naturalWidth,
                                            height = img.naturalHeight;

                                        // unload it
                                        window.URL.revokeObjectURL(img.src);

                                        // check its dimensions
                                        if (square) {
                                            if (width === height) {
                                                setSelectedFile(
                                                    e.target.files[0]
                                                );
                                            } else {
                                                alert(t('createCollectionUploadErrorSquare'));
                                            }
                                        } else {
                                            setSelectedFile(e.target.files[0]);
                                        }
                                    };
                                }
                            }}
                            hidden
                        />
                    )}
                </Button>
                {!isLoading && !camera && <Video />}
                {!isLoading && camera && <Camera />}
                {isLoading && (
                    <CircularProgress
                        size={24}
                        thickness={4}
                        sx={{ color: "#FFF" }}
                    />
                )}
                {link !== "" && camera && (
                    <img
                        src={link}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            borderRadius: "32px",
                            width: "100%",
                            height: "100%",
                        }}
                        alt="cover"
                    />
                )}
                {link !== "" && !camera && (
                    <video
                        ref={videoRef}
                        src={link}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            borderRadius: "32px",
                            width: "100%",
                            height: "100%",
                        }}
                        alt="Musii"
                        loop={true}
                        autoPlay={true}
                        controls={false}
                        playsInline
                    />
                )}
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h3RegularSize1,
                        color: "#ABABCC",
                    }}
                >
                    {t('createCollectionUploadFile')}
                </Typography>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].l1LightSize1,
                        color: "#ABABCC",
                        marginTop: 2,
                    }}
                >
                    {types}
                </Typography>
            </Box>
            {description}
        </Grid>
    );
}
