import {
    Card,
    Grid,
    Typography,
    CardContent,
    Button,
    Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TypeNfts from "../../utils/TypeNfts";
import Fonts from "../../utils/Fonts";
import Verified from "../../assets/verified";
import { useTranslation } from "react-i18next";

export default function NftCardAdmin({ nft, isMobile }) {
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    return (
        <Grid item sm={12} md={"auto"}>
            <Card
                style={{
                    position: "relative",
                    borderRadius: 32,
                    backgroundColor: "#121229",
                    width: "266px",
                }}
            >
                <Box
                    style={{
                        position: "absolute",
                        top: "2px",
                        left: "24px",
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: TypeNfts[nft.categorie.toLowerCase()]
                                .backgroundColor,
                            padding: "8px 16px",
                            borderRadius: 32,
                            display: "inline-flex",
                            alignItems: "center",
                            marginTop: "24px",
                            marginBottom: "32px",
                        }}
                    >
                        <Box
                            style={{
                                marginRight: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {TypeNfts[nft.categorie.toLowerCase()].image}
                        </Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1BoldSize2,
                                color: TypeNfts[nft.categorie.toLowerCase()].color,
                            }}
                        >
                            {nft.subCategorie}
                        </Typography>
                    </Box>
                </Box>
                <Box maxHeight={"264px"}>
                    <img
                        alt={"cover"}
                        style={{
                            height: "264px",
                        }}
                        src={nft.image}
                    />
                </Box>

                <CardContent
                    style={{
                        background:
                            "linear-gradient(130.54deg, rgba(158, 158, 184, 0.125) 0%, rgba(24, 24, 46, 0.25) 102.22%), rgba(55, 55, 92, 0.5)",
                        padding: "24px",
                        position: "relative",
                        height: '100%'
                    }}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                    >
                        <img
                            width={"40px"}
                            height={"40px"}
                            src={nft.artistPhoto}
                            style={{ borderRadius: 99, zIndex: 99 }}
                            alt="cover"
                        />
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            marginLeft={2}
                        >
                            <Box
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    top: 0,
                                    left: 0,
                                    backgroundImage: `url('${nft.image}')`,
                                    backgroundPosition: "bottom",
                                    filter: "blur(56px)",
                                }}
                            ></Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3BoldSize1,
                                    color: "#F0F0FA",
                                    zIndex: 99,
                                }}
                            >
                                {nft.name}
                            </Typography>
                            <Box display={"flex"} alignItems={"center"} gap={"2px"}>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .l1RegularSize1,
                                        color: "#F0F0FA",
                                        zIndex: 99,
                                    }}
                                >
                                    {nft.artistName}
                                </Typography>
                                <Verified size={12} />
                            </Box>
                        </Box>
                    </Box>
                    {nft.status === "ACTIVE" &&
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            marginTop={"16px"}
                        >
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                marginLeft={"16px"}
                                flex={1}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1BoldSize1,
                                        color: "#F0F0FA",
                                        zIndex: 99,
                                    }}
                                >
                                    {parseInt(nft.totalSuply) - parseInt(nft.totalSold)}
                                </Typography>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1RegularSize2,
                                        color: "#ABABCC",
                                        zIndex: 99,
                                    }}
                                >
                                    {t('nftCardAvailable')}
                                </Typography>
                            </Box>
                            <Link
                                aria-label="nfts"
                                to={`/nft/${nft.id}`}
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <Button
                                    style={{

                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1BoldSize1,
                                        marginLeft: "24px",
                                        background: "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                        borderRadius: "40px",
                                        padding: "8px 16px",
                                        color: "#F0F0FA"
                                    }}
                                    variant="contained"
                                >
                                    GO TO PAGE
                                </Button>
                            </Link>
                        </Box>}
                    {nft.status !== "ACTIVE" &&
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            marginTop={"24px"}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1BoldSize1,
                                    color: nft.status === "RECUSED" ? "#FB5E87" : "#F0F0FA",
                                    zIndex: 99,
                                }}
                            >
                                {nft.status}
                            </Typography>
                            <Button
                                onClick={() => navigate("/approval", { state: { nft } })}
                                style={{

                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1BoldSize1,
                                    marginLeft: "24px",
                                    background: "rgba(114, 114, 163, 0.08)",
                                    borderRadius: "40px",
                                    padding: "8px 16px",
                                    color: "#F0F0FA"
                                }}
                                variant="contained"
                            >
                                REVIEW
                            </Button>
                        </Box>}
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        marginTop={"8px"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize2,
                                color: "#ABABCC",
                                zIndex: 99,
                            }}
                        >
                            Date: {nft.createdAt}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}
