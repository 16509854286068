import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useUser } from "../../hooks/useUser";
import ProfileIcon from "../../assets/profileIcon";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import Up from "../../assets/up";
import Down from "../../assets/down";
import Fonts from "../../utils/Fonts";
import WalletConnected from "../../assets/walletConnected";
import { usePera } from "../../hooks/usePera";
import Pera from "../../assets/pera";
import Defly from "../../assets/defly";
import { useDefly } from "../../hooks/useDefly";
import StorageEnum from "../../enums/StorageEnum";
import Storage from "../../utils/Storage";

import PhoneInput from "react-phone-number-input";
import axios from "axios";
import AlertSuccess from "../commons/AlertSuccess";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";

const client = new S3Client({
    region: "us-east-2",
    credentials: fromCognitoIdentityPool({
        clientConfig: { region: "us-east-2" },
        identityPoolId: "us-east-2:06569f2a-2a53-4326-93d3-167f56352778",
    }),
});

const genres = [
    "pop",
    "rap",
    "r-n-b",
    "eletronic",
    "rock",
    "sertanejo",
    "indie",
    "mpb",
    "samba",
    "classical",
    "trap",
    "soul",
    "funk",
    "reggaeton",
    "jazz",
    "reggae",
    "metal",
    "punk",
    "k-pop",
    "gospel",
    "forró",
    "hip-hop",
    "pagode",
];

const Edit = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [selectedFile, setSelectedFile] = useState(null);
    const [counter, setCounter] = useState(0);

    const [validateEmail, setValidateEmail] = useState(false);

    const [t, i18n] = useTranslation("common");
    const { user, logIn } = useUser();

    const [nameField, setName] = useState(user?.name ? user.name : "");
    const [bioField, setBio] = useState(user?.bio ? user.bio : "");

    const [socialsField, setSocials] = useState(user?.socials ? user.socials : []);
    const [genresField, setGenres] = useState(user?.genres ? user.genres : []);

    const [emailField, setEmail] = useState(user?.email ? user.email : "");
    const [phoneField, setPhone] = useState(user?.phone ? user.phone : "");
    const [documentField, setDocument] = useState(user?.document ? user.document : "");

    const [twitterText, setTwitterText] = useState(user?.socials?.findIndex(el => el.social === 'twitter') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'twitter')].value : "")
    const [discordText, setDiscordText] = useState(user?.socials?.findIndex(el => el.social === 'discord') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'discord')].value : "")
    const [instagramText, setInstagramText] = useState(user?.socials?.findIndex(el => el.social === 'instagram') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'instagram')].value : "")
    const [tiktokText, setTiktokText] = useState(user?.socials?.findIndex(el => el.social === 'tiktok') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'tiktok')].value : "")
    const [twitchText, setTwitchText] = useState(user?.socials?.findIndex(el => el.social === 'twitch') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'twitch')].value : "")
    const [youtubeText, setYoutubeText] = useState(user?.socials?.findIndex(el => el.social === 'youtube') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'youtube')].value : "")
    const [spotifyText, setSpotifyText] = useState(user?.socials?.findIndex(el => el.social === 'spotify') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'spotify')].value : "")
    const [deezerText, setDeezerText] = useState(user?.socials?.findIndex(el => el.social === 'deezer') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'deezer')].value : "")
    const [amazonText, setAmazonText] = useState(user?.socials?.findIndex(el => el.social === 'amazon') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'amazon')].value : "")
    const [appleText, setAppleText] = useState(user?.socials?.findIndex(el => el.social === 'apple') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'apple')].value : "")
    const [soundcloudText, setSoundcloudText] = useState(user?.socials?.findIndex(el => el.social === 'soundcloud') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'soundcloud')].value : "")
    const [siteText, setSiteText] = useState(user?.socials?.findIndex(el => el.social === 'site') >= 0 ? user?.socials[user?.socials?.findIndex(el => el.social === 'site')].value : "")

    const { logIn: loginPera, logOut: disconnectPera } = usePera();
    const { logIn: loginDefly, logOut: disconnectDefly } = useDefly();

    const [open, setOpen] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const awsUploadFile = async (file) => {
            const command = new PutObjectCommand({
                ACL: "public-read",
                Bucket: "musii-dev-repository",
                Key: `profile/${user.email}.png`,
                Body: file,
            });

            const uploadObject = await client.send(command);

            console.log(uploadObject)

            await Storage.setItem(StorageEnum.USER, {
                ...user,
                photo: `https://musii-dev-repository.s3.us-east-2.amazonaws.com/profile/${user.email}.png?counter=${new Date().getMilliseconds()}`
            });

            logIn()

            setCounter(counter + 1);
        };

        if (selectedFile) {
            awsUploadFile(selectedFile);
        }
    }, [selectedFile]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'twitter')

        if (index < 0) {
            setSocials([...socialsField, { social: "twitter", value: twitterText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "twitter", value: twitterText }
            setSocials(aux)
        }
    }, [twitterText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'discord')

        if (index < 0) {
            setSocials([...socialsField, { social: "discord", value: discordText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "discord", value: discordText }
            setSocials(aux)
        }
    }, [discordText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'instagram')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "instagram", value: instagramText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "instagram", value: instagramText }
            setSocials(aux)
        }
    }, [instagramText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'tiktok')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "tiktok", value: tiktokText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "tiktok", value: tiktokText }
            setSocials(aux)
        }
    }, [tiktokText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'twitch')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "twitch", value: twitchText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "twitch", value: twitchText }
            setSocials(aux)
        }
    }, [twitchText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'youtube')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "youtube", value: youtubeText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "youtube", value: youtubeText }
            setSocials(aux)
        }
    }, [youtubeText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'spotify')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "spotify", value: spotifyText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "spotify", value: spotifyText }
            setSocials(aux)
        }
    }, [spotifyText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'deezer')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "deezer", value: deezerText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "deezer", value: deezerText }
            setSocials(aux)
        }
    }, [deezerText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'amazon')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "amazon", value: amazonText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "amazon", value: amazonText }
            setSocials(aux)
        }
    }, [amazonText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'apple')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "apple", value: appleText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "apple", value: appleText }
            setSocials(aux)
        }
    }, [appleText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'soundcloud')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "soundcloud", value: soundcloudText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "soundcloud", value: soundcloudText }
            setSocials(aux)
        }
    }, [soundcloudText]);

    useEffect(() => {
        const index = socialsField.findIndex(el => el.social === 'site')
        console.log(index)
        if (index < 0) {
            setSocials([...socialsField, { social: "site", value: siteText }]);
        } else {
            const aux = socialsField
            aux[index] = { social: "site", value: siteText }
            setSocials(aux)
        }
    }, [siteText]);

    async function editUser() {
        const response = await axios.put(
            `${Dev.api}nft/user/${user.email}`,
            {
                ...user,
                name: nameField,
                bio: bioField,
                socials: socialsField,
                genres: genresField,
                email: emailField,
                phone: phoneField,
                document: documentField
            },
            {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                crossDomain: true,
            }
        );

        await Storage.setItem(StorageEnum.USER, {
            ...user,
            name: nameField,
            bio: bioField,
            socials: socialsField,
            genres: genresField,
            email: emailField,
            phone: phoneField,
            document: documentField
        });

        logIn()
        setOpenModal(true)
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <AlertSuccess
                isMobile={isMobile}
                open={openModal}
                handleClose={() => setOpenModal(false)}
                title={"Profile Updated"}
            />
            <Grid container gap={4} justifyContent={"center"}>
                <Grid xs={12} md={9.2} display={"flex"} alignItems={"center"}>
                    <Box>
                        {user && user.photo && (
                            <img
                                width={"80px"}
                                height={"80px"}
                                src={user.photo}
                                style={{ borderRadius: 99 }}
                                alt="cover"
                                referrerpolicy="no-referrer"
                            />
                        )}
                        {(!user || !user.photo) && (
                            <ProfileIcon size={80} />
                        )}

                    </Box>
                    <Box position={"relative"}>
                        <Button
                            component="label"
                            style={{
                                backgroundColor: "transparent",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 99,
                            }}
                        >
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    setSelectedFile(e.target.files[0]);
                                }}
                                hidden
                            />
                        </Button>
                        <Typography
                            style={{
                                color: "#EBEBFA",
                                fontFamily: "Ubuntu",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "20px",
                                lineHeight: "32px",
                                marginLeft: 8,
                            }}
                        >
                            {t('editProfileUpdatePFP')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    xs={12}
                    md={4.5}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            color: "#CACAE5",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "18px",
                            lineHeight: "24px",
                            marginBottom: 8,
                            padding: "6px"
                        }}
                    >
                        {t('editProfileName')}
                    </Typography>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        variant="standard"
                        placeholder={t('editProfileNamePlaceholder')}
                        type="text"
                        value={nameField}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        sx={{
                            boxShadow: 0,
                            "& .MuiInputBase-input": {
                                padding: "16.5px 20px",
                                border: "0px solid #transparent",
                                color: "#B8B8DB",
                                fontFamily: "Ubuntu",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "24px",
                                borderRadius: "24px",
                                borderBottomWidth: 0,
                                marginTop: "20px",
                                background:
                                    "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                            },
                        }}
                    />
                </Grid>
                {user && user.wallet &&
                    <Grid
                        xs={12}
                        md={4.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                color: "#5CB88D",
                                marginBottom: 8,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "8px"
                            }}>
                                <WalletConnected />
                            </Box> Wallet Connected
                        </Typography>
                        <Typography
                            sx={{
                                ...Fonts[isMobile ? "mobile" : "desktop"].l1RegularSize1,
                                color: "#5CB88D",
                            }}
                        >
                            {`${user.wallet.slice(0, 5)}...${user.wallet.slice(
                                user.wallet.length - 5,
                                user.wallet.lenght
                            )}`}
                        </Typography>
                        <Box
                            onClick={() => { disconnectPera() }}
                            style={{
                                width: "fit-content",
                                background:
                                    "#7272A3",
                                borderRadius: "32px",
                                opacity: "0.5",
                                marginTop: "24px",
                                textDecoration: "none",
                                cursor: "pointer",
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3BoldSize1,
                                    color: "#121229",
                                    padding: "16px 28px",
                                    whiteSpace: "nowrap",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {t('editProfileDisconnect')}
                            </Typography>
                        </Box>
                    </Grid>}
                {(!user || !user.wallet) &&
                    <Grid
                        xs={12}
                        md={4.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                color: "#ABABCC",
                                marginBottom: 8,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "8px"
                            }}>
                                <WalletConnected />
                            </Box> {t('editProfileConnectWallet')}
                        </Typography>
                        <Box style={{ display: "flex", }} gap="16px">
                            <Box
                                onClick={() => { loginPera() }}
                                style={{
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "32px",
                                    marginTop: "24px",
                                    textDecoration: "none",
                                    padding: "13px 32px 13px 32px",
                                    cursor: "pointer",
                                    width: "144px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Pera />
                            </Box>
                            <Box
                                onClick={() => { loginDefly() }}
                                style={{
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "32px",
                                    marginTop: "24px",
                                    textDecoration: "none",
                                    padding: "13px 32px 13px 32px",
                                    cursor: "pointer",
                                    width: "144px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Defly />
                            </Box>
                        </Box>
                        <Typography
                            sx={{
                                ...Fonts[isMobile ? "mobile" : "desktop"].l1RegularSize1,
                                color: "#ABABCC",
                                marginTop: "16px",
                            }}
                        >
                            {t('editProfileConnectWalletInfo')}
                        </Typography>

                    </Grid>}
                <Grid
                    xs={12}
                    md={9.2}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            color: "#CACAE5",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "18px",
                            lineHeight: "24px",
                            marginBottom: 8,
                        }}
                    >
                        {t('editProfileBio')}
                    </Typography>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        variant="standard"
                        placeholder={t('editProfileBioPlaceholder')}
                        multiline
                        value={bioField}
                        inputProps={{ maxLength: 1000 }}
                        onChange={(e) => {
                            setBio(e.target.value);
                        }}
                        type="text"
                        sx={{
                            boxShadow: 0,
                            "& .MuiInputBase-input": {
                                padding: "16.5px 20px",
                                border: "0px solid #transparent",
                                color: "#B8B8DB",
                                fontFamily: "Ubuntu",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "24px",
                                borderRadius: "24px",
                                borderBottomWidth: 0,
                                background:
                                    "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                            },
                            "& . Mui-disabled": {
                                "-webkit-text-fill-color": "rgba(1, 1, 1, 1)",
                            },
                        }}
                    />
                </Grid>
                <Grid
                    xs={12}
                    md={9.2}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            color: "#CACAE5",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "18px",
                            lineHeight: "24px",
                            marginBottom: 8,
                        }}
                    >
                        {t('editProfileGenres')}
                    </Typography>
                    <Grid
                        xs={12}
                        md={9.2}
                        gap={2}
                        display={"flex"}
                        flexWrap={"wrap"}
                    >
                        {genres.map((item) => (
                            <Box
                                style={{
                                    background: genresField.includes(item)
                                        ? "linear-gradient(130.54deg, rgba(140, 41, 217, 0.9) 0%, rgba(94, 71, 214, 0.9) 102.22%), rgba(235, 235, 250, 0.25)"
                                        : "rgba(182, 91, 252, 0.4)",
                                    padding: "2px 14px",
                                    borderRadius: "16px",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setGenres([...genresField, item]);
                                }}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        lineHeight: "24px",
                                    }}
                                >
                                    {item}
                                </Typography>
                            </Box>
                        ))}
                    </Grid>
                </Grid>

                <Grid
                    xs={12}
                    md={9.2}
                    display={"flex"}
                    flexDirection={"column"}
                    border={"2px solid #313152"}
                    borderRadius={"24px"}
                    padding="24px"
                >
                    <Grid
                        container
                        xs={12}
                        md={12}
                        gap={4}
                        display={"flex"}
                        onClick={() => setOpen(!open)}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h3BoldSize1,
                                color: "#ABABCC",
                                marginBottom: 8,
                                flex: 1,
                            }}
                        >
                            {t('editProfileSocial')}
                        </Typography>
                        <Box>{open ? <Up /> : <Down />}</Box>
                    </Grid>
                    {open &&
                        <Grid
                            container
                            xs={12}
                            md={12}
                            gap={4}
                            display={"flex"}
                            marginTop={4}
                        >
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Twitter
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"@user"}
                                    type="text"
                                    value={twitterText}
                                    onChange={(e) => {
                                        setTwitterText(e.target.value)
                                    }}

                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Discord
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"User#2109"}
                                    type="text"
                                    value={discordText}
                                    onChange={(e) => {
                                        setDiscordText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Instagram
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"@user"}
                                    type="text"

                                    value={instagramText}
                                    onChange={(e) => {
                                        setInstagramText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    TikTok
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"@user"}
                                    type="text"
                                    value={tiktokText}
                                    onChange={(e) => {
                                        setTiktokText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Twitch
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"@user"}
                                    type="text"
                                    value={twitchText}
                                    onChange={(e) => {
                                        setTwitchText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Youtube
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"https://youtube.com/user"}
                                    type="text"
                                    value={youtubeText}
                                    onChange={(e) => {
                                        setYoutubeText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Spotify
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"https://spotify.com/user"}
                                    type="text"

                                    value={spotifyText}
                                    onChange={(e) => {
                                        setSpotifyText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Deezer
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"https://deezer.com/user"}
                                    type="text"
                                    value={deezerText}
                                    onChange={(e) => {
                                        setDeezerText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Amazon Music
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"https://amazonmusic.com/user"}
                                    type="text"
                                    value={amazonText}
                                    onChange={(e) => {
                                        setAmazonText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Apple Music
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"https://applemusic.com/user"}
                                    type="text"
                                    value={appleText}
                                    onChange={(e) => {
                                        setAppleText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Soundcloud
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"https://soundcloud.com/user"}
                                    type="text"
                                    value={soundcloudText}
                                    onChange={(e) => {
                                        setSoundcloudText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={3.7}
                                display={"flex"}
                                flexDirection={"column"}
                            >
                                <Typography
                                    style={{
                                        color: "#CACAE5",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "24px",
                                        marginBottom: 8,
                                    }}
                                >
                                    Website
                                </Typography>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    variant="standard"
                                    placeholder={"https://link.com"}
                                    type="text"
                                    value={siteText}
                                    onChange={(e) => {
                                        setSiteText(e.target.value)
                                    }}
                                    sx={{
                                        boxShadow: 0,
                                        "& .MuiInputBase-input": {
                                            padding: "16.5px 20px",
                                            border: "0px solid #transparent",
                                            color: "#B8B8DB",
                                            fontFamily: "Ubuntu",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "24px",
                                            borderRadius: "24px",
                                            borderBottomWidth: 0,
                                            background:
                                                "linear-gradient(130.54deg, rgba(158, 158, 184, 0.075) 0%, rgba(24, 24, 46, 0.25) 102.22%), linear-gradient(0deg, rgba(55, 55, 92, 0.5), rgba(55, 55, 92, 0.5)), rgba(24, 24, 46, 0.25)",
                                        },
                                        "& . Mui-disabled": {
                                            "-webkit-text-fill-color":
                                                "rgba(1, 1, 1, 1)",
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid
                    xs={12}
                    md={9}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            color: "#CACAE5",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "18px",
                            lineHeight: "24px",
                            marginBottom: 8,
                        }}
                    >
                        {t('editProfileHiddenInformation')}
                    </Typography>
                </Grid>
                <Grid
                    xs={12}
                    md={3}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize2,
                            color: "#CACAE5",
                            marginBottom: 8,
                            padding: "0px 20px",
                        }}
                    >
                        {t('editProfileEmail')}
                    </Typography>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        variant="standard"
                        value={emailField}
                        placeholder={t('editProfileEmailPlaceholder')}
                        type="email"
                        error={validateEmail}
                        helperText={
                            validateEmail
                                ? t('emailError') 
                                : ""
                        }
                        onBlur={(e) => {
                            if (
                                !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
                                    emailField
                                )
                            ) {
                                setValidateEmail(true);
                            } else {
                                setValidateEmail(false);
                            }
                        }}
                        onChange={(e) => {
                            const { value } = e.target;
                            setEmail(value.toLowerCase());
                        }}
                        sx={{
                            boxShadow: 0,
                            "& .MuiInputBase-input": {
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                padding: "16.5px 20px",
                                border: "0px solid #transparent",
                                color: "#B8B8DB",
                                borderRadius: "24px",
                                borderBottomWidth: 0,
                                background:
                                    "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                            },
                        }}
                    />
                </Grid>
                <Grid
                    xs={12}
                    md={3}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize2,
                            color: "#CACAE5",
                            marginBottom: 8,
                            padding: "0px 20px",
                        }}
                    >
                        {t('editProfilePhone')}
                    </Typography>
                    <PhoneInput
                        value={phoneField}
                        placeholder={t('editProfilePhonePlaceholder')}
                        id={"phonenumberfield"}
                        numberInputProps={{
                            style: {
                                boxShadow: 0,
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                border: "0px solid transparent",
                                backgroundColor: "transparent",
                                color: "#B8B8DB",
                            },
                        }}
                        style={{
                            boxShadow: 0,
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .b1RegularSize1,
                            padding: "16.5px 20px",
                            border: "0px solid transparent",
                            color: "#B8B8DB",
                            borderRadius: "24px",
                            borderBottomWidth: 0,
                            background:
                                "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                        }}
                        placeholderColor="red"
                        onChange={setPhone}
                    />
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        variant="standard"
                        placeholder={"Phone Number"}
                        value={phoneField}
                        onChange={(e) => setPhone(e.target.value)}
                        sx={{
                            boxShadow: 0,
                            "& .MuiInputBase-input": {
                                display: "none",
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                padding: "16.5px 20px",
                                border: "0px solid #transparent",
                                color: "#B8B8DB",
                                borderRadius: "24px",
                                borderBottomWidth: 0,
                                background:
                                    "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                            },
                        }}
                    />
                </Grid>
                <Grid
                    xs={12}
                    md={3}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize2,
                            color: "#CACAE5",
                            marginBottom: 8,
                            padding: "0px 20px",
                        }}
                    >
                        {t('editProfileCPForId')}
                    </Typography>
                    <TextField
                        InputProps={{
                            disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder={t('editProfileCPForIdPlaceholder')}
                        type="text"
                        value={documentField}
                        onChange={(e) => {
                            setDocument(e.target.value);
                        }}
                        sx={{
                            boxShadow: 0,
                            "& .MuiInputBase-input": {
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                padding: "16.5px 20px",
                                border: "0px solid #transparent",
                                color: "#B8B8DB",
                                borderRadius: "24px",
                                borderBottomWidth: 0,
                                background:
                                    "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                            },
                        }}
                    />
                </Grid>

                <Grid xs={12} md={9} display={"flex"} flexDirection={"column"}>
                    <Button
                        style={{
                            background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                            borderRadius: "40px",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "17px",
                            lineHeight: "32px",
                            padding: "16px 22px",
                            marginTop: 32,
                        }}
                        onClick={editUser}
                        variant="contained"
                    >
                        {t('editProfileButtonEdit')}
                    </Button>
                </Grid>
                <Box
                    padding="56px 40px"
                    marginY={16}
                >
                </Box>
            </Grid>
        </Box>
    );
};

export default Edit;
