const Check = () => {
    return (
        <svg
            width="112"
            height="112"
            viewBox="0 0 112 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M95.9848 39.9497C98.7185 37.2161 98.7185 32.7839 95.9848 30.0503C93.2512 27.3166 88.819 27.3166 86.0853 30.0503L46.1172 70.0184L31.2701 55.1712C28.5364 52.4375 24.1042 52.4375 21.3706 55.1712C18.6369 57.9049 18.6369 62.337 21.3706 65.0707L41.1696 84.8697C43.9032 87.6034 48.3354 87.6034 51.069 84.8697C51.1406 84.7982 51.2102 84.7255 51.278 84.6517C51.3316 84.6013 51.3847 84.5499 51.4371 84.4975L95.9848 39.9497Z"
                fill="#4CBA87"
            />
        </svg>
    );
};

export default Check;
