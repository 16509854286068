const ProfileTiktok = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=tiktok">
                <path id="Vector" d="M28.2603 12.5605C28.089 12.4698 27.9222 12.3704 27.7606 12.2626C27.2906 11.9443 26.8598 11.5694 26.4772 11.1457C25.5201 10.024 25.1626 8.88599 25.031 8.08924H25.0363C24.9263 7.4279 24.9717 7 24.9786 7H20.6192V24.2664C20.6192 24.4982 20.6192 24.7273 20.6097 24.9537C20.6097 24.9819 20.6071 25.0079 20.6055 25.0382C20.6055 25.0507 20.6055 25.0637 20.6029 25.0767C20.6029 25.0799 20.6029 25.0832 20.6029 25.0864C20.5569 25.7059 20.363 26.3044 20.0383 26.8292C19.7135 27.3539 19.2678 27.7889 18.7404 28.0958C18.1908 28.4161 17.5691 28.5841 16.9367 28.5833C14.9056 28.5833 13.2595 26.8868 13.2595 24.7918C13.2595 22.6967 14.9056 21.0003 16.9367 21.0003C17.3212 20.9999 17.7033 21.0619 18.0689 21.1839L18.0742 16.6374C16.9644 16.4906 15.837 16.5809 14.7631 16.9027C13.6891 17.2245 12.6919 17.7708 11.8344 18.5071C11.083 19.1758 10.4513 19.9737 9.96775 20.8649C9.78373 21.1899 9.08943 22.4958 9.00535 24.6152C8.95247 25.8182 9.30517 27.0645 9.47333 27.5796V27.5904C9.57909 27.8937 9.9889 28.9288 10.6568 29.8014C11.1953 30.5013 11.8316 31.1162 12.5451 31.6262V31.6154L12.5557 31.6262C14.6661 33.0951 17.006 32.9987 17.006 32.9987C17.4111 32.9819 18.7679 32.9987 20.3088 32.2507C22.0179 31.4214 22.9909 30.186 22.9909 30.186C23.6125 29.4478 24.1067 28.6065 24.4525 27.6982C24.847 26.6361 24.9786 25.3621 24.9786 24.853V15.6928C25.0315 15.7253 25.7359 16.2025 25.7359 16.2025C25.7359 16.2025 26.7506 16.8687 28.3338 17.3025C29.4697 17.6113 31 17.6763 31 17.6763V13.2435C30.4638 13.3031 29.375 13.1297 28.2603 12.5605Z" fill="#ABABCC" />
            </g>
        </svg>
    );
};

export default ProfileTiktok;
