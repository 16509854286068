const ProfileDeezer = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=deezer">
                <g id="g8252-1">
                    <path id="path8175-4" fill-rule="evenodd" clip-rule="evenodd" d="M6.35547 35.3256C4.79813 35.3256 3.75 34.2787 3.75 32.5296C3.75 30.7542 4.84481 29.6855 6.36395 29.6899C7.1405 29.6899 7.76004 29.9516 8.09527 30.5361V26.9941H9.57199V35.3256H8.15044V34.5187C7.7855 35.1032 7.13202 35.3256 6.35547 35.3256ZM6.68704 34.1524C7.48056 34.1524 8.14254 33.5287 8.14254 32.5298C8.14254 31.5221 7.48056 30.8765 6.68704 30.8765C5.86806 30.8765 5.23154 31.5134 5.23154 32.5298C5.23154 33.5287 5.86381 34.1524 6.68704 34.1524Z" fill="#ABABCC" />
                    <path id="path8177-2" fill-rule="evenodd" clip-rule="evenodd" d="M12.8948 34.144C13.5695 34.144 13.9981 33.8212 14.1678 33.1756L15.4239 33.686C15.0419 34.7809 14.172 35.3305 12.8905 35.3305C11.1931 35.3305 9.9668 34.2574 9.9668 32.469C9.9668 30.8638 11.0786 29.6816 12.6953 29.6816C14.2102 29.6816 15.2371 30.554 15.2371 32.1026C15.2371 32.3512 15.2159 32.578 15.1692 32.7918H11.4478C11.4647 33.6555 12.1097 34.144 12.8948 34.144ZM12.7 30.7897C12.0932 30.7897 11.5882 31.1561 11.4482 31.7799H13.8033L13.8118 31.7319C13.8118 31.1561 13.362 30.7897 12.7 30.7897Z" fill="#ABABCC" />
                    <path id="path8179-4" fill-rule="evenodd" clip-rule="evenodd" d="M18.3733 34.144C19.048 34.144 19.4766 33.8212 19.6463 33.1756L20.9024 33.686C20.5205 34.7809 19.6506 35.3305 18.369 35.3305C16.6717 35.3305 15.4453 34.2574 15.4453 32.469C15.4453 30.8638 16.5571 29.6816 18.1738 29.6816C19.6887 29.6816 20.7157 30.554 20.7157 32.1026C20.7157 32.3512 20.6944 32.578 20.6478 32.7918H16.9263C16.9432 33.6555 17.5882 34.144 18.3733 34.144ZM18.1787 30.7896C17.5719 30.7896 17.0669 31.156 16.9269 31.7798H19.282L19.2904 31.7318C19.2904 31.156 18.8406 30.7896 18.1787 30.7896Z" fill="#ABABCC" />
                    <path id="path8181-4" d="M25.9267 35.3344V33.9603H22.8247L25.8418 30.9418V29.6855H21.068V30.9942H23.9832L20.9746 34.0258V35.3344H25.9267Z" fill="#ABABCC" />
                    <path id="path8183-9" fill-rule="evenodd" clip-rule="evenodd" d="M28.967 34.144C29.6417 34.144 30.0703 33.8212 30.2401 33.1756L31.4961 33.686C31.1142 34.7809 30.2443 35.3305 28.9628 35.3305C27.2654 35.3305 26.0391 34.2574 26.0391 32.469C26.0391 30.8638 27.1508 29.6816 28.7676 29.6816C30.2825 29.6816 31.3094 30.554 31.3094 32.1026C31.3094 32.3512 31.2882 32.578 31.2415 32.7918H27.52C27.537 33.6555 28.182 34.144 28.967 34.144ZM28.7724 30.7896C28.1656 30.7896 27.6606 31.156 27.5206 31.7798H29.8757L29.8842 31.7318C29.8842 31.156 29.4344 30.7896 28.7724 30.7896Z" fill="#ABABCC" />
                    <rect id="rect8185-4" x="29.1387" y="3.75" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8192-6" x="29.1387" y="9.41797" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8199-6" x="29.1387" y="15.0859" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8206-4" x="3.88672" y="20.7539" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8213-4" x="12.2988" y="20.7539" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8220-6" x="20.7266" y="20.7539" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8227-3" x="29.1387" y="20.7539" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8234-7" x="20.7266" y="15.0859" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8241-3" x="12.2988" y="15.0859" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <rect id="rect8248-9" x="12.2988" y="9.41797" width="6.96618" height="4.08822" fill="#ABABCC" />
                    <path id="path8250-1" d="M34.8121 31.6364C34.8121 31.6931 34.8121 31.7978 34.8121 31.7978H36.2507C36.2507 31.7978 36.2507 31.5186 36.2507 31.366C36.2507 30.3976 35.6608 29.6953 34.6721 29.6953C34.0356 29.6953 33.5858 30.0137 33.3524 30.5415V29.6953H31.8672V35.3354H33.3524V31.7018C33.3524 31.1261 33.6537 30.8076 34.1162 30.8076C34.5406 30.812 34.8121 31.222 34.8121 31.6364Z" fill="#ABABCC" />
                </g>
            </g>
        </svg>
    );
};

export default ProfileDeezer;
