import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Fonts from "../utils/Fonts";
import { useUser } from "../hooks/useUser";
import { Link, useNavigate } from "react-router-dom";

import Hero from "../assets/main.gif";
import { useTranslation } from "react-i18next";

function Musii({ isMobile, setShowModalConnect, setRedirectModal }) {
    const { user } = useUser();
    const [t, i18n] = useTranslation("common");

    const navigate = useNavigate();

    return (
        <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            marginTop={isMobile ? "48px" : "108px"}
            style={{
                display: "flex",
                padding: isMobile ? "20px 30px" : "56px",
                alignItems: isMobile ? "flex-start" : "center",
                borderRadius: 16,
                flexDirection: isMobile ? "column" : "row",
            }}
        >
            <Grid
                xs={12}
                md={5.5}
                xl={4}
                marginRight={isMobile ? "24px" : "122px"}
            >
                <Box style={{
                    width: "35px",
                    height: "6px",
                    background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                    borderRadius: "3px",
                    marginTop: "8px",
                    marginBottom: "16px"
                }}></Box>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"].h1BoldSize1,
                        color: "#EBEBFA",
                        whiteSpace: "break-spaces",
                        marginTop: 4,
                        marginBottom: 4,
                    }}
                >
                    {t('heroT1')}
                </Typography>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h3RegularSize1,
                        color: "#EBEBFA",
                    }}
                >
                    {t('heroT2')}
                </Typography>
                <Box
                    display={"flex"}
                    marginTop={"48px"}
                    flexDirection={isMobile ? "column" : "row"}
                >
                    <Typography
                        onClick={() => {
                            if (user && (user.email || user.name)) {
                                isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint");
                            } else {
                                setShowModalConnect(true)
                                setRedirectModal(true)
                            }
                        }}
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3BoldSize1,
                            color: "#EBEBFA",
                            textAlign: "center",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "#8C29D9E5",
                            borderRadius: 32,
                            width: isMobile ? '80%' : '',
                            padding: "12px 32px",
                            cursor: "pointer",
                            marginRight: "32px",

                            background:
                                "linear-gradient(130.54deg, #8C29D9 0%, #5E47D6 102.22%), rgba(235, 235, 250, 0.25)",
                        }}
                    >
                        {t('heroCtaArtists')}
                    </Typography>
                    <Link aria-label="nft"
                        to={`/marketplace`}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h3BoldSize1,
                                color: "#EBEBFA",
                                textAlign: "center",
                                backgroundColor: "#121229",
                                border: "solid 2px #8C29D9",
                                borderRadius: 32,
                                padding: "12px 32px",
                                cursor: "pointer",
                                width: isMobile ? '80%' : '',
                                marginTop: isMobile ? "24px" : "0px",
                            }}
                        >
                            {t('heroCtaFans')}
                        </Typography>
                    </Link>
                </Box>
            </Grid>
            <img
                src={Hero}
                alt="musii"
                style={{
                    width: isMobile ? "256px" : "406px",
                    marginTop: isMobile ? "24px" : "0px",
                }}
            />
        </Grid>
    );
}

export default Musii;
