const Collectibles = () => {
    return (
        <svg
            width="120"
            height="120"
            viewBox="0 0 184 184"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="92" cy="92" r="92" fill="#1D1736" fillOpacity="0.5" />
            <circle cx="92" cy="92" r="92" fill="#EC98E9" fillOpacity="0.2" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M132.527 66.1154C133.594 66.7317 134.25 67.8695 134.25 69.1012V113.908C134.194 115.131 133.576 116.266 132.527 116.893L93.7214 139.297C93.1898 139.604 92.5935 139.758 91.9981 139.758C91.4018 139.758 90.8064 139.604 90.2747 139.297L51.4733 116.893C50.4162 116.276 49.8062 115.119 49.75 113.908V69.1012C49.75 67.8687 50.4062 66.7317 51.4733 66.1154L90.2747 43.7122C91.3387 43.0959 92.6574 43.0959 93.7214 43.7122L132.527 66.1154ZM127.357 71.0898L91.9981 50.6778L56.6431 71.091V105.311L72.8601 88.3359C74.2149 86.9672 76.5938 86.9672 77.9424 88.3459L83.834 94.5607L98.9485 76.1195C100.303 74.5316 102.923 74.5331 104.28 76.118L127.357 104.262V71.0898ZM75.3858 95.6686L58.7172 113.117L91.9984 132.331L125.565 112.953L101.614 83.7409L88.6131 99.6023L97.7767 109.268C99.0861 110.65 99.0292 112.831 97.649 114.141C96.2666 115.448 94.0877 115.396 92.7752 114.011L75.3858 95.6686ZM89.9631 74.815C89.9631 80.5166 85.3247 85.155 79.6231 85.155C73.9216 85.155 69.2832 80.5166 69.2832 74.815C69.2832 69.1135 73.9216 64.4751 79.6231 64.4751C85.3247 64.4751 89.9631 69.1135 89.9631 74.815ZM83.0698 74.815C83.0698 72.9148 81.525 71.3684 79.6231 71.3684C77.7213 71.3684 76.1765 72.9148 76.1765 74.815C76.1765 76.7153 77.7213 78.2617 79.6231 78.2617C81.525 78.2617 83.0698 76.7153 83.0698 74.815Z"
                fill="#EC98E9"
            />
        </svg>
    );
};

export default Collectibles;
