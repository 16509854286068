import { Grid } from "@mui/material";
import React from "react";
import NftCard from "./commons/NftCard";


function NftList({ isMobile, nfts }) {
    return (
        <Grid container spacing={3} padding={"0px 16px 16px 16px"} style={{display: "flex", justifyContent: "center"}}>
            {nfts.map((nft, index) => (
                <NftCard
                    key={index}
                    index={index}
                    nft={nft}
                    isMobile={isMobile}
                />
            ))}
        </Grid>
    );
}

export default NftList;
