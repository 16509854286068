const Telegram = ({size = 48}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM30.0956 31.7685L34.0662 15.4031L34.0666 15.403C34.308 14.4143 33.3113 13.5812 32.3848 13.9861L13.0373 22.4177C12.0876 22.8303 12.0487 24.1617 12.9673 24.6366L16.8912 26.6609L28.5697 18.5327C28.6941 18.4472 28.8187 18.5483 28.8265 18.6651L19.0397 28.0079C18.7984 28.2415 18.6583 28.5607 18.6583 28.8954V33.2243C18.6583 34.4624 20.2933 34.9217 20.9394 33.8628L23.314 29.9778L28.336 32.5703C29.0445 32.9363 29.9087 32.5392 30.0956 31.7685Z"
                fill="#EBEBFA"
            />
        </svg>
    );
};

export default Telegram;
