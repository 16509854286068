const ProfileSite = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=link">
                <path id="Vector" d="M33.3895 16C33.1903 15.3 32.8915 14.6 32.5927 14C30.5011 9.7 26.4176 6.7 21.6369 6.1L21.1389 6H20.0433H18.9477L18.3502 6.1C13.5695 6.6 9.48593 9.6 7.39437 14C7.09558 14.6 6.79678 15.3 6.59759 16C5.8008 18.6 5.8008 21.4 6.59759 24C6.79678 24.7 7.09558 25.4 7.39437 26C9.48593 30.3 13.5695 33.3 18.3502 33.9L18.9477 34H20.0433H21.1389L21.7365 33.9C26.5172 33.3 30.6007 30.3 32.6923 26C32.9911 25.4 33.2899 24.7 33.4891 24C34.1862 21.3 34.1862 18.6 33.3895 16ZM30.4015 14H25.2224C24.7244 12 23.9276 10.1 22.8321 8.3C26.0192 9.1 28.7084 11.1 30.4015 14ZM24.0272 20C24.0272 21.3 23.9276 22.7 23.6289 24H16.3582C15.8602 21.4 15.8602 18.6 16.3582 16H23.6289C23.9276 17.3 24.0272 18.7 24.0272 20ZM23.2305 26C22.6329 28.2 21.6369 30.2 20.2425 32H19.9437C18.5494 30.2 17.5534 28.2 16.9558 26H23.2305ZM16.8562 14C17.4538 11.8 18.4498 9.8 19.8441 8H20.1429C21.5373 9.8 22.5333 11.8 23.1309 14H16.8562ZM17.2546 8.3C16.159 10.1 15.3622 12 14.8642 14H9.68513C11.3783 11.1 14.0674 9.1 17.2546 8.3ZM8.09156 20C8.09156 18.6 8.29076 17.3 8.78875 16H14.4658C13.9678 18.6 13.9678 21.4 14.4658 24H8.78875C8.29076 22.7 8.09156 21.4 8.09156 20ZM9.68513 26H14.8642C15.3622 28 16.159 29.9 17.2546 31.7C14.0674 30.9 11.3783 28.9 9.68513 26ZM22.8321 31.7C23.9276 29.9 24.7244 28 25.2224 26H30.4015C28.7084 28.9 26.0192 30.9 22.8321 31.7ZM25.7204 24C25.9196 22.7 26.0192 21.3 26.0192 20C26.0192 18.7 25.9196 17.3 25.7204 16H31.2979C32.1943 18.6 32.1943 21.4 31.2979 24H25.7204Z" fill="#ABABCC" />
            </g>
        </svg>
    );
};

export default ProfileSite;
