const WhiteWallet = () => {
    return (
        <svg
            width="32"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.9172 4.16895H3.79445C2.09243 4.16895 0.710938 5.55044 0.710938 7.25246V18.9166C0.710938 20.6186 2.09243 22.0001 3.79445 22.0001H19.9172C21.6192 22.0001 23.0007 20.6186 23.0007 18.9166V15.317C23.0007 15.317 23.0007 15.3138 22.9975 15.3138H15.2374C14.0515 15.3138 13.0418 14.4003 12.9745 13.2176C12.9008 11.9322 13.9297 10.8584 15.199 10.8584H22.9975C22.9975 10.8584 23.0007 10.8584 23.0007 10.8552V7.25246C23.0007 5.55044 21.6192 4.16895 19.9172 4.16895Z"
                fill="#EBEBFA"
            />
            <path
                d="M16.3149 13.0822C16.3149 13.6977 15.8149 14.1977 15.1994 14.1977C14.584 14.1977 14.084 13.6977 14.084 13.0822C14.084 12.4668 14.584 11.9668 15.1994 11.9668C15.8149 11.9668 16.3149 12.4668 16.3149 13.0822Z"
                fill="#EBEBFA"
            />
            <path
                d="M20.8721 3.04695H7.25586L16.7532 0.136516C18.3815 -0.363513 20.106 0.553208 20.6028 2.18151L20.8721 3.04695Z"
                fill="#EBEBFA"
            />
        </svg>
    );
};

export default WhiteWallet;
