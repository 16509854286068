const pt = {
  menuMarketplace: 'Marketplace',
  menuApp: 'App',
  menuAbout: 'Sobre',
  searchBar: 'Pesquisar',
  btLogIn: 'Entrar',
  btUseEmail: 'USE UM EMAIL',
  btForgotPassword: 'Esqueceu a senha?',
  textFieldPassword: 'Senha',
  logInDesc: 'Logar ou criar uma conta:',
  heroT1: 'Um novo jeito de ganhar com música',
  heroT2: 'Musii é a plataforma onde artistas engajam seus fãs e apoiadores por meio da venda de NFTs colecionáveis, com produtos, experiências exclusivas e royalties de música',
  heroCtaArtists: 'COMECE A VENDER',
  heroCtaFans: 'COMECE A COLECIONAR',

  tagCardNewRelease: 'LANÇAMENTO',
  tagCardUpcommingRelease: 'LANÇAMENTO EM BREVE',
  tagCardUpcommingReleaseDays: 'Dias',
  tagCardUpcommingReleaseHours: 'Horas',
  tagCardUpcommingReleaseMin: 'Min',
  tagCardUpcommingReleaseSec: 'Seg',

  categoryCollectible: 'COLECIONÁVEIS',
  categoryProduct: 'PRODUTOS',
  categoryExperience: 'EXPERIÊNCIAS',
  categoryRoyalties: 'ROYALTIES',
  Collectible: 'Colecionável',
  CD: 'CD',
  Vinyl: 'Vinil',
  'Autographed Items': 'Itens Autografados',
  Merchandising: 'Merchandising',
  Phygital: 'Fígital',
  'Show Ticket': 'Ingresso de Show',
  'Studio Session': 'Sessão de Estúdio',
  Membership: 'Memberships',
  'Early Access': 'Acesso Antecipado',
  'Lesson / Workshop': 'Aula / Workshop',
  'Gift Video': 'Vídeo Personalizado',
  'Meet-and-Greet': 'Meet-and-Greet',
  'Q&A Forum': 'Fórum de Q&A',
  Backstage: 'Backstage',

  liveCollectionsT1: 'Marketplace',
  liveCollectionsT2: 'Coleções Disponíveis',
  nftCardBuy: 'COMPRAR',
  nftCardSold: 'ESGOTADO',
  nftCardAvailable: 'disponível',
  viewAll: 'Ver Todos',

  secondarySalesT1: 'Marketplace',
  secondarySalesT2: 'Vendas Secundárias',

  whatSellT1: 'O que os artistas podem vender no Musii?',
  whatSellT2: 'Categorias de Benefícios',
  whatSellB1: 'Ao comprar os NFTs, os fãs podem adquirir benefícios de acordo com a categoria definida no item, como nos exemplos abaixo:',
  categoryCollectiblesB1: 'Arte Digital\nImagens Exclusivas\nArquivo Pessoal',
  categoryProductsB1: 'CD / Vinil\nItens Autografados\nMerchandising\nFígital',
  categoryExperiencesB1: 'Ingressos de Shows\nSessões de Estúdio\nMemberships\nAcesso Antecipado a Novos Lançamentos\nAulas / Workshops\nVídeos Personalizados\nMeet-and-Greet\nFórum de Q&A\nBackstage',
  categoryRoyaltiesB1: 'Antecipação de Royalties de Plataformas de Streaming\n\nEM BREVE',

  verifiedArtistsT2: 'Artistas Verificados:',

  howWorksT1: 'Como Funciona',
  howWorksT2: 'Engaje sua Base de Fãs e Criar uma Nova Fonte de Receita',
  step1T1: 'Passo 01',
  step1T2: 'Envie as informações',
  step1B1: 'Preencha os detalhes sobre você e o item que deseja vender.',
  step2T1: 'Passo 02',
  step2T2: 'Envie para aprovação',
  step2B1: 'Verificaremos se as informações enviadas são legítimas e se você possui os direitos da música.',
  step3T1: 'Passo 03',
  step3T2: 'Comece a vender',
  step3B1: 'Após receber a aprovação, abra a venda e divulgue sua oferta para seus fãs!',

  bannerCtaT1: 'Comece a engajar seus fãs',
  bannerCtaT2: 'Crie um senso de comunidade dentro de sua base de fãs e seja apoiado por aqueles que realmente acreditam em sua carreira',
  bannerCtaButton: 'CRIAR NFT',

  faqT1: 'FAQ',
  faqT1Title: 'Perguntas Frequentes',
  faq1T1: '+ O que é o Musii?',
  faq1B1: 'A Musii é uma plataforma que oferece aos artistas a possibilidade de engajar sua base de fãs e monetizar suas carreiras de novas maneiras, vendendo diferentes categorias de NFTs.',
  faq2T1: '+ O que são NFTs?',
  faq2B1: 'NFTs são itens digitais únicos armazenados para sempre na internet (blockchain) que contêm informações imutáveis e oferecem propriedade, autoria e autenticidade. Dessa forma, os NFTs não podem ser forjados ou alterados sem permissão do criador. Esses itens únicos podem ser trocados entre compradores e, devido à sua oferta limitada, podem se valorizar. No Musii, os artistas podem vender NFTs para seus fãs, que podem ter benefícios (utilidades) anexados a eles, como produtos físicos e experiências.',
  faq3T1: '+ Como comprar NFTs no Musii?',
  faq3B1: 'Você pode conferir um vídeo tutorial passo a passo de como comprar um NFT aqui.\nPara comprar um NFT no Musii, siga os seguintes passos:\n1. Escolha o item desejado.\n2. Selecione o método de pagamento:\na. Carteira de criptomoedas: conecte sua carteira Algorand (Defly ou Pera), recarregue o saldo da carteira com o valor necessário e aceite todas as transações necessárias, se estiver de acordo. Essas transações podem levar alguns minutos para serem concluídas, então, assim que você pressionar para aceitar, seja um pouco paciente.\nb. PIX: escaneie ou copie/cole o código da transação em seu aplicativo bancário e finalize o processo lá. Você receberá uma confirmação do pagamento em seu endereço de e-mail.',
  faq4T1: '+ Como os artistas podem vender NFTs no Musii?',
  faq4B1: '1. Clique em "Vender NFTs" em seu perfil ou na página inicial.\n2. Preencha os detalhes sobre você e o item que deseja vender.\n3. Envie para aprovação. Verificaremos se as informações enviadas são legítimas e se você possui os direitos da música.\n4. Após receber a aprovação, iniciaremos a venda e divulgue sua oferta para seus fãs!',
  faq5T1: '+ Que tipo de categorias de NFTs os artistas podem vender no Musii?',
  faq5B1: 'Atualmente, o Musii oferece 3 opções de categorias para os NFTs:\n- Colecionáveis: NFTs que são arte digital única de coleções feitas pelos artistas, mas não possuem benefícios extras. Apenas uma ilustração para exemplificar: no passado, as pessoas costumavam colecionar e pendurar pôsteres de suas bandas favoritas nas paredes de seus quartos, hoje muitas pessoas colecionam NFTs assim como faziam com seus pôsteres.\n- Produtos: NFTs que vêm junto com produtos físicos, como CDs / Vinis, itens autografados, merchandising e fígitais. Dessa forma, esses NFTs também funcionam como certificados de autenticidade para esses itens.\n- Experiências: NFTs que funcionam como acesso a experiências da vida real, como ingressos para shows, acesso a sessões de estúdio, acesso antecipado a novos lançamentos, aulas/oficinas, vídeos personalizados, meet-and-greet, fóruns de perguntas e respostas e acesso aos bastidores/backstage.\n- Royalties: Esta categoria ainda não está disponível, mas deveremos incluí-la o mais breve possível. Nesse caso, os NFTs representam uma porcentagem dos royalties de streaming, portanto, toda vez que eles gerarem receita nas plataformas distribuídas, cada comprador receberá automaticamente sua respectiva parte.',
  faq6T1: '+ Como funcionam as coleções com benefícios?',
  faq6B1: 'Se desejarem, os criadores podem oferecer benefícios relacionados à sua coleção. A entrega e/ou execução desses benefícios aos compradores são de responsabilidade exclusiva do criador, e o Musii não assume nenhuma responsabilidade pela entrega e/ou execução desses benefícios. Ao oferecer benefícios, os criadores devem incluir um prazo, que será visível na plataforma na página da oferta, para cumprir a entrega e/ou execução desses benefícios.',
  faq7T1: '+ O que são as edições das coleções de NFTs?',
  faq7B1: 'Quando uma coleção de NFTs com edições é criada, o número total de edições (quantidade) disponíveis é definido pelo seu criador. Por exemplo, pode haver 100 unidades de uma coleção de NFTs para uma mesma peça de arte específica. Cada edição recebe um NFT individual e possui um identificador único que a distingue das outras edições. Dessa forma, os NFTs de coleções com poucas edições tendem a ser mais raros, pois há uma quantidade mais restrita deles disponíveis no mercado.',
  faq8T1: '+ Que tipo de música os artistas podem incluir no NFT?',
  faq8B1: 'Os artistas podem usar apenas músicas das quais possuam os direitos. Para comprovar isso, solicitamos algumas evidências de que você possui a propriedade para comercializá-la: captura de tela da página do distribuidor, processo de produção e ISRC com a planilha de royalties dos colaboradores.',
  faq9T1: '+ Como os pagamentos são feitos na plataforma?',
  faq9B1: 'Como artista que vende Coleções no Musii, você precisará nos fornecer uma Carteira Algorand para receber os pagamentos. As transações são feitas instantaneamente, conforme os fãs compram seus itens. Todos os pagamentos são feitos em USDT.\nUSDT é uma criptomoeda stablecoin da Tether que representa um dólar americano. Ela oferece aos artistas um método de pagamento digital estável e conveniente, reduzindo a volatilidade em comparação com outras criptomoedas.\nSe você desejar converter seus ganhos para reais (BRL), poderá fazer isso por meio de uma plataforma de terceiros em seu perfil ou em qualquer exchange de sua preferência que aceite essas moedas.',
  faq10T1: '+ Como o Musii cobra?',
  faq10B1: 'A Musii deduz do criador 5% do valor de cada venda primária, de acordo com o preço estabelecido pelo artista na plataforma. Além disso, o Musii cobra uma taxa de serviço de 5% do comprador em cada transação com base no preço informado pelo artista. As taxas para vendas secundárias são de 10%, sendo 5% do valor destinado ao criador e outros 5% destinados à Musii.',
  faq11T1: '+ Quais direitos eu obtenho ao comprar um NFT no Musii?',
  faq11B1: 'Os compradores possuem apenas a posse do NFT e as vantagens dos benefícios que estão explícitos na descrição do NFT (se ainda estiverem disponíveis e o item tiver sido adquirido no mercado primário). Isso significa que eles não possuem nenhum direito autoral ou propriedade intelectual da música, de acordo com as leis.\nEsteja ciente de que, se você adquirir um NFT no mercado secundário, os benefícios do item (se houverem) podem já ter sido utilizados, e não podemos garantir que o vendedor os transferirá para você (como qualquer tipo de produto ou experiência que já tenha ocorrido).',
  faq12T1: '+ Quais são as diretrizes para um NFT ser aprovado no Musii?',
  faq12B1: 'A Musii é uma plataforma musical e, portanto, só aceitamos NFTs relacionados a esse tema. Estes são os requisitos padrão para vender NFTs no Musii:\n- Ser sobre música: Todas as coleções possuem uma imagem de capa e um vídeo no conteúdo. Esse vídeo deve conter a música do NFT ou algo relacionado a ela.\n- Propriedade da música: Os artistas precisam fornecer evidências de que possuem os direitos sobre a música que estão comercializando.\n- Qualidade e conteúdo adequados: A arte e o conteúdo do NFT devem ser adequados, respeitando as diretrizes do Musii.\n- Ser único: Para que seu nft continue ganhando valor ao longo do tempo e tenha potencial para ser revendido, ele deve ser escasso, isso significa que a coleção deve ser uma oportunidade única na vida para os compradores possuírem essa coleção específica. Portanto, uma condição é nunca mais replicar essa arte ou combinação de arte e vantagem na blockchain.',

  footerCommnunity: 'Somos uma comunidade com dezenas de milhares de usuários conectados pela música.\nSe você quer fazer parte dessa comunidade, baixe nosso aplicativo e siga nossas redes sociais.',
  footerDisclaimer: 'Nenhuma das cotações de custo, dados, notícias e perguntas disponíveis neste site, em nosso aplicativo ou compartilhadas por qualquer canal de comunicação pelo Musii ou suas afiliadas constitui aconselhamento, propostas ou vendas de qualquer oferta para compra ou oferta de qualquer ativo, direito autoral, especulação ou itens de investimento. O desempenho passado não é garantia de retornos futuros, nem é necessariamente indicativo de desempenho futuro. As imagens e fotos dentro do site e do aplicativo são apenas ilustrativas e podem conter informações que não estão mais atualizadas ou válidas. Os criadores podem oferecer benefícios relacionados à sua coleção. A entrega e/ou execução desses benefícios aos compradores são de responsabilidade exclusiva do criador, e o Musii não assume nenhuma responsabilidade pela entrega e/ou execução desses benefícios.',
  footerHome: 'Início',
  footerMission: 'Missão',
  footerTeam: 'Equipe',
  footerPartners: 'Parceirias',
  footerFAQ: 'FAQ',
  footerSupport: 'Suporte',
  footerSellNFT: 'Vender NFTs',
  footerMyCollections: 'Minhas Coleções',
  footerNFTsHold: 'NFTs em Posse',
  footerProfile: 'Perfil',
  footerTerms: 'Termos e Condições',
  footerPrivacy: 'Política de Privacidade',
  footerCopyright: 'Todos direitos reservados © 2023, Musii - Um novo jeito de ganhar com música',

  aboutUs: 'Sobre Nós',
  aboutUsTitle: 'Encorajamos Que os Artistas Façam Música',
  aboutUsDesc: `Queremos que as pessoas possam escolher seguir o que gostam. Acreditamos que qualquer artista pode ganhar a vida com música.

Para aqueles que têm medo de seguir uma carreira musical, por ser difícil ganhar a vida na indústria da música, o Musii torna possível para novos e emergentes artistas venderem NFTs para aqueles que acreditam em seu talento, ajudando esses artistas a alcançarem mais sucesso.`,
  ourMission: 'Nossa Missão',
  ourMissionTitle: 'Democratizar o Mercado da Música',
  ourMissionDesc: `A Musii nasceu com a missão de ajudar artistas independentes. Nossos fundadores conhecem pessoalmente a dificuldade desse mercado e o quão centralizada é a indústria da música.

Artistas estabelecidos conseguem se beneficiar dos algoritmos atuais e dos grandes players, mas artistas emergentes e independentes enfrentam uma enorme dificuldade para serem descobertos. A Musii está mudando esse problema.`,
  musiiSeaLionsTitle: 'Musii Sea Lions',
  musiiSeaLionsDescription: 'Musii Sea Lions é uma coleção de NFTs (ARC-19) criada pela nossa equipe com o objetivo de engajar a comunidade web3 na nobre causa de ajudar artistas independentes, democratizar a indústria da música e fazer com que mais pessoas conheçam o Musii.',
  musiiSeaLionsCTA1: 'SORTEAR NO SHUFFLE',
  musiiSeaLionsCTA2: 'Saiba mais',
  ourSquad: 'Nosso time',
  ourSquadTitle: 'Membros da equipe',
  partners: 'Parceiros',
  partnersTitle: 'Somos parceiros de empresas de destaque',

  appSessionTheMusicSocialMedia: 'A Rede Social da Música',
  appSessionTheMusicSocialMediaInfo: 'Somos uma comunidade com dezenas de milhares de fãs, músicos, compositores, letristas, produtores, todos conectados por um propósito: promover boa música. Experimente nosso aplicativo:',
  appSessionStartSharing: 'Comece a compartilhar música no lugar certo',
  appSessionStartSharingInfo: 'Música deve ser apreciada em conjuntos. No Musii, as pessoas realmente ouvem as músicas que você compartilha. Escolha uma entre mais de 100 milhões de faixas, adicione uma legenda com seus comentários e ganhe relevância quando as pessoas interagem com você para se tornar um influenciador musical.',
  appSessionTheMusicSaveYourDiscoveries: 'Salve suas descobertas diretamente no seu Spotify',
  appSessionTheMusicSaveYourDiscoveriesInfo: 'Expanda seu repertório musical como nunca antes. Descubra novas músicas e artistas que você nunca ouviu em segundos. Salve todas as suas descobertas em uma playlist com um clique usando nossa integração com o Spotify.',
  appSessionStayAhead: 'Fique atualizado do que está rolando na cena da indústria',
  appSessionStayAheadInfo: 'Confira as faixas mais populares da comunidade, quem são os novos talentos em ascensão e seja um dos primeiros a saber quem são as joias fora do círculo tradicional.',
  appSessionFindPeople: 'Encontre pessoas que combinam com seu gosto musical',
  appSessionFindPeopleInfo: 'Descubra pessoas semelhantes a você, participe de sua tribo musical e se aproxime de seus artistas favoritos. Interaja, converse, celebre e experimente música de novas maneiras.',

  profileLogOut: 'SAIR',
  profileCTAAddOffer: 'CRIAR OFERTA',
  profileCTAAddOfferMobile: "Disponível apenas no computador.",
  profileNFTsHold: 'NFTs em Posse',
  profileMyCollections: 'Minhas Coleções',
  profileMyCollectionsStatusDeclined: 'RECUSADO',
  profileMyCollectionsStatusPending: 'PENDENTE',
  profileMyCollectionsStatusWaitingApproval: 'AGUARDANDO APROVAÇÃO',
  profileMyCollectionsStatusWaitingWallet: 'AGUARDANDO CARTEIRA',
  profileMyCollectionsStatusReview: 'REVISAR',
  profileCollections: 'Coleções',
  profileProfile: 'Perfil',
  profileNFTsHoldStatusView: 'VER',
  profileNFTsHoldStatusRedeem: 'RESGATAR',
  profileNFTsHoldEmptyWallet: 'Carteira Vazia',
  profileNFTsHoldEmptyWalletMessage: 'Desculpe, não encontramos nenhum ativo nesta carteira.',
  profileNFTsHoldCTAConnectWallet: 'CONECTAR CARTEIRA',
  editProfileUpdatePFP: 'Atualizar Foto de Perfil',
  editProfileDisconnect: 'DESCONECTAR',
  editProfileName: 'Nome',
  editProfileNamePlaceholder: 'Como você gostaria de ser chamado(a)?',
  editProfileBio: 'Bio',
  editProfileBioPlaceholder: 'Escreva algo sobre você aqui.',
  editProfileConnectWallet: 'Conectar Carteira',
  editProfileConnectWalletInfo: 'Isso será usado para mostrar os NFTs em seu perfil e acelerar o processo de compra com carteira. Você só pode conectar uma carteira por vez.',
  editProfileGenres: 'Gêneros Musicais',
  editProfileSocial: 'Redes Sociais:',
  editProfileHiddenInformation: 'Informações Ocultas: ',
  editProfileEmail: 'E-mail',
  editProfileEmailPlaceholder: 'seu@email.com',
  editProfilePhone: 'Telefone',
  editProfilePhonePlaceholder: '+55 (11) 99999-9999',
  editProfileCPForId: 'CPF ou CNPJ',
  editProfileCPForIdPlaceholder: '123.456.789-00',
  editProfileButtonEdit: 'EDITAR',
  editProfileSuccessConfirmation: 'Perfil Atualizado',
  editProfileButtonSuccessConfirmation: 'OK',

  createCollectionTitle: 'Criar Coleção',
  createCollectionStepProfile: 'Perfil',
  createCollectionStepCollection: 'Coleção',
  createCollectionStepWallet: 'Carteira',
  createCollectionArtisticName: 'Nome Artístico',
  createCollectionArtisticNamePlaceholder: 'Nome Artístico ou da sua banda',
  createCollectionArtisticNameInfo: 'Deve ser a mesma denominação que você usa nas plataformas de streaming.',
  createCollectionEmail: 'E-mail',
  createCollectionEmailPlaceholder: 'seu@email.com',
  createCollectionEmailInfo: 'Por favor, forneça um endereço de e-mail válido para que possamos entrar em contato, se necessário.',
  createCollectionPhone: 'Telefone',
  createCollectionPhonePlaceholder: '+55 (11) 99999-9999',
  createCollectionPhoneInfo: 'Por favor, forneça um número de telefone válido para que possamos entrar em contato, se necessário.',
  createCollectionCPForId: 'CPF ou CNPJ',
  createCollectionCPForIdPlaceholder: '123.456.789-10',
  createCollectionCPForIdInfo: 'Por favor, forneça o número completo com dígito.',
  createCollectionMandatoryFiled: 'Este campo é obrigatório.',
  createCollectionCardCollectionName: 'Nome da Coleção',
  createCollectionCardNext: 'PRÓXIMO',
  createCollectionCardSend: 'ENVIAR PARA APROVAÇÃO',
  createCollectionIncludeBenefits: 'Incluir benefícios?',
  createCollectionIncludeBenefitsInfo: 'Músicos podem anexar benefícios especiais aos seus NFTs no Musii, como produtos físicos e experiências da vida real. Caso você optar por não incluir nenhum benefício, seu NFT será classificado como "Colecionável". Se você deseja incluir algum benefício, abra a caixa abaixo e selecione-o.',
  createCollectionCategories: 'Categorias:',
  createCollectionCategoriesCollectiblesInfo: 'Qualquer NFT que não inclua benefícios é classificado no Musii como "Colecionável".',
  createCollectionCategoriesProductsInfo: 'Venda produtos únicos com seu NFT. Esta categoria inclui objetos físicos, como os listados acima.',
  createCollectionCategoriesProductsInfo2: `A entrega desses benefícios é de responsabilidade do vendedor (o endereço do comprador será coletado para esse fim).`,
  createCollectionCategoriesExperiencesInfo: 'Venda ingressos e acesso especial a experiências da vida real.',
  createCollectionCategoriesExperiencesInfo2: `A produção do evento ou experiência é de sua responsabilidade exclusiva.`,
  createCollectionCategoriesRoyaltiesInfo: `EM BREVE \n Se você estiver interessado em vender royalties da sua música, `,
  createCollectionCategoriesRoyaltiesInfo2: `clique aqui para falar com a gente.`,
  createCollectionThumbnail: 'Thumbnail do NFT',
  createCollectionThumbnailInfo: 'Inclua a imagem de capa do seu NFT. Ela aparecerá nas páginas quando seu vídeo não estiver sendo reproduzido.',
  createCollectionVideo: 'Vídeo (conteúdo)',
  createCollectionVideoInfo: 'Inclua o vídeo (conteúdo) do seu NFT.',
  createCollectionVideoInfo2: 'Esteja ciente de que ele deve ter sua música tocando ao fundo.',
  createCollectionUploadFile: 'ENVIAR ARQUIVO',
  createCollectionUploadErrorSquare: 'O arquivo enviado não é quadrado. Sugerimos que você envie um com as dimensões de 512px x 512px.',
  createCollectionName: 'Nome da Coleção',
  createCollectionNamePlaceHolder: 'Nome da Coleção',
  createCollectionNameInfo: 'Seja criativo e dê um nome marcante para sua coleção.',
  createCollectionDescription: 'Descrição',
  createCollectionDescriptionPlaceHolder: 'Descrição',
  createCollectionDescriptionInfo: 'Fale sobre a coleção e o projeto por trás dela. Caso haja algum benefício, não se esqueça de descrever seus detalhes e condições, como local/data/hora dos eventos, área de entrega, prazo de validade para solicitar os produtos ou a forma como o fã irá recebê-los. Forneça o máximo de detalhes possível para evitar interpretações equivocadas. Essas informações serão incluídas nos metadados do NFT, o que significa que elas funcionarão como as "cláusulas contratuais" da sua oferta.',
  createCollectionTags: 'Palavras-chave',
  createCollectionTagsPlaceHolder: 'Adicione tags para que seu(s) NFT(s) possam ser encontrados',
  createCollectionTagsInfo: 'Preencha cada tag separada por vírgula.',
  createCollectionExternalURL: 'URL Externa',
  createCollectionExternalURLPlaceHolder: 'Link',
  createCollectionExternalURLInfo: 'Inclua um link para que as pessoas possam encontrar mais informações sobre você e seu projeto.',
  createCollectionTotalSupply: 'Quantidade disponível',
  createCollectionTotalSupplyPlaceHolder: '0',
  createCollectionTotalSupplyInfo: 'O número de edições que esta coleção terá.',
  createCollectionPrice: 'Preço',
  createCollectionPricePlaceHolder: 'R$0,00',
  createCollectionPriceInfo: 'O preço para cada edição/item da sua coleção. Lembre-se de considerar a taxa da nossa plataforma de 5%.',
  createCollectionLegitimacyVerification: 'Verificação de Legitimidade:',
  createCollectionLegitimacyVerificationInfo: 'Para vender sua coleção como artista na plataforma, precisaremos que você nos envie as seguintes informações para verificar a legitimidade do seu trabalho e carreira. Isso comprovará que você realmente tem os direitos de explorar comercialmente essa música a ser oferecida como um NFT. Se necessário, também poderemos solicitar confirmações por meio de mensagens diretas nas suas redes sociais para garantir que ninguém esteja tentando se passar por você. Apenas lembrando que os compradores e detentores dos seus NFTs não poderão exercer uso comercial da sua obra por contrato. Se você for aprovado pela primeira vez, automaticamente receberá um selo de artista no seu perfil.',
  createCollectionISRC: 'ISRC',
  createCollectionISRCPlaceHolder: 'BR-1A23456789',
  createCollectionISRCInfo: 'O código ISRC de registro da sua música, indicando seu número e distribuição de direitos (isrcsearch.ifpi.org).',
  createCollectionAssociation: 'Associação',
  createCollectionAssociationInfo: 'Informe a associação da qual você faz parte.',
  createCollectionAssociationOther: 'Outra',
  createCollectionAssociationOtherPlaceHolder: 'Outra',
  createCollectionStreamingDashboard: 'Painel de Streaming*',
  createCollectionStreamingDashboardInfo: 'Faça o upload de uma captura de tela da sua área logada de artista em um serviço de streaming no qual você tenha músicas distribuídas (por exemplo, Spotify for Artists).',
  createCollectionDistributorProfile: 'Painel do Distribuidor',
  createCollectionDistributorProfileInfo: 'Faça o upload de uma captura de tela da sua área logada na plataforma de distribuição na qual você tenha músicas distribuídas.',
  createCollectionPayOff: 'Conta de Pagamento:',
  createCollectionPayOffInfo: 'Para receber os pagamentos da sua coleção, você precisará fornecer uma carteira da Algorand (se ainda não tiver uma, aprenda como criar aqui). Essa carteira será usada para criar esta coleção e receber os ganhos dela. Você NÃO poderá alterar essa carteira depois de tê-la definido como sua conta de recebimento para esta coleção específica.',
  createCollectionPayOffInfoLearnMore: 'Os pagamentos são feitos em USDT (saiba mais).',
  createCollectionWalletConnected: 'Carteira Conectada',
  createCollectionTutorial: 'Ainda não tem uma carteira? Assista este vídeo de tutorial para saber como criar uma.',
  createCollectionFinalInformation: 'Por favor, confirme as seguintes informações',
  createCollectionCheckInformationTrue: 'Garanto que todas as informações fornecidas são verdadeiras.',
  createCollectionCheckUsdt: 'Confirmo que fiz o opt-in de USDT na minha carteira para receber os valores das minhas vendas.',
  createCollectionCheckRightHolder: 'Eu sou o detentor dos direitos de todo o conteúdo incluído no NFT, incluindo a música.',
  createCollectionCheckBenefits: 'Se a coleção incluir algum benefício, concordo que sou totalmente responsável pela entrega e/ou execução  deles.',
  createCollectionCheckAgreeWithTax: 'Estou ciente e concordo com as taxas de serviço do Musii de 5% para vendedores e 5% para compradores sobre o valor das vendas.',
  createCollectionCheckTerms: 'Ao prosseguir, estou concordando com os Termos de Serviço e as cláusulas contratuais do Musii.',
  createCollectionCheckButtomCancel: 'CANCELAR',
  createCollectionCheckButtomContinue: 'CONTINUAR',
  createCollectionSent: 'Coleção enviada para aprovação!',
  createCollectionSentInfo: 'Parabéns, suas informações foram enviadas com sucesso. Agora, aguarde um período de até 72 horas para que nossa equipe verifique se tudo está dentro de nossas diretrizes. Enquanto isso, que tal completar seu perfil para que as pessoas possam saber mais sobre você?',
  createCollectionSentButtomViewRequest: 'VER MEU PEDIDO',
  createCollectionSentButtomCompleteProfile: 'COMPLETAR MEU PERFIL',

  changePassword: 'Alterar Senha:',
  changePasswordDesc: 'Por favor, forneça uma nova senha e a confirme.',
  changePasswordEmail: 'E-mail para recuperar senha.',
  changePasswordCode: 'Código*',
  changePasswordCodeDesc: 'Código enviado por email.',
  changePasswordNewPassword: 'Nova senha*',
  changePasswordConfirmPassword: 'Confirme a nova senha*',
  changePasswordButton: 'ALTERAR SENHA',

  reviewFlowDeletedField: 'Este campo foi excluído, preencha e envie novamente.',
  emailError: 'Este e-mail não é válido.',
  cpfError: 'Você inseriu um CPF inválido, caso queira pagar com PIX, você deve preencher um CPF válido. Por favor, forneça o número completo com dígito para que a transação possa ser concluída.',
  websiteError: 'Este site não é válido.',
  possibleErrorsLogin: 'Existem duas possibilidades de erro: 1- este e-mail já foi usado para criar uma conta no Musii via Google/Facebook ou; 2- a senha está incorreta.',
  possibleErrorsForgot: 'Existem duas possibilidades de erro: 1- este e-mail já foi usado para criar uma conta no Musii via Google/Facebook ou; 2- não existe uma conta com esse email no musii.',
  passwordNotMatchError: 'Deve ser igual à sua nova senha.',
  passwordWithoutRules: 'Deve conter pelo menos 8 caracteres (letras e números).',
  purchaseFlowCollectionDetails: 'Detalhes da coleção:',
  purchaseFlowCollectionDetailsCreator: 'Criador',
  purchaseFlowCollectionDetailsMetadata: 'Padrões de Metadados',
  purchaseFlowCollectionDetailsSupply: 'Edições',
  purchaseFlowCollectionEditions: 'edições',
  purchaseFlowCollectionAttention: 'Os benefícios são válidos apenas para compradores de vendas primárias.\n*A entrega e/ou execução desses benefícios aos compradores são de responsabilidade exclusiva do criador, e o Musii não assume nenhuma responsabilidade pela entrega e/ou execução desses benefícios.',
  purchaseFlowPersonalDataIcon: 'Dados pessoais',
  purchaseFlowPaymentIcon: 'Pagamento',
  purchaseFlowPersonalDataTitle: 'Dados pessoais e entrega',
  purchaseFlowPersonalDataInfo: 'Essas informações são necessárias para que o criador da coleção possa entregar os benefícios a você e entrar em contato, se necessário.',
  purchaseFlowName: 'Nome Completo',
  purchaseFlowNamePlaceholder: 'Nome e sobrenome',
  purchaseFlowEmail: 'E-mail',
  purchaseFlowEmailPlaceholder: 'seu@email.com',
  purchaseFlowEmailInfo: 'Por favor, forneça um endereço de e-mail válido para que possamos entrar em contato, se necessário.',
  purchaseFlowPhone: 'Telefone',
  purchaseFlowPhonePlaceholder: '+55 (11) 99999-9999',
  purchaseFlowPhoneInfo: 'Por favor, forneça um número de telefone válido para que possamos entrar em contato, se necessário.',
  purchaseFlowCPForId: 'CPF',
  purchaseFlowCPForIdInfo: 'Por favor, forneça o número completo com dígito.',
  purchaseFlowZipCode: 'CEP',
  purchaseFlowZipCodePlaceholder: 'XXXXX-XXX',
  purchaseFlowAddress: 'Endereço',
  purchaseFlowAddressPlaceholder: 'Rua/Avenida/Alameda',
  purchaseFlowAddressNumber: 'Número',
  purchaseFlowAddressComplement: 'Complemento',
  purchaseFlowAddressComplementPlaceholder: 'Apartamento/Andar/Bloco',
  purchaseFlowAddressNeighborhood: 'Bairro',
  purchaseFlowAddressNeighborhoodPlaceholder: 'Bairro/Distrito',
  purchaseFlowAddressCity: 'Cidade',
  purchaseFlowAddressCityPlaceholder: 'Cidade',
  purchaseFlowAddressState: 'Estado',
  purchaseFlowAddressStatePlaceholder: 'Estado/Província/Região',
  purchaseFlowAddressCountry: 'País',
  purchaseFlowAddressCountryPlaceholder: 'País',
  purchaseFlowCheckSharingData: 'Autorizo o compartilhamento dos meus dados com terceiros para a entrega de benefícios e, a partir de agora, coloco-me à disposição para contato.',
  purchaseFlowCheckAgreeTerms: 'Ao prosseguir, concordo com os Termos de Serviço e cláusulas contratuais do Musii.',
  purchaseFlowCheckoutTitle: 'Finalização da compra - Método de pagamento',
  purchaseFlowCheckoutInfo: 'Se você pagar com PIX (moeda fiduciária), seu NFT estará disponível em seu perfil e, se desejar, poderá resgatá-lo para uma cripto-carteira posteriormente, clicando em "resgatar" e conectando uma carteira.',
  purchaseFlowPayWithWallet: 'Pague com $ALGO:',
  purchaseFlowPayWithPix: 'Pague com PIX',
  purchaseFlowOrderSummary: 'Resumo do Pedido',
  purchaseFlowOrderSubtotal: 'Subtotal',
  purchaseFlowOrderTotal: 'Total',
  purchaseFlowServiceTax: 'Taxa de serviço',
  purchaseFlowProcessingMint: 'Processando Mint',
  purchaseFlowProcessingMintDesc: 'Aguardando o compasso certo para conectar sua carteira, obrigado pela paciência enquanto trabalhamos nos bastidores.',
  purchaseFlowProcessingMintWallet: 'Mantenha sua carteira aberta durante o processo e verifique quando for necessário aceitar transações.',
  purchaseFlowConfirmationTitle: 'Compra Aprovada',
  purchaseFlowConfirmationInfo: 'Para acessar seu NFT, basta entrar em seu perfil e clicar na aba "NFTs em posse".',
  purchaseFlowConfirmationButtonView: 'VER MEUS NFTS',

  tutorialTitle: 'Aprenda neste vídeo tutorial o passo a passo do processo de criar ou conectar uma carteira para receber pagamentos.',
  tutorialNftTitle: 'Aprenda neste vídeo tutorial o passo a passo do processo de criar uma NFT no musii.'
};

export default pt;
