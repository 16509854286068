import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, Grid, Typography } from "@mui/material";
import NftList from "../components/NftList";
import Footer from "../components/Footer";

import axios from "axios";
import Dev from "../utils/Dev";

const BaseadosDigitaisCategories = ({ category }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showModalConnect, setShowModalConnect] = useState(false);

    const [nfts, setNfts] = useState([]);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const loadInfos = async () => {
            const responseNft = await axios.get(
                `${Dev.api}nft/tag?tag=${category}`)

            setNfts(responseNft.data.Items)
        };

        loadInfos();

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            <Box display={"flex"} justifyContent={"center"} paddingY={"56px"} marginTop={"96px"}>
                <Grid
                    container
                    marginLeft={isMobile ? 2 : 0}
                    gap={"32px"}
                    maxWidth={"95vw"}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <Grid xs={12} md={11} lg={11} xl={10}>
                        <NftList nfts={nfts} />
                    </Grid>
                </Grid>
            </Box>
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default BaseadosDigitaisCategories;
