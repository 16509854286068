const ProfileInstagram = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=instagram">
                <g id="perfil/redes/instagram_off">
                    <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M33.6801 14.3351C33.6157 12.8739 33.3793 11.8694 33.0408 10.9989C32.6915 10.0749 32.1542 9.24771 31.4502 8.56003C30.7624 7.86165 29.9296 7.31898 29.0161 6.97524C28.1405 6.63675 27.141 6.40046 25.6795 6.33604C24.2071 6.26616 23.7397 6.25 20.0054 6.25C16.271 6.25 15.8036 6.26616 14.3366 6.33058C12.8752 6.39501 11.8704 6.63151 11 6.96979C10.0757 7.31898 9.24828 7.85619 8.56047 8.56003C7.86196 9.24771 7.31939 10.0804 6.97538 10.9937C6.63683 11.8694 6.40049 12.8685 6.33605 14.3296C6.26616 15.8017 6.25 16.2691 6.25 20.0027C6.25 23.7364 6.26616 24.2037 6.3306 25.6704C6.39503 27.1315 6.63158 28.1361 6.97013 29.0066C7.31939 29.9305 7.86196 30.7577 8.56047 31.4454C9.24828 32.1438 10.0811 32.6865 10.9946 33.0302C11.8704 33.3687 12.8697 33.605 14.3314 33.6694C15.7981 33.7341 16.2657 33.75 20.0001 33.75C23.7345 33.75 24.2019 33.7341 25.6688 33.6694C27.1303 33.605 28.135 33.3687 29.0054 33.0302C30.8539 32.3157 32.3154 30.8545 33.0301 29.0066C33.3684 28.1308 33.605 27.1315 33.6694 25.6704C33.7338 24.2037 33.75 23.7364 33.75 20.0027C33.75 16.2691 33.7445 15.8017 33.6801 14.3351ZM29.4555 12.2674C29.4555 13.2165 28.6859 13.9861 27.7366 13.9861C26.7875 13.9861 26.018 13.2165 26.018 12.2674C26.018 11.318 26.7875 10.5486 27.7366 10.5486C28.6859 10.5486 29.4555 11.318 29.4555 12.2674ZM13.125 20C13.125 16.2043 16.2045 13.125 20 13.125C23.7957 13.125 26.875 16.2043 26.875 20C26.875 23.7957 23.7957 26.875 20 26.875C16.2045 26.875 13.125 23.7957 13.125 20ZM14.8438 20C14.8438 22.8472 17.153 25.1562 20 25.1562C22.8472 25.1562 25.1562 22.8472 25.1562 20C25.1562 17.1528 22.8472 14.8438 20 14.8438C17.153 14.8438 14.8438 17.1528 14.8438 20Z" fill="#ABABCC" />
                </g>
            </g>
        </svg>
    );
};

export default ProfileInstagram;
