import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import Collectibles from "../assets/collectibles";
import Products from "../assets/products";
import Experiences from "../assets/experiences";
import Royalties from "../assets/royalties";
import Fonts from "../utils/Fonts";
import { useTranslation } from "react-i18next";

function Categories({ isMobile }) {
    const [t, i18n] = useTranslation("common");

    return (
        <Box display={"flex"} justifyContent={"center"}>
            <Grid
                container
                marginLeft={isMobile ? 2 : 0}
                paddingTop={isMobile ? "50px" : "56px"}
                paddingBottom={isMobile ? "50px" : "32px"}
                gap={"16px"}
                maxWidth={"80vw"}
            >
                <Grid
                    xs={12}
                    md={12}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize2,
                            color: "#ABABCC",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('whatSellT1')}
                    </Typography>
                    <Box
                        style={{
                            width: "35px",
                            height: "6px",
                            background:
                                "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                            borderRadius: "3px",
                            marginTop: "8px",
                            marginBottom: "16px",
                        }}
                    ></Box>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('whatSellT2')}
                    </Typography>
                </Grid>
                <Grid xs={12} md={12} marginBottom={4}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2RegularSize1,
                            color: "#ABABCC",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('whatSellB1')}
                    </Typography>
                </Grid>
                <Grid
                    xs={12}
                    md={2.8}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Collectibles />
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize1,
                            color: "#EC98E9",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                            marginTop: 32,
                            marginBottom: 32,
                        }}
                    >
                        {t('categoryCollectible')}
                    </Typography>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3RegularSize1,
                            color: "#EC98E9",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('categoryCollectiblesB1')}
                    </Typography>
                </Grid>
                <Grid
                    xs={12}
                    md={2.8}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Products />
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize1,
                            color: "#806FDC",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                            marginTop: 32,
                            marginBottom: 32,
                        }}
                    >
                        {t('categoryProduct')}
                    </Typography>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3RegularSize1,
                            color: "#806FDC",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('categoryProductsB1')}
                    </Typography>
                </Grid>
                <Grid
                    xs={12}
                    md={2.8}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Experiences />
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize1,
                            color: "#49A6A3",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                            marginTop: 32,
                            marginBottom: 32,
                        }}
                    >
                        {t('categoryExperience')}
                    </Typography>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3RegularSize1,
                            color: "#49A6A3",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('categoryExperiencesB1')}
                    </Typography>
                </Grid>
                <Grid
                    xs={12}
                    md={2.8}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Royalties />
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize1,
                            color: "#E69E39",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                            marginTop: 32,
                            marginBottom: 32,
                        }}
                    >
                        {t('categoryRoyalties')}
                    </Typography>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3RegularSize1,
                            color: "#E69E39",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('categoryRoyaltiesB1')}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Categories;
