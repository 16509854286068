const ProfileSoundcloud = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=soundcloud">
                <g id="perfil/redes/soundcloud_off">
                    <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M31 18.7037C30.644 18.7037 30.294 18.7403 29.958 18.8096C29.508 14.432 25.662 11 21 11C20.448 11 20 11.4314 20 11.963V27.3704C20 27.9019 20.448 28.3333 21 28.3333H31C33.758 28.3333 36 26.1744 36 23.5185C36 20.8646 33.758 18.7037 31 18.7037ZM17.3333 12.3333C16.5973 12.3333 16 12.7813 16 13.3333V27.3333C16 27.8853 16.5973 28.3333 17.3333 28.3333C18.0693 28.3333 18.6667 27.8853 18.6667 27.3333V13.3333C18.6667 12.7813 18.0693 12.3333 17.3333 12.3333ZM12 17.3333C12 16.7813 12.5973 16.3333 13.3333 16.3333C14.0693 16.3333 14.6667 16.7813 14.6667 17.3333V27.3333C14.6667 27.8853 14.0693 28.3333 13.3333 28.3333C12.5973 28.3333 12 27.8853 12 27.3333V17.3333ZM9.33333 16.3333C8.59733 16.3333 8 16.7813 8 17.3333V27.3333C8 27.8853 8.59733 28.3333 9.33333 28.3333C10.0693 28.3333 10.6667 27.8853 10.6667 27.3333V17.3333C10.6667 16.7813 10.0693 16.3333 9.33333 16.3333ZM4 21.3333C4 20.7813 4.59733 20.3333 5.33333 20.3333C6.06933 20.3333 6.66667 20.7813 6.66667 21.3333V27.3333C6.66667 27.8853 6.06933 28.3333 5.33333 28.3333C4.59733 28.3333 4 27.8853 4 27.3333V21.3333Z" fill="#ABABCC" />
                </g>
            </g>
        </svg>
    );
};

export default ProfileSoundcloud;
