import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 4,
    width: 16,
    height: 16,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#7272A3",
    backgroundColor: "transparent",
    "input:hover ~ &": {
        background:
            "linear-gradient(130.54deg, rgba(140, 41, 217, 0.9) 0%, rgba(94, 71, 214, 0.9) 102.22%), rgba(235, 235, 250, 0.25)",
        borderColor: "transparent",
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    borderWidth: 0,
    borderColor: "transparent",
    width: 20,
    height: 20,
    background:
        "linear-gradient(130.54deg, rgba(140, 41, 217, 0.9) 0%, rgba(94, 71, 214, 0.9) 102.22%), rgba(235, 235, 250, 0.25)",
    "&:before": {
        display: "block",
        width: 20,
        height: 20,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    "input:hover ~ &": {
        borderWidth: 2,
        borderColor: "#7272A3",
    },
});

export default function MusiiCheckbox(props) {
    return (
        <Checkbox
            sx={{
                "&:hover": { bgcolor: "transparent" },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            {...props}
        />
    );
}
