import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Close from "../../assets/close";
import Fonts from "../../utils/Fonts";
import Google from "../../assets/google";
import Facebook from "../../assets/facebook";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";

import axios from "axios";
import StorageEnum from "../../enums/StorageEnum";
import { useUser } from "../../hooks/useUser";
import Storage from "../../utils/Storage";
import AlertLoginEmail from "./AlertLoginEmail";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";

function AlertLogin({ isMobile, open, redirect, handleClose }) {
    const { logIn } = useUser();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    const [showModalConnect, setShowModalConnect] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [validatePassword, setValidatePassword] = useState(false);

    const callLoginApi = async (authenticationToken, email, name, photo) => {
        try {
            const response = await axios.post(
                `${Dev.api}nft/signin`,
                {
                    authenticationToken, email, name, photo
                },
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            console.log(response.data)

            if (response.data.user) {
                await Storage.setItem(StorageEnum.USER, {
                    ...response.data.user,
                    token: response.data.token,
                });
            } else {
                await Storage.setItem(StorageEnum.USER, {
                    email,
                    authenticationToken,
                    name,
                    photo,
                    token: response.data.token,
                });
            }

            logIn();
            if (redirect) {
                isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint");
            }
            handleClose();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setValidatePassword(true);
        }

    }

    const responseFacebook = async (responseFacebook) => {
        if (responseFacebook && !responseFacebook.error) {
            setIsLoading(true);

            const token = responseFacebook.id;
            const email = responseFacebook.email.toLowerCase();

            const firstName = responseFacebook.name;
            const photo = responseFacebook.picture.data.url;

            await callLoginApi(token, email, `${firstName}`, photo)


        }
    };

    const responseGoogle = async (googleAuth) => {
        setIsLoading(true);

        const token = googleAuth.googleId;
        const email = googleAuth.profileObj.email.toLowerCase();

        const firstName = googleAuth.profileObj.givenName;
        const lastName = googleAuth.profileObj.familyName;
        const photo = googleAuth.profileObj.imageUrl;

        await callLoginApi(token, email, `${firstName} ${lastName}`, photo)
    };

    async function responseFail(error) { }

    return (
        <>
            <AlertLoginEmail
                isMobile={isMobile}
                open={showModalConnect}
                handleClose={() => setShowModalConnect(false)}
            />
            {open && (
                <Box>
                    <Box
                        style={{
                            position: "absolute",
                            width: "100vw",
                            height: "100vh",
                            background: "#121229",
                            mixBlendMode: "normal",
                            opacity: "0.75",
                            top: 0,
                            left: 0,
                            zIndex: 9999,
                        }}
                    ></Box>
                    <Grid
                        container
                        paddingX={"48px"}
                        paddingY={"40px"}
                        zIndex={99}
                        style={{
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "min-content",
                            borderRadius: 16,
                            display: "flex",
                            maxWidth: '93%',
                            flexDirection: isMobile ? "column" : "",
                            justifyContent: "center",
                            zIndex: 99999,
                            background:
                                "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                        }}
                    >
                        <Box
                            onClick={handleClose}
                            style={{
                                position: "absolute",
                                right: "32px",
                                top: "32px",
                                cursor: "pointer",
                            }}
                        >
                            <Close />
                        </Box>
                        <Grid xs={12} md={12}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize1,
                                    color: "#ABABCC",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                {t('btLogIn')}
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={12}
                            marginBottom={4}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#ABABCC",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                {t('logInDesc')}
                            </Typography>
                        </Grid>

                        {!navigator.userAgent.includes("Instagram") && (
                            <Grid
                                xs={12}
                                md={10.5}
                                marginBottom={4}
                                gap={"24px"}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <GoogleLogin
                                    clientId="205011694189-gepcin3mbra82bbtq4c5bhbetfo4od8c.apps.googleusercontent.com"
                                    buttonText="Login"
                                    onSuccess={responseGoogle}
                                    onFailure={responseFail}
                                    cookiePolicy={"single_host_origin"}
                                    render={(renderProps) => (
                                        <div
                                            style={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                borderRadius: 32,
                                                marginTop: 16,
                                                height: "56px",
                                                cursor: "pointer",
                                                display: "flex",
                                                flex: 1,
                                                backgroundColor:
                                                    "#20202E",
                                                zIndex: 999999,
                                            }}
                                            onClick={renderProps.onClick}
                                        >
                                            {!isLoading && <Google />}
                                            {isLoading && (
                                                <CircularProgress
                                                    size={24}
                                                    thickness={4}
                                                    sx={{ color: "#FFF" }}
                                                />
                                            )}
                                        </div>
                                    )}
                                />
                                <FacebookLogin
                                    appId="833133827474078"
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    disableMobileRedirect={true}
                                    isMobile={false}
                                    render={(renderProps) => (
                                        <Box
                                            style={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                textAlign: "center",
                                                marginTop: 16,
                                                borderRadius: 48,
                                                cursor: "pointer",
                                                display: "flex",
                                                zIndex: 999999,
                                                flex: 1,
                                                height: "56px",
                                                backgroundColor:
                                                    "rgba(58, 40, 163, 0.9)",
                                            }}
                                            onClick={renderProps.onClick}
                                        >
                                            {!isLoading && <Facebook />}
                                            {isLoading && (
                                                <CircularProgress
                                                    size={24}
                                                    thickness={4}
                                                    sx={{ color: "#FFF" }}
                                                />
                                            )}
                                        </Box>
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid
                            xs={12}
                            md={10.5}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            {validatePassword && <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    color: "#FD607ACC",
                                    display: "flex",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center",
                                    whiteSpace: "break-spaces"
                                }}
                            >{`This email has already been used to create a Musii account via email.`}</Typography>}
                        </Grid>
                        <Grid
                            xs={12}
                            md={10.5}
                            marginBottom={"40px"}
                            style={{
                                marginTop: 16,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    handleClose();
                                    setShowModalConnect(true);
                                }}
                                fullWidth
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                    background: "#7272A3",
                                    borderRadius: "48px",
                                    padding: "16px 32px",
                                    width: !isMobile ? "468px" : "80vw",
                                    height: "56px",
                                    color: "#121229",
                                }}
                                variant="contained"
                            >
                                {t('btUseEmail')}
                            </Button>
                        </Grid>
                        <Grid xs={12} md={10}>
                            <Button
                                onClick={handleClose}
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"].h2BoldSize2,
                                    background: "transparent",
                                    borderRadius: "40px",
                                    padding: "16px 32px",
                                    color: "#7272A3",
                                }}
                                fullWidth
                                variant="text"
                            >
                                {t('createCollectionCheckButtomCancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}

export default AlertLogin;
