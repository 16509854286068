const Product = ({ size = "16" }) => {
    return (
        <svg
            width={`${size}`}
            height={`${size}`}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.4055 0.462962L28.3346 6.77323V6.77369C28.8518 7.06323 29.2812 7.48682 29.5775 8.00013C29.8739 8.51322 30.0261 9.09703 30.0181 9.68974V22.3103C30.0261 22.903 29.8739 23.4868 29.5775 24.0001C29.2814 24.5134 28.8518 24.937 28.3346 25.2265L17.4055 31.5368C16.8963 31.84 16.3148 32 15.7221 32C15.1294 32 14.5479 31.84 14.0387 31.5368L3.10951 25.2265C2.59232 24.937 2.16275 24.5132 1.86662 23.9999C1.57026 23.4866 1.41807 22.9027 1.42608 22.31V9.6895C1.41807 9.09679 1.57026 8.51297 1.86662 7.99966C2.16277 7.48633 2.59231 7.06272 3.10951 6.77323L14.0387 0.462962C14.5478 0.159968 15.1296 0 15.7221 0C16.3146 0 16.8963 0.159962 17.4055 0.462962ZM15.7218 2.00377C15.4809 2.00377 15.2442 2.06968 15.038 2.1944L4.42429 8.32247L9.06808 11.0034L20.3657 4.48075L16.4057 2.1944C16.1994 2.06968 15.9628 2.00377 15.7218 2.00377ZM22.3656 5.63546L11.0681 12.1581L15.722 14.8448L27.0196 8.32247L22.3656 5.63546ZM3.60092 22.9974C3.72152 23.2061 3.89705 23.3778 4.10828 23.4938L14.7221 29.6218V16.5766L3.42447 10.0536V22.3098C3.41944 22.5509 3.48031 22.7887 3.60092 22.9974ZM16.7218 29.6219L27.3356 23.4939C27.5469 23.3778 27.7224 23.2062 27.843 22.9975C27.9636 22.7888 28.0245 22.551 28.0194 22.3098V10.0537L16.7218 16.5766V29.6219Z"
                fill="#806FDC"
            />
        </svg>
    );
};

export default Product;
