import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box } from "@mui/material";
import Footer from "../components/Footer";

import AppComponent from "../components/AppComponent";

import Mock2 from "../assets/mock_2.png";
import Mock3 from "../assets/mock_3.png";
import Mock4 from "../assets/mock_4.png";
import Mock5 from "../assets/mock_5.png";

import DownloadApp from "../components/DownloadApp";
import { useTranslation } from "react-i18next";

const App = () => {
    const [showModalConnect, setShowModalConnect] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const [t, i18n] = useTranslation("common");

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            <DownloadApp isMobile={isMobile} />
            <AppComponent isMobile={isMobile} title={t('appSessionStartSharing')} description={t('appSessionStartSharingInfo')} image={Mock2} />
            <AppComponent isMobile={isMobile} reverse={true} title={t('appSessionTheMusicSaveYourDiscoveries')} description={t('appSessionTheMusicSaveYourDiscoveriesInfo')} image={Mock3} />
            <AppComponent isMobile={isMobile} title={t('appSessionStayAhead')} description={t('appSessionStayAheadInfo')} image={Mock4} />
            <AppComponent isMobile={isMobile} reverse={true} title={t('appSessionFindPeople')} description={t('appSessionFindPeopleInfo')} image={Mock5} />
            <Box marginBottom={"64px"}></Box>
            <Footer isMobile={isMobile} setShowModalConnect={setShowModalConnect} />
        </Box>
    );
};

export default App;
