const Play = ({ size = 96 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 88 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44 0C68.3005 0 88 19.6995 88 44C88 68.3005 68.3005 88 44 88C19.6995 88 0 68.3005 0 44C0 19.6995 19.6995 0 44 0Z"
                fill="#121229"
                fillOpacity="0.2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44 0C68.3005 0 88 19.6995 88 44C88 68.3005 68.3005 88 44 88C19.6995 88 0 68.3005 0 44C0 19.6995 19.6995 0 44 0ZM44 1.83333C20.712 1.83333 1.83333 20.712 1.83333 44C1.83333 67.288 20.712 86.1667 44 86.1667C67.288 86.1667 86.1667 67.288 86.1667 44C86.1667 20.712 67.288 1.83333 44 1.83333Z"
                fill="#EBEBFA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.0216 59.7724C34.9949 61.479 32.541 60.0809 32.541 56.6522V31.3502C32.541 27.9181 34.9949 26.5219 38.0216 28.2269L60.5202 40.9098C63.548 42.617 63.548 45.3829 60.5202 47.0897L38.0216 59.7724Z"
                fill="#EBEBFA"
            />
        </svg>
    );
};

export default Play;
