import {
    Box,
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    Popover,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import Close from "../../assets/close";
import Fonts from "../../utils/Fonts";

import axios from "axios";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import Dev from "../../utils/Dev";

function AlertPix({ isMobile, open, handleClose, nftPayment, nft }) {
    const { user } = useUser();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(0);
    const [progress, setProgress] = useState(0);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseCopied = () => {
        setAnchorEl(null);
    };

    const openCopied = Boolean(anchorEl);
    const id = openCopied ? 'simple-popover' : undefined;

    return (
        <>
            {open && (
                <Box>
                    <Box
                        style={{
                            position: "fixed",
                            width: "100vw",
                            height: "100vh",
                            background: "#121229",
                            mixBlendMode: "normal",
                            opacity: "0.75",
                            top: 0,
                            left: 0,
                            zIndex: 9999,
                        }}
                    ></Box>
                    <Grid
                        container
                        gap={isMobile ? "4px" : "16px"}
                        paddingX={isMobile ? 2 : 12}
                        paddingY={isMobile ? 2 : 4}
                        zIndex={99999}
                        style={{
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            minWidth: isMobile ? "95vw" : "900px",
                            maxWidth: isMobile ? "95vw" : "90vw",
                            borderRadius: "32px",
                            display: "flex",
                            width: "fit-content",
                            justifyContent: "center",
                            background:
                                "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                        }}
                    >
                        <Box
                            onClick={handleClose}
                            style={{
                                position: "absolute",
                                right: isMobile ? 16 : 64,
                                cursor: "pointer",
                            }}
                        >
                            <Close />
                        </Box>
                        <Grid xs={12} md={12}>
                            <Typography
                                style={{
                                    color: "#ABABCC",
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize1,
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                Siga os passos para finalizar sua compra com PIX
                            </Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={5.5}
                            marginY={"8px"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            gap={'8px'}
                        >

                            <QRCodeSVG value={nftPayment.data.codigo_pix}
                                size={isMobile ? 128 : 168}
                                includeMargin={true}
                                style={{
                                    borderRadius: "16px",
                                }} />
                            <Box display={"flex"}
                                flexDirection={isMobile ? "row" : "column"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                                <Typography
                                    style={{
                                        color: "#ABABCC",
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1RegularSize1,
                                        whiteSpace: "break-spaces",
                                    }}
                                >
                                    {nftPayment.data.codigo_pix.slice(0, isMobile ? 10 : 30)}...
                                </Typography>
                                <Button
                                    onClick={(e) => {
                                        handleClick(e)
                                        navigator.clipboard.writeText(nftPayment.data.codigo_pix)
                                        setTimeout(handleCloseCopied, 1500)
                                    }}
                                    style={{
                                        background:
                                            "#7272A3",
                                        borderRadius: "40px",
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h2RegularSize1,
                                        padding: isMobile ? "4px 8px" : "8px 16px",
                                        maxWidth: "400px",
                                        color: "#121229",
                                        marginLeft: isMobile ? "16px" : ""
                                    }}
                                    variant="contained"
                                >
                                    COPIAR CÓDIGO
                                </Button>
                            </Box>
                            <Popover
                                id={id}
                                open={openCopied}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                            >
                                <Typography
                                    style={{
                                        backgroundColor: "#121229",
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .l1LightSize1,
                                        color: "#F0F0FA",
                                        display: "flex",
                                        cursor: "pointer",
                                        userSelect: "none",
                                    }}
                                >PIX copiado!</Typography>
                            </Popover>
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            md={5.5}
                            marginY={"8px"}
                            justifyContent={"center"}
                            gap={isMobile ? '8px' : '24px'}
                        >
                            <Box display={"flex"}>
                                <Box style={{
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    marginRight: '16px'
                                }}>
                                    <Typography
                                        style={{
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .h2BoldSize2,
                                            color: "#ABABCC",
                                            display: "flex",
                                            userSelect: "none",
                                            textAlign: "center",
                                            borderRadius: '999px',
                                            padding: "8px 18px",
                                            border: ' 2px solid #ABABCC',

                                        }}
                                    >1
                                    </Typography>
                                </Box>
                                <Typography
                                    style={{
                                        ...isMobile ? Fonts["mobile"].l1RegularSize1 : Fonts["desktop"].b1RegularSize1,
                                        color: "#ABABCC",
                                        display: "flex",
                                        userSelect: "none",
                                    }}
                                >
                                    Abra o app ou site do seu banco e busque a opção de pagar com PIX.
                                </Typography>
                            </Box>
                            <Box display={"flex"}>
                                <Box style={{
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    marginRight: '16px'
                                }}>
                                    <Typography
                                        style={{
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .h2BoldSize2,
                                            color: "#ABABCC",
                                            display: "flex",
                                            userSelect: "none",
                                            textAlign: "center",
                                            borderRadius: '999px',
                                            padding: "8px 18px",
                                            border: ' 2px solid #ABABCC',

                                        }}
                                    >2
                                    </Typography>
                                </Box>
                                <Typography
                                    style={{
                                        ...isMobile ? Fonts["mobile"].l1RegularSize1 : Fonts["desktop"].b1RegularSize1,
                                        color: "#ABABCC",
                                        display: "flex",
                                        userSelect: "none",
                                    }}
                                >
                                    Escaneie o código QR com a câmera do seu celular ou copie e cole o código indicado. Confira todas as informações e, se tudo estiver certo, autorize o pagamento.

                                </Typography>
                            </Box>
                            <Box display={"flex"}>
                                <Box style={{
                                    display: "flex",
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    marginRight: '16px'
                                }}>
                                    <Typography
                                        style={{
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .h2BoldSize2,
                                            color: "#ABABCC",
                                            display: "flex",
                                            userSelect: "none",
                                            textAlign: "center",
                                            borderRadius: '999px',
                                            padding: "8px 18px",
                                            border: ' 2px solid #ABABCC',
                                        }}
                                    >3
                                    </Typography>
                                </Box>
                                <Typography
                                    style={{
                                        ...isMobile ? Fonts["mobile"].l1RegularSize1 : Fonts["desktop"].b1RegularSize1,
                                        color: "#ABABCC",
                                        display: "flex",
                                        userSelect: "none",
                                    }}
                                >
                                    Pronto! Caso a transação tiver sido concluída, você receberá sua NFT na aba de NFTs em Posse no seu Perfil.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={12} >
                            <LinearProgress color="inherit" variant="determinate" value={progress}
                                sx={{
                                    borderRadius: '32px',
                                    "&.MuiLinearProgress-root": {
                                        color: "#8930F0"
                                    }
                                }} />
                        </Grid>
                        <Grid
                            xs={12}
                            md={12}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <Typography
                                style={{
                                    ...isMobile ? Fonts["mobile"].l1LightSize1 : Fonts["desktop"].l1LightSize1,
                                    color: "#ABABCC",
                                    display: "flex",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center",
                                    whiteSpace: "break-spaces"
                                }}
                            >{isMobile ? `Código válido por 5 minutos. O pagamento será processado por FF Soluções Digitais e Pagamentos Ltda.` :
                                `Código válido por 5 minutos. O pagamento será processado por FF Soluções Digitais e Pagamentos Ltda.
Se o pagamento não for confirmado, não se preocupe. O pedido será cancelado automaticamente.`}</Typography>
                        </Grid>
                        <Grid
                            xs={12}
                            md={12}
                            marginBottom={4}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            {loading && (
                                <Button
                                    style={{
                                        background:
                                            "linear-gradient(130.54deg, rgba(140, 41, 217, 0.9) 0%, rgba(94, 71, 214, 0.9) 102.22%), rgba(235, 235, 250, 0.25)",
                                        borderRadius: "40px",
                                        fontFamily: "Ubuntu",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        lineHeight: "32px",
                                        padding: "16px 32px",
                                        maxWidth: "400px",
                                        color: "#FFFFFF",
                                    }}
                                    fullWidth
                                    variant="contained"
                                >
                                    <CircularProgress sx={{ color: "white" }} />
                                </Button>
                            )}
                            {!loading && (
                                <Button
                                    onClick={async () => {
                                        setLoading(true)
                                        try {

                                            let timeoutID = setInterval(function () {
                                               setProgress(progress => progress+2)
                                            }, 1000);

                                            await new Promise(resolve => setTimeout(resolve, 50000)); // 3 sec

                                            clearInterval(timeoutID);

                                            const response = await axios.post(
                                                `${Dev.api}nft/payment/check`,
                                                {
                                                    paymentId: nftPayment.id,
                                                    id: nft.id,
                                                    email: user.email,
                                                },
                                                {
                                                    headers: {
                                                        "Content-Type": "application/json;charset=utf-8"
                                                    },
                                                    crossDomain: true,
                                                }
                                            );

                                            setProgress(0)
                                            navigate("/payment-approved");
                                            setLoading(false)
                                        } catch (e) {
                                            setError(error + 1)
                                            setLoading(false)
                                        }
                                    }}
                                    style={{
                                        background:
                                            "linear-gradient(130.54deg, rgba(140, 41, 217, 0.9) 0%, rgba(94, 71, 214, 0.9) 102.22%), rgba(235, 235, 250, 0.25)",
                                        borderRadius: "40px",
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h2BoldSize2,
                                        padding: isMobile ? "8px 16px" : "16px 32px",
                                        maxWidth: "400px",
                                        color: "#FFFFFF",
                                    }}
                                    fullWidth={!isMobile}
                                    variant="contained"
                                >
                                    Confirmar Pagamento
                                </Button>
                            )}
                        </Grid>
                        <Grid
                            xs={12}
                            md={12}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            {error === 1 && <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    color: "#FD607ACC",
                                    display: "flex",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center",
                                    whiteSpace: "break-spaces"
                                }}
                            >{`Erro ao processar o pagamento
Clique novamente em alguns segundos.`}</Typography>}
                            {error > 1 && <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    color: "#FD607ACC",
                                    display: "flex",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    textAlign: "center",
                                    whiteSpace: "break-spaces"
                                }}
                            >{`Erro ao processar o pagamento, clique novamente em alguns segundos.
Caso o pagamento tenha sido realizado e este erro persistir, clique no botão de WhatsApp ou entre em contato por e-mail support@musii.app para que possamos te auxiliar.`}</Typography>}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}

export default AlertPix;
