import React from "react";

import Logo from "../assets/logo";
import Discord from "../assets/discord";
import Twitter from "../assets/twitter";
import Instagram from "../assets/instagram";
import Linkedin from "../assets/linkedin";
import Telegram from "../assets/telegram";

import { Box, Typography, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import Playstore from "../assets/playstore.png";
import Appstore from "../assets/appstore.png";
import Whatsapp from "../assets/whatsapp";
import { useUser } from "../hooks/useUser";
import { useTranslation } from "react-i18next";

function Footer({ isMobile, setShowModalConnect }) {
    const { user } = useUser();
    const [t, i18n] = useTranslation("common");
    const navigate = useNavigate();

    return (
        <Box
            backgroundColor="#211C45"
            paddingTop={2}
            paddingX={isMobile ? "32px" : "88px"}
        >
            <Grid
                container
                marginTop={6}
                gap={"28px"}
                maxWidth={isMobile ? "95vw" : "90vw"}
            >
                <Grid
                    xs={12}
                    md={5.6}
                    xl={7.3}
                    marginRight={isMobile ? "20px" : 12}
                >
                    <Logo />
                    <Typography
                        marginY={1}
                        fontFamily="Ubuntu"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="24px"
                        color="#EBEBFA"
                        marginTop={2}
                        marginBottom={5}
                    >
                        {t('footerCommnunity')}
                    </Typography>
                    <Box>
                        <a
                            aria-label="discord"
                            href="https://discord.com/invite/25X3kRYeAv"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                marginRight: 16,
                                marginTop: 32,
                            }}
                        >
                            <Discord size={isMobile ? 32 : 48} />
                        </a>
                        <a
                            aria-label="twitter"
                            href="https://twitter.com/musiiapp"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                marginRight: 16,
                            }}
                        >
                            <Twitter size={isMobile ? 32 : 48} />
                        </a>
                        <a
                            aria-label="telegram"
                            href="telegra.com"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                marginRight: 16,
                            }}
                        >
                            <Telegram size={isMobile ? 32 : 48} />
                        </a>
                        <a
                            aria-label="instagram"
                            href="https://www.instagram.com/musii.app/"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                                marginRight: 16,
                            }}
                        >
                            <Instagram size={isMobile ? 32 : 48} />
                        </a>
                        <a
                            aria-label="linkedin"
                            href="https://www.linkedin.com/company/37061330"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Linkedin size={isMobile ? 32 : 48} />
                        </a>
                    </Box>
                    <Box>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.musii.musii"
                            rel={"noreferrer"}
                            target="_blank"
                        >
                            <img
                                src={Playstore}
                                alt="playstore"
                                style={{
                                    marginRight: "40px",
                                    marginTop: "40px",
                                }}
                            />
                        </a>
                        <a
                            href="https://apps.apple.com/us/app/id1517893386"
                            rel={"noreferrer"}
                            target="_blank"
                        >
                            <img src={Appstore} style={{
                                marginTop: "24px",
                            }} alt="appstore" />
                        </a>
                    </Box>
                </Grid>
                {isMobile &&
                    <Grid container xs={12}>
                        <Grid xs={6}>
                            <Link
                                to="/"
                                aria-label="home"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    Home
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Link
                                to="/marketplace"
                                aria-label="marketplace"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('menuMarketplace')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Link
                                to="/about"
                                aria-label="marketplace"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('menuAbout')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Link
                                to="/app"
                                aria-label="marketplace"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('menuApp')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Link
                                to="/about#mission"
                                aria-label="mission"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerMission')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Link
                                to="/about#team"
                                aria-label="team"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerMission')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Link
                                to="/about#partners"
                                aria-label="partners"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerPartners')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Link
                                to="/#faq"
                                aria-label="faq"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerFAQ')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <a
                                href="https://api.whatsapp.com/send?phone=5511951564022&text=Olá, me chamo XXX e estou precisando de ajuda com o Musii."
                                target={"_blank"}
                                rel={"noreferrer"}
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerSupport')}
                                </Typography>
                            </a>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Typography
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint");
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                marginY={1}
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="32px"
                                color="#F0F0FA"
                            >
                                {t('footerSellNFT')}
                            </Typography>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Typography
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        navigate("/profile", { state: { selected: 0 } })
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                marginY={1}
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="32px"
                                color="#F0F0FA"
                            >
                                {t('footerMyCollections')}
                            </Typography>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Typography
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        navigate("/profile", { state: { selected: 0 } })
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                marginY={1}
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="32px"
                                color="#F0F0FA"
                            >
                                {t('footerNFTsHold')}
                            </Typography>
                        </Grid>
                        <Grid gap={2} xs={6}>
                            <Typography
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        navigate("/profile", { state: { selected: 4 } })
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                marginY={1}
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="32px"
                                color="#F0F0FA"
                            >
                                {t('footerProfile')}
                            </Typography>
                        </Grid>
                        <Grid gap={2} xs={6}>
                        </Grid>
                    </Grid>
                }
                {!isMobile &&
                    <Grid xs={12} md={"auto"}>
                        <Grid gap={2}>
                            <Link
                                to="/"
                                aria-label="home"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    Home
                                </Typography>
                            </Link>
                            <Link
                                to="/marketplace"
                                aria-label="marketplace"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('menuMarketplace')}
                                </Typography>
                            </Link>
                            <Link
                                to="/about"
                                aria-label="about"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('menuAbout')}
                                </Typography>
                            </Link>
                            <Link
                                to="/app"
                                aria-label="app"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('menuApp')}
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>}
                {!isMobile &&
                    <Grid xs={12} md={"auto"}>
                        <Grid gap={2}>
                            <Link
                                to="/about#mission"
                                aria-label="mission"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerMission')}
                                </Typography>
                            </Link>
                            <Link
                                to="/about#team"
                                aria-label="team"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerTeam')}
                                </Typography>
                            </Link>
                            <Link
                                to="/about#partners"
                                aria-label="partners"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerPartners')}
                                </Typography>
                            </Link>
                            <Link
                                to="/#faq"
                                aria-label="faq"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"
                                >
                                    {t('footerFAQ')}
                                </Typography>
                            </Link>
                            <a
                                href="https://api.whatsapp.com/send?phone=5511951564022&text=Olá, me chamo XXX e estou precisando de ajuda com o Musii."
                                target={"_blank"}
                                rel={"noreferrer"}
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    marginY={1}
                                    fontFamily="Ubuntu"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="16px"
                                    lineHeight="32px"
                                    color="#F0F0FA"

                                >
                                    {t('footerSupport')}
                                </Typography>
                            </a>
                        </Grid>
                    </Grid>
                }
                {!isMobile &&
                    <Grid xs={12} md={"auto"}>
                        <Grid gap={2}>
                            <Typography
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint"); navigate("/mint");
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                marginY={1}
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="32px"
                                color="#F0F0FA"
                            >
                                {t('footerSellNFT')}
                            </Typography>
                            <Typography
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        navigate("/profile", { state: { selected: 0 } })
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                marginY={1}
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="32px"
                                color="#F0F0FA"
                            >
                                {t('footerMyCollections')}
                            </Typography>
                            <Typography
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        navigate("/profile", { state: { selected: 1 } })
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                marginY={1}
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="32px"
                                color="#F0F0FA"
                            >
                                {t('footerNFTsHold')}
                            </Typography>
                            <Typography
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        navigate("/profile", { state: { selected: 4 } })
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                                style={{
                                    cursor: "pointer"
                                }}
                                marginY={1}
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="32px"
                                color="#F0F0FA"
                            >
                                {t('footerProfile')}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Typography
                color="#EBEBFA"
                fontFamily="Ubuntu"
                fontStyle="normal"
                fontWeight="400"
                fontSize="18px"
                lineHeight="32px"
                marginBottom={1}
                marginTop={"40px"}
            >
                Disclaimer
            </Typography>
            <Typography
                color="#EBEBFA"
                fontFamily="Ubuntu"
                fontStyle="normal"
                fontWeight="300"
                fontSize="10px"
                lineHeight="24px"
                marginTop={"8px"}
            >
                {t('footerDisclaimer')}
            </Typography>
            <Box
                padding={"32px 0px"}
                marginTop={"40px"}
                borderTop={0.5}
                borderColor={"#F6F5FC80"}
                style={{ zIndex: 999 }}
            >
                <Grid container>
                    <Grid xs={12} md={8.5}>
                        <Typography
                            color="#EBEBFA"
                            fontFamily="Ubuntu"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="24px"
                        >
                            {t('footerCopyright')}
                        </Typography>
                    </Grid>
                    <Grid xs={5.8} md={1.8}>
                        <a
                            href="./files/termos.pdf"
                            target={"_blank"}
                            rel={"noreferrer"}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                color="#EBEBFA"
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="24px"
                                textAlign={isMobile ? 'left' : 'right'}
                                marginTop={isMobile ? "32px" : ""}
                            >
                                {t('footerTerms')}
                            </Typography>
                        </a>
                    </Grid>
                    <Grid xs={5.5} md={1.5}>
                        <a
                            href="./files/privacidade.pdf"
                            target={"_blank"}
                            rel={"noreferrer"}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                marginLeft={"20px"}
                                color="#EBEBFA"
                                fontFamily="Ubuntu"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="24px"
                                textAlign={'right'}
                                marginTop={isMobile ? "32px" : ""}
                            >
                                {t('footerPrivacy')}
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </Box>
            <Box
                style={{
                    position: "fixed",
                    display: "flex",
                    zIndex: 9999,
                    right: "14px",
                    bottom: "23px",
                    cursor: "pointer",
                }}
            >
                <a
                    href="https://api.whatsapp.com/send?phone=5511951564022&text=Olá, me chamo XXX e estou precisando de ajuda com o Musii."
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    <Whatsapp />
                </a>
            </Box>
        </Box>
    );
}

export default Footer;
