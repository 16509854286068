import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import NftCardAdmin from "./commons/NftCardAdmin";
import Dev from "../utils/Dev";

const ProfileMenuAdmin = () => {

    const [width, setWidth] = useState(window.innerWidth);
    const [nfts, setNfts] = useState([]);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        async function getNfts() {
            const response = await axios.get(
                `${Dev.api}nft/all`)

            setNfts(response.data.Items)
        }

        getNfts()

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box>
            <Grid container spacing={3} padding={isMobile ? "0px 32px" : "0px 128px"} marginBottom={"64px"}>
                <Grid item sm={12} md={12}>
                    <Grid container spacing={3} padding={0}>
                        {nfts.map((nft, index) => (
                            <NftCardAdmin
                                key={index}
                                index={index}
                                nft={nft}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};


export default ProfileMenuAdmin;
