import { Card, Typography, CardContent, Button, Box } from "@mui/material";
import Fonts from "../../utils/Fonts";
import TypeNfts from "../../utils/TypeNfts";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/useUser";

export default function MintNftCard({
    image = "",
    name = "",
    price = 0,
    totalSuply = 0,
    categorie = "COLLECTIBLES",
    subCategorie = "Collectible",
    artistName = "Artistic Name",
    artistPhoto = "",
    isMobile,
}) {
    const { cotation } = useUser();
    const [t, i18n] = useTranslation("common");

    return (
        <Card
            style={{
                position: "relative",
                borderRadius: 32,
                backgroundColor: "#121229",
                width: "276px",
                height: "426px",
            }}
        >
            <Box
                style={{
                    position: "absolute",
                    top: "2px",
                    left: "24px",
                }}
            >
                <Box
                    style={{
                        backgroundColor:
                            TypeNfts[categorie.toLowerCase()].backgroundColor,
                        padding: "8px 16px",
                        borderRadius: 32,
                        display: "inline-flex",
                        alignItems: "center",
                        marginTop: "24px",
                        marginBottom: "32px"
                    }}
                >
                    <Box
                        style={{
                            marginRight: "8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {TypeNfts[categorie.toLowerCase()].image}
                    </Box>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .b1BoldSize2,
                            color: TypeNfts[categorie.toLowerCase()].color,
                        }}
                    >
                        {subCategorie}
                    </Typography>
                </Box>
            </Box>
            {image !== "" &&
                <img
                    alt={"cover"}
                    style={{
                        width: "276px",
                        height: "276px"
                    }}
                    src={image}
                />}
            {image === "" &&
                <Box
                    style={{
                        width: "276px",
                        height: "276px"
                    }}
                />}

            <CardContent
                style={{
                    background:
                        "linear-gradient(130.54deg, rgba(158, 158, 184, 0.125) 0%, rgba(24, 24, 46, 0.25) 102.22%), rgba(55, 55, 92, 0.5)",
                    padding: "16px 24px",
                    position: "relative",
                }}
            >
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                >
                    <img
                        width={"40px"}
                        height={"40px"}
                        src={artistPhoto}
                        style={{ borderRadius: 99, zIndex: 99 }}
                        alt="cover"
                    />
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        marginLeft={2}
                    >
                        <Box
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                top: 0,
                                left: 0,
                                backgroundImage: `url('${image}')`,
                                backgroundPosition: "bottom",
                                filter: "blur(56px)",
                            }}
                        ></Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#F0F0FA",
                                zIndex: 99,
                            }}
                        >
                            {name === '' ? t('createCollectionCardCollectionName') : name.length < 12 ? name : `${name.slice(0, 12)}...`}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1RegularSize1,
                                color: "#F0F0FA",
                                zIndex: 99,
                            }}
                        >
                            {artistName.length < 12 ? artistName : `${artistName.slice(0, 12)}...`}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"16px"}
                >
                    <Box display={"flex"} flexDirection={"column"}>
                        <Box
                            display={"flex"}
                            style={{
                                zIndex: 99,
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1BoldSize1,
                                    color: "#F0F0FA",
                                    zIndex: 99,
                                }}
                            >
                                R$ {price ? Math.floor(price) : "0"}
                            </Typography>
                        </Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize2,
                                color: "#ABABCC",
                                zIndex: 99,
                            }}
                        >
                            {cotation === "-" ? cotation : parseFloat(price / cotation) > 1 ? parseFloat(price / cotation).toFixed(0) : parseFloat(price / cotation).toFixed(2)} USDT
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        marginLeft={"16px"}
                        flex={1}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1BoldSize1,
                                color: "#F0F0FA",
                                zIndex: 99,
                            }}
                        >
                            {totalSuply ? totalSuply : "0"}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize2,
                                color: "#ABABCC",
                                zIndex: 99,
                            }}
                        >
                            {t('nftCardAvailable')}
                        </Typography>
                    </Box>
                    <Button
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .b1BoldSize1,
                            marginLeft: "12px",
                            background:
                                "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                            borderRadius: "40px",
                            padding: "8px 16px",
                            color: "#F0F0FA",
                        }}
                        variant="contained"
                    >
                        {t('nftCardBuy')}
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}
