import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Box, Button, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";

import Faq from "../components/Faq";
import AboutUs from "../components/AboutUs";
import OurMission from "../components/OurMission";
import SeaLionCollection from "../components/SeaLionCollection";
import Fonts from "../utils/Fonts";
import Team from "../components/Team";
import Partners from "../components/Partners";
import { useUser } from "../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const About = () => {
    const [showModalConnect, setShowModalConnect] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const [t, i18n] = useTranslation("common");

    const { user } = useUser();
    const navigate = useNavigate();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            <AboutUs isMobile={isMobile} />
            <OurMission isMobile={isMobile} />
            <SeaLionCollection isMobile={isMobile} />
            <Team isMobile={isMobile} />
            <Partners isMobile={isMobile} />
            <Grid
                container
                gap={"32px"}
                justifyContent={"center"}
            >
                <Grid
                    xs={12}
                    md={9}
                    xl={9}
                    padding="32px 40px"
                    marginTop={"104px"}
                    borderRadius={8}
                    display={"flex"}
                    justifyContent={"center"}
                    style={{
                        background: "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)"
                    }}
                >
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        flexDirection={isMobile ? "column" : "row"}
                    >
                        <Box maxWidth={"75%"}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h1BoldSize1,
                                    color: "#EBEBFA",
                                    whiteSpace: "break-spaces",
                                    marginTop: 4,
                                    marginBottom: 4,
                                }}
                            >
                                {t('bannerCtaT1')}
                            </Typography>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3RegularSize1,
                                    color: "#EBEBFA",
                                }}
                            >
                                {t('bannerCtaT2')}
                            </Typography>
                        </Box>

                        <Button
                            style={{
                                marginLeft: isMobile ? 0 : "56px",
                                width: "169px",
                                maxHeight: "40px",
                                background:
                                    "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                borderRadius: "40px",
                                padding: "12px 32px",
                                gap: "8px",
                                flex: "none",
                                order: 1,
                                flexGrow: 0,
                            }}
                            variant="contained"
                            onClick={() => {
                                if (user && (user.email || user.name)) {
                                    isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint");
                                } else {
                                    setShowModalConnect(true)
                                }
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3BoldSize1,
                                    color: "#F0F0FA",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {t('bannerCtaButton')}
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Faq />
            <Footer isMobile={isMobile} setShowModalConnect={setShowModalConnect} />
        </Box>
    );
};

export default About;
