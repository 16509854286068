const Linkedin = ({size = 48}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM13.7143 16.408C13.7143 14.9232 14.9232 13.7143 16.408 13.7143C17.893 13.7143 19.102 14.9232 19.102 16.408C19.102 17.893 17.893 19.102 16.408 19.102C14.9232 19.102 13.7143 17.893 13.7143 16.408ZM25.4692 21.88C26.2758 20.9693 27.3527 20.0815 29.1426 20.0815L29.1427 20.0816C31.0324 20.0816 32.3426 20.6014 33.1605 21.5587C33.9782 22.5159 34.2854 23.8679 34.2854 25.4694L34.2856 34.0407C34.2856 34.169 34.1689 34.2857 34.0406 34.2857H29.8774C29.7491 34.2857 29.6325 34.169 29.6325 34.0407V26.6939C29.6325 26.0245 29.4247 25.3956 29.1043 24.9491C28.7838 24.5022 28.3663 24.2449 27.9181 24.2449H27.4283C26.9801 24.2449 26.5626 24.5022 26.2421 24.9489C25.9216 25.3956 25.7141 26.0242 25.7141 26.6937V34.0406C25.7141 34.1689 25.5974 34.2855 25.4691 34.2855H21.306C21.1777 34.2855 21.061 34.1689 21.061 34.0406V20.8162C21.061 20.688 21.1777 20.5713 21.306 20.5713H25.2244C25.3527 20.5713 25.4693 20.688 25.4693 20.8162L25.4692 21.88ZM14.403 20.5714C14.2907 20.5928 14.2019 20.7019 14.204 20.8162V34.0406C14.204 34.1688 14.3206 34.2855 14.4489 34.2855H18.6121C18.7404 34.2855 18.8571 34.1688 18.8571 34.0406V20.8162C18.8571 20.6879 18.7404 20.5713 18.6121 20.5713H14.403L14.403 20.5714Z"
                fill="#EBEBFA"
            />
        </svg>
    );
};

export default Linkedin;
