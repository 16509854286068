const Google = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5165 12.2655C23.5165 11.279 23.4365 10.5591 23.2632 9.8125H11.998V14.2652H18.6105C18.4772 15.3717 17.7573 17.0382 16.1575 18.158L16.135 18.3071L19.6969 21.0664L19.9436 21.091C22.21 18.9979 23.5165 15.9183 23.5165 12.2655Z" fill="#4285F4" />
            <path d="M11.9979 23.9965C15.2374 23.9965 17.957 22.9299 19.9435 21.0902L16.1573 18.1572C15.1441 18.8638 13.7843 19.357 11.9979 19.357C8.82501 19.357 6.13206 17.264 5.17209 14.3711L5.03138 14.383L1.32773 17.2493L1.2793 17.384C3.25235 21.3034 7.30515 23.9965 11.9979 23.9965Z" fill="#34A853" />
            <path d="M5.1726 14.3721C4.9193 13.6255 4.77271 12.8256 4.77271 11.9991C4.77271 11.1724 4.9193 10.3726 5.15927 9.62602L5.15256 9.46702L1.40249 6.55469L1.2798 6.61305C0.46661 8.23952 0 10.066 0 11.9991C0 13.9321 0.46661 15.7585 1.2798 17.385L5.1726 14.3721Z" fill="#FBBC05" />
            <path d="M11.9979 4.63935C14.2509 4.63935 15.7707 5.61255 16.6373 6.42583L20.0235 3.11959C17.9438 1.18651 15.2374 0 11.9979 0C7.30515 0 3.25235 2.69296 1.2793 6.6124L5.15877 9.62538C6.13206 6.73243 8.82501 4.63935 11.9979 4.63935Z" fill="#EB4335" />
        </svg>
    );
};

export default Google;
