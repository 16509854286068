import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import Fonts from "../../utils/Fonts";
import { Check } from "@mui/icons-material";

import "react-phone-number-input/style.css";

import PhoneInput from "react-phone-number-input";

import axios from "axios";
import MusiiCheckbox from "../commons/MusiiCheckbox";
import { useUser } from "../../hooks/useUser";
import Storage from "../../utils/Storage";
import StorageEnum from "../../enums/StorageEnum";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";


const Step1 = ({ setStep }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const { user, logIn } = useUser();
    const [t, i18n] = useTranslation("common");

    const [nameField, setName] = useState("");
    const [emailField, setEmail] = useState("");
    const [phoneField, setPhone] = useState("");
    const [documentField, setDocument] = useState("");

    const [documentError, setDocumentError] = useState(false);

    const [zipCodeField, setZipCode] = useState("");
    const [addressField, setAddress] = useState("");
    const [numberField, setNumber] = useState("");
    const [complementField, setComplement] = useState("");
    const [neighborhoodField, setNeighborhood] = useState("");
    const [cityField, setCity] = useState("");
    const [stateField, setState] = useState("");
    const [countryField, setCounty] = useState("");

    const [canClick, setCanClick] = useState(false);

    const [acceptedBox1, setAcceptedBox1] = useState(false);
    const [acceptedBox2, setAcceptedBox2] = useState(false);

    async function saveFields() {
        await axios.put(
            `${Dev.api}nft/user/${user.email}`,
            {
                name: nameField,
                email: emailField,
                phone: phoneField,
                document: documentField,
                zipCode: zipCodeField,
                address: addressField,
                number: numberField,
                neighborhood: neighborhoodField,
                city: cityField,
                state: stateField,
                country: countryField,
                complement: complementField
            },
            {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                crossDomain: true,
            }
        );

        await Storage.setItem(StorageEnum.USER, {
            ...user,
            name: nameField,
            email: emailField,
            phone: phoneField,
            document: documentField,
            zipCode: zipCodeField,
            address: addressField,
            number: numberField,
            neighborhood: neighborhoodField,
            city: cityField,
            state: stateField,
            country: countryField,
            complement: complementField
        });

        logIn()
    }

    useEffect(() => {
        if (nameField !== "" &&
            emailField !== "" &&
            phoneField &&
            phoneField !== "" &&
            documentField !== "" &&
            zipCodeField !== "" &&
            addressField !== "" &&
            numberField !== "" &&
            neighborhoodField !== "" &&
            cityField !== "" &&
            stateField !== "" &&
            countryField !== "" &&
            acceptedBox1 && acceptedBox2
        ) {
            saveFields()
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [nameField, emailField, phoneField, documentField, zipCodeField, addressField, numberField, neighborhoodField, cityField, stateField, countryField, acceptedBox1, acceptedBox2]);

    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setName(user.name);
        setEmail(user.email);
        setPhone(user.phone);
        setDocument(user.document);

        setZipCode(user.zipCode);
        setAddress(user.address);
        setNumber(user.number);
        setNeighborhood(user.neighborhood);
        setCity(user.city);
        setState(user.state);
        setCounty(user.country);
        setComplement(user.complement);

    }, [user]);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <Box>
            <Grid container position="relative" xs={12} marginBottom={12}>
                <Grid
                    container
                    paddingRight={isMobile ? 4 : 0}
                    paddingLeft={isMobile ? 4 : 8}
                    marginTop={6}
                    gap={8}
                    xs={12}
                    md={10}
                    lg={10}
                    xl={9}
                    display={"flex"}
                    justifyContent={isMobile ? "center" : ""}
                >
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"20px"}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('btLogIn')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#7272A3",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    2
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#CACAE5",
                                }}
                            >
                                {t('purchaseFlowPersonalDataIcon')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "rgba(114, 114, 163, 0.4)",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "rgba(114, 114, 163, 0.4)",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "rgba(114, 114, 163, 0.4)",
                                }}
                            >
                                {t('purchaseFlowPaymentIcon')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                textAlign: "left",
                                padding: "16px 0px",
                            }}
                        >
                            {t('purchaseFlowPersonalDataTitle')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                color: "#7272A3",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 0px",
                            }}
                        >
                            {t('purchaseFlowPersonalDataInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={8}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowName')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowNamePlaceholder')}
                            value={nameField}
                            onChange={(e) => setName(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowEmail')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowEmailPlaceholder')}
                            value={emailField}
                            type="email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowEmailInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowPhone')}*
                        </Typography>
                        <PhoneInput
                            value={phoneField}
                            onChange={setPhone}
                            placeholder={t('purchaseFlowPhonePlaceholder')}
                            id={"phonenumberfield"}
                            numberInputProps={{
                                style: {
                                    boxShadow: 0,
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    border: "0px solid transparent",
                                    backgroundColor: "transparent",
                                    color: "#B8B8DB",
                                },
                            }}
                            style={{
                                boxShadow: 0,
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                padding: "16.5px 20px",
                                border: "0px solid transparent",
                                color: "#B8B8DB",
                                borderRadius: "24px",
                                borderBottomWidth: 0,
                                background:
                                    "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                            }}
                            placeholderColor="red"
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowPhoneInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowCPForId')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowCPForIdInfo')}
                            value={documentField}
                            type="cpf"
                            error={documentError}
                            helperText={documentError ? t('cpfError') : ""}
                            onBlur={(e) => {
                                let cpf = e.target.value;

                                cpf = cpf.replace(/[^\d]+/g, '');
                                if (cpf == '') {
                                    setDocumentError(true)
                                    return false
                                };
                                // Elimina CPFs invalidos conhecidos	
                                if (cpf.length != 11 ||
                                    cpf == "00000000000" ||
                                    cpf == "11111111111" ||
                                    cpf == "22222222222" ||
                                    cpf == "33333333333" ||
                                    cpf == "44444444444" ||
                                    cpf == "55555555555" ||
                                    cpf == "66666666666" ||
                                    cpf == "77777777777" ||
                                    cpf == "88888888888" ||
                                    cpf == "99999999999") {
                                    setDocumentError(true)
                                    return false
                                }
                                // Valida 1o digito	
                                let add = 0;
                                for (let i = 0; i < 9; i++)
                                    add += parseInt(cpf.charAt(i)) * (10 - i);
                                let rev = 11 - (add % 11);
                                if (rev == 10 || rev == 11)
                                    rev = 0;
                                if (rev != parseInt(cpf.charAt(9))) {
                                    setDocumentError(true)
                                    return false
                                }
                                // Valida 2o digito	
                                add = 0;
                                for (let i = 0; i < 10; i++)
                                    add += parseInt(cpf.charAt(i)) * (11 - i);
                                rev = 11 - (add % 11);
                                if (rev == 10 || rev == 11)
                                    rev = 0;
                                if (rev != parseInt(cpf.charAt(10))) {
                                    setDocumentError(true)
                                    return false
                                }
                                setDocumentError(false)
                                return true;

                            }}
                            onChange={(e) => {
                                setDocument(e.target.value);
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowCPForIdInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowZipCode')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowZipCodePlaceholder')}

                            value={zipCodeField}
                            onBlur={async () => {
                                if (zipCodeField.replace(/[^0-9]/gm, "").length === 8) {
                                    const response = await axios.get(`https://viacep.com.br/ws/${zipCodeField.replace(/[^0-9]/gm, '')}/json/`,
                                        {
                                            headers: {
                                                'Content-Type': 'application/json;charset=utf-8'
                                            },
                                            crossDomain: true
                                        });

                                    setAddress(response.data.logradouro)
                                    setNeighborhood(response.data.bairro)
                                    setCity(response.data.localidade)
                                    setState(response.data.uf)
                                    setCounty("Brasil")
                                }
                            }}
                            onChange={(e) => setZipCode(e.target.value?.replaceAll("-", ""))}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowAddress')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowAddressPlaceholder')}
                            value={addressField}
                            onChange={(e) => setAddress(e.target.value)}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowAddressNumber')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={"123"}
                            value={numberField}
                            onChange={(e) => setNumber(e.target.value)}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>

                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowAddressComplement')}
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowAddressComplementPlaceholder')}
                            value={complementField}
                            onChange={(e) => setComplement(e.target.value)}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>

                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowAddressNeighborhood')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowAddressNeighborhoodPlaceholder')}
                            value={neighborhoodField}
                            onChange={(e) => setNeighborhood(e.target.value)}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>

                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowAddressCity')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowAddressCityPlaceholder')}
                            value={cityField}
                            onChange={(e) => setCity(e.target.value)}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>

                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowAddressState')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowAddressStatePlaceholder')}
                            value={stateField}
                            onChange={(e) => setState(e.target.value)}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>

                    <Grid
                        xs={12}
                        md={3.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('purchaseFlowAddressCountry')}*
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('purchaseFlowAddressCountryPlaceholder')}
                            value={countryField}
                            onChange={(e) => setCounty(e.target.value)}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={10}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}

                        >
                            <MusiiCheckbox
                                checked={acceptedBox1}
                                onChange={(e) => {
                                    setAcceptedBox1(e.target.checked);
                                }}
                            />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#7272A3",
                                }}
                            >
                                {t('purchaseFlowCheckSharingData')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={10}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}

                        >
                            <MusiiCheckbox
                                checked={acceptedBox2}
                                onChange={(e) => {
                                    setAcceptedBox2(e.target.checked);
                                }}
                            />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2RegularSize1,
                                    color: "#7272A3",
                                }}
                            >
                                {t('purchaseFlowCheckAgreeTerms')}
                            </Typography>
                        </Box>
                    </Grid>
                    {documentError &&
                        <Grid xs={10}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3RegularSize1,
                                    color: "#FB5E87",
                                }}
                            >
                                {t('cpfError')}
                            </Typography>
                        </Grid>}
                    <Grid
                        marginTop={4}
                        xs={8}
                        md={10}
                    >
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: canClick
                                    ? "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)"
                                    : "#B8B8DB",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                if (canClick) {
                                    setStep(1);
                                }
                            }}
                            variant="contained"
                        >
                            {t('createCollectionCardNext')}
                        </Button>
                    </Grid>
                </Grid>


            </Grid>
        </Box>
    );
};

export default Step1;
