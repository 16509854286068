import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Close from "../../assets/close";
import Check from "../../assets/check";

function AlertSuccess({
    isMobile,
    open,
    handleClose,
    title,
}) {
    return (
        <>
            {open && (
                <Box>
                    <Box
                        style={{
                            position: "fixed",
                            width: "100vw",
                            height: "100vh",
                            background: "#121229",
                            mixBlendMode: "normal",
                            opacity: "0.75",
                            top: 0,
                            left: 0,
                            zIndex: 99999,
                        }}
                    ></Box>
                    <Grid
                        container
                        gap={isMobile ? 4 : "16px"}
                        paddingX={isMobile ? 0 : 12}
                        paddingY={4}
                        zIndex={999999}
                        style={{
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            maxWidth: "90vw",
                            borderRadius: "32px",
                            display:"flex",
                            width: "fit-content",
                            justifyContent: "center",
                            background:
                                "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                        }}
                    >
                        <Box
                            onClick={handleClose}
                            style={{
                                position: "absolute",
                                right: 64,
                                cursor: "pointer",
                            }}
                        >
                            <Close />
                        </Box>
                        <Grid>
                            <Check />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Typography
                                style={{
                                    color: "#EBEBFA",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    fontSize: "32px",
                                    lineHeight: "48px",
                                    whiteSpace: "break-spaces",
                                    textAlign: "center",
                                }}
                            >
                                {title}
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={5} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "16px" }}>
                            <Button
                                onClick={handleClose}
                                style={{
                                    background:
                                    "linear-gradient(130.54deg, rgba(140, 41, 217, 0.9) 0%, rgba(94, 71, 214, 0.9) 102.22%), rgba(235, 235, 250, 0.25)",
                                    borderRadius: "40px",
                                    fontFamily: "Ubuntu",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "32px",
                                    padding: isMobile ? "16px 64px" : "16px 32px",
                                    color: "#FFFFFF",
                                }}
                                fullWidth={isMobile ? false : true}
                                variant="contained"
                            >
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}

export default AlertSuccess;
