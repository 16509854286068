import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Fonts from "../utils/Fonts";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const faq = [
    {
        id: "faq1",
        title: "faq1T1",
        response:"faq1B1",
    },
    {
        id: "faq2",
        title: "faq2T1",
        response:"faq2B1",
    },
    {
        id: "faq3",
        title: "faq3T1",
        response:"faq3B1",
    },
    {
        id: "faq4",
        title: "faq4T1",
        response:"faq4B1",
    },
    {
        id: "faq5",
        title: "faq5T1",
        response:"faq5B1",
    },
    {
        id: "faq6",
        title: "faq6T1",
        response:"faq6B1",
    },
    {
        id: "faq7",
        title: "faq7T1",
        response:"faq7B1",
    },
    {
        id: "faq8",
        title: "faq8T1",
        response:"faq8B1",
    },
    {
        id: "faq9",
        title: "faq9T1",
        response:"faq9B1",
    },
    {
        id: "faq10",
        title: "faq10T1",
        response:"faq10B1",
    },
    {
        id: "faq11",
        title: "faq11T1",
        response:"faq11B1",
    },
    {
        id: "faq12",
        title: "faq12T1",
        response:"faq12B1",
    },
];

function Faq({ isMobile }) {
    const [t, i18n] = useTranslation("common");
    const { hash } = useLocation();

    const [selected, setSelected] = useState(hash ? faq.findIndex((item, index) => { if (item.id === hash.replace("#", "")) { return index } return false }) : -1);

    return (
        <Box
            id={"faq"}
            display={"flex"}
            justifyContent={"center"}
            paddingTop={isMobile ? "20px" : "56px"}
            marginY={"32px"}
            paddingBottom={isMobile ? "20px" : "112px"}
        >
            <Grid
                container
                marginLeft={isMobile ? "0px" : 8}
                gap={isMobile ? 4 : "56px"}
                maxWidth={"80vw"}
                justifyContent={"center"}
            >
                <Grid
                    xs={12}
                    md={12}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize2,
                            color: "#ABABCC",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('faqT1')}
                    </Typography>
                    <Box
                        style={{
                            width: "35px",
                            height: "6px",
                            background:
                                "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                            borderRadius: "3px",
                            marginTop: "8px",
                            marginBottom: "16px",
                        }}
                    ></Box>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('faqT1Title')}
                    </Typography>
                </Grid>
                {faq.map((item, index) => (
                    <Grid
                        xs={12}
                        md={12}
                        xl={10}
                        borderRadius={8}
                        style={{
                            cursor: "pointer",
                        }}
                        id={item.id}
                        onClick={() =>
                            index === selected
                                ? setSelected(-1)
                                : setSelected(index)
                        }
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#F0F0FA",
                                whiteSpace: "break-spaces",
                            }}
                        >
                            {selected === index
                                ? t(item.title).replace("+", "-")
                                : t(item.title)}
                        </Typography>
                        {selected === index && (
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3RegularSize1,
                                    color: "#ABABCC",
                                    whiteSpace: "break-spaces",
                                }}
                            >
                                {t(item.response)}
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Faq;
