const Search = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="10" r="5.5" stroke="#ABABCC" stroke-width="3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6732 15.403C13.1407 14.7684 13.2234 13.8222 13.8581 13.2897C14.4927 12.7572 15.4388 12.84 15.9713 13.4746L20.4708 18.8369C21.0033 19.4715 20.9205 20.4177 20.2859 20.9502C19.6513 21.4827 18.7052 21.3999 18.1727 20.7653L13.6732 15.403Z" fill="#ABABCC" />
        </svg>
    );
};

export default Search;
