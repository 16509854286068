import React, { useEffect, useState } from "react";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import Fonts from "../../utils/Fonts";
import { Check } from "@mui/icons-material";
import Play from "../../assets/play";
import MintNftCard from "../commons/MintNftCard";

import WalletConnected from "../../assets/walletConnected";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";

const Step3 = ({ setStep, nft, setNft }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    const [accountAddress,] = useState(nft.wallet);
    const [comments, setComments] = useState(nft.recusedReason);

    useEffect(() => {
        setNft({ ...nft, recusedReason: comments })
    }, [comments]);

    const isMobile = width <= 768;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <Box>
            <Grid container position="relative" xs={12} marginBottom={12}>
                <Grid xs={12} md={12}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('createCollectionTitle')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    paddingRight={isMobile ? 4 : 0}
                    paddingLeft={isMobile ? 4 : 8}
                    marginTop={6}
                    gap={8}
                    xs={12}
                    md={10}
                    lg={10}
                    xl={9}
                    display={"flex"}
                    justifyContent={isMobile ? "center" : ""}
                >
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"20px"}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                                cursor: "pointer",
                            }}
                            onClick={() => setStep(0)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('createCollectionStepProfile')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                                cursor: "pointer",
                            }}
                            onClick={() => setStep(1)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('createCollectionStepCollection')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#7272A3",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#ABABCC",
                                }}
                            >
                                {t('createCollectionStepWallet')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 0px",
                                whiteSpace: "break-spaces",
                                display: "flex",
                                alignItems: "flex-end",
                            }}
                        >
                            Created at: {nft.createdAt}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder={"Comments"}
                            fullWidth
                            multiline
                            type="text"
                            value={comments}
                            onChange={(e) => {
                                setComments(e.target.value);
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 0px",
                                whiteSpace: "break-spaces",
                                display: "flex",
                                alignItems: "flex-end",
                            }}
                        >
                            {t('createCollectionPayOff')}
                        </Typography>
                        <Link
                            to="/tutorial-create-wallet"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    color: "#7272A3",
                                    textAlign: "left",
                                }}
                            >
                                {t('createCollectionPayOffInfo')}
                            </Typography>
                        </Link>
                        <Link
                            to="/#faq9"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[
                                        isMobile ? "mobile" : "desktop"
                                    ].b1BoldSize1,
                                    color: "#7272A3",
                                    textAlign: "left",
                                    cursor: "pointer",
                                }}
                            >
                                {t('createCollectionPayOffInfoLearnMore')}
                            </Typography>
                        </Link>
                    </Grid>
                    {nft.wallet && (
                        <Grid
                            xs={12}
                            md={8}
                            display={"flex"}
                            flexDirection={"column"}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <WalletConnected />
                                <Typography
                                    size="small"
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        marginLeft: 3,
                                        color: "#5CB88D",
                                    }}
                                >
                                    {t('createCollectionWalletConnected')}
                                </Typography>
                            </Box>
                            <Typography
                                size="small"
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1RegularSize1,
                                    color: "#5CB88D",
                                }}
                            >
                                {nft.wallet}
                            </Typography>
                        </Grid>
                    )}
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        alignItems="center"
                    >
                        <Play size="46" />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1RegularSize1,
                                color: "#ABABCC",
                                marginTop: 8,
                                textAlign: "left",
                                marginLeft: "24px",
                            }}
                        >
                            {t('createCollectionTutorial')}
                        </Typography>
                    </Grid>
                </Grid>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Grid
                        marginTop={4}
                        gap={8}
                        xs={8}
                        md={2}
                        style={{
                            position: isMobile ? "unset" : "fixed",
                            right: 86,
                            top: "30%",
                            transform: isMobile ? "" : "translateY(-20%)",
                        }}
                    >
                        <MintNftCard
                            image={nft.imageField}
                            name={nft.nameField}
                            price={nft.priceField}
                            totalSuply={nft.totalSuplyField}
                            categorie={nft.categorieField}
                            subCategorie={nft.subCategorieField}
                            artistName={nft.artistNameField}
                            artistPhoto={nft.artistPhotoField}
                            isMobile={isMobile}
                        />
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                const response = await axios.put(
                                    `${Dev.api}nft/approve/id`,
                                    {
                                        id: nft.id,
                                        email: "admin@musii.app",
                                    },
                                    {
                                        headers: {
                                            "Content-Type": "application/json;charset=utf-8",
                                        },
                                        crossDomain: true,
                                    }
                                );

                                navigate("/profile");
                            }}
                            variant="contained"
                        >
                            Approve
                        </Button>
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                const remove = {}

                                nft.recusedData.map(item =>
                                    remove[item] = ""
                                )

                                const body = {
                                    id: nft.id,
                                    email: "admin@musii.app",
                                    recusedReason: comments,
                                    recusedData: nft.recusedData,
                                    remove
                                }

                                const response = await axios.put(
                                    `${Dev.api}nft/reprove/id`,
                                    body,
                                    {
                                        headers: {
                                            "Content-Type": "application/json;charset=utf-8",
                                        },
                                        crossDomain: true,
                                    }
                                );

                                console.log(response)
                                navigate("/profile");
                            }}
                            variant="contained"
                        >
                            Recuse
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
};

export default Step3;
