import React, { useCallback, useState } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";

import { styled } from "@mui/material/styles";

import WhiteWallet from "../assets/whiteWallet";
import Play from "../assets/play";

import { Link, useNavigate } from "react-router-dom";
import Fonts from "../utils/Fonts";
import TypeNfts from "../utils/TypeNfts";
import Verified from "../assets/verified";
import Pause from "../assets/pause";
import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useUser } from "../hooks/useUser";

import Gif from "../assets/gif_new_release.gif"
import Capa from "../assets/capa_colecao.png"
import Dev from "../utils/Dev";

const MusiiLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    width: "40%",
    borderRadius: 8,
    marginLeft: 16,
    backgroundColor: "rgba(18, 18, 41, 0.25)",
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 8,
        backgroundColor: "#EBEBFA",
    },
}));

function Featured({ isMobile }) {
    const navigate = useNavigate();
    const videoRef = useCallback((x) => (x ? (x.volume = 0.4) : ""), []);
    const [t, i18n] = useTranslation("common");

    const { cotation } = useUser();
    const [counter, setCounter] = useState(0)
    const [featured, setFeatured] = useState({
        id: "baseados-digitais",
        image: Gif,
        video: "https://nfts-site-assets.s3.us-east-2.amazonaws.com/PlantaRaiz/1/1.mp4",
        name: "Baseados Digitais",

        categorie: "collectibles",
        subCategorie: "Itens Variados",

        price: "300-1000",
        totalSold: 12,
        totalSuply: 100,
        status: "ACTIVE",

        artistName: "Planta e Raiz",
        artistEmail: "planta-e-raiz",
        artistPhoto: Capa
    });

    const [play, setPlay] = useState(false);

    useEffect(() => {
        const loadInfos = async () => {
            axios.get(
                `${Dev.api}nft/tag?tag=Planta e Raiz`).then(value => {
                    let counter = 0
                    value.data.Items.map(item => {
                        if (item.totalSold === 1 || item.totalSold === "1") {
                            counter++
                        }
                    })
                    setCounter(counter)
                })

            /*const responseNft = await axios.get(
                `${Dev.api}nft/featured`)*/

            //setFeatured(responseNft.data[0])
        };

        loadInfos();
    }, []);

    return (
        <Grid
            container
            padding={isMobile ? "24px" : "56px 64px"}
            style={{ maxWidth: "100vw" }}
            position={"relative"}
            display={"flex"}
            justifyContent={"center"}
            
        >
            {featured?.name &&
                <Grid
                    container
                    xs={12}
                    md={12}
                    xl={10}
                    padding={isMobile ? "24px" : "40px"}
                    style={{
                        boxSizing: "border-box",
                        background: `linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)`,
                        boxShadow: "0px 4px 16px rgba(4, 4, 20, 0.5)",
                        outline: "1px solid",
                        outlineColor:
                            "rgba(171, 171, 204, 0.1)",
                    }}
                    borderRadius={"48px"}
                >
                    <Grid xs={12} md={"auto"}>
                        <Link
                            aria-label="nft"
                            //to={`/nft/${featured.id}`}
                            to={`/${featured.id}`}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {!play && (
                                <img
                                    width={"100%"}
                                    src={featured.image}
                                    style={{
                                        borderRadius: 32,
                                        width: isMobile ? "100%" : "360px",
                                    }}
                                    alt="cover"
                                />
                            )}
                            {play && (
                                <video
                                    ref={videoRef}
                                    style={{
                                        width: isMobile ? "100%" : "360px",
                                        borderRadius: 32,
                                    }}
                                    autoPlay={true}
                                    controls={true}
                                    src={featured.video}
                                    alt="Musii"
                                    playsInline
                                />
                            )}
                        </Link>
                    </Grid>
                    <Grid xs={12} md marginLeft={isMobile ? "0px" : "56px"}>
                        <Box>
                            <Link
                                aria-label="nft"
                                //to={`/nft/${featured.id}`}
                                to={`/${featured.id}`}
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h1BoldSize1,
                                        color: "#EBEBFA",
                                        marginTop: isMobile ? "16px" : 0,
                                    }}
                                >
                                    {featured.name}
                                </Typography>
                            </Link>
                            <Box
                                display={"flex"}
                                justifyItems={"center"}
                                alignItems={"center"}
                                marginTop={2}
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    //navigate(`/user/${featured.artistName}`, {
                                    navigate(`/${featured.artistEmail}`, {
                                        state: {
                                            user: {
                                                name: featured.artistName,
                                                photo: featured.artistPhoto,
                                                email: featured.artistEmail
                                            }
                                        },
                                    })
                                }
                            >
                                <img
                                    width={"32px"}
                                    height={"32px"}
                                    src={featured.artistPhoto}
                                    style={{ borderRadius: 99 }}
                                    alt="cover"
                                />
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h2RegularSize1,
                                        color: "#EBEBFA",
                                        marginRight: 8,
                                        marginLeft: 12,
                                    }}
                                >
                                    {featured.artistName}
                                </Typography>
                                <Verified />
                            </Box>
                            <Box
                                style={{
                                    backgroundColor:
                                        TypeNfts[featured.categorie.toLowerCase()].backgroundColor,
                                    padding: "8px 16px",
                                    borderRadius: 32,
                                    display: "inline-flex",
                                    alignItems: "center",
                                    marginTop: "24px",
                                    marginBottom: "32px",
                                }}
                            >
                                <Box
                                    style={{
                                        marginRight: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {TypeNfts[featured.categorie.toLowerCase()].image}
                                </Box>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .b1BoldSize2,
                                        color: TypeNfts[featured.categorie.toLowerCase()].color,
                                    }}
                                >
                                    {featured.subCategorie}
                                </Typography>
                            </Box>
                            <Box
                                display={"flex"}
                                justifyItems={"center"}
                                alignItems={"center"}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h2BoldSize2,
                                        color: "#EBEBFA",
                                        marginRight: 8,
                                        marginLeft: 12,
                                    }}
                                >
                                    {counter}
                                </Typography>
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h2RegularSize1,
                                        color: "#C5C5DB",
                                    }}
                                >
                                    / {featured.totalSuply} {t('purchaseFlowCollectionEditions')}
                                </Typography>

                                <MusiiLinearProgress
                                    variant="determinate"
                                    value={
                                        (parseInt(counter) / parseInt(featured.totalSuply)) *
                                        100
                                    }
                                />
                            </Box>

                            {isMobile && (
                                <Typography
                                    style={{
                                        position: "absolute",
                                        top: "56px",
                                        right: "56px",
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h2BoldSize2,
                                        color: "#C5C5DB",
                                        padding: "8px 16px",
                                        background: "#121229CC",
                                        borderRadius: "16px",
                                    }}
                                >
                                    {t('tagCardNewRelease')}
                                </Typography>
                            )}

                            <Box
                                display={"flex"}
                                flexDirection={isMobile ? "column" : "row"}
                                alignItems={isMobile ? "left" : "flex-end"}
                                marginTop={"32px"}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={isMobile ? "flex-start" : ""}
                                    backgroundColor="rgba(18, 18, 41, 0.25)"
                                    padding="16px 24px"
                                    width={"fit-content"}
                                    borderRadius={8}
                                >
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2BoldSize1,
                                            color: "#EBEBFA",
                                        }}
                                    >
                                        A partir de R$300
                                    </Typography>
                                </Box>
                                {/*
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={isMobile ? "flex-start" : ""}
                                    backgroundColor="rgba(18, 18, 41, 0.25)"
                                    padding="16px 24px"
                                    width={"fit-content"}
                                    borderRadius={8}
                                >
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile ? "mobile" : "desktop"
                                                ].h2BoldSize1,
                                                color: "#EBEBFA",
                                            }}
                                        >
                                            R$ {featured.price}.00
                                        </Typography>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile ? "mobile" : "desktop"
                                                ].h2RegularSize1,
                                                color: "#F0F0FA",
                                                marginLeft: "4px",
                                            }}
                                        >
                                            BRL
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile ? "mobile" : "desktop"
                                                ].h3RegularSize1,
                                                color: "#ABABCC",
                                                marginLeft: "2px",
                                            }}
                                        >
                                            {cotation === "-" ? cotation : parseFloat(featured.price / cotation).toFixed(2)}
                                        </Typography>
                                        <Typography
                                            style={{
                                                ...Fonts[
                                                    isMobile ? "mobile" : "desktop"
                                                ].h3RegularSize1,
                                                color: "#7272A3",
                                                marginLeft: "4px",
                                            }}
                                        >
                                            USDT
                                        </Typography>
                                    </Box>
                                        </Box>*/}

                                <Link
                                    aria-label="nft"
                                    //to={`/nft/${featured.id}`}
                                    to={`/${featured.id}`}
                                    rel="noreferrer"
                                    style={{
                                        textDecoration: "none",
                                        height: "96px",
                                        marginBottom: isMobile ? "24px" : ""
                                    }}
                                >
                                    <Button
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2BoldSize1,
                                            marginLeft: isMobile ? 0 : 64,
                                            marginTop: isMobile ? "24px" : 0,
                                            marginBottom: isMobile ? "24px" : 0,
                                            background:
                                                "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                            borderRadius: "999px",
                                            padding: "24px 48px",
                                            height: isMobile ? "" : "96px",
                                        }}
                                        startIcon={<WhiteWallet />}
                                        variant="contained"
                                    >
                                        {parseInt(counter) >= parseInt(featured.totalSuply)
                                            ? t('nftCardSold')
                                            : t('nftCardBuy')}
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    {!isMobile && (
                        <Grid
                            xs={12}
                            md={"auto"}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-end",
                            }}
                        >
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#C5C5DB",
                                    padding: "8px 16px",
                                    background: "rgba(18, 18, 41, 0.25)",
                                    borderRadius: "16px",
                                }}
                            >
                                {t('tagCardNewRelease')}
                            </Typography>
                            <Box
                                display="flex"
                                justifyContent={"right"}
                                style={{ flex: 1, alignItems: "flex-end", cursor: "pointer" }}
                                onClick={() => {
                                    setPlay(!play)
                                }}
                            >
                                {!play && <Play />}
                                {play && <Pause />}
                            </Box>
                        </Grid>
                    )}
                </Grid>}
        </Grid>
    );
}

export default Featured;
