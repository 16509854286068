const Online = () => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6.16536" cy="6.16536" r="5.83333" fill="#5CB88D" />
        </svg>
    );
};

export default Online;
