const en = {
  menuMarketplace: 'Marketplace',
  menuApp: 'App',
  menuAbout: 'About',
  searchBar: 'Search',
  btLogIn: 'Log In',
  btUseEmail: 'USE AN EMAIL',
  btForgotPassword: 'Forgot password?',
  textFieldPassword: 'Password',
  logInDesc: 'Log in or create account with:',
  heroT1: 'A new way to earn with music',
  heroT2: 'Musii is the platform where artists engage their fans and supporters through the sale of collectible NFTs with products, exclusive experiences and music royalties',
  heroCtaArtists: 'BECOME A CREATOR',
  heroCtaFans: 'START COLLECTING',

  tagCardNewRelease: 'NEW RELEASE',
  tagCardUpcommingRelease: 'UPCOMMING RELEASE',
  tagCardUpcommingReleaseDays: 'Days',
  tagCardUpcommingReleaseHours: 'Hours',
  tagCardUpcommingReleaseMin: 'Min',
  tagCardUpcommingReleaseSec: 'Sec',
  //tagCardEditions: 'editions',
  //tagCardCtaBuy: 'BUY',

  categoryCollectible: 'COLLECTIBLES',
  categoryProduct: 'PRODUCTS',
  categoryExperience: 'EXPERIENCES',
  categoryRoyalties: 'ROYALTIES',
  Collectible: 'Collectible',
  CD: 'CD',
  Vinyl: 'Vinyl',
  'Autographed Items': 'Autographed Items',
  Merchandising: 'Merchandising',
  Phygital: 'Phygital',
  'Show Ticket': 'Show Ticket',
  'Studio Session': 'Studio Session',
  Membership: 'Membership',
  'Early Access': 'Early Access',
  'Lesson / Workshop': 'Lesson / Workshop',
  'Gift Video': 'Gift Video',
  'Meet-and-Greet': 'Meet-and-Greet',
  'Q&A Forum': 'Q&A Forum',
  Backstage: 'Backstage',

  liveCollectionsT1: 'Marketplace',
  liveCollectionsT2: 'Live Collections',
  nftCardBuy: 'BUY',
  nftCardSold: 'SOLD OUT',
  nftCardAvailable: 'available',
  viewAll: 'View All',

  secondarySalesT1: 'Marketplace',//TODO
  secondarySalesT2: 'Secondary Sales',//TODO

  whatSellT1: 'What Can Artists Sell on Musii?',
  whatSellT2: 'Categories of Benefits',
  whatSellB1: 'By purchasing the NFTs, fans can acquire benefits according with the category set on the item, as in the examples below:',
  //categoryCollectiblesT1: 'COLLECTIBLES',
  categoryCollectiblesB1: 'Digital Art\nExclusive images\nPersonal archive',
  //categoryProductsT1: 'PRODUCTS',
  categoryProductsB1: 'CD / Vinyl\nAutographed items\nMerchandising\nPhygitals',
  //categoryExperiencesT1: 'EXPERIENCES',
  categoryExperiencesB1: 'Tickets for shows\nStudio sessions\nMemberships\nEarly access to new releases\nLessons / Workshops\nGift videos\nMeet-and-greet\nQ&A forums\nBackstage access',
  //categoryRoyaltiesT1: 'ROYALTIES',
  categoryRoyaltiesB1: 'Anticipation of royalties from streaming platforms\n\nCOMING SOON',

  verifiedArtistsT2: 'Verified Artists:',

  howWorksT1: 'How It Works',
  howWorksT2: 'Engage Your Fanbase and Make a New Profit Source',
  step1T1: 'Step 01',
  step1T2: 'Send the informations',
  step1B1: 'Fill out the details about you and the item you’d like to sell.',
  step2T1: 'Step 02',
  step2T2: 'Send to approval',
  step2B1: 'We will verify if the information sent to us is legit and if you have the rights of the song.',
  step3T1: 'Step 03',
  step3T2: 'Start to sell',
  step3B1: 'Once you receive the approval, open the sale and spread your offer out to your fans!',

  bannerCtaT1: 'Start to engage your fans',
  bannerCtaT2: 'Create a community sense within your fanbase and be supported by the ones that truly believe in your career',
  bannerCtaButton: 'CREATE NOW',

  faqT1: 'FAQ',
  faqT1Title: 'Frequently Asked Questions',
  faq1T1: '+ What is Musii?',
  faq1B1: 'Musii is a platform that provide to artists the possibility to engage their fanbase and monetize their careers in new ways, by selling different categories of NFTs.',
  faq2T1: '+ What are NFTs?',
  faq2B1: 'NFTs are unique digital items stored forever on the internet (blockchain) that contain immutable information and offer ownership, creatorship and authenticity. This way, NFTs cannot be forged or changed without the permission of the creator. These unique items can be exchanged among buyers and, due to their limited supply, they can appreciate in value. On Musii, artists can sell NFTs to their fans that can have benefits (utilities) attached to them, such as physical products and experiences.',
  faq3T1: '+ How to buy NFTs on Musii?',
  faq3B1: 'You can check a step by step tutorial video of how to buy an NFT here.\nTo buy an NFT on musii follow the next steps:\n1. Choose the desired item.\n2. Select the payment method:\na. Crypto wallet: connect your Algorand wallet (Defly or Pera), recharge the wallet balance with the amount needed and accept all transactions required if you are in accordance with. This transactions can take a couple of minutes to conclude, so once you press to accept, be a little patient.\nb. PIX: scan or copy/paste the transaction code on your bank app and finalize the process there. You’ll receive a confirmation of the payment on your email address.',
  faq4T1: '+ How can artists sell NFTs on Musii?',
  faq4B1: '1. Click to start to ‘Sell NFTs’ on your profile or on the home page.\n2. Fill out the details about you and the item you’d like to sell.\n3. Send to approval. We will verify if the information sent to us is legit and if you have the rights of the song.\n4. Once you receive the approval, open the sale and spread your offer out to your fans!',
  faq5T1: '+ What kind of NFTs categories can artists sell on Musii?',
  faq5B1: `Currently Musii provides 3 options of categories for the NFTs:\n- Collectibles: NFTs that are unique digital art of collections made by the artists, but don’t come with any extra benefit. Just an parallel to illustrate: in the past people used to collect and hang posters of their favorite bands on their bedrooms walls, today many people collect NFTs as they did with their posters.\n- Products: NFTs that come together with physical products, such as CD's / Vinyl's, autographed items, merchandising and phygitals. In this way, these NFTs also works as certificates of authenticity for those items.\n- Experiences: NFTs that work as access to real life experiences, such as tickets for shows, studio session memberships, early access to new releases, lessons / workshops, gift video, meet-and-greet, Q&A forums amd backstage access.\n- Royalties: This category is not available yet, but we should include it as soon as possible. On this case the NFTs represent a percentage of the streaming royalties, so every time it make earning on the distributed platforms, each buyer automatically  receives their respective part.`,
  faq6T1: '+ How collections with benefits works?',
  faq6B1: 'If they desire to do so, creators can offer benefits related to their collection. The delivery and/or execution of such benefits to buyers are the sole and exclusive responsibility of the creator, and Musii assumes no responsibility for the delivery and/or execution of these benefits. When offering benefits, creators must include a deadline, which will be visible on the platform on the offer page, to fulfill the delivery and/or execution of these benefits.',
  faq7T1: '+ What are the editions of the NFT collections?',
  faq7B1: 'When an NFT collection with editions is created, the total number of editions (total supply) available is defined by its creator. For example, there may only be 100 issues of an NFT collection for a specific artwork. Each edition is assigned an individual NFT and has a unique identifier that distinguishes it from other editions. In this way, NFTs from collections with few editions tend to be rarer, as there is a more restricted amount of them available on the market.',
  faq8T1: '+ What kind of song can artists include on the NFT?',
  faq8B1: 'Artists can use just songs that they hold the rights of. To prove that, we ask for some evidences that you have the ownership to commercialize it: screenshot of the distributor page, the production process and the ISRC with collaborators royalties sheet.',
  faq9T1: '+ How the payments are made on the platform?',
  faq9B1: 'As an artist selling Collections on Musii, you’ll need to provide us an Algorand Wallet to receive the pay-offs. The transaction are made instantly as fans are buying your items. All payments are made in USDT.\nUSDT is a stablecoin cryptocurrency of  Tether, that represents one US dollar. It offers artists a stable and convenient digital payment method, reducing volatility compared to other cryptocurrencies.\nIf you’d like to change your earnings to Real (BRL), you’ll be able to do that through a third party platform on your profile or any exchange you prefer, that accepts these currencies.',
  faq10T1: '+ How Musii charges?',
  faq10B1: `Musii deducts from the creator 5% of the value of each primary sale according to the price set by the artist on the platform. In addition, Musii charges a 5% service fee from the buyer on each transaction based on the price entered by the artist.
Fees for a secondary sales are 10 %, with 5 % of the value going to the creator and another 5 % going to Musii.`,
  faq11T1: '+ What rights do I obtain by purchasing an NFT on Musii?',
  faq11B1: 'Buyers only have the ownership of the NFT and the advantages of the benefits that are explicit on the NFT description (if they are still available and the item was acquired on the primary market). This mean they don’t own any rights of author or intellectual property of the song, in accordance with the laws.\nBe aware that if you get an NFT on the secondary market, the benefits of the item (if there are any) can have already been used, and we cannot give any guarantee that the seller will transfer them to you (such as any kind of product or experience that have already happened).',
  faq12T1: '+ What are the guidelines for an NFT to be approved on Musii?',
  faq12B1: 'Musii is a music platform and therefore we only accept NFTs that are about that theme. These are the standard requirements to sell NFTs on Musii:\n- Be about music: All Collections have a thumbnail image and a video in the content. This video must contain the artist music playing on the background.\n- Have the rights: The sellers must have all the copyrights of the materials provided.\n- Appropriate quality and content: NFT artwork and content must be appropriate, respecting Musii guidelines.\n- Be unique: For your nft to keep gaining value through time and have the potential to be resold it must be scarce, this means the collection must be a once in a lifetime opportunity for buyers to own this specific collection. Therefore one condition is to not replicate this art or combination of art and perk on the blockchain ever again.',

  footerCommnunity: 'We are a community with tens of thousands of users connected by music.\nIf you want to be part of this community, download our app and join our social networks.',
  footerDisclaimer: `None of the cost citations, data, news and inquire about available in this website, our application or shared through any communication channel by Musii or its Associates constitute advice, proposals or sales of any offer to purchase or offer any asset, copyright, speculation or investment items. Past performances are't a ensure of future returns, nor is it fundamentally demonstrative of future performance. The pictures and images within the site and the app are only illustrative and may contain information that are no longer update or valid. Creators can offer benefits related to their collection. The delivery and/or execution of such benefits to buyers are the sole and exclusive responsibility of the creator, and Musii assumes no responsibility for the delivery and/or execution of these benefits.`,
  footerHome: 'Home',
  //footerMarketplace: 'Marketplace',
  //footerAbout: 'About',
  //footerApp: 'App',
  footerMission: 'Mission',
  footerTeam: 'Team',
  footerPartners: 'Partners',
  footerFAQ: 'FAQ',
  footerSupport: 'Support',
  footerSellNFT: 'Sell NFT',
  footerMyCollections: 'My Collections',
  footerNFTsHold: 'NFTs Hold',
  footerProfile: 'Profile',
  footerTerms: 'Terms & Conditions',
  footerPrivacy: 'Privacy Policy',
  footerCopyright: 'Copyright © 2023, Musii - A new way to earn with music',

  aboutUs: 'About Us',
  aboutUsTitle: 'We Encourage Artists to Make Music',
  aboutUsDesc: `We want people to be able to choose to follow what they like. We believe that any artist can make a living from music.

For those who are afraid to pursue a music career as it is difficult to make a living in the music industry, Musii makes it possible for new and emerging artists to sell NFTs to those who believe in their talent, helping these artists achieve more success.`,
  ourMission: 'Our mission',
  ourMissionTitle: 'Democratizing The Music Market',
  ourMissionDesc: `Musii was born with the mission to help independent artists. Our founders personally know the pain of this market and how centralized the music industry is.

Established artists are able to benefit from current algorithms and big players, but emerging and independent artists face a huge difficulty to be discovered. Musii is changing this problem.`,
  musiiSeaLionsTitle: 'Musii Sea Lions',
  musiiSeaLionsDescription: 'Musii Sea Lions is an NFT collection (ARC-19) created by our team with the aim of engaging the web3 community in the noble cause of helping independent artists, democratizing the music industry and letting more people know about Musii.',
  musiiSeaLionsCTA1: 'COLLECT ON SHUFFLE',
  musiiSeaLionsCTA2: 'Learn more',
  ourSquad: 'Our squad',
  ourSquadTitle: 'Team mebmers',
  partners: 'Partners',
  partnersTitle: 'We are partnered with top brands',  

  appSessionTheMusicSocialMedia: 'The Music Social Media',
  appSessionTheMusicSocialMediaInfo: 'We are a community of thousands of fans, musicians, composers, songwriters, producers, all connected by one purpose: promote good music. Try out our app:',
  appSessionStartSharing: 'Start sharing music in the right place',
  appSessionStartSharingInfo: 'Music supposed to be enjoyed together. On Musii people actually listen to the songs you’re sharing. Choose one from over 100 million tracks, add a caption with your thoughts and gain relevance when people interact with you to become a music influencer.',
  appSessionTheMusicSaveYourDiscoveries: 'Save your discoveries directly on your Spotify',
  appSessionTheMusicSaveYourDiscoveriesInfo: 'Expand your musical repertoire like you never did before. Discover new songs and artists that you never heard in seconds. Save all your findings to a playlist in one click with our Spotify integration.',
  appSessionStayAhead: 'Stay ahead of what’s going on in the industry scene',
  appSessionStayAheadInfo: 'Check out the most trending tracks of the community, who are the new talents on the rise and be among the first to know who are the gems outside the traditional circle.',
  appSessionFindPeople: 'Find people that match  your musical taste',
  appSessionFindPeopleInfo: 'Discover people similar to you, take part on your music tribe and get closer to your favorite artists. Engage, chat, celebrate and experience music in new ways.',

  profileLogOut: 'LOG OUT',
  profileCTAAddOffer: 'ADD OFFER',
  profileCTAAddOfferMobile: "Available on PC only",
  profileNFTsHold: 'NFTs Hold',
  profileMyCollections: 'My Collections',
  profileMyCollectionsStatusDeclined: 'DECLINED',
  profileMyCollectionsStatusPending: 'PENDING',
  profileMyCollectionsStatusWaitingApproval: 'WAITING APPROVAL',
  profileMyCollectionsStatusWaitingWallet: 'WAITING WALLET',
  profileMyCollectionsStatusReview: 'REVIEW',
  //profileMyCollectionsSoldOut: 'SOLD OUT',
  profileCollections: 'Collections',
  profileProfile: 'Profile',
  profileNFTsHoldStatusView: 'VIEW',
  profileNFTsHoldStatusRedeem: 'REDEEM',
  profileNFTsHoldEmptyWallet: 'Empty Wallet',
  profileNFTsHoldEmptyWalletMessage: `Sorry, we’ve couldn't found any assets on this wallet.`,
  profileNFTsHoldCTAConnectWallet: 'CONNECT WALLET',
  //profileNFTsHoldCTAStartCollecting: 'START COLLECTING',
  editProfileUpdatePFP: 'Update Profile Picture',
  editProfileDisconnect: 'DISCONNECT',
  editProfileName: 'Name',
  editProfileNamePlaceholder: 'How would you like to be called?',
  editProfileBio: 'Bio',
  editProfileBioPlaceholder: 'Write here something about you.',
  editProfileConnectWallet: 'Connect Wallet',
  editProfileConnectWalletInfo: 'This will be used to show the NFTs you have on your profile and to accelerate your purchases process with wallet. You can only connect one wallet per time.',
  editProfileGenres: 'Genres',
  editProfileSocial: 'Social:',
  editProfileHiddenInformation: 'Hidden Information: ',
  editProfileEmail: 'Email',
  editProfileEmailPlaceholder: 'your@email.com',
  editProfilePhone: 'Phone',
  editProfilePhonePlaceholder: '+1 123 456 789',
  editProfileCPForId: 'CPF, CNPJ or ID',
  editProfileCPForIdPlaceholder: '123 456 789',
  editProfileButtonEdit: 'EDIT',
  editProfileSuccessConfirmation: 'Profile Updated',
  editProfileButtonSuccessConfirmation: 'OK',

  createCollectionTitle: 'Create Collection',
  createCollectionStepProfile: 'Profile',
  createCollectionStepCollection: 'Collection',
  createCollectionStepWallet: 'Wallet',
  //createCollectionUpdatePFP: 'Update Profile Picture',
  createCollectionArtisticName: 'Artistic Name',
  createCollectionArtisticNamePlaceholder: 'Artistic Name',
  createCollectionArtisticNameInfo: 'Must be the same denomination you use on the streaming platforms.',
  createCollectionEmail: 'Email',
  createCollectionEmailPlaceholder: 'Email',
  createCollectionEmailInfo: 'Please provide a valid email address so we can contact you, if needed.',
  createCollectionPhone: 'Phone',
  createCollectionPhonePlaceholder: 'Phone Number',
  createCollectionPhoneInfo: 'Please provide a valid phone so we can contact you, if needed.',
  createCollectionCPForId: 'CPF, CNPJ or ID',
  createCollectionCPForIdPlaceholder: '123 456 789',
  createCollectionCPForIdInfo: 'Please provide the whole number with digit.',
  createCollectionMandatoryFiled: 'This field is mandatory.',
  createCollectionCardCollectionName: 'Collection Name',
  //createCollectionCardAvailable: 'available',
  //createCollectionCardBuy: 'BUY',
  //createCollectionCardCollectibleStep1: 'Collectible',
  createCollectionCardNext: 'NEXT',
  createCollectionCardSend: 'SEND TO APPROVAL',
  createCollectionIncludeBenefits: 'Include benefits?',
  createCollectionIncludeBenefitsInfo: 'Musicians can attach special benefits to their NFTs on Musii, as physical products and real life experiences. If you choose to not include any benefit, your NFT will be classified as a ‘Collectible’. If you’d like to include any, open the box below and select it.',
  createCollectionCategories: 'Categories:',
  createCollectionCategoriesCollectiblesInfo: `Any NFT that does not include benefits is classified in musii as 'Collectible'.`,
  createCollectionCategoriesProductsInfo: `Sell unique products with your NFT. This category includes physical objects such as the ones listed above.`,
  createCollectionCategoriesProductsInfo2: `The delivery of these benefits is the seller's responsibility(the buyers' address will be collected for this purpose).`,
  createCollectionCategoriesExperiencesInfo: `Sell tickets and special access to real life experiences.`,
  createCollectionCategoriesExperiencesInfo2: `The production of the event or experience is your sole responsibility.`,
  createCollectionCategoriesRoyaltiesInfo: `COMMING SOON. \nIf you are interested in selling the royalties of your song, `,
  createCollectionCategoriesRoyaltiesInfo2: `click here to contact us.`,
  createCollectionThumbnail: 'NFT Thumbnail',
  createCollectionThumbnailInfo: 'Include the cover picture of your NFT. It will appear on pages when your video is not playing.',
  createCollectionVideo: 'Video (content)',
  createCollectionVideoInfo: 'Include the video (content) of your NFT.',
  createCollectionVideoInfo2: 'Be aware that it must have your song playing on the background.',
  createCollectionUploadFile: 'UPLOAD FILE',
  createCollectionUploadErrorSquare: 'The file you sent is not square. We suggest for you to send one with 512px x 512px.',
  createCollectionName: 'Collection Name',
  createCollectionNamePlaceHolder: 'Collection Name',
  createCollectionNameInfo: 'Be creative and give a remarkable name for your collection.',
  createCollectionDescription: 'Description',
  createCollectionDescriptionPlaceHolder: 'Description',
  createCollectionDescriptionInfo: 'Talk about the collection and the project behind of it. In case there is any benefit, don’t forget to describe it’s details and conditions, such as place/date/time of events, delivery area, expiration date to request the products, or the way the fan will receive it. Give as much details as possible to avoid misinterpretations. These information will be included on the NFT’s metadata, this mean it will work as the “contract clauses” of your offer.',
  createCollectionTags: 'Tags',
  createCollectionTagsPlaceHolder: 'Add tags for your NFT(s) to be found',
  createCollectionTagsInfo: 'Fill out each tag separated with a comma.',
  createCollectionExternalURL: 'External URL',
  createCollectionExternalURLPlaceHolder: 'Link',
  createCollectionExternalURLInfo: 'Include a link so folks can find more about you and your project.',
  createCollectionTotalSupply: 'Total Supply',
  createCollectionTotalSupplyPlaceHolder: '0',
  createCollectionTotalSupplyInfo: 'The number of editions this collection will have.',
  createCollectionPrice: 'Price',
  createCollectionPricePlaceHolder: 'R$0.00',
  createCollectionPriceInfo: 'The price for each edition/item of your collection. Remember to consider our platform fee of 5%.',
  createCollectionLegitimacyVerification: 'Legitimacy verification:',
  createCollectionLegitimacyVerificationInfo: 'In order to sell your collection as an artist on the platform, we will need you to send us the following information to verify the legitimacy of your work and career. This will prove that you really have the rights to commercially exploit this song to be offered as an NFT. If needed, we also may ask for confirmations via DM on your social media to make sure nobody is trying to impersonate you. Just reminding that the buyers and holders of your NFTs will not be able to exercise commercial use of your work by contract. If you’re being approved for the first time, you’ll automatically earn an Artist Badge on your profile.',
  createCollectionISRC: 'ISRC',
  createCollectionISRCPlaceHolder: 'AA-6Q20000047',
  createCollectionISRCInfo: 'The code of your song published on the ISRC showing its number and rights distribution (isrcsearch.ifpi.org).',
  createCollectionAssociation: 'Association',
  createCollectionAssociationInfo: 'Inform the association you take part of.',
  createCollectionAssociationOther: 'Other',
  createCollectionAssociationOtherPlaceHolder: 'Other',
  createCollectionStreamingDashboard: 'Streaming Dashboard*',
  createCollectionStreamingDashboardInfo: 'Upload a screenshot of your artist dashboard on a streaming service that you have songs distributed in (eg. Spotify for Artists)',
  createCollectionDistributorProfile: 'Distributor Profile',
  createCollectionDistributorProfileInfo: 'Upload a screenshot of your logged area on the distribution platform that you have songs distributed in.',
  createCollectionPayOff: 'Pay-offs account:',
  createCollectionPayOffInfo: `In order to receive the payments of your collection you'll need to provide an Algorand wallet(if don’t have one yet, learn how to create here).This wallet will be used to create this collection and receive the earnings of it.You WON’T be able to alter this wallet once you have set it as your receiving account for this specific collection.`,
  createCollectionPayOffInfoLearnMore: `The payments are made in USDT(learn more).`,
  createCollectionWalletConnected: `Wallet Connected`,
  createCollectionTutorial: 'Don`t have a wallet yet? Watch the video tutorial to see how to create one.',
  createCollectionFinalInformation: 'Please confirm the following information',
  createCollectionCheckInformationTrue: 'I guarantee all the information provided are true.',
  createCollectionCheckUsdt: 'I confirm that I opted in for USDT in my wallet to receive my sales amounts.',
  createCollectionCheckRightHolder: 'I’m the right holder of all contents included in the NFT, including the song.',
  createCollectionCheckBenefits: 'If the Collection has any benefit included, I agree that I’m fully responsible for the delivery and/or execution of them.',
  createCollectionCheckAgreeWithTax: `I'm aware of and agree with Musii's service taxes of 5% for sellers and 5% for buyers of the sales value amount.`,
  createCollectionCheckTerms: 'By proceeding, I’m agreeing with Musii’s Terms of Service and contract clauses.',
  createCollectionCheckButtomCancel: 'CANCEL',
  createCollectionCheckButtomContinue: 'CONTINUE',
  createCollectionSent: 'Collection sent to approval!',
  createCollectionSentInfo: 'Congratulations, your information has been successfully submitted. Now, just wait for a period of up to 72 hours for our team to check if you are within our guidelines. In the meantime, how about making your profile more complete, so folks can learn more about you?',
  createCollectionSentButtomViewRequest: 'VIEW MY REQUEST',
  createCollectionSentButtomCompleteProfile: 'COMPLETE MY PROFILE',

  reviewFlowDeletedField: 'This field has been deleted, fill out and resubmit.',
  emailError: 'This email is not valid.',
  cpfError: 'This CPF is invalid, if you want to pay with PIX, you must fill in a valid CPF. Please provide the complete number with a digit so that the transaction can be completed.',
  websiteError: 'This website is not valid.',
  //supplyError: 'You must choose a supply between 1 - 99,999.',
  //priceError: 'You must choose a price between R$ 1.00 - R$ 9,999.00.',
  possibleErrorsLogin: 'There are two error possibilities: 1- this email has already been used to create a Musii account via Google/Facebook or;\n 2- the password is incorrect.',
  possibleErrorsForgot: `There are two error possibilities: 1- this email has already been used to create a Musii account via Google/Facebook or;\n 2- the email don't have an account on musii`,
  //passwordError: `Password incorrect. Please try again or click on 'forgot password'.`,
  passwordNotMatchError: 'Must match your new password.',
  passwordWithoutRules: 'Must contain at least 8 characters (letters and number).',

  changePassword: 'Change Password:',
  changePasswordDesc: 'Please provide a new password and confirm it.',
  changePasswordEmail: 'Email to recover password.',
  changePasswordCode: 'Code*',
  changePasswordCodeDesc: 'Code sent by email.',
  changePasswordNewPassword: 'New Password*',
  changePasswordConfirmPassword: 'Confirm New Password*',
  changePasswordButton: 'CHANGE PASSWORD',

  purchaseFlowCollectionDetails: 'Collection Details:',
  purchaseFlowCollectionDetailsCreator: 'Creator',
  purchaseFlowCollectionDetailsMetadata: 'Metadata Standards',
  purchaseFlowCollectionDetailsSupply: 'Supply',
  purchaseFlowCollectionEditions: 'editions',
  purchaseFlowCollectionAttention: '*Benefits are valid for primary sales buyers only. \n**The delivery and/or execution of such benefits to buyers are the sole and exclusive responsibility of the creator, and Musii assumes no responsibility for the delivery and/or execution of these benefits.',
  //purchaseFlowCollectionButtomBuy: 'BUY',
  purchaseFlowPersonalDataIcon: 'Personal Data',
  purchaseFlowPaymentIcon: 'Payment',
  purchaseFlowPersonalDataTitle: 'Personal Data and Shipping',
  purchaseFlowPersonalDataInfo: 'This information is necessary for the collection creator to be able to deliver the benefits to you and to contact you if necessary.',
  purchaseFlowName: 'Full Name',
  purchaseFlowNamePlaceholder: 'Name and Last Name',
  purchaseFlowEmail: 'Email',
  purchaseFlowEmailPlaceholder: 'Email',
  purchaseFlowEmailInfo: 'Please provide a valid email address so we can contact you, if needed.',
  purchaseFlowPhone: 'Phone',
  purchaseFlowPhonePlaceholder: '+1 123 456 789',
  purchaseFlowPhoneInfo: 'Please provide a valid phone so we can contact you, if needed.',
  purchaseFlowCPForId: 'CPF or ID',
  purchaseFlowCPForIdInfo: 'Please provide the whole number with digit.',
  //purchaseFlowMandatoryFiled: 'This field is mandatory.',
  purchaseFlowZipCode: 'Zip Code',
  purchaseFlowZipCodePlaceholder: 'XXXXX-XXXX',
  purchaseFlowAddress: 'Address',
  purchaseFlowAddressPlaceholder: 'Street/Avenue/Boulevard',
  purchaseFlowAddressNumber: 'Number',
  purchaseFlowAddressComplement: 'Complement',
  purchaseFlowAddressComplementPlaceholder: 'Apartment/Floor/Block',
  purchaseFlowAddressNeighborhood: 'Neighborhood',
  purchaseFlowAddressNeighborhoodPlaceholder: 'Neighborhood/District',
  purchaseFlowAddressCity: 'City',
  purchaseFlowAddressCityPlaceholder: 'City/Town',
  purchaseFlowAddressState: 'State',
  purchaseFlowAddressStatePlaceholder: 'State/Province/Region',
  purchaseFlowAddressCountry: 'Country',
  purchaseFlowAddressCountryPlaceholder: 'Country',
  purchaseFlowCheckSharingData: 'I authorize the sharing of my data with third parties to deliver benefits and from now on, I make myself available for contact.',
  purchaseFlowCheckAgreeTerms: 'By proceeding, I’m agreeing with Musii’s Terms of Service and contract clauses.',
  purchaseFlowCheckoutTitle: 'Checkout - Payment Method',
  purchaseFlowCheckoutInfo: 'If you pay with PIX (fiat), your NFT will be available in your profile and if you’d like to, you’ll be able to claim it to a crypto wallet there latter, clicking on ‘redeem’ and connecting a wallet.',
  purchaseFlowPayWithWallet: 'Pay with $ALGO:',
  purchaseFlowPayWithPix: 'Pay with PIX',
  purchaseFlowOrderSummary: 'Order Summary',
  purchaseFlowOrderSubtotal: 'Subtotal',
  purchaseFlowOrderTotal: 'Total',
  purchaseFlowServiceTax: 'Service Tax',
  purchaseFlowProcessingMint: 'Processing Mint',
  purchaseFlowProcessingMintDesc: 'Waiting for the right beat to connect your wallet, thanks for your patience while we work on the backstage.',
  purchaseFlowProcessingMintWallet: 'Keep your wallet open during the process and check it when necessary to accept transactions.',
  purchaseFlowConfirmationTitle: 'Purchase Approved',
  purchaseFlowConfirmationInfo: 'To access your NFT, just enter on your profile and them click on the tab of ‘NFTs Hold’.',
  purchaseFlowConfirmationButtonView: 'VIEW MY NFTS',

  tutorialTitle: 'Discover the step-by-step process of creating or connecting a wallet to receive payments in this informative tutorial video.',
  tutorialNftTitle: 'Discover the step-by-step process of creating a NFT on musii'
};

export default en;
