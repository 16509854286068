const ProfileApple = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=apple_music">
                <g id="Group">
                    <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M22.0178 6.54113C22.0178 7.26915 21.7666 7.94846 21.2642 8.57943C20.6486 9.33619 19.9475 9.67603 19.1609 9.59858C19.1515 9.54969 19.1467 9.50378 19.1467 9.45974V9.32182C19.1467 8.62292 19.4027 7.96824 19.9141 7.3563C20.1703 7.04632 20.4947 6.78878 20.8877 6.58462C21.2812 6.3812 21.6529 6.26941 22.0036 6.25C22.013 6.29852 22.0178 6.34686 22.0178 6.39575V6.54113ZM24.5894 11.1993C23.5473 11.8592 23.0353 12.7812 23.0548 13.9655C23.0643 14.8777 23.3818 15.6447 24.0063 16.2649C24.2904 16.5465 24.6079 16.7657 24.9585 16.9206C24.9206 17.0373 24.8826 17.1509 24.8449 17.2625C24.8066 17.3741 24.7641 17.4831 24.7172 17.5899C24.6167 17.8444 24.5004 18.0924 24.369 18.3325C24.2466 18.5554 24.1162 18.7739 23.9781 18.9875C23.8124 19.2352 23.6394 19.478 23.4591 19.7153C23.303 19.9191 23.1582 20.0791 23.0262 20.1961C22.6467 20.5548 22.2395 20.7442 21.8036 20.7632C21.51 20.7632 21.1307 20.6664 20.6667 20.4717C20.2493 20.2877 19.847 20.1959 19.4587 20.1959C19.0417 20.1959 18.6244 20.2883 18.2079 20.4717C17.7625 20.6664 17.4028 20.7681 17.1282 20.7782C16.7013 20.7979 16.285 20.6035 15.8771 20.1961C15.5937 19.9337 15.2609 19.5164 14.8823 18.9436C14.4565 18.3134 14.1054 17.5807 13.8307 16.746C13.5371 15.872 13.3906 14.9988 13.3906 14.1254C13.3906 13.1551 13.5892 12.3204 13.9875 11.6213C14.3 11.059 14.7167 10.6146 15.2378 10.2894C15.7587 9.96444 16.3227 9.79704 16.9289 9.78715C17.289 9.78715 17.7251 9.8939 18.2363 10.1074C18.7486 10.3215 19.0943 10.4276 19.2744 10.4276C19.3595 10.4276 19.7435 10.302 20.4256 10.0494C21.0597 9.81608 21.581 9.71978 21.9887 9.75804C23.1349 9.86535 24.0017 10.3453 24.5894 11.1993Z" fill="#ABABCC" />
                    <g id="Group 2">
                        <path id="Path" d="M6.42902 29.3966H6.37716L4.20543 24.4375H2.5V32.4387H3.98633V27.2568H4.05533L5.87024 31.3716H6.91261L8.72176 27.2568H8.79104V32.4387H10.2945V24.4375H8.60075L6.42902 29.3966Z" fill="#ABABCC" />
                        <path id="Path_2" d="M27.666 24.4375H29.3366V32.4389H27.666V24.4375Z" fill="#ABABCC" />
                        <path id="Path_3" d="M34.3844 25.7092C35.3752 25.7092 35.8991 26.4714 35.8991 27.292H37.5011V27.122C37.5011 25.5746 36.32 24.2852 34.3727 24.2852C32.2872 24.2852 31.043 25.6567 31.043 27.9957V28.8985C31.043 31.2368 32.2756 32.5914 34.3668 32.5914C36.366 32.5914 37.5011 31.2837 37.5011 29.8303V29.6545H35.8993C35.8993 30.4813 35.3867 31.167 34.3962 31.167C33.3819 31.167 32.7366 30.4459 32.7366 28.904V28.0019C32.7366 26.4653 33.3878 25.7092 34.3844 25.7092Z" fill="#ABABCC" />
                        <path id="Path_4" d="M16.6822 29.4901C16.6822 30.5632 16.0543 31.1549 15.1326 31.1549C14.2051 31.1549 13.5773 30.5688 13.5773 29.4901V24.4375H11.918V29.7247C11.918 31.4309 13.1276 32.5914 15.1324 32.5914C17.1315 32.5914 18.3469 31.4309 18.3469 29.7247V24.4375H16.6822V29.4901Z" fill="#ABABCC" />
                        <path id="Path_5" d="M23.8767 27.7788L22.6843 27.5095C21.9816 27.3625 21.6129 27.052 21.6129 26.5887C21.6129 25.9735 22.1139 25.5864 22.9209 25.5864C23.831 25.5864 24.286 26.1081 24.3262 26.671H25.8817C25.8644 25.2581 24.6836 24.2734 22.9263 24.2734C21.2617 24.2734 19.9481 25.147 19.9481 26.7179C19.9481 28.0778 20.87 28.7104 21.976 28.9514L23.1568 29.2267C23.9518 29.4084 24.355 29.6724 24.355 30.2171C24.355 30.8389 23.8595 31.2611 22.9322 31.2611C21.9355 31.2611 21.4112 30.7333 21.3654 30.1411H19.7637C19.7871 31.2726 20.5359 32.5915 22.8519 32.5915C24.7989 32.5915 26.0031 31.6243 26.0031 30.0528C26.0026 28.5991 25.0289 28.0305 23.8767 27.7788Z" fill="#ABABCC" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ProfileApple;
