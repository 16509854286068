import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

import MintNftCard from "../commons/MintNftCard";
import Fonts from "../../utils/Fonts";
import { Check } from "@mui/icons-material";
import Verified from "../../assets/verified";

import { NumericFormat } from "react-number-format";
import axios from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MusiiCheckbox from "../commons/MusiiCheckbox";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            disabled
            getInputRef={ref}
            isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return formattedValue === "" || (floatValue > 0 && floatValue <= 9999.99);
            }}
            allowNegative={false}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix="R$"
        />
    );
});

const SuplyFormatCustom = React.forwardRef(function SuplyFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            disabled
            getInputRef={ref}
            isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return formattedValue === "" || (formattedValue.length < 6 && floatValue > 0 && floatValue <= 99999);
            }}
            allowedDecimalSeparators={[",", "."]}
            allowNegative={false}
            allowLeadingZeros={true}
            decimalScale={0}
        />
    );
});

const Step2 = ({ setStep, nft, setNft }) => {
    const [t, i18n] = useTranslation("common");

    const [width, setWidth] = useState(window.innerWidth);
    const [categorie,] = useState(nft.categorie ? nft.categorie : "COLLECTIBLES");
    const [subCategorie,] = useState(nft.subCategorie ? nft.subCategorie : "Collectible");

    const [image,] = useState(nft.image ? nft.image : "");
    const [video,] = useState(nft.video ? nft.video : "");

    const [name,] = useState(nft.name ? nft.name : "");
    const [description,] = useState(nft.description ? nft.description : "");
    const [link,] = useState("");
    const [price,] = useState(nft.price ? nft.price : "");
    const [totalSuply,] = useState(nft.totalSuply ? nft.totalSuply : "");
    const [tags,] = useState(nft.tags ? nft.tags : "");

    const [distributorProfileImage,] = useState(nft.distributorProfileImage ? nft.distributorProfileImage : "");
    const [stmDashboardImage,] = useState(nft.stmDashboardImage ? nft.stmDashboardImage : "");

    const [isrc,] = useState(nft.isrc ? nft.isrc : "");
    const [association,] = useState(nft.association ? nft.association : "");
    const [associationOther,] = useState(nft.associationOther ? nft.associationOther : "");

    const isMobile = width <= 768;

    const navigate = useNavigate();
    const videoRef = useCallback((x) => (x ? (x.volume = 0) : ""), []);

    const [recusedImage, setRecusedImage] = useState(nft.recusedData?.includes("image") ? true : false);
    const [recusedVideo, setRecusedVideo] = useState(nft.recusedData?.includes("video") ? true : false)
    const [recusedName, setRecusedName] = useState(nft.recusedData?.includes("name") ? true : false)
    const [recusedDescription, setRecusedDescription] = useState(nft.recusedData?.includes("description") ? true : false)
    const [recusedLink, setRecusedLink] = useState(nft.recusedData?.includes("link") ? true : false)
    const [recusedPrice, setRecusedPrice] = useState(nft.recusedData?.includes("link") ? true : false)
    const [recusedTotalSuply, setRecusedTotalSuply] = useState(nft.recusedData?.includes("totalSuply") ? true : false)
    const [recusedTags, setRecusedTags] = useState(nft.recusedData?.includes("tags") ? true : false)
    const [recusedDistributorProfileImage, setRecusedDistributorProfileImage] = useState(nft.recusedData?.includes("distributorProfileImage") ? true : false)
    const [recusedStmDashboardImage, setRecusedStmDashboardImage] = useState(nft.recusedData?.includes("stmDashboardImage") ? true : false)
    const [recusedIsrc, setRecusedIsrc] = useState(nft.recusedData?.includes("isrc") ? true : false)
    const [recusedAssociation, setRecusedAssociation] = useState(nft.recusedData?.includes("association") ? true : false)

    const [comments, setComments] = useState(nft.recusedReason);

    useEffect(() => {
        setNft({ ...nft, recusedReason: comments })
    }, [comments]);

    useEffect(() => {
        if (recusedImage) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("image")) {
                    nft.recusedData.push("image")
                }
            } else {
                nft.recusedData = ["image"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'image')
        }

    }, [recusedImage]);

    useEffect(() => {
        if (recusedVideo) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("video")) {
                    nft.recusedData.push("video")
                }
            } else {
                nft.recusedData = ["video"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'video')
        }

    }, [recusedVideo]);

    useEffect(() => {
        if (recusedName) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("name")) {
                    nft.recusedData.push("name")
                }
            } else {
                nft.recusedData = ["name"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'name')
        }

    }, [recusedName]);

    useEffect(() => {
        if (recusedDescription) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("description")) {
                    nft.recusedData.push("description")
                }
            } else {
                nft.recusedData = ["description"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'description')
        }

    }, [recusedDescription]);

    useEffect(() => {
        if (recusedLink) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("link")) {
                    nft.recusedData.push("link")
                }
            } else {
                nft.recusedData = ["link"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'link')
        }

    }, [recusedLink]);

    useEffect(() => {
        if (recusedPrice) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("price")) {
                    nft.recusedData.push("price")
                }
            } else {
                nft.recusedData = ["price"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'price')
        }

    }, [recusedPrice]);

    useEffect(() => {
        if (recusedTotalSuply) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("totalSuply")) {
                    nft.recusedData.push("totalSuply")
                }
            } else {
                nft.recusedData = ["totalSuply"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'totalSuply')
        }

    }, [recusedTotalSuply]);

    useEffect(() => {
        if (recusedTags) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("tags")) {
                    nft.recusedData.push("tags")
                }
            } else {
                nft.recusedData = ["tags"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'tags')
        }

    }, [recusedTags]);

    useEffect(() => {
        if (recusedDistributorProfileImage) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("distributorProfileImage")) {
                    nft.recusedData.push("distributorProfileImage")
                }
            } else {
                nft.recusedData = ["distributorProfileImage"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'distributorProfileImage')
        }

    }, [recusedDistributorProfileImage]);

    useEffect(() => {
        if (recusedStmDashboardImage) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("stmDashboardImage")) {
                    nft.recusedData.push("stmDashboardImage")
                }
            } else {
                nft.recusedData = ["stmDashboardImage"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'stmDashboardImage')
        }

    }, [recusedStmDashboardImage]);

    useEffect(() => {
        if (recusedIsrc) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("isrc")) {
                    nft.recusedData.push("isrc")
                }
            } else {
                nft.recusedData = ["isrc"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'isrc')
        }

    }, [recusedIsrc]);

    useEffect(() => {
        if (recusedAssociation) {
            if (nft.recusedData) {
                if (!nft.recusedData.includes("association")) {
                    nft.recusedData.push("association")
                }
            } else {
                nft.recusedData = ["association"]
            }

        } else {
            nft.recusedData = nft.recusedData?.filter(e => e !== 'association')
        }

    }, [recusedAssociation]);


    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <Box>
            <Grid container position="relative" xs={12} marginBottom={12}>
                <Grid xs={12} md={12}>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('createCollectionTitle')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    paddingRight={isMobile ? 4 : 0}
                    paddingLeft={isMobile ? 4 : 8}
                    marginTop={6}
                    gap={8}
                    xs={12}
                    md={10}
                    lg={10}
                    xl={9}
                    display={"flex"}
                    justifyContent={isMobile ? "center" : ""}
                >
                    <Grid
                        xs={12}
                        md={12}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"20px"}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                                cursor: "pointer",
                            }}
                            onClick={() => setStep(0)}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#5CB88D",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Check backgroundColor="#F0F0FA" />
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#5CB88D",
                                }}
                            >
                                {t('createCollectionStepProfile')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "#5CB88D",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "#7272A3",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    2
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "#CACAE5",
                                }}
                            >
                                {t('createCollectionStepCollection')}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "56px",
                                height: "2px",
                                border: "solid",
                                borderBottomWidth: "2px",
                                borderTopWidth: "0px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomColor: "rgba(114, 114, 163, 0.4)",
                            }}
                        ></Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginBottom: 8,
                            }}
                        >
                            <Box
                                style={{
                                    width: "56px",
                                    height: "56px",
                                    background: "rgba(114, 114, 163, 0.4)",
                                    borderRadius: "20px",
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "12px",
                                }}
                                onClick={() => setStep(2)}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize2,
                                        color: "#121229",
                                    }}
                                >
                                    3
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize2,
                                    color: "rgba(114, 114, 163, 0.4)",
                                }}
                            >
                                {t('createCollectionStepWallet')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 0px",
                                whiteSpace: "break-spaces",
                                display: "flex",
                                alignItems: "flex-end",
                            }}
                        >
                            Created at: {nft.createdAt}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder={"Comments"}
                            fullWidth
                            multiline
                            type="text"
                            value={comments}
                            onChange={(e) => {
                                setComments(e.target.value);
                            }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                textAlign: "left",
                                padding: "16px 0px",
                            }}
                        >
                            {t('createCollectionIncludeBenefits')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                color: "#7272A3",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 0px",
                            }}
                        >
                            {t('createCollectionIncludeBenefitsInfo')}
                        </Typography>
                    </Grid>
                    <img
                        src={image}
                        style={{
                            borderRadius: "32px",
                            width: "222px",
                            height: "222px",
                        }}
                        alt="cover"
                    />
                    <MusiiCheckbox
                        checked={recusedImage}
                        onChange={(e) => {
                            setRecusedImage(e.target.checked);
                        }}
                    />
                    <video
                        ref={videoRef}
                        src={video}
                        style={{
                            borderRadius: "32px",
                            width: "222px",
                            height: "222px",
                        }}
                        alt="Musii"
                        loop={true}
                        autoPlay={true}
                        controls={true}
                        playsInline
                    />
                    <MusiiCheckbox
                        checked={recusedVideo}
                        onChange={(e) => {
                            setRecusedVideo(e.target.checked);
                        }}
                    />
                    <Grid
                        xs={12}
                        md={2}
                        display={"flex"}
                        flexDirection={"column"}
                    ></Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionName')}*
                            <MusiiCheckbox
                                checked={recusedName}
                                onChange={(e) => {
                                    setRecusedName(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionNamePlaceHolder')}
                            value={name}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionNameInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionDescription')}*
                            <MusiiCheckbox
                                checked={recusedDescription}
                                onChange={(e) => {
                                    setRecusedDescription(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionDescriptionPlaceHolder')}
                            multiline
                            value={description}
                            inputProps={{ maxLength: 1000 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderBottomWidth: 0,
                                    borderRadius: "24px",
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionDescriptionInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionTags')}
                            <MusiiCheckbox
                                checked={recusedTags}
                                onChange={(e) => {
                                    setRecusedTags(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionTagsPlaceHolder')}
                            value={tags.toString()}
                            type="text"
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionTagsInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionExternalURL')}
                            <MusiiCheckbox
                                checked={recusedLink}
                                onChange={(e) => {
                                    setRecusedLink(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionExternalURLPlaceHolder')}
                            type="url"
                            inputProps={{ maxLength: 100 }}
                            value={link}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionExternalURLInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionTotalSupply')}*
                            <MusiiCheckbox
                                checked={recusedTotalSuply}
                                onChange={(e) => {
                                    setRecusedTotalSuply(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{
                                disableUnderline: true,
                                inputComponent: SuplyFormatCustom,
                            }}
                            variant="standard"
                            placeholder={t('createCollectionTotalSupplyPlaceHolder')}
                            value={totalSuply}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionTotalSupplyInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionPrice')}*
                            <MusiiCheckbox
                                checked={recusedPrice}
                                onChange={(e) => {
                                    setRecusedPrice(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{
                                disableUnderline: true,
                                inputComponent: NumericFormatCustom,
                            }}
                            variant="standard"
                            placeholder={t('createCollectionPricePlaceHolder')}
                            value={price}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .l1LightSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionPriceInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={10.5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 28px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Verified /> <Box marginRight={1} />
                            {t('createCollectionLegitimacyVerification')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 28px",
                            }}
                        >
                            {t('createCollectionLegitimacyVerificationInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionISRC')}*
                            <MusiiCheckbox
                                checked={recusedIsrc}
                                onChange={(e) => {
                                    setRecusedIsrc(e.target.checked);
                                }}
                            />
                        </Typography>
                        <TextField
                            disabled
                            InputProps={{ disableUnderline: true }}
                            variant="standard"
                            placeholder={t('createCollectionISRCPlaceHolder')}
                            type="url"
                            inputProps={{ maxLength: 100 }}
                            value={isrc}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionISRCInfo')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={5}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionAssociation')}*
                            <MusiiCheckbox
                                checked={recusedAssociation}
                                onChange={(e) => {
                                    setRecusedAssociation(e.target.checked);
                                }}
                            />
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                disabled
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={association}
                                label="Age"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                disableUnderline
                                sx={{
                                    boxShadow: "none",
                                    border: "0px solid transparent",
                                    borderRadius: "24px",
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,
                                    },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,
                                    },
                                    "& .MuiInputBase-input": {
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].b1RegularSize1,
                                        padding: "16.5px 20px",
                                        border: "0px solid transparent",
                                        color: "#B8B8DB",
                                        borderRadius: "24px",
                                        borderBottomWidth: 0,
                                        background:
                                            "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                    },
                                    "& .Mui-focused": {
                                        boxShadow: "none",
                                        border: "0px solid transparent",
                                        borderRadius: "24px",
                                    },
                                    "& .Mui-selected": {
                                        boxShadow: "none",
                                        border: "0px solid transparent",
                                        borderRadius: "24px",
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                        border: 0,
                                    },
                                    ".MuiSelect-icon": {
                                        color: "#B8B8DB",
                                    },
                                }}
                            >
                                <MenuItem value={"Abramus"}>Abramus</MenuItem>
                                <MenuItem value={"Amar Sombrás"}>
                                    Amar Sombrás
                                </MenuItem>
                                <MenuItem value={"ASSIM"}>ASSIM</MenuItem>
                                <MenuItem value={"SbaceM"}>SbaceM</MenuItem>
                                <MenuItem value={"Sicam"}>Sicam</MenuItem>
                                <MenuItem value={"SOCINPRO"}>SOCINPRO</MenuItem>
                                <MenuItem value={"UBC"}>UBC</MenuItem>
                                <MenuItem value={"Other"}>{t('createCollectionAssociationOther')}:</MenuItem>
                            </Select>
                        </FormControl>
                        {association === "Other" && (
                            <TextField
                                disabled
                                InputProps={{ disableUnderline: true }}
                                variant="standard"
                                placeholder={t('createCollectionAssociationOtherPlaceHolder')}
                                type="text"
                                inputProps={{ maxLength: 100 }}
                                value={associationOther}
                                sx={{
                                    boxShadow: 0,
                                    "& .MuiInputBase-input": {
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].b1RegularSize1,
                                        padding: "16.5px 20px",
                                        border: "0px solid #transparent",
                                        color: "#B8B8DB",
                                        borderRadius: "24px",
                                        borderBottomWidth: 0,
                                        marginTop: 2,
                                        background:
                                            "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                    },
                                }}
                            />
                        )}
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('createCollectionAssociationInfo')}
                        </Typography>
                    </Grid>
                    <img
                        src={stmDashboardImage}
                        style={{
                            borderRadius: "32px",
                            width: "222px",
                            height: "222px",
                        }}
                        alt="cover"
                    />
                    <MusiiCheckbox
                        checked={recusedStmDashboardImage}
                        onChange={(e) => {
                            setRecusedStmDashboardImage(e.target.checked);
                        }}
                    />
                    <img
                        src={distributorProfileImage}
                        style={{
                            borderRadius: "32px",
                            width: "222px",
                            height: "222px",
                        }}
                        alt="cover"
                    />
                    <MusiiCheckbox
                        checked={recusedDistributorProfileImage}
                        onChange={(e) => {
                            setRecusedDistributorProfileImage(e.target.checked);
                        }}
                    />
                </Grid>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Grid
                        marginTop={4}
                        gap={8}
                        xs={8}
                        md={2}
                        style={{
                            position: isMobile ? "unset" : "fixed",
                            right: 86,
                            top: "30%",
                            transform: isMobile ? "" : "translateY(-20%)",
                        }}
                    >
                        <MintNftCard
                            image={image}
                            name={name}
                            price={price}
                            totalSuply={totalSuply}
                            isMobile={isMobile}
                            categorie={categorie}
                            subCategorie={subCategorie}
                            artistName={nft.artistName}
                            artistPhoto={nft.artistPhoto}
                        />
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                const response = await axios.put(
                                    `${Dev.api}nft/approve/id`,
                                    {
                                        id: nft.id,
                                        email: "admin@musii.app",
                                    },
                                    {
                                        headers: {
                                            "Content-Type": "application/json;charset=utf-8",
                                        },
                                        crossDomain: true,
                                    }
                                );

                                navigate("/profile");
                            }}
                            variant="contained"
                        >
                            Approve
                        </Button>
                        <Button
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                background: "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                                borderRadius: "40px",
                                padding: "16px 20px",
                                marginTop: 32,
                            }}
                            fullWidth
                            onClick={async () => {
                                const remove = {}

                                nft.recusedData.map(item =>
                                    remove[item] = ""
                                )

                                const body = {
                                    id: nft.id,
                                    email: "admin@musii.app",
                                    recusedReason: comments,
                                    recusedData: nft.recusedData,
                                    remove
                                }

                                const response = await axios.put(
                                    `${Dev.api}nft/reprove/id`,
                                    body,
                                    {
                                        headers: {
                                            "Content-Type": "application/json;charset=utf-8",
                                        },
                                        crossDomain: true,
                                    }
                                );

                                console.log(response)
                                navigate("/profile");
                            }}
                            variant="contained"
                        >
                            Recuse
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
};

export default Step2;
