import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

import Step1 from "../assets/step1.png";
import Step2 from "../assets/step2.png";
import Step3 from "../assets/step3.png";

import Backstep from "../assets/backstep";
import Fonts from "../utils/Fonts";
import { useUser } from "../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Living({ isMobile, setShowModalConnect }) {
    const { user } = useUser();
    const [t, i18n] = useTranslation("common");

    const navigate = useNavigate();

    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            paddingY={isMobile ? "20px" : "56px"}
        >
            <Grid
                container
                gap={"32px"}
                maxWidth={"80vw"}
                justifyContent={"center"}
            >
                <Grid
                    xs={12}
                    md={12}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                >
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize2,
                            color: "#ABABCC",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('howWorksT1')}
                    </Typography>
                    <Box
                        style={{
                            width: "35px",
                            height: "6px",
                            background:
                                "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                            borderRadius: "3px",
                            marginTop: "8px",
                            marginBottom: "16px",
                        }}
                    ></Box>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                            textAlign: "center",
                        }}
                    >
                        {t('howWorksT2')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    marginTop={6}
                    gap={4}
                    justifyContent={"center"}
                >
                    <Grid
                        xs={12}
                        md={3.7}
                        xl={3}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: isMobile ? "40px 20px" : "40px",
                            borderRadius: 16,
                            backgroundColor: "#211C45",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img src={Step1} alt="step 1" />

                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize1,
                                    color: "#B555FF",
                                    whiteSpace: "break-spaces",
                                    marginLeft: "16px",
                                    flex: 1,
                                }}
                            >
                                {t('step1T1')}
                            </Typography>
                            <Backstep />
                        </Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#F0F0FA",
                                whiteSpace: "break-spaces",
                                marginTop: "12px",
                            }}
                        >
                            {t('step1T2')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                marginTop: "8px",
                                color: "#F0F0FA",
                            }}
                        >
                            {t('step1B1')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.7}
                        xl={3}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: isMobile ? "40px 20px" : "40px",
                            borderRadius: 16,
                            backgroundColor: "#211C45",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img src={Step2} alt="step 2" />

                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize1,
                                    color: "#B555FF",
                                    whiteSpace: "break-spaces",
                                    marginLeft: "16px",
                                    flex: 1,
                                }}
                            >
                                {t('step2T1')}
                            </Typography>
                            <Backstep />
                        </Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#F0F0FA",
                                whiteSpace: "break-spaces",
                                marginTop: "12px",
                            }}
                        >
                            {t('step2T2')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                marginTop: "8px",
                                color: "#F0F0FA",
                            }}
                        >
                            {t('step2B1')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={3.7}
                        xl={3}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: isMobile ? "40px 20px" : "40px",
                            borderRadius: 16,
                            backgroundColor: "#211C45",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img src={Step3} alt="step 3" />
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h2BoldSize1,
                                    color: "#B555FF",
                                    whiteSpace: "break-spaces",
                                    marginLeft: "16px",
                                    flex: 1,
                                }}
                            >
                                {t('step3T1')}
                            </Typography>
                        </Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#F0F0FA",
                                whiteSpace: "break-spaces",
                                marginTop: "12px",
                            }}
                        >
                            {t('step3T2')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1RegularSize1,
                                marginTop: "8px",
                                color: "#F0F0FA",
                            }}
                        >
                            {t('step3B1')}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    xs={12}
                    md={12}
                    xl={10}
                    padding={isMobile ? "24px 32px" : "32px 40px"}
                    marginTop={"104px"}
                    borderRadius={8}
                    display={"flex"}
                    justifyContent={"center"}
                    style={{
                        background: "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)"
                    }}
                >
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        flexDirection={isMobile ? "column" : "row"}
                    >
                        <Box maxWidth={isMobile ? "100%" : "75%"}>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h1BoldSize1,
                                    color: "#EBEBFA",
                                    whiteSpace: "break-spaces",
                                    marginTop: 4,
                                    marginBottom: 4,
                                }}
                            >
                                {t('bannerCtaT1')}
                            </Typography>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3RegularSize1,
                                    color: "#EBEBFA",
                                }}
                            >
                                {t('bannerCtaT2')}
                            </Typography>
                        </Box>

                        <Box marginBottom={3} width={isMobile ? "100%" : ""}>
                            <Button
                                style={{
                                    marginLeft: isMobile ? 0 : "56px",
                                    marginTop: isMobile ? "24px" : 0,

                                    width: "169px",
                                    maxHeight: "40px",
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "40px",
                                    padding: "12px 32px",
                                    gap: "8px",
                                    flex: "none",
                                    order: 1,
                                    width: isMobile ? '100%' : '',
                                    flexGrow: 0,
                                }}
                                variant="contained"
                                onClick={() => {
                                    if (user && (user.email || user.name)) {
                                        isMobile ? alert(t('profileCTAAddOfferMobile')) : navigate("/mint");
                                    } else {
                                        setShowModalConnect(true)
                                    }
                                }}
                            >
                                <Typography
                                    style={{
                                        ...Fonts[isMobile ? "mobile" : "desktop"]
                                            .h3BoldSize1,
                                        color: "#F0F0FA",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {t('bannerCtaButton')}
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Living;
