import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import Footer from "../components/Footer";
import Fonts from "../utils/Fonts";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import Close from "../assets/close";
import Check from "../assets/check";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Dev from "../utils/Dev";

const ResetPassword = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showModalConnect, setShowModalConnect] = useState(false);
    const [redirectModal, setRedirectModal] = useState(false);

    const [searchParams] = useSearchParams();
    const [t, i18n] = useTranslation("common");

    const [open, setOpen] = useState(false);
    const [canClick, setCanClick] = useState(false);

    const [finished, setFinished] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [errorPassword, setErrorPassword] = useState(false);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [email, setEmail] = useState(searchParams.get("email"));
    const [code, setCode] = useState(searchParams.get("code"));

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (
            password !== "" &&
            confirmPassword !== "" &&
            !errorPassword &&
            !errorConfirmPassword
        ) {
            setCanClick(true);
        } else {
            setCanClick(false);
        }
    }, [password, confirmPassword, errorPassword, errorConfirmPassword]);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            {open && (
                <Box
                    zIndex={99}
                    style={{
                        position: "fixed",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        left: "50%",
                        top: "50%",
                        padding: "48px",
                        transform: "translate(-50%, -50%)",
                        minWidth: "600px",
                        minHeight: "380px",
                        borderRadius: 16,
                        boxShadow: "0px 4px 16px rgba(4, 4, 20, 0.5)",
                        background:
                            "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                    }}
                >
                    <Box
                        onClick={() => setOpen(false)}
                        style={{
                            position: "absolute",
                            right: 64,
                            top: 24,
                            cursor: "pointer",
                        }}
                    >
                        <Close />
                    </Box>
                    <Box marginTop={"72px"}>
                        <Check />
                    </Box>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h2BoldSize1,
                            color: "#ABABCC",
                            marginTop: 8,
                            marginBottom: "32px",
                            flex: 1,
                        }}
                    >
                        Password changed
                    </Typography>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            setShowModalConnect(true);
                        }}
                        style={{
                            background:
                                "linear-gradient(130.54deg, rgba(137, 48, 240, 0.8) 0%, rgba(94, 71, 214, 0.8) 102.22%), rgba(240, 240, 250, 0.05)",
                            borderRadius: "40px",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "18px",
                            lineHeight: "32px",
                            padding: "16px 48px",
                        }}
                        fullWidth
                        variant="contained"
                    >
                        LOG IN
                    </Button>
                </Box>
            )}
            <Header isMobile={isMobile} showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} redirectModal={redirectModal} setRedirectModal={setRedirectModal} />
            <Box backgroundColor={"#121229"}>
                <Grid container justifyContent={"center"}>
                    <Grid
                        container
                        xs={12}
                        md={9}
                        display={"flex"}
                        flexDirection={"column"}
                        marginTop="156px"
                        style={{ padding: "16px 20px", }}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize1,
                                color: "#ABABCC",
                                marginBottom: 8,
                                flex: 1,
                            }}
                        >
                            {t('changePassword')}
                        </Typography>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1RegularSize1,
                                color: "#ABABCC",
                                marginBottom: 8,
                                flex: 1,
                            }}
                        >
                            {t('changePasswordDesc')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={4.5}
                        display={"flex"}
                        flexDirection={"column"}
                        style={{
                            padding: "16px 44px 16px 0px",
                        }}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            Email*
                        </Typography>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder={"mail@provider.com"}
                            value={email}
                            type={"email"}
                            onChange={(e) => setEmail(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('changePasswordEmail')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={4.5}
                        display={"flex"}
                        flexDirection={"column"}
                        style={{
                            padding: "16px 44px 16px 0px",
                        }}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('changePasswordCode')}
                        </Typography>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder={"123456"}
                            value={code}
                            type={"number"}
                            onChange={(e) => setCode(e.target.value)}
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('changePasswordCodeDesc')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={4.5}
                        display={"flex"}
                        flexDirection={"column"}
                        style={{
                            padding: "16px 44px 16px 0px",
                        }}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('changePasswordNewPassword')}
                        </Typography>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOffOutlined htmlColor="#B8B8DB" />
                                            ) : (
                                                <VisibilityOutlined htmlColor="#B8B8DB" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            placeholder={"New Password"}
                            value={password}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={(e) => {
                                if (
                                    !/(?=.*\d)(?=.*[A-z]).{8,}/.test(password)
                                ) {
                                    setErrorPassword(true);
                                } else {
                                    setErrorPassword(false);
                                }
                            }}
                            inputProps={{ maxLength: 50 }}
                            error={errorPassword}
                            helperText={
                                errorPassword
                                    ? t('passwordWithoutRules')
                                    : ""
                            }
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('passwordWithoutRules')}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={12}
                        md={4.5}
                        display={"flex"}
                        flexDirection={"column"}
                        style={{
                            padding: "16px 0px 16px 44px",
                        }}
                    >
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h2BoldSize2,
                                color: "#CACAE5",
                                marginBottom: 8,
                                padding: "16px 20px",
                            }}
                        >
                            {t('changePasswordConfirmPassword')}
                        </Typography>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowConfirmPassword(
                                                    !showConfirmPassword
                                                )
                                            }
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOffOutlined htmlColor="#B8B8DB" />
                                            ) : (
                                                <VisibilityOutlined htmlColor="#B8B8DB" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            placeholder={"Confirm New Password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onBlur={(e) => {
                                if (password !== confirmPassword) {
                                    setErrorConfirmPassword(true);
                                } else {
                                    setErrorConfirmPassword(false);
                                }
                            }}
                            inputProps={{ maxLength: 50 }}
                            color="#B8B8DB"
                            type={showConfirmPassword ? "text" : "password"}
                            error={errorConfirmPassword}
                            helperText={
                                errorConfirmPassword
                                    ? "Must match your new password."
                                    : ""
                            }
                            sx={{
                                boxShadow: 0,
                                "& .MuiInputBase-input": {
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .b1RegularSize1,
                                    padding: "16.5px 20px",
                                    border: "0px solid #transparent",
                                    color: "#B8B8DB",
                                    borderRadius: "24px",
                                    borderBottomWidth: 0,
                                    background:
                                        "linear-gradient(129.23deg, rgba(171, 171, 204, 0.1089) 0%, rgba(18, 18, 41, 0.1089) 100%), linear-gradient(0deg, rgba(36, 36, 71, 0.66), rgba(36, 36, 71, 0.66)), rgba(18, 18, 41, 0.33)",
                                },
                                "&.Mui-selected selector": {
                                    color: "#B8B8DB",
                                },
                            }}
                        />
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .l1LightSize1,
                                color: "#B8B8DB",
                                marginTop: 8,
                                textAlign: "left",
                                padding: "16px 20px",
                            }}
                        >
                            {t('passwordWithoutRules')}
                        </Typography>
                    </Grid>

                    <Grid
                        xs={12}
                        md={9}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Button
                            style={{
                                background:
                                    canClick && !finished
                                        ? "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)"
                                        : "#B8B8DB",
                                borderRadius: "40px",
                                fontFamily: "Ubuntu",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "17px",
                                lineHeight: "32px",
                                padding: "16px 22px",
                                marginTop: "72px",
                                marginBottom: "224px",
                            }}
                            onClick={async () => {
                                if (canClick && !finished) {
                                    const response = await axios.put(
                                        `${Dev.api}nft/user/user/change-password`,
                                        {
                                            email: email.toLowerCase(),
                                            code,
                                            password
                                        },
                                        {
                                            headers: {
                                                "Content-Type": "application/json;charset=utf-8",
                                            },
                                            crossDomain: true,
                                        }
                                    );


                                    setOpen(true);
                                    setFinished(true);
                                }
                            }}
                            variant="contained"
                        >
                            {t('changePasswordButton')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default ResetPassword;
