import React, { useEffect, useState, useContext } from "react";
import { createContext } from "react";
import StorageEnum from "../enums/StorageEnum";
import Storage from "../utils/Storage";
import { useUser } from "./useUser";
import { DeflyWalletConnect } from "@blockshake/defly-connect";

const deflyWallet = new DeflyWalletConnect();

export const DeflyContext = createContext({});

export function DeflyProvider({ children }) {
    const [accountAddress, setAccountAddress] = useState(null);
    const [defly, setDefly] = useState(null);
    const userHook = useUser();

    async function logOut() {
        defly.disconnect();

        setDefly(defly);
        setAccountAddress(null);
    }

    useEffect(() => {
        async function checkDefly() {
            deflyWallet.reconnectSession().then((accounts) => {
                // Setup the disconnect event listener
                deflyWallet.connector?.on("disconnect", () => {
                    deflyWallet.disconnect();

                    setDefly(null);
                    setAccountAddress(null);
                });

                if (accounts.length) {
                    setAccountAddress(accounts[0]);
                }
            });

            setDefly(deflyWallet);
        }

        checkDefly();
    }, []);

    async function logIn(handleClose) {
        deflyWallet
            .connect()
            .then(async (newAccounts) => {
                // Setup the disconnect event listener
                deflyWallet.connector?.on("disconnect", logOut);

                await Storage.setItem(StorageEnum.USER, {
                    ...userHook.user,
                    wallet: newAccounts[0],
                });
                userHook.logIn();

                setAccountAddress(newAccounts[0]);
                handleClose();
            })
            .catch((error) => {
                // You MUST handle the reject because once the user closes the modal, deflyWallet.connect() promise will be rejected.
                // For the async/await syntax you MUST use try/catch
                if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
                    // log the necessary errors
                }
            });

        setDefly(deflyWallet);
    }

    return (
        <DeflyContext.Provider
            value={{
                accountAddress,
                defly,
                logOut,
                logIn,
            }}
        >
            {children}
        </DeflyContext.Provider>
    );
}

export function useDefly() {
    const context = useContext(DeflyContext);

    return context;
}
