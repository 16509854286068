import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import EditProfile from "../assets/editProfile";
import NftHold from "../assets/nftHold";
import MyCollections from "../assets/myCollections";
import { useUser } from "../hooks/useUser";
import { useTranslation } from "react-i18next";

function ProfileMenu({ selected, setSelected, isMobile, anotherUser, another = false }) {
    const [t, i18n] = useTranslation("common");

    const { user } = useUser();

    return (
        <Box display={"flex"} justifyContent={"center"}>
            <Grid container marginBottom={16} maxWidth={"90vw"} paddingX={4} gap={isMobile ? 2 : 0}>
                {(another || user?.artist) && <Grid
                    xs={12}
                    md={another ? 6 : 4}
                    borderBottom={1}
                    borderColor={selected === 0 ? "#8C29D9E5" : "#9E9EB8"}
                    paddingBottom={2}
                    onClick={() => setSelected(0)}
                    style={{ cursor: "pointer" }}
                >
                    <Typography
                        size="small"
                        style={{
                            color: selected === 0 ? "#8C29D9E5" : "#9E9EB8",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "24px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <MyCollections selected={selected === 0} />{t('profileMyCollections')}
                    </Typography>
                </Grid>}
                <Grid
                    xs={12}
                    md={another || !user?.artist ? 6 : 4}
                    borderBottom={1}
                    borderColor={selected === 1 ? "#8C29D9E5" : "#9E9EB8"}
                    paddingBottom={2}
                    onClick={() => setSelected(1)}
                    style={{ cursor: "pointer" }}
                >
                    <Typography
                        size="small"
                        style={{
                            color: selected === 1 ? "#8C29D9E5" : "#9E9EB8",
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "24px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <NftHold selected={selected === 1} />{t('profileNFTsHold')}
                    </Typography>
                </Grid>
                {!another && (
                    <Grid
                        xs={12}
                        md={user?.artist ? 4 : 6}
                        borderBottom={1}
                        borderColor={selected === 4 ? "#8C29D9E5" : "#9E9EB8"}
                        paddingBottom={2}
                        onClick={() => setSelected(4)}
                        style={{ cursor: "pointer" }}
                    >

                        <Typography
                            size="small"
                            style={{
                                color: selected === 4 ? "#8C29D9E5" : "#9E9EB8",
                                fontFamily: "Ubuntu",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <EditProfile selected={selected === 4} />{t('profileProfile')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export default ProfileMenu;
