import {
    Card,
    Grid,
    Typography,
    CardContent,
    Button,
    Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TypeNfts from "../../utils/TypeNfts";
import Fonts from "../../utils/Fonts";
import Withdraw from "../../assets/withdraw";
import Verified from "../../assets/verified";
import { VisibilityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AlertTransaction from "./AlertTransaction";

import { useState } from "react";
import algo from "../../utils/Algorand"
import { usePera } from "../../hooks/usePera";
import axios from "axios";
import { useUser } from "../../hooks/useUser";
import Dev from "../../utils/Dev";

export default function NftCardProfile({ nft, redeem, asset, isMobile }) {
    const [t, i18n] = useTranslation("common");

    const { user } = useUser();
    const navigate = useNavigate();

    const { pera, accountAddress, logIn: loginPera } = usePera();

    const [openMint, setOpenMint] = useState(false);
    const [progress, setProgress] = useState(0);

    return (
        <Grid item sm={12} md={"auto"}>
            <AlertTransaction
                open={openMint}
                handleClose={() => setOpenMint(false)}
                isMobile={isMobile}
                progress={progress}
            />
            <Card
                style={{
                    position: "relative",
                    borderRadius: 32,
                    backgroundColor: "#121229",
                    width: "266px",
                    height: "416px",
                }}
            >
                <Box
                    style={{
                        position: "absolute",
                        top: "2px",
                        left: "24px",
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: asset
                                ? TypeNfts.asset.backgroundColor
                                : TypeNfts[nft.categorie.toLowerCase()]
                                    .backgroundColor,
                            padding: "8px 16px",
                            borderRadius: 32,
                            display: "inline-flex",
                            alignItems: "center",
                            marginTop: "24px",
                            marginBottom: "32px",
                        }}
                    >
                        <Box
                            style={{
                                marginRight: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {asset
                                ? TypeNfts.asset.image
                                : TypeNfts[nft.categorie.toLowerCase()].image}
                        </Box>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .b1BoldSize2,
                                color: asset
                                    ? TypeNfts.asset.color
                                    : TypeNfts[nft.categorie.toLowerCase()].color,
                            }}
                        >
                            {asset ? "ASSET" : nft.subCategorie}
                        </Typography>
                    </Box>
                </Box>
                <Box height={"264px"}>
                    <img
                        alt={"cover"}
                        style={{
                            height: "264px",
                        }}
                        src={nft.image}
                    />
                </Box>

                <CardContent
                    style={{
                        background:
                            "linear-gradient(130.54deg, rgba(158, 158, 184, 0.125) 0%, rgba(24, 24, 46, 0.25) 102.22%), rgba(55, 55, 92, 0.5)",
                        padding: "24px",
                        position: "relative",
                    }}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                    >
                        <img
                            width={"40px"}
                            height={"40px"}
                            src={nft.artistPhoto}
                            style={{ borderRadius: 99, zIndex: 99 }}
                            alt="cover"
                        />
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            marginLeft={2}
                        >
                            <Box
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    top: 0,
                                    left: 0,
                                    backgroundImage: `url('${nft.photo}')`,
                                    backgroundPosition: "bottom",
                                    filter: "blur(56px)",
                                }}
                            ></Box>
                            <Typography
                                style={{
                                    ...Fonts[isMobile ? "mobile" : "desktop"]
                                        .h3BoldSize1,
                                    color: "#F0F0FA",
                                    zIndex: 99,
                                }}
                            >
                                {nft.name}
                            </Typography>
                            {nft.artistName &&
                                <Box display={"flex"} alignItems={"center"} gap={"2px"}>
                                    <Typography
                                        style={{
                                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                                .l1RegularSize1,
                                            color: "#F0F0FA",
                                            zIndex: 99,
                                        }}
                                    >
                                        {nft.artistName}
                                    </Typography>
                                    <Verified size={12} />
                                </Box>}
                        </Box>
                    </Box>
                    {!nft.redeemed && asset === false && (
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            marginTop={"16px"}
                        >
                            <Button
                                onClick={async () => {
                                    setOpenMint(true)
                                    if (!accountAddress || accountAddress === "") {
                                        await loginPera()
                                    }

                                    const assetId = await algo.mint(pera, accountAddress, nft, setProgress)
                                    await algo.transfer(pera, accountAddress, assetId, setProgress)

                                    const response = await axios.post(
                                        `${Dev.api}nft/payment/redeeem`,
                                        {
                                            id: nft.buyedId,
                                            redeemed: true,
                                        },
                                        {
                                            headers: {
                                                "Content-Type": "application/json;charset=utf-8"
                                            },
                                            crossDomain: true,
                                        }
                                    );

                                    setOpenMint(false)
                                    navigate("/payment-approved");
                                }}
                                style={{
                                    ...Fonts[
                                        isMobile ? "mobile" : "desktop"
                                    ].b1BoldSize1,
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "40px",
                                    padding: "8px 24px",
                                    color: "#F0F0FA",
                                }}
                                startIcon={<Withdraw htmlColor="#F0F0FA" />}
                                variant="contained"
                            >
                                {t('profileNFTsHoldStatusRedeem')}
                            </Button>
                        </Box>

                    )}
                    {nft.redeemed && asset === false && (
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            marginTop={"16px"}
                        >
                            <Link
                                aria-label="nfts"
                                to={`/nft/${nft.id}`}
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <Button
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].b1BoldSize1,
                                        background:
                                            "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                        borderRadius: "40px",
                                        padding: "8px 24px",
                                        color: "#F0F0FA",
                                    }}
                                    startIcon={<VisibilityOutlined />}
                                    variant="contained"
                                >
                                    {t('profileNFTsHoldStatusView')}
                                </Button>
                            </Link>
                        </Box>
                    )}
                    {asset && <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        marginTop={"16px"}
                    >
                        <a
                            aria-label="nfts"
                            target="_blank"
                            href={`https://algoexplorer.io/asset/${nft.assetId}`}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Button
                                style={{
                                    ...Fonts[
                                        isMobile ? "mobile" : "desktop"
                                    ].b1BoldSize1,
                                    background:
                                        "linear-gradient(130.54deg, #8930F0 0%, #5E47D6 102.22%), #F0F0FA",
                                    borderRadius: "40px",
                                    padding: "8px 24px",
                                    color: "#F0F0FA",
                                }}
                                startIcon={<VisibilityOutlined htmlColor="#F0F0FA" />}
                                variant="contained"
                            >
                                {t('profileNFTsHoldStatusView')}
                            </Button>
                        </a> </Box>}
                </CardContent>
            </Card>
        </Grid>
    );
}
