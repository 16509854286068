import About from "./About";
import AnotherProfile from "./AnotherProfile";
import App from "./App";
import Home from "./Home";
import Item from "./Item";
import Marketplace from "./Marketplace";
import PaymentApproved from "./PaymentApproved";
import Profile from "./Profile";
import PromoVideo from "./PromoVideo";
import ResetPassword from "./ResetPassword";
import SentToApproval from "./SentToApproval";
import Shop from "./Shop";
import TutorialWallet from "./TutorialWallet";
import TutorialNft from "./TutorialNft";

import BaseadosDigitais from "./BaseadosDigitais";
import PlantaRaiz from "./PlantaRaiz";
import BaseadosDigitaisCategories from "./BaseadosDigitaisCategories";

const routes = [
    { path: "/", component: <Home /> },
    { path: "/marketplace", component: <Marketplace /> },
    { path: "/about", component: <About /> },
    { path: "/app", component: <App /> },
    { path: "/profile", component: <Profile /> },
    { path: "/planta-e-raiz", component: <PlantaRaiz /> },
    { path: "/baseados-digitais", component: <BaseadosDigitais /> },
    { path: "/baseados-digitais-comum", component: <BaseadosDigitaisCategories category="comum" /> },
    { path: "/baseados-digitais-raro", component: <BaseadosDigitaisCategories category="raro" /> },
    { path: "/baseados-digitais-epico", component: <BaseadosDigitaisCategories category="epico" /> },
    { path: "/baseados-digitais-lendario", component: <BaseadosDigitaisCategories category="lendario" /> },
    { path: "/shop", component: <Shop /> },
    { path: "/sent-to-approval", component: <SentToApproval /> },
    { path: "/payment-approved", component: <PaymentApproved /> },
    { path: "/nft/:id", component: <Item /> },
    { path: "/user/:id", component: <AnotherProfile /> },
    { path: "/reset-password", component: <ResetPassword /> },
    { path: "/promo-video", component: <PromoVideo /> },
    { path: "/tutorial-create-wallet", component: <TutorialWallet /> },
    { path: "/tutorial-create-nft", component: <TutorialNft /> },
];

export default routes;
