const Camera = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.33594 14.8272V29.3239C3.33594 31.5367 5.15907 33.3337 7.40396 33.3337H32.6012C34.8461 33.3337 36.6693 31.5367 36.6693 29.3239V14.8272C36.6693 12.7218 34.9346 11.012 32.7985 11.012H27.3836L27.2543 10.4554C26.7305 8.2226 24.7373 6.66699 22.4108 6.66699H17.5876C15.2679 6.66699 13.2747 8.2226 12.7441 10.4554L12.6148 11.012H7.20669C5.07063 11.012 3.33594 12.7285 3.33594 14.8272ZM13.2815 12.6548C13.6693 12.6548 14.0026 12.3933 14.091 12.0178L14.37 10.8242C14.7237 9.34238 16.0434 8.30977 17.5876 8.30977H22.4108C23.955 8.30977 25.2747 9.34238 25.6285 10.8242L25.9074 12.0178C25.9958 12.3865 26.3291 12.6548 26.7169 12.6548H32.7917C34.0094 12.6548 34.9958 13.627 34.9958 14.8272V29.3239C34.9958 30.6315 33.921 31.6909 32.5944 31.6909H7.40396C6.07743 31.6909 5.0026 30.6315 5.0026 29.3239V14.8272C5.0026 13.627 5.989 12.6548 7.20669 12.6548H13.2815Z"
                fill="#CACAE6"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.4488 28.8888C23.4284 28.8888 26.671 25.6462 26.671 21.6666C26.671 17.687 23.4284 14.4443 19.4488 14.4443C15.4692 14.4443 12.2266 17.6799 12.2266 21.6666C12.2266 25.6532 15.4692 28.8888 19.4488 28.8888ZM19.4488 16.1639C22.4809 16.1639 24.9514 18.6345 24.9514 21.6666C24.9514 24.6986 22.4809 27.1692 19.4488 27.1692C16.4167 27.1692 13.9461 24.6986 13.9461 21.6666C13.9461 18.6345 16.4167 16.1639 19.4488 16.1639Z"
                fill="#CACAE6"
            />
        </svg>
    );
};

export default Camera;
