import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Verified } from "@mui/icons-material";
import Fonts from "../utils/Fonts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import Capa from "../assets/capa_colecao.png"
import Dev from "../utils/Dev";

function VerifiedArtists({ isMobile }) {
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");

    const [artist, setArtists] = useState([]);

    useEffect(() => {
        async function getNfts() {
            const response = await axios.get(
                `${Dev.api}nft/artists`)

            setArtists(response.data.Items)
        }

        //getNfts()
    }, []);

    return (
        <Box
            display={"flex"}
            justifyContent={"center"}
            paddingTop={isMobile ? "20px" : "0px"}
            paddingBottom={isMobile ? "20px" : "56px"}
        >
            <Grid
                container
                marginLeft={isMobile ? 0 : 8}
                gap={"32px"}
                maxWidth={"90vw"}
            >
                <Grid xs={12} md={12} style={isMobile ? {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                } : {}}>
                    <Box
                        style={{
                            width: "35px",
                            height: "6px",
                            background:
                                "linear-gradient(180deg, #8930F0 0%, #4F36CF 100%)",
                            borderRadius: "3px",
                            marginTop: "8px",
                            marginBottom: "16px",
                        }}
                    ></Box>
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h1BoldSize1,
                            color: "#EBEBFA",
                            whiteSpace: "break-spaces",
                        }}
                    >
                        {t('verifiedArtistsT2')}
                    </Typography>
                </Grid>
                <Grid xs={12} md={11} lg={11} xl={10}>
                    <Grid container gap={"24px"} style={isMobile ? {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    } : {}}>
                        <Grid
                                item
                                sm={12}
                                md={"auto"}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyItems={"center"}
                                alignItems={"center"}
                                onClick={() =>
                                    navigate(`/planta-e-raiz`, { state: { user: artist } })
                                }
                            >
                                <img
                                    width={"128px"}
                                    height={"128px"}
                                    src={Capa}
                                    style={{
                                        borderRadius: 9999,
                                        cursor: "pointer",
                                    }}
                                    alt="cover"
                                />
                                <Box
                                    display={"flex"}
                                    justifyItems={"center"}
                                    alignItems={"center"}
                                    marginTop={2}
                                >
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2RegularSize1,
                                            color: "#EBEBFA",
                                            marginRight: "8px",
                                            cursor: "pointer",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        {"Planta e Raiz"}
                                    </Typography>
                                    <Verified
                                        style={{
                                            color: "#EBEBFA",
                                            width: 16,
                                            height: 16,
                                        }}
                                    />
                                </Box>
                            </Grid>
                        {[
                            {
                                name: "MAIKAO",
                                email: "luizv945@gmail.com",
                                photo: "https://musii-dev-repository.s3.us-east-2.amazonaws.com/profile/luizv945@gmail.com.png?counter=968"
                            },
                            {
                                name: "PRIV4TE",
                                email: "emaildocrocco@gmail.com",
                                photo: "https://musii-dev-repository.s3.us-east-2.amazonaws.com/profile/emaildocrocco@gmail.com.png?counter=674"
                            },
                            {
                                name: "Nat Ventura",
                                email: "natalia.vieira.colombo@gmail.com",
                                photo: "https://musii-dev-repository.s3.us-east-2.amazonaws.com/profile/natalia.vieira.colombo@gmail.com.png?counter=846"
                            },
                            {
                                name: "Nicolas Candido",
                                email: "nicolascandido@musii.app",
                                photo: "https://musii-dev-repository.s3.us-east-2.amazonaws.com/profile/nicolascandido@musii.app.png?counter=789"
                            },
                            {
                                name: "Lumme//Prismo",
                                email: "lummeprismo@musii.app",
                                photo: "https://musii-dev-repository.s3.us-east-2.amazonaws.com/profile/lummeprismo@musii.app.png?counter=851"
                            }
                        ].map((artist, index) => (
                            <Grid
                                item
                                sm={12}
                                md={"auto"}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyItems={"center"}
                                alignItems={"center"}
                                onClick={() =>
                                    navigate(`/user/${artist?.name.replaceAll("/", "")}`, { state: { user: artist } })
                                }
                            >
                                <img
                                    width={"128px"}
                                    height={"128px"}
                                    src={artist.photo}
                                    style={{
                                        borderRadius: 9999,
                                        cursor: "pointer",
                                    }}
                                    alt="cover"
                                />
                                <Box
                                    display={"flex"}
                                    justifyItems={"center"}
                                    alignItems={"center"}
                                    marginTop={2}
                                >
                                    <Typography
                                        style={{
                                            ...Fonts[
                                                isMobile ? "mobile" : "desktop"
                                            ].h2RegularSize1,
                                            color: "#EBEBFA",
                                            marginRight: "8px",
                                            cursor: "pointer",
                                            whiteSpace: "nowrap"
                                        }}
                                    >
                                        {artist.name.length > 15
                                            ? `${artist.name.slice(0, 12)}...`
                                            : artist.name}
                                    </Typography>
                                    <Verified
                                        style={{
                                            color: "#EBEBFA",
                                            width: 16,
                                            height: 16,
                                        }}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {/*@TODO
                <Grid xs={12} md={12}>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Typography
                            style={{
                                ...Fonts[isMobile ? "mobile" : "desktop"]
                                    .h3BoldSize1,
                                marginTop: "16px",
                                color: "#EBEBFA",
                                whiteSpace: "break-spaces",
                                textAlign: "center",
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "#8C29D9E5",
                                borderRadius: 32,
                                padding: "12px 32px",
                                cursor: "pointer",
                            }}
                        >
                            {t('viewAll')}
                        </Typography>
                    </Box>
                </Grid>*/}
            </Grid>
        </Box>
    );
}

export default VerifiedArtists;
