const Backstep = () => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4279 24.3172C14.7086 25.1446 13.4548 25.2323 12.6275 24.5131C11.8001 23.7938 11.7124 22.54 12.4316 21.7126L17.3976 16L12.4316 10.2874C11.7124 9.45996 11.8001 8.20617 12.6275 7.48694C13.4548 6.7677 14.7086 6.85537 15.4279 7.68276L21.5052 14.674C21.8378 15.0565 21.9978 15.5301 21.992 16C21.9978 16.4698 21.8378 16.9435 21.5052 17.326L15.4279 24.3172Z"
                fill="#A359F7"
            />
        </svg>
    );
};

export default Backstep;
