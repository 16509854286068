const Withdraw = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_4081_246024)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.125 7.25272V1.875C6.125 0.839467 6.96447 0 8 0C9.03553 0 9.875 0.839466 9.875 1.875V7.25272H12.5127C13.0035 7.25272 13.1481 7.49431 12.8335 7.79435L8.5677 11.8632C8.25409 12.1623 7.74658 12.1632 7.43201 11.8632L3.16621 7.79435C2.85259 7.49522 2.99566 7.25272 3.487 7.25272H6.125ZM12.375 14.8077C12.375 15.3084 11.9691 15.7143 11.4684 15.7143H4.53159C4.0309 15.7143 3.625 15.3084 3.625 14.8077C3.625 14.307 4.0309 13.9011 4.53159 13.9011H11.4684C11.9691 13.9011 12.375 14.307 12.375 14.8077Z"
                    fill="#F0F0FA"
                />
            </g>
            <defs>
                <clipPath id="clip0_4081_246024">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Withdraw;
