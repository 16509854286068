import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
    Box, Typography,
} from "@mui/material";
import Footer from "../components/Footer";
import Fonts from "../utils/Fonts";

const PromoVideo = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [showModalConnect, setShowModalConnect] = useState(false);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"}>
            <Header
                showModalConnect={showModalConnect}
                setShowModalConnect={setShowModalConnect}
                isMobile={isMobile}
            />
            <Box style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "138px",
            }}>
                <Typography
                    style={{
                        ...Fonts[isMobile ? "mobile" : "desktop"]
                            .h2RegularSize1,

                        color: "#F0F0FA",
                        textAlign: "center",
                        padding: "12px 32px",
                        marginRight: "32px",
                        width: "70%"
                    }}
                >
                    Musii Sea Lions is an NFT collection (ARC-19) created with the aim of engaging the web3 community in the noble cause of helping independent artists, democratizing the music industry and letting more people know about Musii.
                </Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "16px" }} >
                <video
                    style={{ width: "70%" }}
                    controls={true}
                    autoPlay
                    src={
                        "https://nfts-site-assets.s3.us-east-2.amazonaws.com/video.mp4"
                    }
                />
            </Box>
            <Box style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "48px"
            }}>
                <a
                    aria-label="musii collection"
                    href="https://algoxnft.com/shuffle/1745"
                    target={"_blank"}
                    rel="noreferrer"
                    style={{
                        textDecoration: "none",
                    }}
                >
                    <Typography
                        style={{
                            ...Fonts[isMobile ? "mobile" : "desktop"]
                                .h3BoldSize1,
                            color: "#EBEBFA",
                            textAlign: "center",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "#8C29D9E5",
                            borderRadius: 32,
                            padding: "12px 32px",
                            cursor: "pointer",
                            marginRight: "32px",
                            background:
                                "linear-gradient(130.54deg, #8C29D9 0%, #5E47D6 102.22%), rgba(235, 235, 250, 0.25)",
                        }}
                    >
                        COLLECT ON SHUFFLE
                    </Typography>
                </a>
            </Box>
            <Box style={{ marginTop: 128 }} />
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default PromoVideo;
