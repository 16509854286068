import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Step1 from "../components/shop/Step1";
import Step2 from "../components/shop/Step2";
import { useLocation } from "react-router-dom";

const Shop = () => {
    const location = useLocation();

    const [showModalConnect, setShowModalConnect] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [step, setStep] = useState(0);
    const [nft, ] = useState(location?.state?.nft);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });
    }, [step]);

    const isMobile = width <= 768;

    return (
        <Box backgroundColor={"#121229"} marginTop={"120px"}>
            <Header showModalConnect={showModalConnect} setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
            {step === 0 && <Step1 setStep={setStep} />}
            {step === 1 && <Step2 setStep={setStep} isMobile={isMobile} nft={nft} />}
            <Footer setShowModalConnect={setShowModalConnect} isMobile={isMobile} />
        </Box>
    );
};

export default Shop;
