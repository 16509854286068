const Password = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.07146 10.7039C7.20294 10.7064 6.5 11.3987 6.5 12.2641V17.4397C6.5 18.3016 7.20506 19 8.07613 19H15.9239C16.7945 19 17.5 18.3067 17.5 17.4397V12.2641C17.5 11.4039 16.7975 10.7059 15.9285 10.7039V8.88848C15.9285 6.7407 14.1653 5 11.9999 5C9.83024 5 8.07122 6.7407 8.07122 8.88848C8.07122 11.0363 8.07146 10.7039 8.07146 10.7039ZM9.64293 10.7039V8.88943C9.64293 7.59725 10.6984 6.55551 12.0001 6.55551C13.2991 6.55551 14.3573 7.60036 14.3573 8.88943V10.7039H9.64293Z" fill="#ABABCC" />
        </svg>

    );
};

export default Password;
