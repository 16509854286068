import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { PeraProvider } from "./hooks/usePera";
import { AuthProvider } from "./hooks/useUser";
import { DeflyProvider } from "./hooks/useDefly";

import { initReactI18next, I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_en from "./translations/en";
import common_pt from "./translations/pt";
import common_es from "./translations/es";

i18next
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    fallbackLng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en
      },
      es: {
        common: common_es
      },
      pt: {
        common: common_pt
      }
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <DeflyProvider>
      <PeraProvider>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </PeraProvider>
    </DeflyProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
