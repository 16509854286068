const EditProfile = ({ selected }) => {
    return (
        selected ?
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0007 3C9.68114 3 7.80078 4.88052 7.80078 7.20012C7.80078 9.51972 9.68114 11.4 12.0007 11.4C14.3204 11.4 16.2008 9.51972 16.2008 7.20012C16.2008 4.88052 14.3204 3 12.0007 3ZM12.0012 13.0165C8.24444 13.0165 5.15666 14.4702 4.80078 17.9025C6.67299 19.6696 9.20696 20.7538 11.9966 20.7538C14.7905 20.7538 17.3278 19.6663 19.2008 17.8944C18.8408 14.4679 15.7551 13.0165 12.0012 13.0165Z" fill="url(#paint0_linear_1265_94705)" />
                <defs>
                    <linearGradient id="paint0_linear_1265_94705" x1="4.80078" y1="3" x2="23.2369" y2="15.7892" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#8930F0" />
                        <stop offset="1" stop-color="#5E47D6" />
                    </linearGradient>
                </defs>
            </svg>
            :

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1263_94518" fill="white">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0007 3C9.68114 3 7.80078 4.88052 7.80078 7.20012C7.80078 9.51972 9.68114 11.4 12.0007 11.4C14.3204 11.4 16.2008 9.51972 16.2008 7.20012C16.2008 4.88052 14.3204 3 12.0007 3ZM12.0012 13.0165C8.24444 13.0165 5.15666 14.4702 4.80078 17.9025C6.67299 19.6696 9.20696 20.7538 11.9966 20.7538C14.7905 20.7538 17.3278 19.6663 19.2008 17.8944C18.8408 14.4679 15.7551 13.0165 12.0012 13.0165Z" />
                </mask>
                <path d="M4.80078 17.9025L2.81145 17.6963L2.70956 18.6789L3.42799 19.357L4.80078 17.9025ZM19.2008 17.8944L20.5753 19.3473L21.2931 18.6682L21.1898 17.6854L19.2008 17.8944ZM12.0007 1C8.57645 1 5.80078 3.77608 5.80078 7.20012H9.80078C9.80078 5.98497 10.7858 5 12.0007 5V1ZM5.80078 7.20012C5.80078 10.6243 8.57659 13.4 12.0007 13.4V9.4C10.7857 9.4 9.80078 8.41513 9.80078 7.20012H5.80078ZM12.0007 13.4C15.4249 13.4 18.2008 10.6244 18.2008 7.20012H14.2008C14.2008 8.41505 13.216 9.4 12.0007 9.4V13.4ZM18.2008 7.20012C18.2008 3.776 15.425 1 12.0007 1V5C13.2158 5 14.2008 5.98504 14.2008 7.20012H18.2008ZM12.0012 11.0165C9.90116 11.0165 7.78432 11.4173 6.07643 12.4697C4.28929 13.571 3.0567 15.3309 2.81145 17.6963L6.79012 18.1088C6.90074 17.0419 7.38998 16.3587 8.17488 15.8751C9.03904 15.3426 10.3444 15.0165 12.0012 15.0165V11.0165ZM3.42799 19.357C5.65858 21.4624 8.67914 22.7538 11.9966 22.7538V18.7538C9.73477 18.7538 7.68739 17.8769 6.17357 16.4481L3.42799 19.357ZM11.9966 22.7538C15.3191 22.7538 18.3437 21.4584 20.5753 19.3473L17.8263 16.4415C16.3119 17.8743 14.2619 18.7538 11.9966 18.7538V22.7538ZM21.1898 17.6854C20.9416 15.3231 19.708 13.5663 17.9219 12.4672C16.2147 11.4167 14.0996 11.0165 12.0012 11.0165V15.0165C13.6566 15.0165 14.9613 15.342 15.8255 15.8738C16.6107 16.357 17.0999 17.0392 17.2117 18.1034L21.1898 17.6854Z" fill="#7272A3" mask="url(#path-1-inside-1_1263_94518)" />
            </svg>
    );
};

export default EditProfile;
