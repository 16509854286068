const Instagram = ({size = 48}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM19.4647 13.0662C20.638 13.0127 21.0126 13 24 13C26.9874 13 27.362 13.0127 28.5353 13.0662C29.7061 13.1196 30.5057 13.3056 31.2054 13.5775C31.9288 13.8586 32.5422 14.2347 33.1538 14.8462C33.7653 15.4577 34.1414 16.0712 34.4225 16.7946C34.6944 17.4943 34.8804 18.2939 34.9338 19.4647C34.9873 20.638 35 21.0126 35 24C35 26.9874 34.9873 27.362 34.9338 28.5353C34.8804 29.7061 34.6944 30.5057 34.4225 31.2054C34.1414 31.9288 33.7653 32.5422 33.1538 33.1538C32.5422 33.7653 31.9288 34.1414 31.2054 34.4225C30.5057 34.6944 29.7061 34.8804 28.5353 34.9338C27.362 34.9873 26.9874 35 24 35C21.0126 35 20.638 34.9873 19.4647 34.9338C18.2939 34.8804 17.4943 34.6944 16.7946 34.4225C16.0712 34.1414 15.4578 33.7653 14.8463 33.1538C14.2347 32.5422 13.8586 31.9288 13.5775 31.2054C13.3055 30.5057 13.1196 29.7061 13.0662 28.5353C13.0126 27.362 13 26.9874 13 24C13 21.0126 13.0126 20.638 13.0662 19.4647C13.1196 18.2939 13.3055 17.4943 13.5775 16.7946C13.8586 16.0712 14.2347 15.4577 14.8463 14.8462C15.4578 14.2347 16.0712 13.8586 16.7946 13.5775C17.4943 13.3056 18.2939 13.1196 19.4647 13.0662Z"
                fill="#EBEBFA"
            />
            <path
                d="M24.0002 27.6671C21.9751 27.6671 20.3335 26.0255 20.3335 24.0005C20.3335 21.9754 21.9751 20.3338 24.0002 20.3338C26.0253 20.3338 27.6669 21.9754 27.6669 24.0005C27.6669 26.0255 26.0253 27.6671 24.0002 27.6671ZM24.0002 18.3518C20.8805 18.3518 18.3516 20.8808 18.3516 24.0005C18.3516 27.1201 20.8805 29.6491 24.0002 29.6491C27.1199 29.6491 29.6489 27.1201 29.6489 24.0005C29.6489 20.8808 27.1199 18.3518 24.0002 18.3518M31.1921 18.1286C31.1921 18.8577 30.601 19.4486 29.872 19.4486C29.1431 19.4486 28.552 18.8577 28.552 18.1286C28.552 17.3996 29.1431 16.8086 29.872 16.8086C30.601 16.8086 31.1921 17.3996 31.1921 18.1286"
                fill="#EBEBFA"
            />
        </svg>
    );
};

export default Instagram;
