const Twitter = ({size = 48}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM32.7608 20.0687C32.7608 19.8717 32.7608 19.6764 32.7474 19.4811C33.6305 18.8376 34.3937 18.0412 34.9997 17.1268C34.1766 17.4949 33.3025 17.7361 32.4078 17.8423C33.3498 17.2744 34.055 16.3808 34.392 15.3287C33.5053 15.8579 32.5365 16.2314 31.5257 16.4321C29.8168 14.6025 26.9576 14.5143 25.1397 16.235C23.9671 17.3446 23.4702 18.9987 23.8339 20.5772C20.2043 20.3945 16.8232 18.6684 14.5316 15.8291C13.334 17.9062 13.9453 20.5628 15.9286 21.8974C15.21 21.8758 14.5075 21.6805 13.8801 21.3287V21.3863C13.8801 23.5497 15.395 25.4126 17.5007 25.841C16.8366 26.0237 16.1395 26.0507 15.4629 25.9193C16.0546 27.7705 17.7483 29.0385 19.6797 29.0754C18.0808 30.3398 16.1064 31.0264 14.074 31.0246C13.7147 31.0237 13.3563 31.0022 12.9997 30.959C15.0643 32.2927 17.4659 33 19.9183 32.9964V33C28.2205 33 32.7608 26.0741 32.7608 20.0687Z"
                fill="#EBEBFA"
            />
        </svg>
    );
};

export default Twitter;
