const ProfileYoutube = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon=youtube">
                <g id="Group">
                    <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M31.7207 10.631C33.0117 10.9782 34.0282 12.0014 34.3732 13.3005L34.3731 13.3006C35 15.6553 35 20.5684 35 20.5684C35 20.5684 35 25.4812 34.3731 27.8362C34.028 29.1353 33.0116 30.1583 31.7206 30.5057C29.3812 31.1365 19.9999 31.1365 19.9999 31.1365C19.9999 31.1365 10.6186 31.1365 8.27913 30.5057C6.98832 30.1581 5.97159 29.1353 5.62681 27.836C5 25.4811 5 20.5683 5 20.5683C5 20.5683 5 15.6552 5.62681 13.3005C5.97159 12.0014 6.98832 10.9782 8.27926 10.631C10.6188 10 20 10 20 10C20 10 29.3814 10 31.7207 10.631ZM24.7726 20.5684L16.9317 25.0288V16.1076L24.7726 20.5684Z" fill="#ABABCC" />
                </g>
            </g>
        </svg>
    );
};

export default ProfileYoutube;
