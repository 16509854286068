import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import NftCardProfile from "../commons/NftCardProfile";
import Fonts from "../../utils/Fonts";
import Empty from "../../assets/empty";
import algosdk from "algosdk";
import { useUser } from "../../hooks/useUser";

import axios from "axios";
import { useTranslation } from "react-i18next";
import Dev from "../../utils/Dev";

const Collections = ({ setSelected, another, anotherUser }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [assets, setAssets] = useState([]);
    const [nfts, setNfts] = useState([]);

    const [t, i18n] = useTranslation("common");

    const { user } = useUser();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        async function getAssets() {

            if (user) {
                try {
                const response = await axios.get(
                    `${Dev.api}nft/user/${another ? anotherUser.email : user.email}/nfts`)

                setNfts(response.data.Items ? response.data.Items : [])
                } catch {

                }
            }

            if (user && user.wallet) {
                const node = Dev.node

                const algod = new algosdk.Algodv2(
                    "",
                    node,
                    443
                );

                const asas = [];
                const account = await algod
                    .accountInformation(user.wallet)
                    .do();

                await Promise.all(
                    account.assets.map(async (item) => {
                        if (item.amount >= 1) {
                            const asa = await algod
                                .getAssetByID(item["asset-id"])
                                .do();
                            if (asa.params.url) {
                                try {
                                    const meta = await axios.get(asa.params.url)
                                    console.log(meta)
                                    if (meta?.data?.image) {
                                        asas.push({
                                            assetId: item["asset-id"],
                                            image: meta.data.image,
                                            name: asa.params.name,
                                            artistPhoto: meta.data.image,
                                            nft: `${asa.params.creator.slice(
                                                0,
                                                5
                                            )}...${asa.params.creator.slice(
                                                asa.params.creator.length - 5,
                                                asa.params.creator.lenght
                                            )}`,
                                        });
                                    }
                                } catch (e) {
                                    console.log(e)
                                }
                            }
                        }
                    })
                );

                setAssets(asas);
            }
        }

        getAssets();
    }, [user]);

    useEffect(() => {

        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Box display={"flex"} justifyContent={"center"} >
            <Grid
                container
                gap={"32px"}
                display={"flex"}
                justifyContent={"center"}
            >
                <Grid xs={12} md={11} lg={11} xl={10}>
                    <Grid container padding={"0px 16px 16px 16px"} spacing={"28px"}>
                        {nfts &&
                            nfts.length !== 0 &&
                            nfts.map((nft, index) => (
                                <NftCardProfile
                                    key={index}
                                    index={index}
                                    nft={nft}
                                    redeem={nft.redeem}
                                    asset={false}
                                />
                            ))}
                        {assets &&
                            assets.length !== 0 &&
                            assets.map((nft, index) => (
                                <NftCardProfile
                                    key={index}
                                    index={index}
                                    nft={nft}
                                    asset={true}
                                />
                            ))}

                        {((!assets || assets.length === 0) && (!nfts || nfts.length === 0)) && (
                            <Grid
                                item
                                sm={12}
                                md={12}
                                xl={12}
                                style={{ textAlign: "center" }}
                            >
                                <Empty />
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h2BoldSize1,
                                        color: "#F0F0FA",
                                        marginTop: "20px",
                                    }}
                                >
                                    {t('profileNFTsHoldEmptyWallet')}
                                </Typography>
                                <Typography
                                    style={{
                                        ...Fonts[
                                            isMobile ? "mobile" : "desktop"
                                        ].h3RegularSize1,
                                        color: "#F0F0FA",
                                    }}
                                >
                                    {t('profileNFTsHoldEmptyWalletMessage')}
                                </Typography>
                                {!another &&
                                    <Box display={"flex"} justifyContent="center" alignItems={"center"} gap={"24px"}>
                                        {(!user || !user.wallet) &&
                                            <Button
                                                onClick={() => setSelected(4)}
                                                style={{
                                                    background:
                                                        "linear-gradient(130.54deg, rgba(137, 48, 240, 0.8) 0%, rgba(94, 71, 214, 0.8) 102.22%), rgba(240, 240, 250, 0.05)",
                                                    borderRadius: "40px",
                                                    padding: "12px 32px",
                                                    maxWidth: "20%",
                                                    gap: "8px",
                                                    flex: "none",
                                                    order: 1,
                                                    flexGrow: 0,
                                                    marginTop: "56px",
                                                }}
                                                fullWidth
                                                variant="contained"
                                            >
                                                <Typography
                                                    style={{
                                                        ...Fonts[
                                                            isMobile ? "mobile" : "desktop"
                                                        ].h3BoldSize1,
                                                        color: "#F0F0FA",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {t('profileNFTsHoldCTAConnectWallet')}
                                                </Typography>
                                            </Button>
                                        }
                                        <Button
                                            style={{
                                                background:
                                                    "linear-gradient(130.54deg, rgba(137, 48, 240, 0.8) 0%, rgba(94, 71, 214, 0.8) 102.22%), rgba(240, 240, 250, 0.05)",
                                                borderRadius: "40px",
                                                padding: "12px 32px",
                                                maxWidth: user && user.wallet ? "50%" : "20%",
                                                gap: "8px",
                                                flex: "none",
                                                order: 1,
                                                flexGrow: 0,
                                                marginTop: "56px",
                                            }}
                                            fullWidth
                                            variant="contained"
                                        >
                                            <Typography
                                                style={{
                                                    ...Fonts[
                                                        isMobile ? "mobile" : "desktop"
                                                    ].h3BoldSize1,
                                                    color: "#F0F0FA",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {t('heroCtaFans')}
                                            </Typography>
                                        </Button>
                                    </Box>}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Collections;
